import React, { useState, useEffect, useContext } from 'react';
import { Link, withRouter } from 'react-router-dom';
import withAuthConsumer from '../../../api/auth/AuthContext';
import withGlobalConsumer, { GlobalContext } from '../../../global/GlobalProvider';
import { ConfigSettingsContainer } from './ConfigSettingsContainer';
import { Row, Col, Button, Input } from 'reactstrap';
import apiClient from '../../../api/ApiClient';
import KotoDate from '../../common/KotoDate';
import KotoTable from '../../common/KotoTable';
import { notificationService } from '../../notifications/NotificationService';
import { handleDefaultError } from '../../../utils/response-handler';

export async function handleDownload(response, filename) {
  const a = document.createElement('a');
  a.href = window.URL.createObjectURL(response.data);
  //firefox does not initiate a download if not for the following two lines
  document.body.appendChild(a);
  a.setAttribute('type', 'hidden');
  a.download = filename;
  a.click();
}

const DriverReports = (props) => {
  const [page, setPage] = useState(0);
  const [totalCollections, setTotalCollections] = useState(0);
  const [collections, setCollections] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [locations, setLocations] = useState([]);
  const { drivers } = useContext(GlobalContext);
  const [selectedDriver, setSelectedDriver] = useState(null);

  function parseQueryParams() {
    const limit = 'limit=20';
    const offset = page ? `offset=${20 * page}` : 'offset=0';
    const location = selectedLocation ? `location=${selectedLocation}` : '';
    const driver = selectedDriver ? `driver=${selectedDriver}` : '';

    return `${limit}&${offset}&${location}&${driver}`;
  }

  async function fetchData() {
    const url = `/order_collections?${parseQueryParams()}`;
    try {
      const response = await apiClient.get(url);
      await setTotalCollections(response?.data?.count);
      await setCollections(response?.data?.results);
    } catch (e) {
      notificationService.error(e);
    }
  }

  async function fetchLocations() {
    try {
      const response = await apiClient.get('/location/options');
      setLocations(
        response.data.map((item) => {
          return { value: item.id, label: item.short_name };
        })
      );
    } catch (e) {
      notificationService.error(e);
    }
  }

  function handlePageChange(change) {
    const maxPage = Math.floor(totalCollections / 20);
    let newPage = page + change;
    if (newPage < 0) {
      newPage = 0;
    }
    if (newPage > maxPage) {
      newPage = maxPage;
    }
    setPage(newPage);
  }

  async function getReport(id) {
    try {
      const response = await apiClient.get(`/order_collections/${id}/report`, {
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/json'
        }
      });
      await handleDownload(response, `report_collection-${id}-.pdf`);
    } catch (e) {
      notificationService.error(e);
    }
  }

  useEffect(() => {
    fetchData();
  }, [page, selectedLocation, selectedDriver]);

  useEffect(() => {
    fetchLocations();
  }, []);

  const collectionColumns = [
    {
      Header: 'id',
      accessor: 'id',
      width: '10%'
    },
    {
      Header: 'Datum',
      Cell: (row) => {
        return <KotoDate format={'LL'} date={row.original.created} />;
      },
      width: '20%'
    },
    {
      Header: 'Voznik',
      Cell: (row) => `${row.original?.driver.first_name} ${row.original?.driver.last_name}`,
      width: '20%'
    },
    {
      Header: '#naročil',
      Cell: (row) => row.original?.orders.length,
      width: '7%'
    },
    {
      Header: '#kosov embalaže',
      Cell: (row) => row.original?.orders?.map((item) => item.collected_quantity).reduce((a, b) => a + b, 0),
      width: '7%'
    },
    {
      Header: '#kg',
      Cell: (row) => row.original?.orders?.map((item) => item.collected_weight).reduce((a, b) => a + b, 0),
      width: '7%'
    },
    {
      Header: '',
      Cell: (row) => (
        <Button color="primary" onClick={() => getReport(row.original?.id)}>
          Prenesi PDF
        </Button>
      ),
      width: '9%'
    }
  ];

  return (
    <ConfigSettingsContainer>
      <Row>
        <Col md={8}>
          <h4 className="p-2 m-2">Voznikova poročila</h4>
        </Col>
      </Row>
      <Row className="m-2">
        <Col md={3}>
          <h6 className="text-nowrap">Lokacija</h6>
          <Input
            type="select"
            name="select"
            className="small w-100"
            id="selectLocation"
            value={selectedLocation}
            onChange={(e) => setSelectedLocation(e.target.value)}
          >
            <option key="" value="">
              Vse
            </option>
            {locations?.map((item) => (
              <option key={item.value} value={item.value}>
                {item.label}
              </option>
            ))}
          </Input>
        </Col>
        <Col md={3}>
          <h6 className="text-nowrap">Voznik</h6>
          <Input
            type="select"
            name="select"
            className="small w-100"
            id="selectDriver"
            value={selectedDriver}
            onChange={(e) => setSelectedDriver(e.target.value)}
          >
            <option key="" value="">
              Vsi
            </option>
            {drivers?.map((item) => (
              <option key={item.value} value={item.value}>
                {item.label}
              </option>
            ))}
          </Input>
        </Col>
      </Row>
      <div className="m-4">
        <KotoTable
          columns={collectionColumns}
          data={collections}
          SubComponent={(row) => {
            return (
              <table className="table" style={{ backgroundColor: '#f3f5a2' }}>
                <colgroup>
                  <col span="1" style={{ width: '10%' }} />
                  <col span="1" style={{ width: '15%' }} />
                  <col span="1" style={{ width: '35%' }} />
                  <col span="1" style={{ width: '20%' }} />
                  <col span="1" style={{ width: '20%' }} />
                </colgroup>
                <tbody>
                  {row.original?.orders.map((order) => {
                    return (
                      <tr>
                        <td>
                          <Link to={`/orders/${order.id}`}>{order.id}</Link>
                        </td>
                        <td>
                          <KotoDate format={'LL'} date={order.order_completed_time} />
                        </td>
                        <td>
                          <Link to={`/locations/${order.location.id}`}>{order.location.short_name}</Link>
                        </td>
                        <td>Kilogramov: {order.collected_weight}</td>
                        <td>Kosov embalaže: {order.collected_quantity}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            );
          }}
        />
      </div>
      <Row>
        <Col className="mt-3" md={{ size: 4, offset: 4 }}>
          <Button color="primary" onClick={() => handlePageChange(-1)}>
            Prejšna
          </Button>
          <span className="ml-4 mr-4">Stran: {page + 1}</span>
          <Button color="primary" onClick={() => handlePageChange(+1)}>
            Naslednja
          </Button>
        </Col>
      </Row>
    </ConfigSettingsContainer>
  );
};

export default withGlobalConsumer(withAuthConsumer(DriverReports));
