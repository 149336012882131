import React, { Component, Fragment } from 'react';

import withI18n from '../../../i18n';
import withAuthConsumer from '../../../api/auth/AuthContext';
import withGlobalConsumer from '../../../global/GlobalProvider';
import apiClient from '../../../api/ApiClient';
import KotoContainer from '../KotoContainer';
import SingleTableHeader from '../../common/SingleTableHeader';
import KotoTwoRowTable from '../../common/KotoTwoRowTable';
import { Button, NavLink, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import KotoDate from '../../common/KotoDate';
import { handleDefaultError } from '../../../utils/response-handler';
import KotoModal from '../../common/KotoModal';
import CreateClient from './CreateClient';
import { KotoBadge } from '../../common/KotoBadge';
import { KotoSubTable } from '../../common/KotoSubTable';
import Badge from 'reactstrap/es/Badge';
import { FaEdit } from 'react-icons/fa';
import { UpdateClientConversions } from './UpdateClientConversions';

const columns = (translate) => {
  return [
    {
      Header: 'Id',
      width: 50,
      Cell: (row) => (
        <NavLink className="p-0" to={`${row.id}`} tag={Link}>
          {row.id}
        </NavLink>
      )
    },

    {
      Header: translate('short_name'),
      width: 250,
      accessor: 'short_name'
    },

    {
      Header: translate('address_description'),
      accessor: 'description'
    },
    {
      Header: translate('largo_client_code'),
      width: 300,
      accessor: 'largo_client_code'
    },
    {
      Header: translate('litres_to_kilos_conversion_rate'),
      accessor: 'litres_to_kilos_conversion_rate'
    },
    {
      Header: 'Odštevam težo embalaže (se nanaša na vse produkte razen OJO)',
      Cell: (row) => (
        <KotoBadge
          color={row.subtract_barrel_weight ? 'success' : 'danger'}
          text={row.subtract_barrel_weight ? translate('boolean_true') : translate('boolean_false')}
        />
      )
    },
    {
      Header: 'ZOL',
      accessor: 'zol_turnover_type'
    },

    {
      Header: translate('client_added'),
      Cell: (row) => row.added_timestamp && <KotoDate format={'LLLL'} date={row.added_timestamp} />
    },

    {
      Header: translate('client_active'),
      Cell: (row) => (
        <KotoBadge
          color={row.active ? 'success' : 'danger'}
          text={row.active ? translate('boolean_true') : translate('boolean_false')}
        />
      )
    },
    {
      Header: translate('page_locations_title'),
      Cell: (row) =>
        row.locations
          .map((location) => (
            <NavLink className="p-0" to={`/locations/${location.id}`} tag={Link}>
              {location.short_name}
              <span className="text-black-50">, &nbsp;</span>
            </NavLink>
          ))
          .reduce(
            (acc, x) =>
              acc === null ? (
                x
              ) : (
                <Row noGutters>
                  {acc} {x}{' '}
                </Row>
              ),
            null
          )
    }
  ];
};

class SingleClient extends Component {
  state = {
    client: null,
    locations: null,
    editOpen: false,
    conversionsOpen: false
  };

  async componentWillMount() {
    await this.fetchClient();
  }

  async toggleEditOpen() {
    await this.setState({ editOpen: !this.state.editOpen });
  }
  async toggleConversionsOpen() {
    await this.setState({ conversionsOpen: !this.state.conversionsOpen });
  }

  async fetchClient() {
    this.props.startLoading();
    try {
      const res = await apiClient.get(`/client/${this.props.match.params.id}`);
      const client = res.data;
      await this.setState({
        client
      });
    } catch (e) {
      await handleDefaultError(e, this.props);
    }
    this.props.stopLoading();
  }

  render() {
    return (
      <div>
        <KotoContainer name={this.props.translate('page_clients_title')}>
          <div>
            {this.state.client && (
              <Fragment>
                <SingleTableHeader
                  title={this.props.translate('client_single') + ' ' + this.state.client.short_name}
                  onEdit={this.toggleEditOpen.bind(this)}
                />

                <KotoTwoRowTable
                  title={this.props.translate('client_data')}
                  dataObject={this.state.client}
                  columns={columns(this.props.translate)}
                  onEdit={this.toggleEditOpen.bind(this)}
                  subTable={false}
                />
                <Row noGutters className="justify-content-between pb-2 align-items-center">
                  <h4 className="text-secondary ">Podatki pretvorb</h4>
                  <Button onClick={async () => await this.toggleConversionsOpen()} color={'primary'}>
                    <FaEdit size={'1.5em'} className={'mb-1 mr-2'} />
                    Popravi pretvorbe
                  </Button>
                </Row>

                <KotoSubTable
                  bordered
                  title={'Podatki pretvorb'}
                  data={this.state.client.conversions ?? []}
                  inverse={false}
                  columns={[
                    {
                      Header: 'Produkt',
                      Cell: (row) => {
                        // console.log("Row: ",row)
                        return (
                          <Badge style={{ backgroundColor: row?.product_type?.color }}>
                            {row?.product_type?.price_conversion_name}
                          </Badge>
                        );
                      },
                      width: '20%'
                    },
                    {
                      Header: 'EUR za enoto',
                      Cell: (row) => <div style={{ color: row?.product_type?.color }}>{row?.ratio}</div>
                    }
                  ]}
                />

                <KotoModal
                  title={
                    this.props.translate('client_update') + ' ' + (this.state.client && this.state.client.short_name)
                  }
                  isOpen={this.state.editOpen}
                  toggle={this.toggleEditOpen.bind(this)}
                >
                  {this.state.client && (
                    <CreateClient
                      toggle={this.toggleEditOpen.bind(this)}
                      refresh={this.fetchClient.bind(this)}
                      client={this.state.client}
                      clientId={this.state.client.id}
                    />
                  )}
                </KotoModal>
                <KotoModal
                  title={'Popravljane pretvorb stranki' + ' ' + (this.state.client && this.state.client.short_name)}
                  isOpen={this.state.conversionsOpen}
                  toggle={this.toggleConversionsOpen.bind(this)}
                >
                  {this.state.client && (
                    <UpdateClientConversions
                      toggle={this.toggleConversionsOpen.bind(this)}
                      refresh={this.fetchClient.bind(this)}
                      client={this.state.client}
                      clientId={this.state.client.id}
                    />
                  )}
                </KotoModal>
              </Fragment>
            )}
          </div>
        </KotoContainer>
      </div>
    );
  }
}

export default withI18n(withAuthConsumer(withGlobalConsumer(SingleClient)));
