import React from 'react';
import withI18n from '../../../i18n';
import withAuthConsumer from '../../../api/auth/AuthContext';
import withGlobalConsumer from '../../../global/GlobalProvider';
import {Field, FieldArray, Form, Formik} from 'formik';
import { notificationService } from '../../notifications/NotificationService';
import { FormEffect } from '../../common/FormEffect';
import {Col, Input, Label, Row} from 'reactstrap';
import FormGroup from 'reactstrap/es/FormGroup';
import { FormInput, FormSelect } from '../../common/FormInput';
import FormConfirmCancel from '../../common/FormConfirmCancel';
import moment from 'moment';
import apiClient from '../../../api/ApiClient';
import { handleDefaultError } from '../../../utils/response-handler';
import KotoDateTime from '../../common/KotoDateTime';
import * as Yup from 'yup';
import { getFirstGoodOrderDay } from '../../../utils/order-utils';
import { productTypes } from '../../common/statuses';
import { getCurrentProvider, providers } from '../../navbar/providers';
import { withRouter } from 'react-router-dom';
import InputGroup from "reactstrap/es/InputGroup";
import InputGroupAddon from "reactstrap/es/InputGroupAddon";
import InputGroupText from "reactstrap/es/InputGroupText";

let Datetime = require('react-datetime');

const orderSchema = (translate) => {
  const notBlank = translate('value_not_empty');
  const atLeastOneProductChosen = translate('order_at_least_one_product_chosen');
  const orderStartSameAsEnd = translate('order_start_same_as_end');

  return Yup.object().shape({
    location_id: Yup.string().required(notBlank),
    preferred_end_time: Yup.date().test('error,', 'Končni čas mora biti večji od začetnega', function (value) {
      const { preferred_start_time } = this.parent;
      return preferred_start_time.isBefore(value);
      // && preferred_start_time.isSame(value,"day") &&moment(value).diff(preferred_start_time,"minutes")>=60;
    })
  });
};


// This is create and update order
class CreateOrder extends React.Component {
  state = {
    selectedOption: { value: '', label: '' },
    query: '',
    selectLoading: false,
    locations: null,
    pools: null,
    startDate: moment(),
    notifiedAboutOptimization: false
  };

  async componentDidMount() {
    // if(this.props.order && this.props.order.id) {
    //     await this.setState({
    //         selectedOption:{value: this.props.client.id ,label:this.props.client.short_name}
    //     })
    // }

    // WIp One component for create and update
    if (this.props.order) {
      let selectedOption = { label: '', value: '' };
      if (this.props.order?.location_id) {
        selectedOption = this.props.locations.find((location) => location.value === this.props.order.location_id);
      }
      await this.setState({
        selectedOption
      });
    }
    await this.fetchData();
  }

  handleInputChange(query) {
    if (!query && !this.state.query) {
      return;
    }
    // eslint-disable-next-line eqeqeq
    if (query != this.state.query) {
      this.setState(
        {
          query
        },
        this.fetchData
      );
    }
  }

  handleSelectChange = async (selectedOption, formik) => {
    await formik.setFieldValue('location_id', selectedOption.value, true);
    await this.setState({ selectedOption });
  };

  handlePoolSelectChange = async (selectedOption, formik) => {
    await formik.setFieldValue('pool_id', selectedOption.value, true);
  };

  handleDateChange = async (date, label, formik) => {
    await formik.setFieldValue(label, date, true);
  };

  isStartDateValid = (current) => {
    if (!this.props.order) {
      const min = moment().subtract(1, 'days');
      return current.isAfter(min) && current.day() !== 0 && current.day() !== 6;
    } else {
      return true;
    }

    // const min = moment().subtract(1, "days");
    // return current.isAfter(min) && current.day() !== 0 && current.day() !== 6;
  };

  async fetchData() {
    if(this.props.order){
      try {
        await this.setState({ selectLoading: true });
        let clientFilter = '';
        let queryFilter = '';
        if (this.props.order.client_id && this.props.order.client_id) {
          let prefix = '&';
          if (queryFilter) {
            prefix = '';
          }
          if (this.props.order.client_id !== 1) {
            clientFilter = `${prefix}client=${this.props.order.client_id}`;
          }
        }
        if (this.state.query) {
          queryFilter = `search=${this.state.query}`;
        }
        const response = await apiClient.get(`/location?${queryFilter}${clientFilter}`);
        const locations = response.data.results.map((data) => ({ value: data.id, label: data.short_name }));
        await this.setState({ locations });

        const poolsResponse = await apiClient.get('/pools');
        const pools = poolsResponse.data.map((data) => ({ value: data.id, label: data.name }));
        await this.setState({ pools: pools });

        await this.setState({ selectLoading: false });
      } catch (e) {
        await handleDefaultError(e, this.props);
      }
    }
    else {
      try {
        await this.setState({ selectLoading: true });
        const response = await apiClient.get(`/location?search=${this.state.query}`);
        const locations = response.data.results.map((data) => ({
          value: data.id,
          label: data.short_name,
          provider: data.provider?.id
        }));
        const poolsResponse = await apiClient.get('/pools');
        const pools = poolsResponse.data.map((data) => ({ value: data.id, label: data.name }));
        await this.setState({ pools: pools });
        await this.setState({ locations });
        await this.setState({ selectLoading: false });
      } catch (e) {
        await handleDefaultError(e, this.props);
      }
    }
  }

  getSelectedProvider = () => {
    if (this.state.locations && this.state.selectedOption) {
      const selectedLocation = this.state.locations.find((loc) => this.state.selectedOption?.value == loc.value);
      const providerId = selectedLocation?.provider;

      return providers[providerId];
    }

    return null;
  };

  render() {

    let adminUser = false;
    if (this.props.authProfile) {
      const userType = this.props.authProfile.type;
      adminUser = userType === 100 || userType === 0;
    }

    return (
      <div>
        <h1>{this.props.title}</h1>

        <Formik
          initialValues={{
            location_id: this.props.order ? this.props.order.location_id : '',
            order_status_id: 10,
            preferred_start_time: this.props.order?.preferred_start_time
                ? moment(this.props.order.preferred_start_time)
                : getFirstGoodOrderDay(),
            preferred_end_time: this.props.order?.preferred_end_time
                ? moment(this.props.order.preferred_end_time)
                : getFirstGoodOrderDay().add(61, 'minutes'),
            pool_id: this.props.order && this.props.order.pool ? this.props.order.pool.id : undefined,
            order_ordered_barrels:
                this.props.order?.order_ordered_barrels ?
                    this.props.barrelTypes.map((c) => {
                      const spec_el = this.props.order.order_ordered_barrels.filter((el) => el.barrel_type.pk === c.id)[0]
                      if(spec_el){
                        return (
                            { barrel_type: c.id, number: spec_el?.number || 0, id: spec_el?.pk }
                        )
                      } else {
                        return (
                            { barrel_type: c.id, number: 0 }
                        )
                      }
                    }
                        )
                    : this.props.barrelTypes.map((c) => ({ barrel_type: c.id, number: 0 }))  // TODO ! add initial values
          }}
          validationSchema={orderSchema(this.props.translate)}
          onSubmit={async (values) => {
            let { preferred_start_time, preferred_end_time, pool_id, ...rest } = values;

            const isAfter13 = moment().isAfter(moment('00:59 pm', 'HH:mm a'));
            const endTimeIsTomorrow = moment().add('1', 'days').isSame(preferred_end_time, 'd');

            if (!this.state.notifiedAboutOptimization && endTimeIsTomorrow && isAfter13) {
              this.setState({ notifiedAboutOptimization: true });
              notificationService.info(
                'Če boste naredili naročilo po 13 uri za jutri, potem naročilo ne bo šlo v optimizator'
              );
              return;
            }

            preferred_start_time = preferred_start_time.toISOString();
            preferred_end_time = preferred_end_time.toISOString();

            let req = null;
            if (pool_id) {
              req = { preferred_start_time, preferred_end_time, pool_id, ...rest };
            } else {
              req = { preferred_start_time, preferred_end_time, ...rest };
            }
            try {
              if(this.props.order){
                await this.setState({ selectLoading: true });
                const response = await apiClient.patch(`/order/${this.props.order.id}`, req);

                notificationService.success('Succesfully updated order with id:' + response.data.id);
              } else {
                await this.setState({ selectLoading: true });
                const response = await apiClient.post(`/order`, req);

                notificationService.success('Succesfully created order with id:' + response.data.id);
                this.props.history.push(`/orders/${response.data.id}`);
              }
            } catch (e) {
              //console.log(e)
              await handleDefaultError(e, this.props);
              return;
            }

            await this.props.stopLoading();
            await this.props.refresh();
            await this.props.toggle();
            await this.props.stopLoading();
          }}
        >
          {(formik) => (
            <Form>
              <FormEffect
                formik={formik}
                onSubmissionError={(e) => {
                  return notificationService.error(this.props.translate('error_form'));
                }}
              />


              {(this.props.order?.location_short_name === 'Koto' || adminUser || !this.props.order ) && (
              <Row form>
                <Col md={6}>
                  <FormGroup>
                    <Row noGutters className="justify-content-between">
                      <Label for="location_id">{this.props.translate('locations_single')}</Label>
                      {this.getSelectedProvider() && (
                        <div className="font-italic  rounded align-items-center">
                          Ponudnik: {this.getSelectedProvider()}
                        </div>
                      )}
                    </Row>

                    {this.state.locations && (
                      <FormSelect
                        formik={formik}
                        name={'location_id'}
                        isDisabled={this.props.orderId}
                        isClearable={false}
                        isLoading={this.state.selectLoading}
                        defaultValue={this.state.selectedOption}
                        options={this.state.locations}
                        onInputChange={(e) => this.handleInputChange(e)}
                        onChange={(e) => this.handleSelectChange(e, formik)}
                      />
                    )}
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Row>
                      <Label className="ml-3" for="pool_id">
                        Bazen lokacij - <i>neobvezno</i>
                      </Label>
                    </Row>
                    {this.state.pools && (
                      <FormSelect
                        formik={formik}
                        name={'pool_id'}
                        isDisabled={this.props.orderId}
                        isClearable={false}
                        isLoading={this.state.selectLoading}
                        options={this.state.pools}
                        onInputChange={(e) => this.handleInputChange(e)}
                        onChange={(e) => this.handlePoolSelectChange(e, formik)}
                      />
                    )}
                  </FormGroup>
                </Col>
              </Row>
              )}

              <Row form>
                <Col lg={6} md={12}>
                  <FormGroup>
                    <Label for="preferred_start_time">{this.props.translate('order_preferred_start_time')}</Label>
                    <KotoDateTime
                      value={formik.values['preferred_start_time']}
                      formik={formik}
                      name={'preferred_start_time'}
                      isValidDate={this.isStartDateValid}
                      onChange={(e) => this.handleDateChange(e, 'preferred_start_time', formik)}
                    />
                  </FormGroup>
                </Col>
                <Col lg={6} md={12}>
                  <FormGroup>
                    <Label for="preferred_end_time">{this.props.translate('order_preferred_end_time')}</Label>
                    <KotoDateTime
                      value={formik.values['preferred_end_time']}
                      formik={formik}
                      name={'preferred_end_time'}
                      isValidDate={this.isStartDateValid}
                      onChange={(e) => this.handleDateChange(e, 'preferred_end_time', formik)}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <FieldArray
                  name="order_ordered_barrels"
                  render={(arrayHelpers) => (
                      <div>
                        <Row noGutters>
                          <Col>
                            <h6 className="m-0 p-0">Tip embalaže</h6>
                          </Col>
                          <Col>
                            <h6 className="m-0 p-0">Količina</h6>
                          </Col>
                        </Row>
                        <hr />

                        {formik.values.order_ordered_barrels && formik.values.order_ordered_barrels.length > 0 ? (
                            formik.values.order_ordered_barrels.map((barrelAmount, index) => {
                              const barrelType = this.props.barrelTypes?.find((pt) => pt.id === barrelAmount.barrel_type);
                              return (
                                  <InputGroup className={'py-1'} key={index}>
                                    <InputGroupAddon className={'w-50'} addonType="prepend">
                                      <InputGroupText
                                          style={{
                                            backgroundColor: '#11aa11', // barrelType?.color,
                                            width: '100%',
                                            color: 'white'
                                          }}
                                      >
                                        {barrelType?.screen_name}
                                      </InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                        min={0}
                                        type={'number'}
                                        step="1"
                                        value={barrelAmount.number}
                                        onChange={(e) => {
                                          // console.log(e.target.value)
                                          // console.log(arrayHelpers)
                                          arrayHelpers.replace(index, {
                                            ...barrelAmount,
                                            number: e.target.value
                                          });
                                        }}
                                    />
                                  </InputGroup>
                              );
                            })
                        ) : (
                            <div />
                        )}
                        {/*<FormConfirmCancel onCancel={toggle} />*/}
                      </div>
                  )}
              />
              <FormConfirmCancel onCancel={this.props.toggle} />
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

export default withRouter(withI18n(withAuthConsumer(withGlobalConsumer(CreateOrder))));
