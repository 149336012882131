import React, { useContext } from 'react';

import { Field, Form, Formik } from 'formik';

import { Button, Col, Label, Row } from 'reactstrap';
import FormGroup from 'reactstrap/es/FormGroup';

import * as Yup from 'yup';
import { GlobalContext } from '../../../global/GlobalProvider';
import { AuthContext } from '../../../api/auth/AuthContext';
import { I18nContext } from '../../../i18n';
import { notificationService } from '../../notifications/NotificationService';
import apiClient from '../../../api/ApiClient';
import { handleDefaultError } from '../../../utils/response-handler';
import { FormEffect } from '../../common/FormEffect';
import FormConfirmCancel from '../../common/FormConfirmCancel';
import { FormInput } from '../../common/FormInput';

const passwordSchema = (translate) => {
  const toShort = translate('value_to_short');
  const notBlank = translate('value_not_empty');

  return Yup.object().shape({
    new_password: Yup.string()
      .required(notBlank)
      .min(8, toShort + ' 8')
      .test('passwords-match-old', translate('password_must_match'), function (value) {
        return this.parent.new_password_repeat === value;
      }),
    my_password: Yup.string().required(notBlank),
    new_password_repeat: Yup.string()
      .required(notBlank)
      .min(8, toShort + ' 8')
      .test('passwords-match-new', translate('password_must_match'), function (value) {
        return this.parent.new_password === value;
      })
  });
};

const ForceChangePassword = ({ userId, withCancel, toggle, ...props }) => {
  const { startLoading, stopLoading } = useContext(GlobalContext);
  const { logout } = useContext(AuthContext);
  const { translate } = useContext(I18nContext);

  return (
    <div>
      <Formik
        initialValues={{
          new_password: '',
          new_password_repeat: '',
          my_password: ''
        }}
        validationSchema={passwordSchema(translate)}
        onSubmit={async (values) => {
          startLoading();
          try {
            const req = { new_password: values.new_password, my_password: values.my_password };
            const response = await apiClient.post(`user/${userId}/set_new_password`, req);
            notificationService.info(translate('password_successfully_changed'));
            toggle();
          } catch (e) {
            props = { logout, translate, stopLoading, ...props };
            await handleDefaultError(e, props);
          }
          stopLoading();
        }}
      >
        {(formik) => {
          return (
            <Form>
              <FormEffect
                formik={formik}
                onSubmissionError={() => notificationService.error(translate('error_form'))}
              />
              <Row form>
                <Col>
                  <FormGroup>
                    <Label for="new_password">Vpišite novo geslo uporabnika:</Label>
                    <Field name="new_password" type={'password'} component={FormInput} />
                  </FormGroup>
                </Col>
              </Row>
              <Row form>
                <Col>
                  <FormGroup>
                    <Label for="new_password_repeat">Ponovno vpišite novo geslo uporabnika:</Label>
                    <Field name="new_password_repeat" type={'password'} component={FormInput} />
                  </FormGroup>
                </Col>
              </Row>
              <Row form>
                <Col>
                  <FormGroup>
                    <Label for="my_password">Zaradi varnosti vpišite še svoje geslo:</Label>
                    <Field name="my_password" type={'password'} component={FormInput} />
                  </FormGroup>
                </Col>
              </Row>
              <Row className="justify-content-between">
                {withCancel ? (
                  <Col>
                    <FormConfirmCancel onCancel={toggle} />
                  </Col>
                ) : (
                  <Col>
                    <Button color={'primary'}>{translate('button_confirm')}</Button>
                  </Col>
                )}
              </Row>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default ForceChangePassword;
