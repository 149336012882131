import * as React from 'react';
import { PureComponent } from 'react';
import { Marker } from 'react-map-gl';

const ICON = `M20.2,15.7L20.2,15.7c1.1-1.6,1.8-3.6,1.8-5.7c0-5.6-4.5-10-10-10S2,4.5,2,10c0,2,0.6,3.9,1.6,5.4c0,0.1,0.1,0.2,0.2,0.3
  c0,0,0.1,0.1,0.1,0.2c0.2,0.3,0.4,0.6,0.7,0.9c2.6,3.1,7.4,7.6,7.4,7.6s4.8-4.5,7.4-7.5c0.2-0.3,0.5-0.6,0.7-0.9
  C20.1,15.8,20.2,15.8,20.2,15.7z`;

const SIZE = 20;

// Important for perf: the markers never change, avoid rerender when the map viewport changes
export default class Pins extends PureComponent {
  render() {
    const { data, onClick, detailedPin } = this.props;

    return data.map((entry, index) => {
      if (!entry.latitude || !entry.longitude) {
        return null;
      }
      let longitude = entry.reload_stop ? entry.longitude + Math.random() / 500 : entry.longitude;
      let latitude = entry.reload_stop ? entry.latitude + Math.random() / 500 : entry.latitude;

      let cantWiddenTimeWindowStyle = {
        borderColor: 'red',
        borderStyle: 'solid',
        borderWidth: '3px'
      };

      let startTime = entry?.preferred_start_time;
      let endTime = entry?.preferred_end_time;
      let estimatedTime = entry?.estimated_pickup_time;

      let outsideTimeRangeStyle = {};

      if (startTime && endTime && estimatedTime) {
        if (estimatedTime < startTime || estimatedTime > endTime) {
          outsideTimeRangeStyle = { backgroundColor: 'white' };
        }
      }

      if (detailedPin) {
        return (
          <Marker key={`marker-${index}` + Math.random().toString()} longitude={longitude} latitude={latitude}>
            <div
              style={{
                transform: `translate(${-SIZE / 2}px,${-SIZE}px)`,
                borderRadius: '5px',
                ...(!entry.can_optimizer_widden_time_window ? cantWiddenTimeWindowStyle : {}),
                ...outsideTimeRangeStyle
              }}
            >
              <svg
                height={entry.reload_stop ? SIZE + 5 : SIZE}
                viewBox="0 0 24 24"
                style={{
                  cursor: 'pointer',
                  fill: entry.reload_stop ? 'yellow' : this.props.color,
                  stroke: 'none'
                }}
                onClick={() => onClick(entry)}
              >
                <path d={ICON}></path>
                <text
                  fontSize="15"
                  fill={entry.reload_stop ? 'black' : 'white'}
                  fontFamily="Verdana"
                  textAnchor="middle"
                  alignmentBaseline="baseline"
                  x="12"
                  y="16.4"
                >
                  {index + 1}
                </text>
              </svg>
            </div>
          </Marker>
        );
      } else {
        return (
          <Marker key={`marker-${index}` + Math.random().toString()} longitude={longitude} latitude={latitude}>
            <svg
              height={20}
              viewBox="0 0 24 24"
              style={{
                cursor: 'pointer',
                fill: entry.reload_stop ? 'yellow' : this.props.color,
                stroke: 'none',
                transform: `translate(${-SIZE / 2}px,${-SIZE}px)`
              }}
              onClick={() => onClick(entry)}
            >
              <path d={ICON}></path>
              <text
                fontSize={16}
                fill={entry.reload_stop ? 'black' : 'white'}
                fontFamily="Verdana"
                textAnchor="middle"
                alignmentBaseline="baseline"
                x="12"
                y="16.4"
              >
                {index + 1}
              </text>
            </svg>
          </Marker>
        );
      }
    });
  }
}
