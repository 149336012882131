import React, { Fragment, useContext } from 'react';

import { I18nContext } from '../../../i18n';
import { Form, Formik, Field } from 'formik';
import { Col, Label, Row } from 'reactstrap';
import FormGroup from 'reactstrap/es/FormGroup';
import { FormSelect, FormCheckbox } from '../../common/FormInput';
import FormConfirmCancel from '../../common/FormConfirmCancel';
import { GlobalContext } from '../../../global/GlobalProvider';
import KotoDateTime from '../../common/KotoDateTime';
import moment from 'moment';
import apiClient from '../../../api/ApiClient';
import { notificationService } from '../../notifications/NotificationService';
import { handleDefaultError } from '../../../utils/response-handler';
import { getProviderSelectValues } from '../../navbar/providers';

export const CreateOptimizerVehicle = ({ optimizerSetting, availableDrivers, availableVehicles, ...props }) => {
  const { translate } = useContext(I18nContext);
  const { pools, startingLocations, startLoading, stopLoading } = useContext(GlobalContext);

  return (
    <Fragment>
      <div>
        <div>
          <h1>{props.title}</h1>

          <Formik
            initialValues={{
              driver: null,
              pool: null,
              vehicle: null,
              start_location: null,
              end_location: null,
              start_time: moment().set({ hour: 8, minute: 0 }),
              end_time: moment().set({ hour: 16, minute: 0 }),
              is_starting_from_home: false
            }}
            onSubmit={async (values) => {
              const { start_time, end_time, ...restVal } = values;
              startLoading();
              try {
                const response = await apiClient.post(`optimizer_settings/${optimizerSetting?.id}/change_vehicles`, {
                  add: [
                    {
                      start_time: start_time.format('HH:mm'),
                      end_time: end_time.format('HH:mm'),
                      ...restVal
                    }
                  ]
                });
                notificationService.success('Uspešno dodano vozilo opzimiratorju');
                await props.refresh();
                await props.toggle();
              } catch (e) {
                await handleDefaultError(e, {
                  translate: translate,
                  stopLoading: stopLoading,
                  startLoading
                });
              }
              stopLoading();
            }}
          >
            {(formik) => {
              const defaultProvider = getProviderSelectValues().find((gps) => gps.value == formik?.values?.provider);
              return (
                <Form>
                  <Row form>
                    <Col lg={6} md={12}>
                      <FormGroup>
                        <Label for="driver">Voznik</Label>
                        <FormSelect
                          formik={formik}
                          name="driver"
                          options={availableDrivers}
                          onChange={(e) => formik.setFieldValue('driver', e.value, true)}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={6} md={12}>
                      <FormGroup>
                        <Label for="driver">Vozilo</Label>
                        <FormSelect
                          formik={formik}
                          name="vehicle"
                          options={availableVehicles}
                          onChange={(e) => formik.setFieldValue('vehicle', e.value, true)}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={6} md={12}>
                      <FormGroup>
                        <Label for="pool">Bazen</Label>
                        <FormSelect
                          formik={formik}
                          name="pool"
                          options={pools}
                          onChange={(e) => formik.setFieldValue('pool', e.value, true)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row form>
                    <Col lg={6} md={6}>
                      <FormGroup>
                        <Label for="start_location">Začetna in končna lokacija</Label>
                        <FormSelect
                          formik={formik}
                          name="start_location"
                          options={startingLocations}
                          onChange={(e) => {
                            formik.setFieldValue('start_location', e.value, true);
                            formik.setFieldValue('end_location', e.value, true);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={6} md={6} className="mt-1 pl-3">
                      <Label for="is_starting_from_home">Začenja od doma?</Label>
                      <Field
                        name="is_starting_from_home"
                        type="checkbox"
                        className="pl-1"
                        checked={formik.values.is_starting_from_home}
                        size="2.3"
                        component={FormCheckbox}
                      />
                    </Col>
                  </Row>
                  <Row form>
                    <Col lg={6} md={12}>
                      <FormGroup>
                        <Label for="start_time">Začetni čas</Label>
                        <KotoDateTime
                          value={formik.values.start_time}
                          dateFormat={false}
                          timeFormat={'HH:mm'}
                          onChange={(e) => formik.setFieldValue('start_time', e)}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={6} md={12}>
                      <FormGroup>
                        <Label for="end_time">Končni čas</Label>
                        <KotoDateTime
                          value={formik.values.end_time}
                          dateFormat={false}
                          timeFormat={'HH:mm'}
                          name={'end_time'}
                          onChange={(e) => formik.setFieldValue('end_time', e)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <FormConfirmCancel onCancel={props.toggle} />
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </Fragment>
  );
};
