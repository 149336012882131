import React, { Fragment } from 'react';

import Row from 'reactstrap/es/Row';
import { Col } from 'reactstrap';
import Container from 'reactstrap/es/Container';
import ConfigNavigation from './ConfigNavigation';

export const ConfigSettingsContainer = ({ children }) => (
  <Fragment>
    <Container fluid className="pt-3">
      <Row>
        <Col>
          <ConfigNavigation />
        </Col>
        <Col md={10} xs={12}>
          {children}
        </Col>
      </Row>
    </Container>
  </Fragment>
);
