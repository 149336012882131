import React from 'react';
import KotoModal from '../../../common/KotoModal';
import { Row } from 'reactstrap';
import Button from 'reactstrap/es/Button';

const DeletePredictionGroupModal = ({ isOpen, toggle, onDelete, group, ...props }) => {
  const title = `Si prepričan, da želiš izbrisati skupino ${group.name}?`;

  return (
    <KotoModal isOpen={isOpen} toggle={toggle} title={title}>
      <Row className="mr-2 ml-2 justify-content-between">
        <Button onClick={toggle} color="danger">
          Prekliči
        </Button>
        <Button onClick={onDelete} color="primary">
          Izbriši!
        </Button>
      </Row>
    </KotoModal>
  );
};

export default DeletePredictionGroupModal;
