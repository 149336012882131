export function getSelectOptions(valueField, labelField, statuses, exclude, allField) {
  let selectStatuses = statuses
    .filter((status) => {
      return status[valueField] !== exclude;
    })
    .map((status) => ({ value: status[valueField].toString(), label: status[labelField] }));
  if (allField) {
    selectStatuses.unshift(allField);
  }
  return selectStatuses;
}

export const colorArray = [
  '#FF6633',
  '#ff2600',
  '#a8419c',
  '#2b7940',
  '#00B3E6',
  'rgba(202,7,84,0.45)',
  '#3366E6',
  '#3a058e',
  '#b69e00',
  '#B34D4D',
  '#80B300',
  '#715446',
  '#E6B3B3',
  '#6680B3',
  '#66991A',
  '#FF99E6',
  '#CCFF1A',
  '#FF1A66',
  '#E6331A',
  '#33FFCC',
  '#66994D',
  '#B366CC',
  '#a8b418',
  '#B33300',
  '#CC80CC',
  '#66664D',
  '#991AFF',
  '#E666FF',
  '#4DB3FF',
  '#1AB399',
  '#E666B3',
  '#33991A',
  '#CC9999',
  '#dcc906',
  '#00E680',
  '#4D8066',
  '#809980',
  '#E6FF80',
  '#1AFF33',
  '#999933',
  '#FF3380',
  '#dac51a',
  '#66E64D',
  '#4D80CC',
  '#9900B3',
  '#E64D66',
  '#4DB380',
  '#FF4D4D',
  '#99E6E6',
  '#6666FF'
];

export const getFromStorage = (keyValueString, defaultValue, parseFunction) => {
  if (!keyValueString.includes('.')) {
    throw new Error(`invalid keyValueString(${keyValueString}), must contain .`);
  }
  const [key, value] = keyValueString.split('.');
  if (key?.length < 1 || value?.length < 1) {
    throw new Error(`key or value for ${key}  and ${value} must be at least length one`);
  }
  const currentItem = localStorage.getItem(keyValueString);
  if (currentItem === null) {
    return defaultValue;
  }

  let parsedItem = defaultValue;
  try {
    parsedItem = JSON.parse(currentItem);
  } catch (e) {
    return defaultValue;
  }

  if (parseFunction) {
    return parseFunction(parsedItem);
  }
  return parsedItem;
};
