import axios from 'axios';
import { authService } from './auth/AuthService';

import _ from 'lodash';

const apiClient = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`
});

apiClient.interceptors.request.use(
  (config) => {
    // Do something before request is sent

    config.headers['Authorization'] = 'Bearer ' + authService.getToken();
    const provider = JSON.parse(localStorage.getItem('koto-provider'));
    if (provider?.length > 0 && !_.isNaN(provider)) {
      config.headers['PROVIDER'] = provider;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
export default apiClient;
