import React, { useContext } from 'react';
import { I18nContext } from '../../../i18n';
import { Field, Form, Formik } from 'formik';
import apiClient from '../../../api/ApiClient';
import { notificationService } from '../../notifications/NotificationService';
import { handleDefaultError } from '../../../utils/response-handler';
import { FormEffect } from '../../common/FormEffect';
import Row from 'reactstrap/es/Row';
import { Col, Label } from 'reactstrap';
import { FormCheckbox, FormInput } from '../../common/FormInput';
import FormConfirmCancel from '../../common/FormConfirmCancel';
import * as Yup from 'yup';

export const GroupCreate = ({ id, onCancel, refresh, ...props }) => {
  const { translate } = useContext(I18nContext);
  return (
    <>
      <Formik
        initialValues={{
          name: ''
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required('Ne sme biti prazno')
        })}
        onSubmit={async (values) => {
          try {
            const response = await apiClient.post('location_groups', values);
            notificationService.success('Uspešno dodana nova skupina!');
            refresh();
          } catch (e) {
            props = { translate, ...props };
            await handleDefaultError(e, props);
          }
          onCancel();
        }}
      >
        {(formik) => {
          return (
            <Form>
              <FormEffect
                formik={formik}
                onSubmissionError={() => notificationService.error('Please correct red colored fields')}
              />
              <>
                <Row className="align-items-center py-4">
                  <Col className="align-items-center">
                    <Label className="align-items-center m-0" for="name">
                      Ime
                    </Label>{' '}
                  </Col>
                  <Col md={11}>
                    <Field name="name" type={'text'} component={FormInput} />
                  </Col>
                </Row>

                <FormConfirmCancel translate={translate} onCancel={onCancel} />
              </>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
