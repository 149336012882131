import _ from 'lodash';
import { useLocalStorage } from '../../utils/hooks/useLocalStorage';

export const providers = {
  '': 'Vsi',
  1: 'Koto',
  2: 'Gostinski odpadki'
};

export const DEFAULT_PROVIDER_STORAGE_VALUE = 'koto-provider';

export const getProviderSelectValues = (showAll = false) => {
  return Object.keys(providers)
    .filter((key) => {
      if (!showAll) {
        return key !== '';
      }
      return true;
    })
    .sort((a, b) => a - b)
    .map((key) => ({
      value: key,
      label: providers[key]
    }));
};

export const getCurrentProvider = (defaultProvider) => {
  const provider = JSON.parse(localStorage.getItem('koto-provider'));
  if (provider?.length > 0 && !_.isNaN(provider) && Object.keys(providers).includes(provider)) {
    return provider;
  }
  return defaultProvider;
};

export const useProvider = () => {
  const [provider, setProvider] = useLocalStorage(DEFAULT_PROVIDER_STORAGE_VALUE, '');
  return [provider, setProvider];
};
