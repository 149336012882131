import React, { Fragment } from 'react';
import ButtonGroup from 'reactstrap/es/ButtonGroup';
import { Button } from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import withI18n from '../../../i18n';

const ConfigNavigation = ({ translate, location }) => (
  <Fragment>
    <ButtonGroup className="w-100" vertical>
      <Button
        className="text-nowrap"
        tag={Link}
        active={location.pathname.startsWith('/configs/orders')}
        to="/configs/orders"
      >
        {translate('order_repeating_title')}
      </Button>

      <Button
        className="text-nowrap"
        tag={Link}
        active={location.pathname.startsWith('/configs/optimizer')}
        to="/configs/optimizer"
      >
        {translate('page_optimizer_title')}
      </Button>

      <Button
        className="text-nowrap"
        tag={Link}
        active={location.pathname.startsWith('/configs/general-settings')}
        to="/configs/general-settings"
      >
        {translate('page_general_settings_title')}
      </Button>
      <Button
        className="text-nowrap"
        tag={Link}
        active={location.pathname.startsWith('/configs/compare-prediction')}
        to="/configs/compare-prediction"
      >
        Primerjaj uspešnost napovedi kg
      </Button>
      <Button
        className="text-nowrap"
        tag={Link}
        active={location.pathname.startsWith('/configs/barrel-discrepancy')}
        to="/configs/barrel-discrepancy"
      >
        Poizvedbe - kosov embalaže
      </Button>
      <Button
        className="text-nowrap"
        tag={Link}
        active={location.pathname.startsWith('/configs/reports')}
        to="/configs/reports"
      >
        Voznikova poročila
      </Button>
    </ButtonGroup>
  </Fragment>
);

export default withI18n(withRouter(ConfigNavigation));
