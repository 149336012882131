import React, { Component, useEffect } from 'react';
import withI18n from '../../../i18n';
import withGlobalConsumer from '../../../global/GlobalProvider';
import { Field, Form, Formik } from 'formik';
import apiClient from '../../../api/ApiClient';
import { notificationService } from '../../notifications/NotificationService';
import { FormEffect } from '../../common/FormEffect';
import { Col, Label, Row } from 'reactstrap';
import FormGroup from 'reactstrap/es/FormGroup';
import { FormInput } from '../../common/FormInput';
import FormConfirmCancel from '../../common/FormConfirmCancel';
import * as Yup from 'yup';
import { handleDefaultError } from '../../../utils/response-handler';
import { userStatuses } from '../../common/statuses';
import LocationSearch from '../../LocationSearch';
import Card from 'reactstrap/es/Card';

const userSchema = (translate, user) => {
  const toShort = translate('value_to_short');
  const toLong = translate('value_to_long');
  const notBlank = translate('value_not_empty');
  let validatePassword = user
    ? Yup.string()
    : Yup.string()
        .required(notBlank)
        .min(8, toShort + ' 8');

  return Yup.object().shape({
    first_name: Yup.string()
      .min(2, toShort + ' 2')
      .max(25, toLong + ' 25')
      .matches(/^[a-zA-Z0-9šđčćžŠĐČĆŽ@ ]*$/, translate('value_alphanumeric'))
      .required(notBlank),
    last_name: Yup.string()
      .min(2, toShort + ' 2')
      .max(25, toLong + ' 25')
      .matches(/^[a-zA-Z0-9šđčćžŠĐČĆŽ@ ]*$/, translate('value_alphanumeric'))
      .required(notBlank),
    email: Yup.string().email().required(translate('login_label_error')),
    password: validatePassword,
    repeated_password: Yup.string().test('error,', 'Gesli se ne ujemata', function (value) {
      const { password } = this.parent;
      return password === value;
    }),
    user_type: Yup.number()
  });
};

class CreateUser extends Component {
  state = {
    selectedLocations: [],
    locations: []
  };
  handleLocationChange = async (selectedLocations) => {
    if (selectedLocations) {
      await this.setState({ selectedLocations: selectedLocations });
    } else {
      await this.setState({ selectedLocations: [] });
    }
  };

  async fetchLocations() {
    try {
      let request = await apiClient.get('/location/options');
      this.setState({ locations: request.data });
    } catch (e) {
      notificationService.error(e);
    }
  }

  componentDidMount() {
    this.fetchLocations();
  }

  render() {
    return (
      <div>
        <h1>{this.props.title}</h1>

        <Formik
          initialValues={{
            first_name: this.props.user && this.props.user.first_name ? this.props.user.first_name : '',
            last_name: this.props.user && this.props.user.last_name ? this.props.user.last_name : '',
            email: this.props.user && this.props.user.email ? this.props.user.email : '',
            user_type: this.props.user && this.props.user.user_type ? this.props.user.user_type : 100,
            password: '',
            repeated_password: '',
            carrier: this.props.user && this.props.user.carrier ? this.props.user.carrier.id : null,
            home_location: this.props.user && this.props.user.home_location ? this.props.user.home_location.id : null
          }}
          isValid={this.props.userId}
          validationSchema={userSchema(this.props.translate, this.props.user)}
          onSubmit={async (values) => {
            try {
              await this.props.startLoading();

              const locations = this.state.selectedLocations.map((location) => location.value);
              let response;
              let successMessage;
              values.user_type = parseInt(values.user_type);
              if (this.props.userId) {
                let { password, ...rest } = values;
                response = await apiClient.put(`/user/${this.props.userId}`, rest);
                successMessage = `${this.props.translate('users_success_update_message')} ${response.data.first_name}`;
              } else {
                response = await apiClient.post(`/user`, { locations: locations, ...values });
                successMessage = `${this.props.translate('users_success_add_message')} ${response.data.first_name}`;
              }

              notificationService.success(successMessage);
            } catch (e) {
              if (e.response && e.response.data && e.response.data.email && e.response.data.email.length !== 0) {
                notificationService.error(this.props.translate('user_email_already_exists'));
                await this.props.stopLoading();
                return;
              } else {
                await handleDefaultError(e, this.props);
              }
            }
            await this.props.stopLoading();
            await this.props.refresh();
            await this.props.toggle();
          }}
        >
          {(formik) => {
            return (
              <Form>
                <FormEffect
                  formik={formik}
                  onSubmissionError={() => notificationService.error(this.props.translate('error_form'))}
                />
                <Row form>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="first_name">{this.props.translate('user_first_name')}</Label>
                      <Field name="first_name" type={'text'} component={FormInput} />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="last_name">{this.props.translate('user_last_name')}</Label>
                      <Field name="last_name" type={'text'} component={FormInput} />
                    </FormGroup>
                  </Col>
                </Row>
                <Row form>
                  <Col>
                    <FormGroup>
                      <Label for="email">{this.props.translate('login_email')}</Label>
                      <Field name="email" type={'text'} component={FormInput} />
                    </FormGroup>
                  </Col>

                  <Col>
                    <FormGroup>
                      <Label for="user_type">{this.props.translate('user_type_label')}</Label>
                      <Field name="user_type" type={'select'} component={FormInput}>
                        {Object.keys(userStatuses)
                          .filter((key) => key != 0)
                          .map((userKey) => (
                            <option
                              key={userStatuses[userKey] + Math.random().toString()}
                              value={this.props.clientId ? this.props.user.user_type : userKey}
                            >
                              {this.props.translate(userStatuses[userKey].name)}
                            </option>
                          ))}
                      </Field>
                    </FormGroup>
                  </Col>
                </Row>

                <Row form>
                  <Col>
                    <FormGroup>
                      <Label for="home_location">Domača lokacija (izpolni le v primeru voznikov)</Label>
                      <Field name="home_location" type={'select'} component={FormInput}>
                        <option value={null}></option>
                        {this.state.locations.map((location) => (
                          <option value={location.id}>{location.short_name}</option>
                        ))}
                      </Field>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label for="carrier_id">Prevoznik (izpolni le v primeru voznikov in prevoznikov)</Label>
                      <Field name="carrier_id" type={'select'} component={FormInput}>
                        <option value={null}></option>
                        <option value={1}>AMG1, Tina Trček s.p</option>
                        <option value={2}>KOTO d.o.o</option>
                      </Field>
                    </FormGroup>
                  </Col>
                </Row>

                {!this.props.user ? (
                  <Row form>
                    <Col>
                      <FormGroup>
                        <Label for="password">{this.props.translate('login_password')}</Label>
                        <Field name="password" type={'password'} component={FormInput} />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label for="repeated_password">{this.props.translate('new_password_repeat')}</Label>
                        <Field name="repeated_password" type={'password'} component={FormInput} />
                      </FormGroup>
                    </Col>
                  </Row>
                ) : null}

                {!this.props.userId && (
                  <Row noGutters>
                    <LocationSearch
                      placeholder={this.props.translate('locations_choose')}
                      handleSelectChange={this.handleLocationChange.bind(this)}
                    />
                  </Row>
                )}
                <br />
                <FormConfirmCancel onCancel={this.props.toggle} />
              </Form>
            );
          }}
        </Formik>
      </div>
    );
  }
}

export default withI18n(withGlobalConsumer(CreateUser));
