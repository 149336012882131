import React from 'react';
import KotoModal from '../../../common/KotoModal';
import KotoDateTime from '../../../common/KotoDateTime';
import { FormGroup, Label } from 'reactstrap';
import { Row, Col } from 'reactstrap';
import Button from 'reactstrap/es/Button';
import Input from 'reactstrap/es/Input';
import styles from './style';

const DriverListItem = ({ vehicle, selectedDrivers, handleSelectDriver }) => {
  const selected = selectedDrivers?.includes(vehicle.id);
  return (
    <Row
      className="m-2 p-3"
      style={{ ...styles.driverListItem, backgroundColor: selected ? '#e1f7dc' : '#edcec5' }}
      onClick={() => handleSelectDriver(vehicle.id)}
    >
      <Col md={3}>
        <div>
          <b>{vehicle.pool}</b>
        </div>
        <div>
          {vehicle.driver.first_name} {vehicle.driver.last_name}
        </div>
        <div style={{ color: 'grey' }}>{vehicle.vehicle.license_plate}</div>
      </Col>
      <Col md={6} className="text-center">
        <Row>
          <Col>
            <div>
              <b>{vehicle.start_time}</b>
            </div>
            <div>
              <i>{vehicle.start_location.short_name}</i>
            </div>
          </Col>
          <Col>
            <div>
              <b>{vehicle.end_time}</b>
            </div>
            <div>
              <i>{vehicle.end_location.short_name}</i>
            </div>
          </Col>
        </Row>
      </Col>
      <Col md={2}>
        <Row className="justify-content-center">
          <span style={{ fontSize: 35 }}>{selected ? <>&#10003;</> : <>&#10006;</>}</span>
        </Row>
      </Col>
    </Row>
  );
};

const AdvancedOptimizationModal = ({ isOpen, toggle, optimizationSettings, pools, onSubmit, ...props }) => {
  const title = `Napredne nastavitve optimizacije`;
  const [selectedOptimizationSettings, setSelectedOptimizationSettings] = React.useState(null);
  const [selectedPool, setSelectedPool] = React.useState(null);
  const [selectedDrivers, setSelectedDrivers] = React.useState([]);
  const [timeLimit, setTimeLimit] = React.useState(900);
  const [targetDate, setTargetDate] = React.useState(null);

  React.useEffect(() => {
    setSelectedDrivers(
      selectedOptimizationSettings?.vehicles
        .filter((vehicle) => vehicle.pool == selectedPool)
        .map((vehicle) => vehicle.id)
    );
  }, [selectedOptimizationSettings, selectedPool]);

  const handleOptimizationSettingsChange = (id) => {
    const settings = optimizationSettings.find((settings) => settings.id == id);
    setSelectedOptimizationSettings(settings);
  };

  const handleSelectDriver = (vehicleId) => {
    if (selectedDrivers.includes(vehicleId)) {
      setSelectedDrivers(selectedDrivers.filter((id) => id != vehicleId));
    } else {
      setSelectedDrivers([...selectedDrivers, vehicleId]);
    }
  };

  const handlePoolChange = (label) => {
    setSelectedPool(pools.find((pool) => pool.label == label)?.label);
  };

  const prepareData = () => {
    return {
      optimization_settings: selectedOptimizationSettings?.id,
      pool_id: pools.find((pool) => pool.label == selectedPool)?.value,
      selected_drivers: selectedDrivers,
      target_date: targetDate?.format('YYYY-MM-DD'),
      time_limit: timeLimit
    };
  };

  return (
    <KotoModal isOpen={isOpen} toggle={toggle} title={title}>
      <FormGroup className="mx-2 w-200">
        <Label style={{ fontSize: '13px' }}>Optimiziraj bazen: </Label>
        <Input
          type="select"
          name="select"
          className="w-200"
          id="poolsSelect"
          value={selectedPool}
          onChange={(event) => handlePoolChange(event.target.value)}
        >
          <option label="...">...</option>
          {pools.map((pool) => (
            <option key={pool.label} value={pool.label}>
              {pool.label}
            </option>
          ))}
        </Input>
      </FormGroup>
      <FormGroup className="mx-2 w-200">
        <Label style={{ fontSize: '13px' }}>Izberi iz nastavitev: </Label>
        <Input
          type="select"
          name="select"
          className="w-200"
          id="settingsSelect"
          value={selectedOptimizationSettings?.id}
          onChange={(event) => handleOptimizationSettingsChange(event.target.value)}
        >
          <option label="...">...</option>
          {optimizationSettings.map((settings) => (
            <option key={settings.id} value={settings.id}>
              {settings.short_name}
            </option>
          ))}
        </Input>
      </FormGroup>
      <Row>
        <Col md={6}>
          <FormGroup className="mx-2 w-200">
            <Label style={{ fontSize: '13px' }}>Datum optmizacije</Label>
            <KotoDateTime onChange={(e) => setTargetDate(e)} width={'300px'} timeFormat={false} value={targetDate} />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup className="mx-2">
            <Label style={{ fontSize: '12px' }}>Maksimalen čas optimizacije (sec)</Label>
            <Input type={'number'} min={'1'} value={timeLimit} onChange={(e) => setTimeLimit(e.target.value)} />
          </FormGroup>
        </Col>
      </Row>
      {selectedOptimizationSettings?.vehicles
        .filter((vehicle) => vehicle.pool == selectedPool)
        .map((vehicle) => (
          <DriverListItem
            key={vehicle.id}
            vehicle={vehicle}
            selectedDrivers={selectedDrivers}
            handleSelectDriver={handleSelectDriver}
          />
        ))}
      <hr />
      <Row className="mr-2 ml-2 justify-content-between">
        <Button onClick={toggle} color="danger">
          Prekliči
        </Button>
        <Button onClick={() => onSubmit(prepareData())} color="success">
          Začni optimizacijo!
        </Button>
      </Row>
    </KotoModal>
  );
};

export default AdvancedOptimizationModal;
