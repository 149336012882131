import * as React from 'react';
import { PureComponent } from 'react';
import * as moment from 'moment';

export default class CityInfo extends PureComponent {
  render() {
    const { info } = this.props;
    return (
      <div>
        <div className="font-weight-bold">{info.name}</div>
        {info.pool}
      </div>
    );
  }
}
