import apiClient from '../ApiClient';
import decode from 'jwt-decode';
import { notificationService } from '../../components/notifications/NotificationService';
import { getTranslation } from '../../i18n';

class AuthService {
  constructor(props) {
    this.tokenIdentifier = 'jwtToken';
  }

  async login(email, password) {
    const req = { email: email, password: password };
    try {
      const loginResponse = await apiClient.post('/api-token-auth', req);
      await this.setToken(loginResponse.data.access);
      return await this.validateLogin();
    } catch (e) {
      if (e.response && e.response.data && e.response.status === 401) {
        notificationService.error(getTranslation('invalid_username_or_password'));
      }
      if (typeof e.response === 'undefined') {
        notificationService.error(getTranslation('network_error'));
        throw e;
      }
      throw e;
    }
  }

  async validateLogin() {
    try {
      const profile = await this.getProfile();
      return profile.type !== 200;
    } catch (e) {
      return false;
    }
  }

  async isLoggedIn() {
    const token = this.getToken();

    const valid = token && !(await this.isTokenExpired(token));
    if (valid) {
      const validUser = await this.validateLogin();
      if (validUser) {
        return true;
      }
      notificationService.error(getTranslation('user_not_allowed'));
    }
    await this.logout();
    return false;
  }

  async logout() {
    await localStorage.removeItem(this.tokenIdentifier);
  }

  async isTokenExpired(token) {
    try {
      const decoded = await decode(token);
      const expiryDate = decoded.exp;
      const currentDate = new Date().getTime() / 1000;

      return expiryDate <= currentDate;
    } catch (err) {
      return true;
    }
  }

  async setToken(idToken) {
    await localStorage.setItem(this.tokenIdentifier, idToken);
  }

  getToken() {
    return localStorage.getItem(this.tokenIdentifier);
  }

  async getProfile() {
    try {
      if (this.getToken()) {
        let allInfo = await decode(this.getToken());
        let { token_type, jti, ...profile } = allInfo;
        return profile;
      }
      return false;
    } catch (e) {
      return false;
    }
  }
}

export const authService = new AuthService();
