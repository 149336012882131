import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import withI18n from '../../../i18n';
import withGlobalConsumer, { GlobalContext } from '../../../global/GlobalProvider';
import apiClient from '../../../api/ApiClient';
import KotoTable from '../../common/KotoTable';
import Button from 'reactstrap/es/Button';
import KotoContainer from '../KotoContainer';
import { Modal, ModalBody, ModalHeader, NavLink } from 'reactstrap';
import Row from 'reactstrap/es/Row';
import Col from 'reactstrap/es/Col';
import KotoModal from '../../common/KotoModal';
import MapBox from './MapBox';
import KotoNotification from '../../common/KotoNotification';
import CreateClient from '../clients/CreateClient';
import { PoolCreate } from './PoolCreate';
import { FiEdit } from 'react-icons/fi';
import { notificationService } from '../../notifications/NotificationService';
import { BsInfoCircle } from 'react-icons/bs';
import ReactTooltip from 'react-tooltip';

const PoolsComponent = ({ startLoading, stopLoading, translate, ...props }) => {
  const [pools, setPools] = useState([]);
  const [missingLocations, setMissingLocations] = useState([]);
  const [poolCreate, setPoolCreate] = useState(false);
  const [mapOpened, setMapOpened] = useState(false);
  const [singlePool, setSinglePool] = useState([]);
  const { fetchPools } = useContext(GlobalContext);

  useEffect(() => {
    fetchCurrentPools();
    fetchMissingLocations();
  }, []);

  async function fetchCurrentPools() {
    try {
      const poolsResponse = await apiClient.get('/pools');
      await setPools(poolsResponse.data);
    } catch (e) {
      //console.log(e);
    }
  }

  async function fetchMissingLocations() {
    try {
      const missingLocationsResponse = await apiClient.get('location/missing_pools');
      await setMissingLocations(missingLocationsResponse.data);
    } catch (e) {
      //console.log(e);
    }
  }

  async function updateName(id, name) {
    try {
      await apiClient.patch(`/pools/${id}`, { name: name });
      fetchCurrentPools();
      fetchPools();
    } catch (e) {
      notificationService.error('Nekaj je šlo narobe.');
    }
  }

  function handleOpenMap(index) {
    if (pools.length > 0) {
      setSinglePool(pools[index]);
    }
    setMapOpened(true);
  }

  function toggleCreatePool() {
    setPoolCreate(!poolCreate);
  }

  const poolsColumns = [
    {
      Header: 'Id',
      width: '10%',
      accessor: 'id',
      Cell: (row) => (
        <NavLink className="pt-0 pb-0" to={`/pools/${row.original.id}`} tag={Link}>
          {row.original.id}
        </NavLink>
      )
    },
    {
      Header: 'Ime',
      width: '25%',
      accessor: 'name',
      Cell: (row) => {
        const [editing, setEditing] = useState(false);
        const [name, setName] = useState(row.original.name);

        return (
          <div>
            {editing ? (
              <div className="align-items-center d-flex justify-content-center">
                <input
                  style={{ marginBottom: '4px' }}
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
                <div>
                  <Button
                    size={'sm'}
                    style={{ marginBottom: '3px', marginLeft: '2px' }}
                    color={'primary'}
                    onClick={async (e) => {
                      await updateName(row.original.id, name);
                    }}
                  >
                    Shrani
                  </Button>
                  <Button
                    size={'sm'}
                    style={{ marginBottom: '3px', marginLeft: '2px' }}
                    onClick={(e) => setEditing(false)}
                    color={'danger'}
                  >
                    Prekliči
                  </Button>
                </div>
              </div>
            ) : (
              <div className="align-items-center">
                {row.original.name}
                <FiEdit style={{ cursor: 'pointer' }} onClick={(e) => setEditing(true)} className={'ml-2 mb-1'} />
              </div>
            )}
          </div>
        );
      }
    },
    {
      Header: 'Število lokacij',
      width: '10%',
      accessor: 'locations',
      Cell: (locations) => {
        return locations?.value ? locations.value.length : 0;
      }
    },
    {
      Header: '# Naročil v prihodnjih dneh',
      accessor: 'upcoming_orders',
      width: '30%',
      Cell: (row) => {
        return row.value.join(' | ');
      }
    },
    {
      Header: 'Ocenjeno število kilogramov',
      accessor: 'upcoming_kilos',
      width: '30%',
      Cell: (row) => {
        return row.value.join(' | ');
      }
    },
    {
      Header: '',
      width: '25%',
      Cell: (row) => {
        return (
          <div>
            <Button color={'warning'} onClick={() => handleOpenMap(row.index)}>
              Prikaži na zemljevidu
            </Button>
          </div>
        );
      }
    }
  ];

  const missingLocationsColumns = [
    {
      Header: 'Id',
      width: 200,
      accessor: 'id',
      Cell: (row) => (
        <NavLink className="pt-0 pb-0" to={`/locations/${row.original.id}`} tag={Link}>
          {row.original.id}
        </NavLink>
      )
    },
    {
      Header: 'Ime',
      width: 400,
      accessor: 'short_name'
    },
    {
      Header: 'Naslov',
      accessor: 'address'
    }
  ];

  const poolsToolTipHelp = `Vsaki lokaciji in vsakemu naročilu lahko nastavimo optimizacijski bazen. <br> Ko poteka optimizacija
    se vsak bazen naročil optimizira posebej z nastavitvami vozil, ki so nastavljena za ta bazen. <br>
    Ko je ustvarjeno naročilo za neko lokacijo, je naročilu nastavljen isti bazen, kot ga ima lokacija naročila. <br> 
    Če je izjemoma potreba, da se neko naročilo optimizira v drugem optimizacijskem bazenu, se lahko posameznemu naročilu posebej nastavi bazen.
    <br>
    Če neko lokacijo prestavimo iz enega bazena v drug bazen, potem se bodo v drug bazen prestavila tudi vsa prihajajoča naročila te lokacije.
    <br>
    Priporočeno je, da je vsaka lokacija del nekega bazena. Lokacije, ki niso del nobenega bazena, ne bodo del nobene optimizacije in se njihova naročila ne bodo optimizirala. 
    <br><br>
    Ko dodajamo lokacije v posamezen bazen, lahko vanj dodamo tudi lokacije, ki so že del drugega bazena. V tem primeru se bo tem lokacijam
    nastavil novo izbrani bazen in ne bodo več del prejšnega bazena.
    `;

  return (
    <KotoContainer>
      {missingLocations.length > 0 && (
        <Row>
          <KotoNotification color={'#fff2ed'} borderColor={'#ffb294'}>
            <strong>OPOZORILO</strong>: Lokacijam manjkajo bazeni. Število lokacij brez nastavljenega bazena:{' '}
            {missingLocations.length}
          </KotoNotification>
          <BsInfoCircle className="m-3" data-tip={poolsToolTipHelp} size="30" /> <ReactTooltip html={true} />
        </Row>
      )}
      {missingLocations.length == 0 && (
        <Row>
          <KotoNotification color={'#ecffe3'} borderColor={'#a6fa7f'}>
            Vsaka lokacija je del nekega bazena.
          </KotoNotification>
          <BsInfoCircle className="m-3" data-tip={poolsToolTipHelp} size="30" /> <ReactTooltip html={true} />
        </Row>
      )}

      <h5 style={{ marginTop: '20px' }}>
        <i>Bazeni lokacij</i>
      </h5>
      <KotoTable columns={poolsColumns} data={pools}></KotoTable>
      <Button style={{ marginTop: '20px' }} color={'primary'} onClick={(e) => toggleCreatePool()}>
        Dodaj nov bazen lokacij
      </Button>
      <hr />

      <KotoModal title={'Dodaj nov bazen lokacij'} isOpen={poolCreate} toggle={() => toggleCreatePool()}>
        <PoolCreate
          onCancel={() => toggleCreatePool()}
          refresh={async () => {
            fetchPools();
            await fetchCurrentPools();
            await fetchMissingLocations();
          }}
        />
      </KotoModal>

      {missingLocations.length > 0 && (
        <Fragment>
          <h5 style={{ marginTop: '30px' }}>
            <i>Lokacije, ki nimajo nastavljenega bazena lokacij</i>
          </h5>
          <KotoTable columns={missingLocationsColumns} data={missingLocations}></KotoTable>
        </Fragment>
      )}

      <Modal style={{ maxWidth: '1900px', width: '80%' }} isOpen={mapOpened} toggle={() => setMapOpened(false)}>
        <ModalBody>
          <ModalHeader className="border-0 p-0" toggle={() => setMapOpened(false)}>
            <i>Zemljevid lokacij izbranega bazena</i>
          </ModalHeader>
          <Row>
            <Col sm={12}>
              <MapBox short={false} pool={singlePool} />
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </KotoContainer>
  );
};

export default withRouter(withI18n(withGlobalConsumer(PoolsComponent)));
