import { Field, Form, Formik } from 'formik';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { FcCancel, FcCheckmark } from 'react-icons/fc';
import { Col, Label } from 'reactstrap';
import Button from 'reactstrap/es/Button';
import Row from 'reactstrap/es/Row';
import * as Yup from 'yup';
import apiClient from '../../../api/ApiClient';
import withAuthConsumer from '../../../api/auth/AuthContext';
import { I18nContext } from '../../../i18n';
import { handleDefaultError } from '../../../utils/response-handler';
import FormConfirmCancel from '../../common/FormConfirmCancel';
import { FormEffect } from '../../common/FormEffect';
import { FormCheckbox, FormInput } from '../../common/FormInput';
import KotoModal from '../../common/KotoModal';
import KotoTwoRowTable from '../../common/KotoTwoRowTable';
import { notificationService } from '../../notifications/NotificationService';
import './ConfigGeneralSettings.css';
import { ConfigSettingsContainer } from './ConfigSettingsContainer';
import KotoDate from '../../common/KotoDate';
import KotoDateTime from '../../common/KotoDateTime';
import RangeSlider from 'react-bootstrap-range-slider';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import moment from 'moment';
import Card from 'reactstrap/es/Card';

const generalSettingsColumns = [
  {
    Header: 'Šifra referenta',
    accessor: 'sifra_referenta'
  },
  {
    Header: 'Številka poročila',
    accessor: 'order_report_number'
  },
  {
    Header: 'Ocenjena mesečna poraba Google API',
    Cell: (row) => <div>{row?.estimated_monthly_usage?.toFixed(2)} $</div>
  }
];

const optimizerSettingsColumns = [
  {
    Header: 'Teža embalaže',
    Cell: (row) => <div>{row?.barrel_weight} kg</div>
  },
  {
    Header: 'Povprečna teža embalaže',
    Cell: (row) => <div>{row?.average_barrel_weight} kg</div>
  },
  {
    Header: 'Optimizator vedno uporabi vse voznike',
    Cell: (row) => {
      if (row?.too_few_drivers_active) {
        return <FcCheckmark size={30} />;
      } else {
        return <FcCancel size={30} />;
      }
    }
  },
  {
    Header: 'Pričakovan čas postanka na lokaciji',
    Cell: (row) => `${row?.expected_loading_time} sekund`
  },
  {
    Header: 'Zadnji izračun statistike',
    Cell: (row) => {
      if (row.last_statistics_calculation_time) {
        return <KotoDate format={'LLLL'} date={row?.last_statistics_calculation_time} />;
      }
    }
  }
];

const settingsSchema = (translate) => {
  const toShort = translate('value_to_short');
  const toLong = translate('value_to_long');
  const notBlank = translate('value_not_empty');

  return Yup.object().shape({
    barrel_weight: Yup.number()
      .min(1, toShort + ' 1')
      .max(100, toLong + ' 100')
      .required(notBlank)
  });
};

const SettingForm = ({ editingObject, refresh, onCancel, ...props }) => {
  const { translate } = useContext(I18nContext);
  return (
    <Fragment>
      <Formik
        initialValues={{
          id: editingObject ? editingObject.id : '',
          sifra_referenta: editingObject ? editingObject.sifra_referenta : '',
          barrel_weight: editingObject ? editingObject.barrel_weight : 1,
          average_barrel_weight: editingObject ? editingObject.average_barrel_weight : 36,
          too_few_drivers_active: editingObject ? editingObject.too_few_drivers_active : false,
          expected_loading_time: editingObject ? editingObject.expected_loading_time : 600
        }}
        validationSchema={settingsSchema(translate)}
        onSubmit={async (values) => {
          try {
            await apiClient.patch(`/settings/${editingObject.id}`, values);
            notificationService.success('Uspešno posodobljene nastavitve!');
            refresh();
          } catch (e) {
            props = { translate, ...props };
            handleDefaultError(e, props);
          }
          onCancel();
        }}
      >
        {(formik) => {
          return (
            <Form>
              <FormEffect
                formik={formik}
                onSubmissionError={() => notificationService.error('Please correct red colored fields')}
              />
              {editingObject && (
                <Fragment>
                  <Row className="row-margin">
                    <Col md={7}>
                      <div className="mt-1" htmlFor="sifra_referenta">
                        Šifra referenta
                      </div>{' '}
                    </Col>
                    <Col md={5}>
                      <Field name="sifra_referenta" type={'text'} component={FormInput} />
                    </Col>
                  </Row>
                  <hr />
                  <h5>Nastavitve povezane z optimizacijo</h5>
                  <Row className="row-margin">
                    <Col md={7}>
                      <div className="mt-1" htmlFor="barrel_weight">
                        Teža embalaže (kg)
                      </div>
                    </Col>
                    <Col md={5}>
                      <Field name="barrel_weight" type={'number'} component={FormInput} />
                    </Col>
                  </Row>
                  <Row className="row-margin">
                    <Col md={7}>
                      <div className="mt-1" htmlFor="average_barrel_weight">
                        Povprečna teža embalaže (kg)
                      </div>
                    </Col>
                    <Col md={5}>
                      <Field name="average_barrel_weight" type={'number'} component={FormInput} />
                    </Col>
                  </Row>
                  <Row className="row-margin">
                    <Col md={7}>
                      <div className="mt-1" htmlFor="expected_loading_time">
                        Pričakovan čas postanka na lokaciji (v sekundah)
                      </div>
                    </Col>
                    <Col md={5}>
                      <Field name="expected_loading_time" type={'number'} component={FormInput} />
                    </Col>
                  </Row>
                  <Row className="row-margin">
                    <Col md={7}>
                      <div className="mt-1" htmlFor="too_few_drivers_active">
                        Optimizator vedno uporabi vse voznike
                      </div>
                    </Col>
                    <Col md={5}>
                      <Field
                        className={'checkbox-padding mt-2'}
                        name="too_few_drivers_active"
                        type="checkbox"
                        component={FormCheckbox}
                        checked={formik.values.too_few_drivers_active}
                        size="1.8"
                        style={{ margin: '5px' }}
                      />
                    </Col>
                  </Row>
                  <hr />
                  <FormConfirmCancel translate={translate} onCancel={onCancel} />
                </Fragment>
              )}
            </Form>
          );
        }}
      </Formik>
    </Fragment>
  );
};

const ConfigGeneralSettings = () => {
  const [settings, setSettings] = useState(null);
  const { translate } = useContext(I18nContext);
  const [editing, setEditing] = useState(false);
  const [editingObject, setEditingObject] = useState(null);

  async function fetchSettings() {
    const response = await apiClient.get('/settings/1');
    setSettings(response.data);
  }

  const editingState = useState(null);

  useEffect(() => {
    fetchSettings();
  }, []);

  return (
    <Fragment>
      <ConfigSettingsContainer>
        <KotoModal title={'Urejanje splošnih nastavitev'} isOpen={editing} toggle={() => setEditing(false)}>
          <SettingForm
            editingObject={settings}
            refresh={() => fetchSettings()}
            onCancel={() => {
              setEditingObject(null);
              return setEditing(false);
            }}
          />
        </KotoModal>
        <div>
          <Row noGutters className="justify-content-between align-items-center">
            <h4 className="p-2 m-2">Nastavitve aplikacije</h4>
            <Button color={'primary'} onClick={() => setEditing(true)}>
              Uredi
            </Button>
          </Row>
          <hr />
          {settings && <KotoTwoRowTable columns={generalSettingsColumns} dataObject={settings} />}
          <hr />
          <h4 className="p-2 m-2">Nastavitve povezane z optimizacijo</h4>
          <hr />
          {settings && <KotoTwoRowTable columns={optimizerSettingsColumns} dataObject={settings} />}
        </div>
      </ConfigSettingsContainer>
    </Fragment>
  );
};

export default withAuthConsumer(ConfigGeneralSettings);
