import React, { Component, Fragment, useContext } from 'react';
import { ConfigSettingsContainer } from './ConfigSettingsContainer';
import Row from 'reactstrap/es/Row';
import Button from 'reactstrap/es/Button';
import KotoModal from '../../common/KotoModal';
import withI18n from '../../../i18n';
import CreateRepeatingOrder from './CreateRepeatingOrder';
import * as PropTypes from 'prop-types';
import apiClient from '../../../api/ApiClient';
import { handleDefaultError } from '../../../utils/response-handler';
import * as queryString from 'query-string';
import _ from 'lodash';
import withGlobalConsumer, { GlobalContext } from '../../../global/GlobalProvider';
import { Col, NavLink, Input, Label } from 'reactstrap';
import { Link } from 'react-router-dom';
import KotoTable from '../../common/KotoTable';
import Container from 'reactstrap/es/Container';
import KotoPagination from '../../common/KotoPagination';
import { weekDays } from '../../common/statuses';
import { KotoBadge } from '../../common/KotoBadge';
import withAuthConsumer from '../../../api/auth/AuthContext';
import { FaEdit } from 'react-icons/fa';
import { getColor } from '../../../utils/getColor';
import LocationSearch from '../../LocationSearch';
import { TiArrowUnsorted } from 'react-icons/ti';
import Select from 'react-select';
import moment from 'moment';
import { MdDelete } from 'react-icons/all';
import KotoPrompt from '../../common/KotoPrompt';
import { notificationService } from '../../notifications/NotificationService';
import { getFromStorage } from '../../../utils/utils';
import KotoToggle from '../../common/KotoToggle';
import Toggle from 'react-toggle';

const columns = (translate, onEdit, onDelete, markSorted, setState, state, props) => {
  async function handleSelectOrder(e, row_id) {
    //console.log(e, row_id);
    const check = state.selectedOrders.includes(row_id);
    if (!check) {
      const orders = [...state.selectedOrders];
      orders.push(row_id);
      await setState({ selectedOrders: orders });
    } else {
      const orders = state.selectedOrders.filter((item) => item != row_id);
      await setState({ selectedOrders: orders });
    }
  }

  async function handleSelectWholePage(e) {
    if (e.target.checked) {
      const orders = state.repeatingOrders.map((item) => item.id);
      await setState({ selectedOrders: orders, wholePageSelected: true });
    } else {
      await setState({ selectedOrders: [], wholePageSelected: false });
    }
  }

  return [
    {
      Header: () => (
        <div className="ml-1">
          <Label check>
            <Input
              type="checkbox"
              checked={state.wholePageSelected}
              onChange={(e) => handleSelectWholePage(e)}
              style={{ width: '50px', height: '50x' }}
            />{' '}
            x
          </Label>
        </div>
      ),
      width: 50,
      Cell: (row) => (
        <div className="ml-1">
          <Label check>
            <Input
              type="checkbox"
              checked={state.selectedOrders.includes(row.original.id)}
              onChange={(e) => handleSelectOrder(e, row.original.id)}
              style={{ width: '50px', height: '50x' }}
            />{' '}
            x
          </Label>
        </div>
      )
    },
    {
      Header: () => (
        <a id="id" style={{ cursor: 'pointer' }} onClick={markSorted}>
          {' '}
          Id <TiArrowUnsorted id={'id'} />
        </a>
      ),
      accessor: 'id',
      width: 50,
      Cell: (row) => <div className="pt-0 pb-0">{row.original.id}</div>
    },
    {
      Header: translate('locations_single'),
      id: 'location',
      width: 150,
      Cell: (row) => (
        <NavLink className="pt-0 pb-0" to={`/locations/${row.original.location.id}`} tag={Link}>
          {row.original.location.short_name}
        </NavLink>
      )
    },
    {
      Header: translate('weekday_title'),
      id: 'weekday',
      Cell: (row) => (
        <div>{translate(weekDays.find((weekDay) => weekDay.value - 1 === row.original.weekday).label)}</div>
      )
    },
    {
      Header: translate('frequency_in_weeks'),
      width: 100,
      accessor: 'frequency_in_weeks'
    },

    {
      Header: () => (
        <a id="preferred_start_time" onClick={markSorted}>
          Začetni čas
          <TiArrowUnsorted id={'preferred_start_time'} style={{ cursor: 'pointer' }} />
        </a>
      ),
      id: 'preferred_start_time',
      width: 130,
      Cell: (row) =>
        row?.original?.preferred_start_time
          ? moment(row?.original?.preferred_start_time, 'HH:mm:ss').format('HH:mm')
          : null
    },
    {
      Header: () => (
        <a id="preferred_end_time" onClick={markSorted}>
          {' '}
          Končni čas <TiArrowUnsorted id={'preferred_end_time'} style={{ cursor: 'pointer' }} />
        </a>
      ),
      id: 'preferred_end_time',
      width: 130,
      Cell: (row) =>
        row?.original?.preferred_end_time ? moment(row?.original?.preferred_end_time, 'HH:mm:ss').format('HH:mm') : null
    },
    // {
    //   Header: '# sodov',
    //   id: 'ordered_barrels',
    //   width: 200,
    //   Cell: (row) => row.original.ordered_barrels
    // },
    {
      Header: translate('user_is_active'),
      id: 'is_active',
      Cell: (row) => (
        <KotoBadge
          large
          color={row.original.is_active ? 'success' : 'danger'}
          text={row.original.is_active ? translate('boolean_true') : translate('boolean_false')}
        />
      )
    },

    {
      Header: 'Velja od',
      id: 'velja_od',
      width: 120,
      Cell: (row) => (row?.original?.start_date ? moment(row?.original?.start_date).format('LL') : null)
    },
    //

    {
      Header: '',
      id: 'edit_order',
      width: 90,
      Cell: (row) => (
        <Row noGutters className="justify-content-around">
          <FaEdit
            style={{ cursor: 'pointer' }}
            id={row}
            color={getColor('primary')}
            size={'1.3em'}
            text={row.original.ordered_number_oil}
            onClick={() => onEdit(row.original)}
          />
          <MdDelete
            style={{ cursor: 'pointer' }}
            onClick={() => onDelete(row.original)}
            size={'1.3em'}
            color={getColor('danger')}
          />
        </Row>
      )
    }
  ];
};

class ConfigOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      createOpen: false,
      url: 'repeating_order',
      ascDesc: true,
      sortParam: 'id',
      selectedLocations: getFromStorage('config-orders.selectedLocations', []),
      selectedDaysOfWeek: getFromStorage('config-orders.selectedDaysOfWeek', []),
      selectedGroup: getFromStorage('config-orders.selectedGroup', null),
      groups: [],
      count: 0,
      activePage: 1,
      filterStatus: null,
      pageSize: 18,
      repeatingOrders: null,
      selectedOrders: [],
      wholePageSelected: false,
      selectAllOrders: false,
      showOnlyActive: false
    };
    this.toggleCreateOpen = this.toggleCreateOpen.bind(this);
  }

  async handleSelectAllOrders() {
    await this.setState({ selectAllOrders: !this.state.selectAllOrders });
  }

  async batchActivate(activate) {
    try {
      const queryString = this.makeQueryString(1);
      const response = await apiClient.post(this.state.url + '/batch_activate' + queryString, {
        ids: this.state.selectedOrders,
        activate: activate,
        selectAll: this.state.selectAllOrders
      });
      const action = activate ? 'aktivirana' : 'deaktivirana';
      notificationService.success(`Izbrana naročila so bila uspešno ${action}`);
      await this.fetchRepeatingOrder(this.state.activePage);
    } catch (e) {
      notificationService.error('Nekaj je šlo narobe ... ');
    }
  }

  makeQueryString(page) {
    let ascDescPrefix = this.state.ascDesc ? '' : '-';
    let daysOfWeekSearch =
      this.state.selectedDaysOfWeek && this.state.selectedDaysOfWeek.length > 0
        ? `&weekday=${this.state.selectedDaysOfWeek?.map((e) => e.value).join(',')}`
        : '';
    let sortParamPath = this.state.sortParam ? `&sortBy=${ascDescPrefix}${this.state.sortParam}` : '';
    const multipleLocations = this.state.selectedLocations.map((location) => location.value).join();
    let location = this.state.selectedLocations.length > 0 ? `&location=${multipleLocations}` : '';
    let filterStatus = this.state.filterStatus ? `&status=${this.state.filterStatus}` : '';
    let group = this.state.selectedGroup ? `&group=${this.state.selectedGroup}` : '';
    let onlyActive = this.state.showOnlyActive ? `&onlyActive=${this.state.showOnlyActive}` : '';
    return `?limit=${this.state.pageSize}&offset=${
      this.state.pageSize * page
    }${filterStatus}${location}${sortParamPath}${daysOfWeekSearch}${group}${onlyActive}`;
  }

  async fetchRepeatingOrder(page) {
    page = page - 1;
    await this.props.startLoading();

    try {
      const queryString = this.makeQueryString(page);
      let url = this.state.url + queryString;
      const response = await apiClient.get(url);
      const count = response.data.count === 0 ? 0 : response.data.count;
      await this.setState({
        repeatingOrders: response.data.results,
        count: count,
        selectedOrders: [],
        wholePageSelected: false,
        selectAllOrders: false
      });
    } catch (e) {
      await handleDefaultError(e, this.props);
    }
    await this.props.stopLoading();
  }

  async componentWillMount() {
    let params = queryString.parse(this.props.location.search);
    let activePage = parseInt(params.activePage);

    if (params.activePage && _.isInteger(activePage)) {
      await this.setState({ activePage: activePage });
    }

    this.fetchRepeatingOrder(this.state.activePage);
    this.fetchGroups();
  }

  async fetchGroups() {
    const response = await apiClient.get('location_groups/options');
    const groups = response.data.map((item) => ({ value: item.id, label: item.name }));
    await this.setState({ groups });
  }

  async toggleCreateOpen(shouldClose) {
    let edit = {};
    if (shouldClose) {
      edit = { editingOrder: null };
    }
    await this.setState({ createOpen: !this.state.createOpen, ...edit });
  }

  handleSelected = async (page) => {
    await this.setState({
      activePage: page
    });
    await this.fetchRepeatingOrder(page);
  };
  handleEdit = async (repeatingOrder) => {
    await this.setState({
      editingOrder: repeatingOrder
    });
    await this.toggleCreateOpen();
  };
  handleDelete = async (repeatingOrder) => {
    await this.setState({
      deletingId: repeatingOrder.id,
      deleteOpen: true
    });
  };
  closeDelete = async () => {
    await this.setState({
      deletingId: null,
      deleteOpen: false
    });
  };
  executeDelete = async () => {
    await this.props.startLoading();
    try {
      const response = apiClient.delete(`${this.state.url}/${this.state.deletingId}`);
      notificationService.success('Uspešno ste izbrisali naročilo' + this.state.deletingId);
      await this.closeDelete();
      await this.fetchRepeatingOrder(this.state.activePage);
    } catch (e) {
      await handleDefaultError(e, this.props);
    }
    await this.props.stopLoading();
  };

  async handleSorted(event) {
    const sortParam = event.target.id;
    const ascDesc = sortParam === this.state.sortParam ? !this.state.ascDesc : this.state.ascDesc;
    await this.setState({
      sortParam,
      ascDesc
    });
    let params = queryString.parse(this.props.location.search);
    let activePage = parseInt(params.activePage);
    if (params.activePage && _.isInteger(activePage)) {
      await this.setState({
        activePage
      });
    }

    await this.fetchRepeatingOrder(this.state.activePage);
  }

  async clearEditingOrder() {
    await this.setState({ editingOrder: null });
  }

  handleLocationChange = async (selectedLocations) => {
    if (selectedLocations) {
      await this.setState({ selectedLocations: selectedLocations });
      localStorage.setItem('config-orders.selectedLocations', JSON.stringify(selectedLocations));
    } else {
      await this.setState({ selectedLocations: [] });
      localStorage.setItem('config-orders.selectedLocations', []);
    }
    await this.fetchRepeatingOrder(this.state.activePage);
  };

  async handleGroupChange(event) {
    await this.setState({ selectedGroup: event.target.value });
    await this.fetchRepeatingOrder(1);
  }

  async handleShowActiveChange(event) {
    await this.setState({ showOnlyActive: event.target.value });
    await this.fetchRepeatingOrder(1);
  }

  render() {
    return (
      <Fragment>
        <KotoModal
          isOpen={this.state.deleteOpen}
          toggle={() => this.closeDelete()}
          title={'Ste prepričani da želite izbrisati ponavljajoče naročilo ' + this.state.deletingId}
        >
          <KotoPrompt onConfirm={() => this.executeDelete()} onCancel={() => this.closeDelete()} />
        </KotoModal>
        <ConfigSettingsContainer>
          <Container fluid>
            <Row>
              <Col className="pl-0" md={4}>
                <h6 className="mt-2 mr-1 d-flex text-nowrap">Lokacija</h6>
                <LocationSearch
                  selectedValue={this.state.selectedLocations}
                  placeholder={this.props.translate('locations_choose')}
                  handleSelectChange={this.handleLocationChange.bind(this)}
                />
              </Col>
              <br />
              <Col className="pl-0" md={4}>
                <h6 className="mt-2 mr-1 d-flex text-nowrap">Dan v tednu</h6>
                <Select
                  className="koto-zIndex-888 w-100"
                  placeholder={'Izberi dan v tednu'}
                  isMulti
                  value={this.state.selectedDaysOfWeek}
                  isClearable={true}
                  options={[1, 2, 3, 4, 5, 6, 7].map((day) => ({
                    label: _.capitalize(moment().day(day).format('dddd')),
                    value: day - 1
                  }))}
                  onChange={async (e) => {
                    if (!e || e.length === 0) {
                      e = [];
                      localStorage.setItem('config-orders.selectedDaysOfWeek', e);
                    } else {
                      e = e.map((v) => ({ value: v.value, label: v.label }));
                    }
                    localStorage.setItem('config-orders.selectedDaysOfWeek', JSON.stringify(e));

                    this.setState({
                      selectedDaysOfWeek: e
                    });

                    await this.fetchRepeatingOrder(this.state.activePage);
                  }}
                />
              </Col>
              <Col>
                <h6 className="mt-2 mr-1 d-flex text-nowrap">Skupina</h6>
                <Input
                  type="select"
                  name="select"
                  className="small w-100"
                  id="exampleSelect2"
                  value={this.state.group}
                  onChange={this.handleGroupChange.bind(this)}
                >
                  <option key="" value="">
                    {this.props.translate('all')}
                  </option>
                  {this.state.groups?.map((group) => (
                    <option key={group.value} value={group.value}>
                      {group.label}
                    </option>
                  ))}
                </Input>
              </Col>
              <Col>
                <h6 className="mt-2 mr-1 d-flex text-nowrap">Aktivnost</h6>
                <Input
                  type="select"
                  name="select"
                  className="small w-100"
                  id="exampleSelect2"
                  value={this.state.group}
                  onChange={(e) => this.handleShowActiveChange(e)}
                >
                  <option key="all" value="all">
                    Pokaži vse
                  </option>
                  <option key="active" value="active">
                    Samo aktivne
                  </option>
                  <option key="inactive" value="inactive">
                    Samo neaktivne
                  </option>
                </Input>
              </Col>
            </Row>
            <br />
            <div className="d-block d-md-none pt-2"></div>
            <Row className="w-100">
              <Button className="mr-2" color={'primary'} onClick={() => this.toggleCreateOpen(true)}>
                Dodaj novo naročilo
              </Button>
              <Button className="mr-2" color={'success'} onClick={() => this.batchActivate(true)}>
                Aktiviraj
              </Button>
              <Button className="mr-2" color={'danger'} onClick={() => this.batchActivate(false)}>
                Deaktiviraj
              </Button>
              <div className="custom-control custom-checkbox mt-2 ml-2">
                <Input
                  type="checkbox"
                  className="custom-control-input"
                  id="doForAll"
                  onChange={(e) => this.handleSelectAllOrders()}
                  checked={this.state.selectAllOrders}
                />
                <label className="custom-control-label" htmlFor="doForAll">
                  Naredi akcijo za vsa ponavljajoča naročila v filtru ({this.state.count} naročil)
                </label>
              </div>
              <KotoModal
                title={
                  this.state.editingOrder
                    ? this.props.translate('order_update_repeating')
                    : this.props.translate('order_create_repeating')
                }
                isOpen={this.state.createOpen}
                toggle={this.toggleCreateOpen}
              >
                <CreateRepeatingOrder
                  refresh={(aa) => this.fetchRepeatingOrder(this.state.activePage)}
                  currentPage={this.state.activePage}
                  repeatingOrder={this.state.editingOrder}
                  clearEditingOrder={this.clearEditingOrder.bind(this)}
                  toggle={this.toggleCreateOpen}
                />
              </KotoModal>
            </Row>
            <br />
            <Row className="pr-3">
              {this.state.repeatingOrders && (
                <KotoTable
                  style={{ width: '100%' }}
                  columns={columns(
                    this.props.translate,
                    this.handleEdit.bind(this),
                    this.handleDelete.bind(this),
                    this.handleSorted.bind(this),
                    this.setState.bind(this),
                    this.state,
                    this.props
                  )}
                  data={this.state.repeatingOrders}
                  defaultPageSize={this.state.pageSize}
                  minRows={this.state.pageSize}
                />
              )}
            </Row>
            <KotoPagination
              totalItems={this.state.count}
              pageSize={this.state.pageSize}
              activePage={this.state.activePage}
              onSelect={this.handleSelected.bind(this)}
            />
          </Container>
        </ConfigSettingsContainer>
      </Fragment>
    );
  }
}

ConfigOrders.propTypes = { props: PropTypes.any };

export default withI18n(withGlobalConsumer(withAuthConsumer(ConfigOrders)));
