import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import withAuthConsumer from '../../../api/auth/AuthContext';
import withGlobalConsumer from '../../../global/GlobalProvider';
import { ConfigSettingsContainer } from './ConfigSettingsContainer';
import { Row, Col, Button, Input } from 'reactstrap';
import apiClient from '../../../api/ApiClient';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import KotoDate from '../../common/KotoDate';
import KotoTable from '../../common/KotoTable';
import { notificationService } from '../../notifications/NotificationService';
import { downloadOrderExcel, handleDownload } from '../orders/Orders';

export async function downloadLocationBarrelExcel(e) {
  const response = await apiClient.get(`/location/download_barrel_report`, {
    responseType: 'blob',
    headers: {
      'Content-Type': 'application/json'
    }
  });
  //console.log(response);
  await handleDownload(response, `PorociloSodovPoLokacijah.xlsx`);
}

const BarrelDiscrepancy = (props) => {
  const [orders, setOrders] = useState([]);
  const [page, setPage] = useState(0);
  const [totalBarrels, setTotalBarrels] = useState(0);
  const [totalOrders, setTotalOrders] = useState(0);
  const [totalLocations, setTotalLocations] = useState(0);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [locations, setLocations] = useState([]);

  function parseQueryParams() {
    const limit = 'limit=20';
    const offset = page ? `offset=${20 * page}` : 'offset=0';
    const location = selectedLocation ? `location=${selectedLocation}` : '';

    return `${limit}&${offset}&${location}`;
  }

  async function fetchData() {
    const url = `/order/barrel_difference?${parseQueryParams()}`;
    try {
      const response = await apiClient.get(url);
      await setTotalBarrels(response?.data?.total_barrels);
      await setOrders(response?.data?.orders);
    } catch (e) {
      notificationService.error(e);
    }
  }

  async function fetchLocations() {
    try {
      const response = await apiClient.get('/location/options');
      setTotalLocations(response.data.length || 0);

      setLocations(
        response.data.map((item) => {
          return { value: item.id, label: item.short_name };
        })
      );
    } catch (e) {
      notificationService.error(e);
    }
  }

  function handlePageChange(change) {
    const maxPage = Math.floor(totalLocations / 20);
    let newPage = page + change;
    if (newPage < 0) {
      newPage = 0;
    }
    if (newPage > maxPage) {
      newPage = maxPage;
    }
    setPage(newPage);
  }

  useEffect(() => {
    fetchData();
  }, [page]);

  useEffect(() => {
    fetchData();
  }, [selectedLocation]);

  useEffect(() => {
    fetchLocations();
  }, []);

  const orderColumns = [
    {
      Header: 'id',
      Cell: (row) => {
        return <Link to={`/orders/${row.original?.id}`}>{row.original?.id}</Link>;
      }
    },
    {
      Header: 'Datum',
      Cell: (row) => {
        return <KotoDate format={'LL'} date={row.original.order_completed_time} />;
      }
    },
    {
      Header: 'Lokacija',
      Cell: (row) => {
        return <Link to={`/locations/${row.original?.location?.id}`}>{row.original?.location.short_name}</Link>;
      }
    },
    {
      Header: 'Pobranih kosov embalaže',
      Cell: (row) => <strong>{row.original?.barrels_collected}</strong>
    },
    {
      Header: 'Vrnjenih kosov embalaže',
      Cell: (row) => <strong>{row.original?.barrels_returned}</strong>
    },
    {
      Header: '+- kosov embalaže na lokaciji',
      Cell: (row) => <strong>{row.original?.barrels_returned - row.original?.barrels_collected}</strong>
    }
  ];

  return (
    <ConfigSettingsContainer>
      <Row>
        <Col md={8}>
          <h4 className="p-2 m-2">Razlike v pobranih/vrnjenih kosov embalaže</h4>
          <h5 className="p-2 m-2">Ocenjeno število kosov embalaže pri strankah: {totalBarrels}</h5>
        </Col>
        <Col md={4}>
          <Row className="justify-content-end mt-3 mr-3">
            <Button className="mr-3" color="primary" onClick={(e) => downloadLocationBarrelExcel(e)}>
              Prenesi Porocilo Kosov Embalaže Po Lokacijah{' '}
            </Button>
            <Col>
              <h6 className="text-nowrap">Lokacija</h6>
              <Input
                type="select"
                name="select"
                className="small w-100"
                id="selectLocation"
                value={selectedLocation}
                onChange={(e) => setSelectedLocation(e.target.value)}
              >
                <option key="" value="">
                  Vse
                </option>
                {locations?.map((item) => (
                  <option key={item.value} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </Input>
            </Col>
          </Row>
        </Col>
      </Row>
      <div className="m-4">
        <KotoTable data={orders} columns={orderColumns} />
      </div>
      <Row>
        <Col className="mt-3" md={{ size: 4, offset: 4 }}>
          <Button color="primary" onClick={() => handlePageChange(-1)}>
            Prejšna
          </Button>
          <span className="ml-4 mr-4">Stran: {page + 1}</span>
          <Button color="primary" onClick={() => handlePageChange(+1)}>
            Naslednja
          </Button>
        </Col>
      </Row>
    </ConfigSettingsContainer>
  );
};

export default withGlobalConsumer(withAuthConsumer(BarrelDiscrepancy));
