import React, { Component } from 'react';
import apiClient from '../../../api/ApiClient';
import 'react-table/react-table.css';
import KotoPagination from '../../common/KotoPagination';
import { Col, NavLink, ButtonGroup, Row } from 'reactstrap';
import withGlobalConsumer from '../../../global/GlobalProvider';
import KotoContainer from '../KotoContainer';
import Button from 'reactstrap/es/Button';
import { notificationService } from '../../notifications/NotificationService';
import withAuthConsumer from '../../../api/auth/AuthContext';
import KotoModal from '../../common/KotoModal';
import withI18n from '../../../i18n';
import KotoTable from '../../common/KotoTable';
import { Link } from 'react-router-dom';
import UpdateLocation from './UpdateLocation';
import * as queryString from 'query-string';
import _ from 'lodash';
import { SearchBar } from '../../SearchBar';
import { KotoBadge } from '../../common/KotoBadge';
import { locationStatuses } from '../../common/statuses';
import { providers } from '../../navbar/providers';
import { getFromStorage } from '../../../utils/utils';

export async function handleDownload(response, filename) {
  const a = document.createElement('a');
  a.href = window.URL.createObjectURL(response.data);
  //firefox does not initiate a download if not for the following two lines
  document.body.appendChild(a);
  a.setAttribute('type', 'hidden');
  a.download = filename;
  a.click();
}

export const columnsLocation = (translate, externalPage) => {
  return [
    {
      Header: 'Id',
      accessor: 'id',
      width: 50,
      Cell: (row) => (
        <NavLink className="pt-0 pb-0" to={`/locations/${row.original.id}`} tag={Link}>
          {row.original.id}
        </NavLink>
      )
    },
    {
      Header: 'Ponudnik',
      accessor: 'provider',
      id: 'provider',
      width: 220,
      Cell: function (row) {
        return <div>{providers[row.original?.provider?.id]}</div>;
      }
    },

    {
      Header: 'Ime',
      id: 'short_name',
      width: 200,
      accessor: 'short_name'
    },

    {
      Header: translate('client_single'),
      width: 300,
      id: 'client_single',
      show: !externalPage,
      Cell: (row) => (
        <NavLink className="pt-0 pb-0" to={`/clients/${row.original.client.id}`} tag={Link}>
          {row.original.client.short_name}
        </NavLink>
      )
    },

    {
      Header: translate('largo_location_code'),
      accessor: 'largo_location_code'
    },

    {
      Header: translate('location_optimizer_status'),
      id: 'location_optimizer_status',
      width: 200,
      Cell: (row) => {
        const status = locationStatuses[row.original.optimizer_status];
        return <KotoBadge color={status?.color} text={translate(status?.name)} />;
      }
    },
    {
      Header: 'Vikend',
      id: 'is_active',
      Cell: (row) => (
        <KotoBadge
          color={row.original?.can_have_orders_on_weekends ? 'success' : 'danger'}
          text={row.original?.can_have_orders_on_weekends ? translate('boolean_true') : translate('boolean_false')}
        />
      )
    },

    {
      Header: 'Praznik',
      id: 'locations_can_have_orders_on_holiday',
      Cell: (row) => {
        return (
          <KotoBadge
            color={row.original?.can_have_orders_on_holiday ? 'success' : 'danger'}
            text={row.original?.can_have_orders_on_holiday ? translate('boolean_true') : translate('boolean_false')}
          />
        );
      }
    },

    {
      Header: 'Časovni interval',
      accessor: 'minimal_time_window_in_minutes'
    },

    {
      Header: 'Dovoljeno razširjanje optimizatorja',
      id: 'can_optimizer_widen_time_window',
      width: 300,
      Cell: (row) => (
        <KotoBadge
          color={row?.original?.can_optimizer_widen_time_window === 1 ? 'success' : 'danger'}
          text={
            row.original?.can_optimizer_widen_time_window === 1 ? translate('boolean_true') : translate('boolean_false')
          }
        />
      )
    },
    {
      Header: translate('locations_address'),
      width: 200,
      id: 'locations_address',
      Cell: (row) => <div>{row.original.street_name + ' ' + row.original.street_number}</div>
    },

    {
      Header: translate('locations_post'),
      width: 200,
      id: 'locations_post',
      Cell: (row) => <div>{row.original.post_code + ' ' + row.original.city}</div>
    }
  ];
};

class Locations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      createOpen: false,
      locations: [],
      count: 1,
      pageSize: 20,
      activePage: 0,
      searchValue: getFromStorage('locations.search', ''),
      searchKey: 'locations.search',
      url: '/location',
      radioOption: 'basicData'
    };
  }

  statsAlltimeColumns = () => {
    return [
      {
        Header: 'Id',
        accessor: 'id',
        width: 100
      },
      {
        Header: 'Ime',
        accessor: 'short_name',
        width: 400,
        Cell: (row) => (
          <NavLink className="pt-0 pb-0" to={`/locations/${row.original.id}`} tag={Link}>
            {row.original.short_name}
          </NavLink>
        )
      },
      {
        Header: 'Število zaključenih naročil',
        accessor: 'completed_orders_alltime'
      },
      {
        Header: 'Povprečno št. kilogramov naročila (kg)',
        accessor: 'alltime_collected_weight_average'
      },
      {
        Header: 'Standardni odklon',
        accessor: 'alltime_collected_weight_std'
      },
      {
        Header: 'Ocenjena teža pobiranja (kg)',
        accessor: 'alltime_weight_prediction',
        Cell: (row) => <span style={{ color: 'green', fontWeight: 900 }}>{row.original.alltime_weight_prediction}</span>
      },
      {
        Header: 'Povprečno število kosov embalaže',
        accessor: 'alltime_collected_number_average'
      }
    ];
  };

  statsPeriodColumns = () => {
    return [
      {
        Header: 'Id',
        accessor: 'id',
        width: 100
      },
      {
        Header: 'Ime',
        accessor: 'short_name',
        width: 400,
        Cell: (row) => (
          <NavLink className="pt-0 pb-0" to={`/locations/${row.original.id}`} tag={Link}>
            {row.original.short_name}
          </NavLink>
        )
      },
      {
        Header: 'Število zaključenih naročil',
        accessor: 'completed_orders_period'
      },
      {
        Header: 'Povprečno št. kilogramov naročila (kg)',
        accessor: 'period_collected_weight_average'
      },
      {
        Header: 'Standardni odklon',
        accessor: 'period_collected_weight_std'
      },
      {
        Header: 'Ocenjena teža pobiranja (kg)',
        Cell: (row) => <span style={{ color: 'green', fontWeight: 900 }}>{row.original.period_weight_prediction}</span>
      },
      {
        Header: 'Povprečno število kosov embalaže',
        accessor: 'period_collected_number_average'
      }
    ];
  };

  async componentWillMount() {
    let params = queryString.parse(this.props.location.search);
    let activePage = parseInt(params.activePage);

    if (params.activePage && _.isInteger(activePage)) {
      await this.setState({ activePage: activePage });
    }
    await this.fetchLocations(this.state.activePage);
  }

  async execSearch(searchValue) {
    await this.setState({ searchValue: searchValue });
    await this.fetchLocations(this.state.activePage);
  }

  async execClear() {
    await this.setState({ searchValue: '' });
    await this.fetchLocations(this.state.activePage);
  }

  async fetchLocations(page) {
    await this.props.startLoading();
    page = page - 1;

    const searchParam =
      this.state.searchValue && this.state.searchValue.length > 0 ? `&search=${this.state.searchValue}` : '';
    try {
      const response = await apiClient.get(
        this.state.url + `?limit=${this.state.pageSize}&offset=${this.state.pageSize * page}${searchParam}`
      );
      await this.setState({
        locations: response.data.results,
        count: response.data.count
      });
    } catch (e) {
      if (e.response.status === 401) {
        this.props.logout();
      } else {
        notificationService.error('Something went wrong');
      }
    }

    await this.props.stopLoading();
  }

  async downloadProductExcel() {
    const searchParam =
      this.state.searchValue && this.state.searchValue.length > 0 ? `&search=${this.state.searchValue}` : '';
    const url = '/location/download_products_excel?' + `limit=${this.state.pageSize}&offset=0${searchParam}`;
    const response = await apiClient.get(url, {
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json'
      }
    });
    await handleDownload(response, `location_products.xlsx`);
  }

  handleSelected = async (page) => {
    await this.fetchLocations(page);
  };

  async toggleCreateOpen() {
    await this.setState({ createOpen: !this.state.createOpen });
  }

  render() {
    return (
      <div>
        <KotoContainer name={this.props.translate('locations_title')}>
          <Row className="pb-2">
            <Col sm={3}>
              <Button color="primary" style={{ float: 'left' }} onClick={this.toggleCreateOpen.bind(this)}>
                {this.props.translate('button_add_new')}
              </Button>
              <Button
                color="success"
                style={{ float: 'left', marginLeft: '4px' }}
                onClick={() => this.downloadProductExcel()}
              >
                Prenesi excel s produkti ({this.state.count})
              </Button>
            </Col>
            <Col sm={9}>
              <ButtonGroup style={{ float: 'right' }}>
                <Button
                  color="warning"
                  onClick={() => this.setState({ radioOption: 'basicData' })}
                  active={this.state.radioOption === 'basicData'}
                >
                  Osnovni podatki
                </Button>
                <Button
                  color="warning"
                  onClick={() => this.setState({ radioOption: 'alltimeStats' })}
                  active={this.state.radioOption === 'alltimeStats'}
                >
                  Skupna statistika pobiranja
                </Button>
                {/* <Button
                  color="warning"
                  onClick={() => this.setState({ radioOption: 'periodStats' })}
                  active={this.state.radioOption === 'periodStats'}
                >
                  Statistika pobiranja v izbranem obdobju
                </Button> */}
              </ButtonGroup>
            </Col>
          </Row>

          <SearchBar
            placeHolder={'Išči lokacije'}
            onSearch={this.execSearch.bind(this)}
            saveKey={this.state.searchKey}
            onClear={this.execClear.bind(this)}
            className={'mb-2'}
          />

          <KotoModal
            title={this.props.translate('locations_create_new')}
            isOpen={this.state.createOpen}
            toggle={this.toggleCreateOpen.bind(this)}
          >
            <UpdateLocation toggle={this.toggleCreateOpen.bind(this)} refresh={this.fetchLocations.bind(this)} />
          </KotoModal>

          {this.state.radioOption === 'basicData' && (
            <KotoTable
              data={this.state.locations}
              defaultPageSize={this.state.pageSize}
              minRows={this.state.pageSize}
              columns={columnsLocation(this.props.translate)}
            />
          )}

          {this.state.radioOption === 'alltimeStats' && (
            <KotoTable
              data={this.state.locations}
              defaultPageSize={this.state.pageSize}
              minRows={this.state.pageSize}
              columns={this.statsAlltimeColumns()}
            />
          )}

          {this.state.radioOption === 'periodStats' && (
            <KotoTable
              data={this.state.locations}
              defaultPageSize={this.state.pageSize}
              minRows={this.state.pageSize}
              columns={this.statsPeriodColumns()}
            />
          )}
          <Col sm="12" md={{ size: 6, offset: 3 }}>
            <KotoPagination
              totalItems={this.state.count}
              pageSize={this.state.pageSize}
              activePage={this.state.activePage}
              onSelect={this.handleSelected.bind(this)}
            />
          </Col>
        </KotoContainer>
      </div>
    );
  }
}

export default withGlobalConsumer(withI18n(withAuthConsumer(Locations)));
