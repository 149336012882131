import React, { Fragment, useContext, useEffect, useState } from 'react';
import { ConfigSettingsContainer } from './ConfigSettingsContainer';
import apiClient from '../../../api/ApiClient';
import KotoPagination from '../../common/KotoPagination';
import { Col, Label, NavLink } from 'reactstrap';
import * as queryString from 'query-string';
import _ from 'lodash';
import { Link, withRouter } from 'react-router-dom';
import withI18n from '../../../i18n';
import Row from 'reactstrap/es/Row';
import Button from 'reactstrap/es/Button';
import KotoModal from '../../common/KotoModal';
import withGlobalConsumer, { GlobalContext } from '../../../global/GlobalProvider';
import { handleDefaultError } from '../../../utils/response-handler';
import KotoDate from '../../common/KotoDate';
import { notificationService } from '../../notifications/NotificationService';
import { useSetState } from 'react-use';
import { CreateOptimizerSetting } from './CreateOptimizerSetting';
import KotoTable from '../../common/KotoTable';
import { KotoSubTable } from '../../common/KotoSubTable';
import { MdDelete } from 'react-icons/all';
import KotoPrompt from '../../common/KotoPrompt';
import UpdateOptimizerSetting from './UpdateOptimizerSetting';
import { CreateOptimizerVehicle } from './CreateOptimizerVehicle';
import { FiEdit } from 'react-icons/fi';
import { FaTruck } from 'react-icons/fa';
import AdvancedOptimizationModal from './AdvancedOptimizationModal';
import ReactTooltip from 'react-tooltip';
import { BsInfoCircle } from 'react-icons/bs';

const ConfigOptimizer = ({ location, startLoading, stopLoading, drivers, vehicles, translate, ...props }) => {
  props = { translate, ...props };
  const [optimizerSettings, setOptimizerSettings] = useState([]);
  const [page, setPage] = useState(0);
  const [totalItems, setTotalItems] = useState(1);
  const pageSize = 18;
  const [settings, setSettings] = useState('cund');
  const { pools } = useContext(GlobalContext);
  const [recentOptimizations, setRecentOptimizations] = useState([]);
  const [advancedOptimizationModalOpen, setAdvancedOptimizationModalOpen] = useState(false);

  const [newOptimizerSettings, setNewOptimizerSettings] = useSetState({
    expanded: { 0: false },
    createFormOpened: false
  });

  async function handleSelected(page) {
    await fetchOptimizer(page);
  }

  async function fetchSettings() {
    const response = await apiClient.get('/settings');
    setSettings(response.data.results);
  }

  async function fetchOptimizer(page) {
    let params = queryString.parse(location.search);
    let activePage = parseInt(params.activePage);

    if (params.activePage && _.isInteger(activePage)) {
      setPage(activePage);
    }
    let response = [];
    try {
      response = await apiClient.get(`/optimizer_settings?limit=${pageSize}&offset=${pageSize * page}&sortBy=id`);
    } catch (e) {
      handleDefaultError(e);
      return;
    }

    setOptimizerSettings(response.data.results);
    setTotalItems(response.data.count);
  }

  async function fetchOptimizations() {
    let response = [];
    try {
      response = await apiClient.get(`/optimization/latest`);
    } catch (e) {
      handleDefaultError(e);
      return;
    }

    setRecentOptimizations(response.data);
  }

  async function cancelOptimization(optimization_pk) {
    let url = `/optimization/${optimization_pk}/stop`;
    try {
      await apiClient.post(url);
      await fetchOptimizations();
    } catch (e) {
      try {
        handleDefaultError(e);
        return;
      } catch (e) {
        return;
      }
    }
  }

  useEffect(() => {
    fetchOptimizer(page);
    fetchSettings();
    fetchOptimizations();

    const interval = setInterval(() => {
      fetchOptimizations();
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  async function updateName(id, updatedName) {
    startLoading();
    try {
      const response = await apiClient.patch(`optimizer_settings/${id}`, { short_name: updatedName });

      await fetchOptimizer(page);
    } catch (e) {
      await handleDefaultError(e, { translate: translate, stopLoading: stopLoading, startLoading });
    }
    stopLoading();

    setNewOptimizerSettings({ deleteConfig: null });
  }

  const columns = [
    {
      Header: 'Id',
      width: 50,
      accessor: 'id'
    },
    {
      Header: translate('short_name'),

      Cell: (row) => {
        const [editing, setEditing] = useState(false);
        const [value, setValue] = useState(row.original?.short_name);

        return (
          <div>
            {editing ? (
              <div className="align-items-center d-flex justify-content-center">
                <input
                  style={{ marginBottom: '4px' }}
                  value={value}
                  onChange={(e) => {
                    setValue(e.target.value);
                  }}
                />
                <div>
                  <Button
                    size={'sm'}
                    style={{ marginBottom: '3px', marginLeft: '2px' }}
                    color={'primary'}
                    onClick={async (e) => {
                      await updateName(row.original.id, value);
                    }}
                  >
                    Shrani
                  </Button>
                  <Button
                    size={'sm'}
                    style={{ marginBottom: '3px', marginLeft: '2px' }}
                    onClick={(e) => setEditing(false)}
                    color={'danger'}
                  >
                    Prekliči
                  </Button>
                </div>
              </div>
            ) : (
              <div className="align-items-center">
                {row.original.short_name}

                <FiEdit style={{ cursor: 'pointer' }} onClick={(e) => setEditing(true)} className={'ml-2 mb-1'} />
              </div>
            )}
          </div>
        );
      }
    },

    {
      Header: translate('optimizer_setting_active'),
      Cell: (row) => (
        <input
          type="radio"
          checked={row.original.is_active}
          onChange={async (event) => {
            startLoading();
            try {
              await apiClient.post(`optimizer_settings/${row.original?.id}/set_active`, {});
            } catch (e) {
              await handleDefaultError(e);
            }
            await fetchOptimizer(page);
            stopLoading();
          }}
        />
      )
    },

    {
      Header: '',
      width: 120,
      Cell: (row) => {
        return (
          <Row className="justify-content-around align-items-center" noGutters>
            <Button
              color={'primary'}
              size={'sm'}
              onClick={() =>
                setNewOptimizerSettings({
                  duplicateConfig: {
                    open: true,
                    id: row?.original.id
                  }
                })
              }
            >
              Podvoji
            </Button>

            <MdDelete
              className="text-danger "
              style={{ cursor: 'pointer' }}
              size="1.5em"
              onClick={() =>
                setNewOptimizerSettings({
                  deleteConfig: {
                    open: true,
                    id: row?.original.id
                  }
                })
              }
            ></MdDelete>
          </Row>
        );
      }
    }
  ];

  const optimizationTableColumns = [
    {
      Header: 'id',
      width: 100,
      accessor: 'pk'
    },
    {
      Header: 'Datum optimizacije',
      width: 200,
      accessor: 'target_date',
      Cell: (row) => {
        return <KotoDate format={'LL'} date={row.original.target_date} />;
      }
    },
    {
      Header: 'Nastavitve',
      width: 150,
      Cell: (row) => {
        if (row.original.optimizer_settings) {
          const vehicles = row.original.optimizer_settings.vehicles;
          const vehicleStrings = vehicles.map((vehicle) => {
            const driver = `${vehicle.driver.first_name} ${vehicle.driver.last_name}`;
            const vehicleLicensePlate = vehicle.vehicle.license_plate;
            const timeFrame = `${vehicle.start_time} - ${vehicle.end_time}`;
            return `<li>${driver} ---- ${vehicleLicensePlate} ---- ${timeFrame}</li>`;
          });
          const toolTip = `
        <ul>
          ${vehicleStrings.join('')}
        </ul>
        `;
          return (
            <>
              <FaTruck className="mr-3" data-tip={toolTip} /> {`${vehicles.length}`}{' '}
              <ReactTooltip className="text-left" html={true} />
            </>
          );
        } else {
          return <FaTruck className="mr-3" />;
        }
      }
    },
    {
      Header: 'Čas. limit',
      width: 100,
      Cell: (row) => `${(row.original.time_limit / 60).toFixed(2)} minut`
    },
    {
      Header: 'Bazen',
      width: 250,
      accessor: 'pool'
    },
    {
      Header: 'Ustvarjeno',
      width: 225,
      accessor: 'optimization_start_time',
      Cell: (row) => {
        return <KotoDate format={'LLL'} date={row.original.optimization_start_time} />;
      }
    },
    {
      Header: 'Optimizacija končana',
      width: 225,
      Cell: (row) => {
        if (row.original.optimization_finished_time) {
          return <KotoDate format={'LLL'} date={row.original.optimization_finished_time} />;
        } else {
          return '';
        }
      }
    },
    {
      Header: 'Status optimizacije',
      width: 200,
      accessor: 'status_display'
    },
    {
      Header: '',
      width: 150,
      Cell: (row) => {
        if ([1, 2].includes(row.original.status)) {
          return (
            <Row className="justify-content-around align-items-center" noGutters>
              <Button color={'danger'} size={'sm'} onClick={() => cancelOptimization(row.original.pk)}>
                Prekliči
              </Button>
            </Row>
          );
        } else {
          return '';
        }
      }
    }
  ];

  async function deleteOptimizerSetting() {
    startLoading();
    try {
      const response = await apiClient.delete(`optimizer_settings/${newOptimizerSettings?.deleteConfig?.id}`);
      notificationService.success('Uspešno odstranjena nastavitev optimizatorja');
      await fetchOptimizer(page);
    } catch (e) {
      await handleDefaultError(e, { translate: translate, stopLoading: stopLoading, startLoading });
    }
    stopLoading();

    setNewOptimizerSettings({ deleteConfig: null });
  }

  async function duplicateOptimizerSetting() {
    startLoading();
    try {
      const response = await apiClient.post(
        `optimizer_settings/${newOptimizerSettings?.duplicateConfig?.id}/duplicate`
      );
      notificationService.success(
        'Uspešno duplicirane nastavitve optimizatorja' + newOptimizerSettings?.duplicateConfig?.id
      );
      await fetchOptimizer(page);
    } catch (e) {
      await handleDefaultError(e, { translate: translate, stopLoading: stopLoading, startLoading });
    }
    stopLoading();

    setNewOptimizerSettings({ duplicateConfig: null });
  }

  async function vehicleDeleteOptimizerSetting() {
    startLoading();
    try {
      const response = await apiClient.post(
        `optimizer_settings/${newOptimizerSettings?.vehicleDeleteConfig?.settingId}/change_vehicles`,
        { remove_ids: [newOptimizerSettings?.vehicleDeleteConfig?.id] }
      );
      notificationService.success('Uspešno odstranjeno vozilo opzimiratorja');
      await fetchOptimizer(page);
    } catch (e) {
      await handleDefaultError(e, { translate: translate, stopLoading: stopLoading, startLoading });
    }
    stopLoading();
    setNewOptimizerSettings({ vehicleDeleteConfig: null });
  }

  async function handleOptimize(values) {
    try {
      await apiClient.post('/optimization/optimize', values);
      notificationService.success('Zahtevek za optimizacijo uspešno poslan!');
      await fetchOptimizations();
      setAdvancedOptimizationModalOpen(false);
    } catch (e) {
      notificationService.error(JSON.stringify(e.response?.data));
    }
  }

  const optimizationHelp = `Optimizacija bo sedaj striktno upoštevala časovni limit. <br/> 
    Če bo po izteku časovnega limita že najdena kakšna rešitev, potem se bo optimizacija takoj zaključila,
    sicer bo optimizacija potekala toliko časa dokler ne najdemo prve rešitve. <br />
    Časovni limit se začne šteti od trenutka, ko optimizator začne z optimizacijo.`;

  return (
    <Fragment>
      <ConfigSettingsContainer>
        <AdvancedOptimizationModal
          isOpen={advancedOptimizationModalOpen}
          toggle={() => setAdvancedOptimizationModalOpen(!advancedOptimizationModalOpen)}
          optimizationSettings={optimizerSettings}
          pools={pools}
          onSubmit={handleOptimize}
        />
        <KotoModal
          isOpen={newOptimizerSettings.deleteConfig?.open}
          toggle={() => setNewOptimizerSettings({ deleteConfig: null })}
          title={
            'Ste prepričani da želite izbrisati nastavitve optimizatorja ' +
            newOptimizerSettings?.deleteConfig?.id +
            '?'
          }
        >
          <KotoPrompt
            onConfirm={async () => await deleteOptimizerSetting()}
            onCancel={() => setNewOptimizerSettings({ deleteConfig: null })}
          />
        </KotoModal>
        <KotoModal
          isOpen={newOptimizerSettings.duplicateConfig?.open}
          toggle={() => setNewOptimizerSettings({ duplicateConfig: null })}
          title={
            'Ste prepričani da želite podvojiti nastavitve optimizatorja ' +
            newOptimizerSettings?.duplicateConfig?.id +
            '?'
          }
        >
          <KotoPrompt
            onConfirm={async () => await duplicateOptimizerSetting()}
            onCancel={() => setNewOptimizerSettings({ duplicateConfig: null })}
          />
        </KotoModal>

        <KotoModal
          isOpen={newOptimizerSettings.vehicleDeleteConfig?.open}
          toggle={() => setNewOptimizerSettings({ vehicleDeleteConfig: null })}
          title={
            'Ste prepričani da želite izbrisati vozilo  ' +
            newOptimizerSettings?.vehicleDeleteConfig?.id +
            ' iz nastavitev optimizatorja?'
          }
        >
          <KotoPrompt
            onConfirm={async () => await vehicleDeleteOptimizerSetting()}
            onCancel={() => setNewOptimizerSettings({ vehicleDeleteConfig: null })}
          />
        </KotoModal>
        <Row noGutters className="justify-content-lg-between alert alert-warning">
          Od 1.3.2021 dalje lahko vsakemu vozniku nastavimo tudi, ali svojo pot začenja in končuje na domu. Če želiš
          označiti, da voznik začenja pot na domu, mu moraš najprej pri uporabnikih dodati domačo lokacijo, nato pa v
          nastavitvah vozila optimizatorja obkljukati, da voznik začenja od doma. <br />
          <strong>POMEMBNO:</strong> Četudi voznik začenja od doma, mu je v nastavitvah vozila optimizatorja potrebno
          nastaviti začetno in končno lokacijo tisto lokacijo, kamor odlaga odpadke. Torej trenutno je to KOTO ali pa
          Preklada Rače. Optimizator in aplikacija pa bosta potem sama poskrbela, da bo voznik začenjal pot na domu,
          odložil odpadke na izbrani lokaciji in se nato vrnil domov. <br />
          Če voznik nima nastavljene domače lokacije, pa naj bi začenjal od doma, potem bo optimizator upošteval, da
          začenja in končuje na svojem odlagališču.
        </Row>
        <Row noGutters className="justify-content-between">
          <Button color={'primary'} onClick={() => setNewOptimizerSettings({ createFormOpened: true })}>
            Dodaj nastavitve
          </Button>
          <div>
            <BsInfoCircle size={25} data-tip={optimizationHelp} />
            <ReactTooltip html />
            <Button className="ml-2" color={'success'} onClick={() => setAdvancedOptimizationModalOpen(true)}>
              Začni napredno optimizacijo
            </Button>
          </div>
        </Row>
        <br />
        <KotoModal
          size={'xl'}
          toggle={() => setNewOptimizerSettings({ createFormOpened: false })}
          isOpen={newOptimizerSettings.createFormOpened}
          title={'Dodajanje nastavitev optimizatorja'}
        >
          <CreateOptimizerSetting
            refetch={() => fetchOptimizer(page)}
            onClose={() => setNewOptimizerSettings({ createFormOpened: false })}
          />
        </KotoModal>
        <KotoModal
          isOpen={newOptimizerSettings?.updateConfig?.open}
          title={'Posodabljanje imena optimizatorja ' + newOptimizerSettings?.updateConfig?.id}
          toggle={() => setNewOptimizerSettings({ updateConfig: null })}
        >
          <UpdateOptimizerSetting
            refresh={() => fetchOptimizer(page)}
            optimizerSetting={newOptimizerSettings?.updateConfig?.optimizerSetting}
            toggle={() => setNewOptimizerSettings({ updateConfig: null })}
          ></UpdateOptimizerSetting>
        </KotoModal>

        <KotoModal
          isOpen={newOptimizerSettings?.vehicleAddConfig?.open}
          title={'Dodajanje voznika optimizatorju '}
          toggle={() => setNewOptimizerSettings({ vehicleAddConfig: null })}
        >
          <CreateOptimizerVehicle
            refresh={() => fetchOptimizer(page)}
            availableDrivers={newOptimizerSettings?.vehicleAddConfig?.availableDrivers}
            availableVehicles={newOptimizerSettings?.vehicleAddConfig?.availableVehicles}
            optimizerSetting={newOptimizerSettings?.vehicleAddConfig?.optimizerSetting}
            toggle={() => setNewOptimizerSettings({ vehicleAddConfig: null })}
          ></CreateOptimizerVehicle>
        </KotoModal>

        {optimizerSettings && (
          <KotoTable
            columns={columns}
            data={optimizerSettings}
            expanded={newOptimizerSettings.expanded}
            onExpandedChange={(e) => {
              setNewOptimizerSettings({ expanded: e });
            }}
            SubComponent={(sbr) => {
              if (sbr.original?.vehicles?.length >= 0) {
                return (
                  <KotoSubTable
                    data={sbr.original?.vehicles ?? []}
                    columns={[
                      {
                        Header: 'Voznik',
                        width: '15%',
                        Cell: (row) => {
                          const driver = row?.driver;
                          if (driver?.first_name || driver?.last_name)
                            return <div>{driver?.first_name + ' ' + driver?.last_name}</div>;
                        }
                      },
                      {
                        Header: 'Bazen',
                        accessor: 'pool',
                        width: '15%'
                      },
                      {
                        Header: 'Vozilo',
                        width: '10%',
                        Cell: (row) => {
                          const vehicle = row?.vehicle;
                          if (vehicle?.license_plate)
                            return (
                              <NavLink className="p-0" to={`/vehicles/${vehicle.id}`} tag={Link}>
                                {vehicle.license_plate}
                              </NavLink>
                            );
                        }
                      },
                      {
                        Header: 'Začetna lokacija',
                        accessor: 'start_location',
                        width: '10%',
                        Cell: (row) => {
                          const startLocation = row?.start_location;
                          if (startLocation?.id && startLocation?.short_name)
                            return (
                              <NavLink className="p-0" to={`/locations/${startLocation.id}`} tag={Link}>
                                {startLocation.short_name}
                              </NavLink>
                            );
                        }
                      },
                      {
                        Header: 'Končna lokacija',
                        accessor: 'end_location',
                        width: '10%',
                        Cell: (row) => {
                          const endLocation = row?.end_location;
                          if (endLocation?.id && endLocation?.short_name)
                            return (
                              <NavLink className="p-0" to={`/locations/${endLocation.id}`} tag={Link}>
                                {endLocation.short_name}
                              </NavLink>
                            );
                        }
                      },
                      {
                        Header: 'Začenja od doma?',
                        width: '10%',
                        Cell: (row) => (row?.is_starting_from_home ? 'Da' : 'Ne')
                      },
                      {
                        Header: 'Začetni čas',
                        accessor: 'start_time',
                        width: '10%'
                      },
                      {
                        Header: 'Končni čas',
                        accessor: 'end_time',
                        width: '10%'
                      },
                      {
                        Header: (row) => {
                          const currentDrivers = sbr?.original.vehicles?.map((vehicle) => vehicle.driver.pk);
                          const currentVehicles = sbr?.original.vehicles?.map((vehicle) => vehicle.vehicle.id);

                          const availableDrivers = drivers.filter((driver) => {
                            return !currentDrivers.includes(driver.value);
                          });

                          const availableVehicles = vehicles.filter((vehicle) => {
                            return !currentVehicles.includes(vehicle.value);
                          });
                          return (
                            <Row className="justify-content-center">
                              <Button
                                size="1.0em"
                                color={'primary'}
                                onClick={() => {
                                  setNewOptimizerSettings({
                                    vehicleAddConfig: {
                                      open: true,
                                      optimizerSetting: sbr.original,
                                      id: sbr.original?.id,
                                      availableDrivers: availableDrivers,
                                      availableVehicles: availableVehicles
                                    }
                                  });
                                }}
                                style={{ cursor: 'pointer' }}
                              >
                                Dodaj vozilo
                              </Button>
                            </Row>
                          );
                        },
                        width: '10%',
                        Cell: (row) => (
                          <MdDelete
                            onClick={() =>
                              setNewOptimizerSettings({
                                vehicleDeleteConfig: {
                                  open: true,
                                  id: row?.id,
                                  settingId: sbr.original.id
                                }
                              })
                            }
                            className="text-danger mr-1"
                            style={{ cursor: 'pointer' }}
                            size="1.5em"
                          ></MdDelete>
                        )
                      }
                    ]}
                  />
                );
              }
            }}
          />
        )}

        <Col sm="12" md={{ size: 6, offset: 3 }}>
          <KotoPagination totalItems={totalItems} pageSize={pageSize} onSelect={handleSelected} />
        </Col>
        <Row>
          <h5>
            <i>Zadnje optimizacije</i>
          </h5>
        </Row>
        <Row>
          <KotoTable columns={optimizationTableColumns} data={recentOptimizations} />
        </Row>
      </ConfigSettingsContainer>
    </Fragment>
  );
};

export default withRouter(withI18n(withGlobalConsumer(ConfigOptimizer)));
