import React, { useState } from 'react';
import Button from 'reactstrap/es/Button';
import Row from 'reactstrap/es/Row';
import { TiUserAdd } from 'react-icons/ti';
import withI18n from '../../../i18n';
import UsersSearch from '../../UsersSearch';
import Col from 'reactstrap/es/Col';
import apiClient from '../../../api/ApiClient';
import Loader from 'react-loader-spinner';
import { handleDefaultError } from '../../../utils/response-handler';
import { notificationService } from '../../notifications/NotificationService';
import withGlobalConsumer from '../../../global/GlobalProvider';

const UserToLocation = ({ currentUsers, translate, locationId, fetch, ...props }) => {
  const [selectedUser, setSelectedUser] = useState(null);
  const [loading, setLoading] = useState(false);

  async function addUser() {
    setLoading(true);
    try {
      const response = await apiClient.post(`location/${locationId}/users`, { add: [selectedUser.value] });
      fetch();
      setSelectedUser(null);
      notificationService.success(translate('locations_success_create_user') + `: ${selectedUser.value}`);
    } catch (e) {
      props = { translate, ...props };
      handleDefaultError(e, props);
    }
    setLoading(false);
  }

  return (
    <Row noGutters className="justify-content-end">
      <Col md={7} className="mr-2 mb-1">
        <UsersSearch currentUsers={currentUsers} handleSelectChange={setSelectedUser} value={selectedUser} />
      </Col>
      <Col md={4} sm={12} className="text-right">
        <Button onClick={async () => addUser(selectedUser)} color="primary" disabled={!selectedUser} className="w-100">
          <Row noGutters className="justify-content-between">
            <Row noGutters>
              <TiUserAdd className="text-white mr-1" style={{ cursor: 'pointer' }} size="1.5em">
                {' '}
              </TiUserAdd>

              <div>{translate('add_user_to_location')}</div>
            </Row>

            {loading && <Loader type="Oval" color="#FFFFFF" width={20} height={20} />}
          </Row>
        </Button>
      </Col>
    </Row>
  );
};

export default withI18n(withGlobalConsumer(UserToLocation));
