import React, { useContext } from 'react';
import { Field, Form, Formik } from 'formik';
import Row from 'reactstrap/es/Row';
import FormConfirmCancel from '../../common/FormConfirmCancel';
import { Col, Label } from 'reactstrap';
import FormGroup from 'reactstrap/es/FormGroup';
import { FormInput } from '../../common/FormInput';
import apiClient from '../../../api/ApiClient';
import { notificationService } from '../../notifications/NotificationService';
import { handleDefaultError } from '../../../utils/response-handler';
import { I18nContext } from '../../../i18n';
import { GlobalContext } from '../../../global/GlobalProvider';

export const AddContact = ({ locationId, toggle, fetch }) => {
  const { translate } = useContext(I18nContext);
  const { startLoading, stopLoading } = useContext(GlobalContext);
  return (
    <div>
      <Formik
        initialValues={{
          name: '',
          phone_number: '',
          email: ''
        }}
        onSubmit={async (values) => {
          startLoading();
          try {
            const response = await apiClient.post(`location/${locationId}/contacts`, { add: [values] });
            fetch();
            notificationService.success('Kontakt uspešno dodan');
            toggle();
          } catch (e) {
            await handleDefaultError(e, { translate: translate, stopLoading: stopLoading, startLoading });
          }
          stopLoading();
        }}
        render={({ values }) => (
          <Form>
            <Row form>
              <Col>
                <FormGroup>
                  <Label for="name">Ime</Label>
                  <Field name="name" type={'text'} component={FormInput} />
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col>
                <FormGroup>
                  <Label for="email">Email</Label>
                  <Field name="email" type={'email'} component={FormInput} />
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col>
                <FormGroup>
                  <Label for="phone_number">Telefonska Številka</Label>
                  <Field name="phone_number" type={'phone'} component={FormInput} />
                </FormGroup>
              </Col>
            </Row>

            <Row noGutters className="pt-2 mr-1 justify-content-end">
              <FormConfirmCancel onCancel={toggle} />
            </Row>
          </Form>
        )}
      />
    </div>
  );
};
