import React from 'react';
import { BreadcrumbItem } from 'reactstrap';
import { NavLink, withRouter } from 'react-router-dom';
import Row from 'reactstrap/es/Row';
import Col from 'reactstrap/es/Col';
import { IoMdArrowBack } from 'react-icons/io';

function KotoContainer({ name, ...props }) {
  let url = props.match.url;
  const id = props.match.params.id;
  if (id) {
    url = url.replace(id, '');
  }
  return (
    <div>
      <Row noGutters className="px-4 py-1">
        <Col>
          {id && <IoMdArrowBack className="text-secondary" onClick={() => props.history.goBack()} size={'2em'} />}
        </Col>
      </Row>
      <div className="px-4 py-2">{props.children}</div>
    </div>
  );
}

export default withRouter(KotoContainer);
