import React, { useContext } from 'react';
import { Field, FieldArray, Form, Formik } from 'formik';
import Input from 'reactstrap/es/Input';
import Row from 'reactstrap/es/Row';
import Col from 'reactstrap/es/Col';
import Label from 'reactstrap/es/Label';
import { I18nContext } from '../../../i18n';
import FormConfirmCancel from '../../common/FormConfirmCancel';
import { useSetState } from 'react-use';
import KotoModal from '../../common/KotoModal';
import KotoPrompt from '../../common/KotoPrompt';
import apiClient from '../../../api/ApiClient';
import { handleDefaultError } from '../../../utils/response-handler';
import { GlobalContext } from '../../../global/GlobalProvider';

export const UpdateCollectedDataForm = ({ orderId, orderProductSet, toggle, fetchOrder }) => {
  const { translate } = useContext(I18nContext);
  const { startLoading, stopLoading, productTypes } = useContext(GlobalContext);

  const getProductForId = (id) => {
    return productTypes.find((pt) => pt.id == id);
  };

  const initialProducts = () => {
    let productInSet = orderProductSet?.map((ops) => {
      const { product_type, collected_quantity_litres, collected_number, collected_weighted_quantity, ordered_number } =
        ops;
      return {
        id: product_type?.id,
        ordered_number: ordered_number ?? 0,
        collected_number: collected_number ?? 0,
        collected_quantity_litres: collected_quantity_litres ?? 0,
        collected_weighted_quantity: collected_weighted_quantity ?? 0
      };
    });

    const inFormIds = productInSet.map((pis) => pis?.id);
    const allIds = productTypes.map((pt) => pt.id);

    allIds
      .filter((allId) => !inFormIds.includes(allId))
      .forEach((id) => {
        const product = createEmpty(id);
        productInSet.push(product);
      });

    return productInSet;
  };

  const createEmpty = (id) => {
    return {
      id: id,
      // ordered_number: 0,
      collected_number: 0,
      collected_quantity_litres: 0,
      collected_weighted_quantity: 0
    };
  };

  const [submitState, setSubmitState] = useSetState({ values: { products: initialProducts() } });

  const executeUpdate = async ({ values }) => {
    startLoading();
    try {
      const response = await apiClient.post(`/order/${orderId}/update_collected_data`, values);
      toggle();
    } catch (e) {
      await handleDefaultError(e, { translate: translate, stopLoading: stopLoading, startLoading: startLoading });
    }
    stopLoading();
    fetchOrder();
    setSubmitState({ values: { products: initialProducts() } });
  };

  return (
    <div>
      <Formik
        initialValues={{ products: initialProducts() }}
        onSubmit={async (values) => {
          executeUpdate({ values: values });
        }}
        render={({ values }) => (
          <Form>
            <FieldArray
              name="products"
              render={(arrayHelpers) => {
                return (
                  <div>
                    {values?.products
                      ?.sort((a, b) => a.id - b.id)
                      .map((product, index) => (
                        <div key={index}>
                          <Row className="align-items-center" noGutters>
                            <div className="font-weight-bold  mr-1">Ime produkta:</div>
                            <div style={{ color: getProductForId(product.id)?.color }}>
                              {getProductForId(product.id)?.screen_name ?? ''}
                            </div>
                          </Row>
                          <Row>
                            <Col sm={4}>
                              <Label className="text-secondary">
                                {' '}
                                {translate('orderedproduct_set_collected_number')}
                              </Label>
                              <Input
                                type={'number'}
                                min={0}
                                value={product.collected_number}
                                onChange={(e) => {
                                  const newProduct = {
                                    ...product,
                                    collected_number: e.target.value
                                  };
                                  arrayHelpers.replace(index, newProduct);
                                }}
                              />
                            </Col>
                            <Col sm={4}>
                              <Label className="text-secondary">
                                {' '}
                                {translate('orderedproduct_set_collected_quantity_litres')}
                              </Label>
                              <Input
                                disabled={product.id != 2}
                                type={'number'}
                                min={0}
                                value={product.collected_quantity_litres}
                                onChange={(e) => {
                                  const newProduct = {
                                    ...product,
                                    collected_quantity_litres: e.target.value
                                  };
                                  arrayHelpers.replace(index, newProduct);
                                }}
                              />
                            </Col>
                            <Col sm={4}>
                              <Label className="text-secondary">
                                {' '}
                                {translate('orderedproduct_set_collected_weighted_quantity')}
                              </Label>
                              <Input
                                type={'number'}
                                min={0}
                                value={product.collected_weighted_quantity}
                                step={'any'}
                                onChange={(e) => {
                                  const newProduct = {
                                    ...product,
                                    collected_weighted_quantity: e.target.value
                                  };
                                  arrayHelpers.replace(index, newProduct);
                                }}
                              />
                            </Col>
                          </Row>
                          {index !== values?.products?.length - 1 && <hr />}
                        </div>
                      ))}
                  </div>
                );
              }}
            />
            <Row noGutters className="pt-2 mr-1 justify-content-end">
              <FormConfirmCancel onCancel={toggle} />
            </Row>
          </Form>
        )}
      />
    </div>
  );
};
