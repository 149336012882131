import React, { useContext } from 'react';
import { Field, FieldArray, Form, Formik } from 'formik';
import InputGroup from 'reactstrap/es/InputGroup';
import InputGroupAddon from 'reactstrap/es/InputGroupAddon';
import InputGroupText from 'reactstrap/es/InputGroupText';
import { Input } from 'reactstrap';
import { GlobalContext } from '../../../global/GlobalProvider';
import FormConfirmCancel from '../../common/FormConfirmCancel';
import apiClient from '../../../api/ApiClient';
import { notificationService } from '../../notifications/NotificationService';
import { handleDefaultError } from '../../../utils/response-handler';
import { I18nContext } from '../../../i18n';
import Col from 'reactstrap/es/Col';
import Row from 'reactstrap/es/Row';

export const UpdateClientConversions = ({ clientId, client, toggle, refresh }) => {
  const { productTypes, startLoading, stopLoading } = useContext(GlobalContext);
  const { translate } = useContext(I18nContext);

  return (
    <div>
      <Formik
        initialValues={{ ratios: client.conversions.map((c) => ({ id: c.product_type.id, ratio: c.ratio })) }}
        onSubmit={async (values) => {
          const { ratios } = values;
          const formattedRatios = ratios.map((rat) => [rat.id, rat.ratio]);
          try {
            startLoading();

            const response = await apiClient.post(`/client/${clientId}/conversions`, { ratios: formattedRatios });
            notificationService.success('Pretvorbe uspešno posodobljene');

            refresh();
            toggle();
          } catch (e) {
            await handleDefaultError(e, { startLoading: startLoading, stopLoading: stopLoading, translate: translate });
          }
          stopLoading();
        }}
        render={({ values }) => (
          <Form>
            <FieldArray
              name="ratios"
              render={(arrayHelpers) => (
                <div>
                  <Row noGutters>
                    <Col>
                      <h6 className="m-0 p-0">Produkt</h6>
                    </Col>
                    <Col>
                      <h6 className="m-0 p-0">Cena na enoto</h6>
                    </Col>
                  </Row>
                  <hr />

                  {values.ratios && values.ratios.length > 0 ? (
                    values.ratios.map((ratio, index) => {
                      const product = productTypes?.find((pt) => pt.id == ratio.id);
                      return (
                        <InputGroup className={'py-1'} key={index}>
                          <InputGroupAddon className={'w-50'} addonType="prepend">
                            <InputGroupText
                              style={{
                                backgroundColor: product?.color,
                                width: '100%',
                                color: 'white'
                              }}
                            >
                              {product?.price_conversion_name}
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            min={0}
                            type={'number'}
                            step="0.00001"
                            value={ratio.ratio}
                            onChange={(e) => {
                              arrayHelpers.replace(index, {
                                ...ratio,
                                ratio: e.target.value
                              });
                            }}
                          />
                        </InputGroup>
                      );
                    })
                  ) : (
                    <div />
                  )}
                  <FormConfirmCancel onCancel={toggle} />
                </div>
              )}
            />
            <br />
          </Form>
        )}
      />
    </div>
  );
};
