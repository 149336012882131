import React, { useEffect, useMemo, useContext } from 'react';
import KotoContainer from '../KotoContainer';
import SingleTableHeader from '../../common/SingleTableHeader';
import { Link, useParams, useHistory } from 'react-router-dom';
import { useSetState } from 'react-use';
import apiClient from '../../../api/ApiClient';
import KotoTwoRowTable from '../../common/KotoTwoRowTable';
import { NavLink } from 'reactstrap';
import { GlobalContext } from '../../../global/GlobalProvider';
import { AddVehicle } from './AddVehicle';
import KotoModal from '../../common/KotoModal';
import { handleDefaultError } from '../../../utils/response-handler';
import { I18nContext } from '../../../i18n';
import { notificationService } from '../../notifications/NotificationService';

export const SingleVehicle = () => {
  const { startLoading, stopLoading } = useContext(GlobalContext);
  const { translate } = useContext(I18nContext);
  const { id } = useParams();
  const history = useHistory();
  const [state, setState] = useSetState({
    data: [],
    editModalOpened: false
  });

  const fetch = () => {
    startLoading();
    const response = apiClient.get(`/vehicle/${id}`).then((response) => {
      const data = response?.data;
      setState({ data });
    });
    stopLoading();
  };

  const vehicleColumns = useMemo(
    () => [
      {
        Header: 'Id',
        id: 'id',
        width: 50,
        Cell: (row) => (
          <NavLink className="p-0" to={`/vehicles/${row?.id}`} tag={Link}>
            {row?.id}
          </NavLink>
        )
      },

      {
        Header: 'Ime',
        accessor: 'name'
      },
      {
        Header: 'Registrska številka',
        accessor: 'license_plate'
      },

      {
        Header: 'Kapaciteta (kg)',
        accessor: 'capacity'
      },
      {
        Header: 'Voznik',
        accessor: 'prefered_driver',
        Cell: (row) => {
          if (row?.first_name || row?.last_name)
            return (
              <NavLink className="p-0" to={`/users/${row?.pk}`} tag={Link}>
                {row?.first_name + ' ' + row?.last_name}
              </NavLink>
            );
        }
      }
    ],
    []
  );

  useEffect(() => {
    fetch();
  }, []);

  const handleDelete = async () => {
    startLoading();

    try {
      const response = await apiClient.delete(`vehicle/${id}`);
      stopLoading();
      notificationService.success('Vozilo uspešno izbrisano');
      history.push('/vehicles');
    } catch (e) {
      handleDefaultError(e, { translate: translate });
    }

    stopLoading();
  };

  return (
    <div>
      <KotoContainer name={'Vozilo'}>
        <SingleTableHeader
          deleteRestoreEnabled={true}
          deleteTitle={`Ste prepričani, da želite izbrisati vozilo ${id}?`}
          deleted={false}
          onDelete={async () => await handleDelete()}
          title={'Vozilo ' + id}
          onEdit={() => setState({ editModalOpened: true })}
        />

        <KotoModal
          title={'Urejanje vozila'}
          isOpen={state.editModalOpened}
          toggle={() => setState({ editModalOpened: !state.editModalOpened })}
        >
          <AddVehicle
            refetch={() => fetch()}
            vehicle={state.data}
            onCancel={() => setState({ editModalOpened: !state.editModalOpened })}
          />
        </KotoModal>
        <KotoTwoRowTable
          title={'Podrobnosti vozila'}
          dataObject={state.data}
          columns={vehicleColumns}
          onEdit={() => console.log('alo ktr')}
          subTable={false}
        />
      </KotoContainer>
    </div>
  );
};
