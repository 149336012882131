import React, { useContext } from 'react';
import { Formik, Form, Field, FieldArray } from 'formik';
import { Col, Label, Row } from 'reactstrap';
import FormGroup from 'reactstrap/es/FormGroup';
import { FormInput, FormSelect, FormCheckbox } from '../../common/FormInput';
import KotoDateTime from '../../common/KotoDateTime';
import Select from 'react-select';
import { GlobalContext } from '../../../global/GlobalProvider';
import Button from 'reactstrap/es/Button';
import FormConfirmCancel from '../../common/FormConfirmCancel';
import * as Yup from 'yup';
import { I18nContext } from '../../../i18n';
import { handleDefaultError } from '../../../utils/response-handler';
import apiClient from '../../../api/ApiClient';
import { notificationService } from '../../notifications/NotificationService';
import { getProviderSelectValues } from '../../navbar/providers';

const FieldArraySelect = ({ object, value, index, options, arrayHelpers, placeholder = 'Izberite ...' }) => {
  return (
    <Select
      className="koto-zIndex-888 w-100"
      placeholder={placeholder}
      isClearable={false}
      options={options}
      onChange={function (e) {
        arrayHelpers.replace(index, { ...object, [value]: e.value });
      }}
    />
  );
};

const createOptimizerSettingSchema = (translate) => {
  const toShort = translate('value_to_short');
  const toLong = translate('value_to_long');
  const notBlank = translate('value_not_empty');
  const regexMatcherWithCommaAndDot = /^[a-zA-Z0-9šđčćžŠĐČĆŽ,.&@/\- ]*$/;

  return Yup.object().shape({
    short_name: Yup.string()
      .min(1, toShort + ' 1')
      .max(50, toLong + ' 50')
      .matches(regexMatcherWithCommaAndDot, translate('value_alphanumeric'))
      .required(notBlank)
  });
};
export const CreateOptimizerSetting = ({ refetch, onClose }) => {
  const { startingLocations, drivers, vehicles, pools, startLoading, stopLoading } = useContext(GlobalContext);
  const { translate } = useContext(I18nContext);

  return (
    <div>
      <Formik
        initialValues={{
          short_name: ''
        }}
        validationSchema={createOptimizerSettingSchema(translate)}
        onSubmit={async (values) => {
          startLoading();
          try {
            const response = await apiClient.post('optimizer_settings', { short_name: values.short_name });
            refetch();
            onClose();
            notificationService.success('Uspešno dodana nova nastavitev optimizatorja');
          } catch (e) {
            await handleDefaultError(e, { translate: translate, stopLoading: stopLoading, startLoading });
          }
          stopLoading();
        }}
        render={({ values }) => (
          <div>
            <Form>
              <FieldArray
                name="vehicles"
                render={(arrayHelpers) => (
                  <div>
                    <Row form>
                      <Col md={12}>
                        <FormGroup>
                          <Label for="short_name">Kratko ime</Label>
                          <Field name="short_name" type={'text'} component={FormInput} />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                )}
              />
              <Row className="justify-content-end m-2">
                <FormConfirmCancel onCancel={onClose} />
              </Row>
            </Form>
          </div>
        )}
      />
    </div>
  );
};
