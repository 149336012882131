import { Table } from 'reactstrap';
import React, { useContext } from 'react';
import _ from 'lodash';
import './KotoTwoRowTable.css';
import { KotoBadge } from './KotoBadge';
import { I18nContext } from '../../i18n';

const KotoTwoRowTable = ({ title, valueSize = 'w-25', subTable, onEdit, onDelete, deleted = false, ...props }) => {
  const { translate } = useContext(I18nContext);
  return (
    <div className="justify-content-md-around">
      <Table striped hover>
        {title && (
          <thead className="bg-secondary">
            <tr>
              <th colSpan="2" className="text-white">
                {title}
              </th>
              <th colSpan="2" className="text-right">
                {deleted && (
                  <div className="mb-1">
                    <KotoBadge text={translate('deleted_single')} />
                  </div>
                )}
              </th>
            </tr>
          </thead>
        )}
        <tbody>
          {props.columns.map((item, i) => {
            if (item.show !== undefined && !item.show) return null;

            const name = item.Header;

            let value = null;

            if (item.accessor) {
              if (_.isFunction(item.accessor)) value = item.accessor(props.dataObject);
              else value = _.get(props.dataObject, item.accessor);
            }

            if (_.isFunction(item.show) && item.show(value) === false) return null;

            if (item.Cell) {
              value = item.Cell(value !== null ? value : props.dataObject, props.dataObject);
            }

            const vSize = subTable ? 'w-50' : valueSize;
            return (
              <tr key={item.id ? item.id : i}>
                <td style={{ verticalAlign: 'middle' }} className="">
                  {typeof name === 'function' ? name() : name}
                </td>
                <td style={{ verticalAlign: 'middle' }} className={`font-italic ${valueSize} align-items-center`}>
                  {value}
                </td>
                <td></td>
                <td></td>
              </tr>
            );
          })}

          <tr></tr>
        </tbody>
      </Table>
    </div>
  );
};
export default KotoTwoRowTable;
