import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import withI18n from '../../../i18n';
import withGlobalConsumer, { GlobalContext } from '../../../global/GlobalProvider';
import apiClient from '../../../api/ApiClient';
import KotoTable from '../../common/KotoTable';
import Button from 'reactstrap/es/Button';
import KotoContainer from '../KotoContainer';
import { Modal, ModalBody, ModalHeader, NavLink } from 'reactstrap';
import KotoModal from '../../common/KotoModal';
import { FiEdit } from 'react-icons/fi';
import { notificationService } from '../../notifications/NotificationService';
import { GroupCreate } from './GroupCreate';

const LocationGroupList = ({ startLoading, stopLoading, translate, ...props }) => {
  const [groups, setGroups] = useState([]);
  const [groupCreate, setGroupCreate] = useState(false);
  const { fetchGroups } = useContext(GlobalContext);

  useEffect(() => {
    fetchCurrentGroups();
  }, []);

  async function fetchCurrentGroups() {
    try {
      const groupsResponse = await apiClient.get('/location_groups');
      await setGroups(groupsResponse.data);
    } catch (e) {
      //console.log(e);
    }
  }

  async function updateName(id, name) {
    try {
      await apiClient.patch(`/location_groups/${id}`, { name: name });
      fetchCurrentGroups();
      fetchGroups();
    } catch (e) {
      notificationService.error('Nekaj je šlo narobe.');
    }
  }

  function toggleCreateGroup() {
    setGroupCreate(!groupCreate);
  }

  const groupsColumns = [
    {
      Header: 'Id',
      width: '10%',
      accessor: 'id',
      Cell: (row) => (
        <NavLink className="pt-0 pb-0" to={`/groups/${row.original.id}`} tag={Link}>
          {row.original.id}
        </NavLink>
      )
    },
    {
      Header: 'Ime',
      width: '25%',
      accessor: 'name',
      Cell: (row) => {
        const [editing, setEditing] = useState(false);
        const [name, setName] = useState(row.original.name);

        return (
          <div>
            {editing ? (
              <div className="align-items-center d-flex justify-content-center">
                <input
                  style={{ marginBottom: '4px' }}
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
                <div>
                  <Button
                    size={'sm'}
                    style={{ marginBottom: '3px', marginLeft: '2px' }}
                    color={'primary'}
                    onClick={async (e) => {
                      await updateName(row.original.id, name);
                    }}
                  >
                    Shrani
                  </Button>
                  <Button
                    size={'sm'}
                    style={{ marginBottom: '3px', marginLeft: '2px' }}
                    onClick={(e) => setEditing(false)}
                    color={'danger'}
                  >
                    Prekliči
                  </Button>
                </div>
              </div>
            ) : (
              <div className="align-items-center">
                {row.original.name}
                <FiEdit style={{ cursor: 'pointer' }} onClick={(e) => setEditing(true)} className={'ml-2 mb-1'} />
              </div>
            )}
          </div>
        );
      }
    },
    {
      Header: 'Število lokacij',
      width: '10%',
      accessor: 'locations',
      Cell: (locations) => {
        return locations?.value ? locations.value.length : 0;
      }
    }
  ];

  return (
    <KotoContainer>
      <h5 style={{ marginTop: '20px' }}>
        <i>Skupine lokacij</i>
      </h5>
      <KotoTable columns={groupsColumns} data={groups}></KotoTable>
      <Button style={{ marginTop: '20px' }} color={'primary'} onClick={(e) => toggleCreateGroup()}>
        {' '}
        Dodaj novo skupino
      </Button>
      <hr />

      <KotoModal title={'Dodaj novo skupino'} isOpen={groupCreate} toggle={() => toggleCreateGroup()}>
        <GroupCreate
          onCancel={() => toggleCreateGroup()}
          refresh={async () => {
            fetchGroups();
            await fetchCurrentGroups();
          }}
        />
      </KotoModal>
    </KotoContainer>
  );
};

export default withRouter(withI18n(withGlobalConsumer(LocationGroupList)));
