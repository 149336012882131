import { ButtonGroup, Input, InputGroup, InputGroupAddon } from 'reactstrap';
import React, { useRef, useState } from 'react';
import Button from 'reactstrap/lib/Button';
import { FaSearch } from 'react-icons/fa';
import { MdClear } from 'react-icons/md';
import './SearchBar.css';
import { useKey } from 'react-use';
import { getFromStorage } from '../utils/utils';

export const SearchBar = ({ saveKey, onSearch, onClear, placeHolder, ...props }) => {
  let startingSearch = '';
  if (saveKey) {
    startingSearch = getFromStorage(saveKey, '');
  }
  const [searchValue, setSearchValue] = useState(startingSearch);
  const inputEl = useRef(null);

  const update = () => onSearch(searchValue);

  useKey('Enter', (event) => {
    const { id, value } = inputEl.current.props;
    if (id === document.activeElement.id) {
      if (value) {
        onSearch(value);
      } else {
        onSearch(searchValue);
      }
    }
  });

  return (
    <div {...props}>
      <InputGroup>
        <Input
          id="search_input"
          ref={inputEl}
          placeholder={placeHolder ? placeHolder : 'Išči'}
          value={searchValue}
          onChange={(e) => {
            setSearchValue(e.target.value);
            localStorage.setItem(saveKey, JSON.stringify(e.target.value));
          }}
        />
        <InputGroupAddon addonType="append">
          <InputGroupAddon addonType="append">
            <ButtonGroup>
              <Button
                outline={false}
                onClick={() => {
                  return onSearch(searchValue);
                }}
                color={'primary'}
              >
                <FaSearch /> Išči
              </Button>
              <Button
                outline={false}
                onClick={() => {
                  setSearchValue('');
                  localStorage.setItem(saveKey, null);
                  return onClear();
                }}
              >
                <MdClear size={'1.4em'} /> Počisti
              </Button>
            </ButtonGroup>
          </InputGroupAddon>
        </InputGroupAddon>
      </InputGroup>
    </div>
  );
};
