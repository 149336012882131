import React from 'react';
import Loader from 'react-loader-spinner';
import withGlobalConsumer from '../global/GlobalProvider';
import { useWindowSize } from '../utils/hooks/useWindowSize';

function KotoLoader(props) {
  const size = useWindowSize();
  return (
    <div>
      <div
        style={{
          opacity: props.isLoading ? 0.3 : 1,
          transition: 'opacity 0.5s ease-out',
          height: `${size.height - 50}px`
        }}
      >
        {props.children}
      </div>

      <div
        style={{
          position: 'fixed',
          left: '50%',
          top: '45%',
          transform: 'translateX(-50%)',
          backGround: 'red'
        }}
      >
        {props.isLoading && <Loader type="Oval" color="#00BFFF" width={100} height={100} />}
      </div>
    </div>
  );
}

export default withGlobalConsumer(KotoLoader);
