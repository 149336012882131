import React, { useContext, useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import withI18n from '../../../i18n';
import withGlobalConsumer, { GlobalContext } from '../../../global/GlobalProvider';
import apiClient from '../../../api/ApiClient';
import KotoContainer from '../KotoContainer';
import Row from 'reactstrap/es/Row';
import Col from 'reactstrap/es/Col';
import { Input } from 'reactstrap';
import KotoDate from '../../common/KotoDate';
import KotoModal from '../../common/KotoModal';
import { Button } from 'reactstrap';
import { handleDefaultError } from '../../../utils/response-handler';

export async function handleDownload(response, filename) {
  const a = document.createElement('a');
  a.href = window.URL.createObjectURL(response.data);
  //firefox does not initiate a download if not for the following two lines
  document.body.appendChild(a);
  a.setAttribute('type', 'hidden');
  a.download = filename;
  a.click();
}

const ProductTable = ({ products, ...props }) => {
  const { productTypes } = useContext(GlobalContext);
  const productIds = productTypes.map((item) => item.id);
  return (
    <table className="table">
      <colgroup>
        <col span="1" style={{ width: '11%' }} />
        <col span="1" style={{ width: '11%' }} />
        <col span="1" style={{ width: '11%' }} />
        <col span="1" style={{ width: '11%' }} />
        <col span="1" style={{ width: '11%' }} />
        <col span="1" style={{ width: '11%' }} />
        <col span="1" style={{ width: '11%' }} />
        <col span="1" style={{ width: '11%' }} />
        <col span="1" style={{ width: '11%' }} />
      </colgroup>
      <thead>
        <tr>
          {productTypes.map((item) => {
            return (
              <th style={{ color: item.color }} key={item.id}>
                {item.screen_name}
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody>
        <tr>
          {productIds.map((productId) => {
            const product = products?.filter((item) => item.product?.id == productId);
            if (product?.length > 0) {
              return <td key={productId}>{product[0].current_barrels} kosov</td>;
            } else {
              return <td key={productId}>0 kosov</td>;
            }
          })}
        </tr>
        <tr>
          {productIds.map((productId) => {
            const product = products?.filter((item) => item.product?.id == productId);
            if (product?.length > 0) {
              return <td key={productId}>{product[0].current_kilos} kg</td>;
            } else {
              return <td key={productId}>0 kg</td>;
            }
          })}
        </tr>
      </tbody>
    </table>
  );
};

const Depots = (props) => {
  const [depots, setDepots] = useState([]);
  const [selectedDepot, setSelectedDepot] = useState(1);
  const [depot, setDepot] = useState(null);
  const [transports, setTransports] = useState([]);
  const [openedItems, setOpenedItems] = useState([]);
  const [confirmModal, setConfirmModal] = useState(false);
  const [currentSelectedTransport, setCurrentSelectedTransport] = useState(null);

  async function fetchDepot() {
    if (selectedDepot) {
      try {
        const response = await apiClient.get(`/depots/${selectedDepot}`);
        setDepot(response.data);
        const responseTransports = await apiClient.get(`/depot_transports?depot=${selectedDepot}`);
        setTransports(responseTransports.data.results.map((item) => ({ opened: false, ...item })));
      } catch (e) {
        //console.log(e);
      }
    }
  }

  async function fetchDepots() {
    try {
      const response = await apiClient.get('/depots/options');
      setDepots(
        response.data?.map((item) => {
          return { value: item.id, label: item.name };
        })
      );
      setSelectedDepot(depots[0]);
    } catch (e) {
      //console.log(e);
    }
  }

  useEffect(() => {
    fetchDepots();
  }, []);

  useEffect(() => {
    fetchDepot();
  }, [selectedDepot]);

  function toggleItem(itemId) {
    if (openedItems.includes(itemId)) {
      setOpenedItems(openedItems.filter((id) => id != itemId));
    } else {
      setOpenedItems([...openedItems, itemId]);
    }
  }

  async function downloadReport(id) {
    const response = await apiClient.get(`/depot_transports/${id}/get_report`, {
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json'
      }
    });
    await handleDownload(response, `depot_transport_${id}.pdf`);
  }

  async function confirmTransport() {
    try {
      await apiClient.post(`/depot_transports/${currentSelectedTransport}/confirm_transport`);
      setConfirmModal(false);
    } catch (e) {
      handleDefaultError(e);
    }
    fetchDepot();
  }

  function openConfirmTransportModal(id) {
    setCurrentSelectedTransport(id);
    setConfirmModal(true);
  }

  return (
    <>
      {confirmModal && (
        <KotoModal
          title={`Ali potrjujete prevoz (id:${currentSelectedTransport})?`}
          isOpen={confirmModal}
          toggle={() => setConfirmModal(!confirmModal)}
        >
          <Row className="m-3">
            <Button style={{ margin: '0 auto' }} color="success" onClick={confirmTransport}>
              Potrdi prevoz
            </Button>
          </Row>
        </KotoModal>
      )}
      <KotoContainer className="m-5">
        <Row>
          <Col md={3}>
            <h6 className="text-nowrap">Skladišča</h6>
            <Input
              type="select"
              name="select"
              className="small w-100"
              id="selectDepot"
              value={selectedDepot}
              onChange={(e) => setSelectedDepot(e.target.value)}
            >
              {depots?.map((item) => (
                <option key={item.value} value={item.value}>
                  {item.label}
                </option>
              ))}
            </Input>
          </Col>
          <Col md={9}>
            <Row>
              <Col md={5}>
                <strong>Lokacija:&nbsp; </strong>
                <br />
                <strong>Veterinska številka zbiralec:&nbsp; </strong> <br />
                <strong>Veterinska številka predelovalec:&nbsp; </strong> <br />
              </Col>
              <Col md={4}>
                <Link to={`/locations/${depot?.location?.id}`}>{depot?.location.short_name}</Link>
                <br />
                {depot?.veterinary_collector_code}
                <br />
                {depot?.veterinary_processor_code}
                <br />
              </Col>
            </Row>
          </Col>
        </Row>
        <hr />
        <div className="m-3">
          <h5>Trenutno stanje v skladišču </h5>
          <div className="alert alert-danger">
            To predstavlja oceno produktov v skladišču. V realnosti lahko prihaja do manjših razlik. Vse količine so
            podane v številu kosov embalaže oziroma kilogramih!
          </div>
          <div className="mt-2">
            <ProductTable products={depot?.products} />
          </div>
        </div>
        <hr />
        <div className="m-3">
          <h5>Pretekli odvozi</h5>
          {transports.map((item) => {
            const color = item.confirmed ? 'success' : 'warning';
            return (
              <div key={item.id}>
                <div className={`alert alert-${color}`} onClick={() => toggleItem(item.id)}>
                  <table style={{ width: '100%' }}>
                    <colgroup>
                      <col span="1" style={{ width: '5%' }} />
                      <col span="1" style={{ width: '15%' }} />
                      <col span="1" style={{ width: '23%' }} />
                      <col span="1" style={{ width: '19%' }} />
                      <col span="1" style={{ width: '19%' }} />
                    </colgroup>
                    <col span="1" style={{ width: '19%' }} />
                    <tbody>
                      <tr>
                        <td key="1">
                          <strong>{item.id}</strong>
                        </td>
                        <td key="2">
                          <strong>{`${item.driver?.first_name} ${item.driver?.last_name}`}</strong>
                        </td>
                        <td key="3">
                          <strong>
                            <KotoDate format={'LLLL'} date={item.created} />
                          </strong>
                        </td>
                        <td key="4">
                          <strong>Kilogramov: </strong>{' '}
                          {item.products.map((item) => item.current_kilos).reduce((a, b) => a + b)}
                        </td>
                        <td key="5">
                          <strong>Kosov embalaže: </strong>
                          {item.products.map((item) => item.current_barrels).reduce((a, b) => a + b)}
                        </td>
                        {item.confirmed ? (
                          <td key="6">
                            <strong>
                              <button className="btn btn-success w-50" onClick={() => downloadReport(item.id)}>
                                Prenesi PDF
                              </button>
                            </strong>
                          </td>
                        ) : (
                          <td key="6">
                            <strong>
                              <button
                                className="btn btn-warning w-50"
                                onClick={() => openConfirmTransportModal(item.id)}
                              >
                                Potrdi
                              </button>
                            </strong>
                          </td>
                        )}
                      </tr>
                    </tbody>
                  </table>
                </div>
                {openedItems.includes(item.id) ? <ProductTable products={item?.products} /> : ''}
              </div>
            );
          })}
        </div>
      </KotoContainer>
    </>
  );
};

export default withRouter(withI18n(withGlobalConsumer(Depots)));
