import React from 'react';
import { authService } from './AuthService';

export const AuthContext = React.createContext();

class AuthProvider extends React.Component {
  state = { isAuth: true };

  constructor() {
    super();
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
  }

  async componentWillMount() {
    this.setState({
      isAuth: await authService.isLoggedIn()
    });
    if (this.state.isAuth) {
      await this.setState({
        authProfile: await authService.getProfile()
      });
    }
  }

  async login(username, password) {
    try {
      await authService.login(username, password);
      const succesLogin = await authService.isLoggedIn();
      succesLogin && this.setState({ isAuth: true, authProfile: await authService.getProfile() });
      return succesLogin;
    } catch (e) {
      return false;
    }
  }

  async logout() {
    await authService.logout();
    this.setState({ isAuth: false, authProfile: null });
  }

  render() {
    return (
      <AuthContext.Provider
        value={{
          isAuth: this.state.isAuth,
          login: this.login,
          logout: this.logout,
          authProfile: this.state.authProfile
        }}
      >
        {this.props.children}
      </AuthContext.Provider>
    );
  }
}

const AuthConsumer = AuthContext.Consumer;
export default function withAuthConsumer(Component) {
  return function ComponentWithAuth(props) {
    return (
      <AuthConsumer>
        {({ isAuth, login, logout, authProfile }) => (
          <Component {...props} isAuth={isAuth} login={login} logout={logout} authProfile={authProfile} />
        )}
      </AuthConsumer>
    );
  };
}
export { AuthProvider, AuthConsumer };
