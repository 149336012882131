import React, { Component } from 'react';
import withI18n from '../../../i18n';
import withGlobalConsumer from '../../../global/GlobalProvider';
import * as Yup from 'yup';
import { Field, FieldArray, Form, Formik } from 'formik';
import apiClient from '../../../api/ApiClient';
import { notificationService } from '../../notifications/NotificationService';
import { handleDefaultError } from '../../../utils/response-handler';
import { FormEffect } from '../../common/FormEffect';
import { Col, Input, Label, Row } from 'reactstrap';
import FormGroup from 'reactstrap/es/FormGroup';
import { FormCheckbox, FormInput, FormSelect } from '../../common/FormInput';
import FormConfirmCancel from '../../common/FormConfirmCancel';
import { withRouter } from 'react-router-dom';
import { findProductTypeById, productTypes } from '../../common/statuses';
import InputGroupAddon from 'reactstrap/es/InputGroupAddon';
import InputGroupText from 'reactstrap/es/InputGroupText';
import InputGroup from 'reactstrap/es/InputGroup';

const clientSchema = (translate) => {
  const toShort = translate('value_to_short');
  const toLong = translate('value_to_long');
  const notBlank = translate('value_not_empty');
  const regexMatcherWithCommaAndDot = /^[a-zA-Z0-9šđčćžŠĐČĆŽ,.&@/\- ]*$/;

  return Yup.object().shape({
    short_name: Yup.string()
      .min(1, toShort + ' 1')
      .max(50, toLong + ' 50')
      .matches(regexMatcherWithCommaAndDot, translate('value_alphanumeric'))
      .required(notBlank),
    largo_client_code: Yup.string()
      .min(1, toShort + ' 1')
      .max(20, toLong + ' 20')
      .matches(/^[a-zA-Z0-9šđčćžŠĐČĆŽ@ ]*$/, translate('value_alphanumeric'))
      .required(notBlank),
    litres_to_kilos_conversion_rate: Yup.number()
      .min(0.1, toShort + ' 0.1')
      .max(100, toLong + '100'),
    description: Yup.string()
      .min(0, toShort + ' 0')
      .max(50, toLong + ' 50')
      .matches(regexMatcherWithCommaAndDot, translate('value_alphanumeric'))
  });
};

class CreateClient extends Component {
  handleSelectChange = async (selectedOption, formik) => {
    await formik.setFieldValue('zol_turnover_type', selectedOption.value, true);
    await this.setState({ selectedOption });
  };

  initialRatios(conversions) {
    if (conversions) {
      return conversions.map((c) => ({ id: c.product_type.id, ratio: c.ratio }));
    } else {
      return this.props.productTypes.map((pt) => ({ id: pt.id, ratio: 0 }));
    }
  }

  render() {
    return (
      <div>
        <div>
          <h1>{this.props.title}</h1>

          <Formik
            initialValues={{
              short_name: this.props.client && this.props.client.short_name ? this.props.client.short_name : '',
              description: this.props.client && this.props.client.description ? this.props.client.description : '',
              largo_client_code: this.props.client ? this.props.client.largo_client_code : 0,
              ratios: this.initialRatios(this.props?.client?.conversions),

              litres_to_kilos_conversion_rate: this.props.client
                ? this.props.client.litres_to_kilos_conversion_rate
                : 0,
              zol_turnover_type: this.props.client ? this.props.client.zol_turnover_type : '',
              subtract_barrel_weight: this.props.client ? this.props.client.subtract_barrel_weight : true,
              locations: []
            }}
            isValid={this.props.clientId}
            validationSchema={clientSchema(this.props.translate)}
            onSubmit={async (values) => {
              try {
                await this.props.startLoading();

                let { ratios, ...rest } = values;
                const formattedRatios = ratios.map((rat) => [rat.id, rat.ratio]);
                let response;
                let successMessage;

                const req = { ratios: formattedRatios, ...rest };

                if (this.props.clientId) {
                  response = await apiClient.put(`/client/${this.props.clientId}`, values);
                  successMessage = `${this.props.translate('client_success_update_message')} ${response.data.id}`;
                } else {
                  response = await apiClient.post(`/client`, req);
                  successMessage = `${this.props.translate('client_success_add_message')} ${response.data.short_name}`;
                }

                notificationService.success(successMessage);
              } catch (e) {
                if (
                  e.response &&
                  e.response.data &&
                  e.response.data.short_name &&
                  e.response.data.short_name.length !== 0
                ) {
                  notificationService.error(this.props.translate('client_short_name_already_exists'));
                  await this.props.stopLoading();
                  return;
                } else {
                  await handleDefaultError(e, this.props);
                }
                await handleDefaultError(e, this.props);
              }
              await this.props.stopLoading();
              await this.props.refresh();
              await this.props.toggle();
            }}
          >
            {(formik) => (
              <Form>
                <FormEffect
                  formik={formik}
                  onSubmissionError={() => notificationService.error('Please correct red colored fields')}
                />
                <Row form>
                  <Col md={12}>
                    <FormGroup>
                      <Label for="short_name">{this.props.translate('short_name')}</Label>
                      <Field name="short_name" type={'text'} component={FormInput} />
                    </FormGroup>
                  </Col>
                </Row>

                <Row form>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="description">{this.props.translate('description')}</Label>
                      <Field name="description" type={'text'} component={FormInput} />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="largo_client_code">{this.props.translate('largo_client_code')}</Label>
                      <Field name="largo_client_code" type={'text'} component={FormInput} />
                    </FormGroup>
                  </Col>
                </Row>
                <Row form>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="litres_to_kilos_conversion_rate">
                        {this.props.translate('litres_to_kilos_conversion_rate')}
                      </Label>
                      <Field name="litres_to_kilos_conversion_rate" type={'number'} component={FormInput} />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <Label for="zol_turnover_type">{'ZOL'}</Label>
                    <FormSelect
                      formik={formik}
                      name={'zol_turnover_type'}
                      defaultValue={{
                        value: formik.values.zol_turnover_type,
                        label: formik.values.zol_turnover_type === '' ? 'Prazno' : formik.values.zol_turnover_type
                      }}
                      isClearable={false}
                      options={[
                        { value: '', label: 'Prazno' },
                        { value: 'PRE', label: 'PRE' },
                        { value: 'PBC', label: 'PBC' }
                      ]}
                      onChange={(e) => this.handleSelectChange(e, formik)}
                    />
                  </Col>
                </Row>
                <Row>
                  <Label className="ml-3 md-4" for="subtract_barrel_weight">
                    Odštej težo embalaže:
                  </Label>
                  <Field
                    name="subtract_barrel_weight"
                    type="checkbox"
                    className="ml-2 pl-2"
                    checked={formik.values.subtract_barrel_weight}
                    size="2"
                    component={FormCheckbox}
                  />
                </Row>

                {!this.props.clientId && (
                  <div>
                    <Row noGutters>
                      <Col>
                        <h6 className="m-0 p-0">Produkt</h6>
                      </Col>
                      <Col>
                        <h6 className="m-0 p-0">Cena na enoto</h6>
                      </Col>
                    </Row>
                    <hr />
                    <FieldArray
                      name="ratios"
                      render={(arrayHelpers) => (
                        <div>
                          {formik.values.ratios && formik.values.ratios.length > 0 ? (
                            formik.values.ratios.map((ratio, index) => {
                              const product = this.props.productTypes.find((pt) => pt.id == ratio.id);
                              return (
                                <InputGroup className={'py-1'} key={index}>
                                  <InputGroupAddon className={'w-50'} addonType="prepend">
                                    <InputGroupText
                                      style={{
                                        backgroundColor: product.color,
                                        width: '100%',
                                        color: 'white'
                                      }}
                                    >
                                      {product.screen_name}
                                    </InputGroupText>
                                  </InputGroupAddon>
                                  <Input
                                    min={0}
                                    type={'number'}
                                    step="0.00001"
                                    value={ratio.ratio}
                                    onChange={(e) => {
                                      arrayHelpers.replace(index, {
                                        ...ratio,
                                        ratio: e.target.value
                                      });
                                    }}
                                  />
                                </InputGroup>
                              );
                            })
                          ) : (
                            <div />
                          )}
                        </div>
                      )}
                    />
                  </div>
                )}
                <hr />
                <FormConfirmCancel onCancel={this.props.toggle} />
              </Form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}

export default withI18n(withGlobalConsumer(withRouter(CreateClient)));
