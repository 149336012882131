import _ from 'lodash';
import moment from 'moment';
import * as queryString from 'query-string';
import React, { Component } from 'react';
import { BsInfoCircle } from 'react-icons/bs';
import { FaDownload } from 'react-icons/fa';
import { TiArrowUnsorted } from 'react-icons/ti';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { Badge, Col, Input, Label, NavLink } from 'reactstrap';
import Button from 'reactstrap/es/Button';
import Row from 'reactstrap/es/Row';
import apiClient from '../../../api/ApiClient';
import withAuthConsumer from '../../../api/auth/AuthContext';
import withGlobalConsumer from '../../../global/GlobalProvider';
import withI18n from '../../../i18n';
import { handleDefaultError } from '../../../utils/response-handler';
import { getFromStorage } from '../../../utils/utils';
import { KotoBadge } from '../../common/KotoBadge';
import KotoDate from '../../common/KotoDate';
import KotoDateTime from '../../common/KotoDateTime';
import KotoModal from '../../common/KotoModal';
import KotoPagination from '../../common/KotoPagination';
import { KotoSubTable } from '../../common/KotoSubTable';
import KotoTable from '../../common/KotoTable';
import { orderStatuses } from '../../common/statuses';
import LocationSearch from '../../LocationSearch';
import { getCurrentProvider, providers } from '../../navbar/providers';
import { SearchBar } from '../../SearchBar';
import KotoContainer from '../KotoContainer';
import CreateOrder from './CreateOrder';

export async function handleDownload(response, filename) {
  const a = document.createElement('a');
  a.href = window.URL.createObjectURL(response.data);
  //firefox does not initiate a download if not for the following two lines
  document.body.appendChild(a);
  a.setAttribute('type', 'hidden');
  a.download = filename;
  a.click();
}

export async function downloadOrderExcel(e, filter_query, props) {
  const response = await apiClient.get(`/order/download_excel` + filter_query, {
    responseType: 'blob',
    headers: {
      'Content-Type': 'application/json'
    }
  });
  await handleDownload(response, `orders.xlsx`);
}

export async function downloadReport(event, id, props) {
  try {
    const response = await apiClient.get(`/order/${id}/report`, {
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json'
      }
    });
    await handleDownload(response, `report-${id}-.pdf`);
  } catch (e) {
    await handleDefaultError(e, props);
  }
}

const columns = (translate, markSorted, props) => {
  return [
    {
      Header: () => (
        <a id="id" style={{ cursor: 'pointer' }} onClick={markSorted}>
          {' '}
          Id <TiArrowUnsorted id={'id'} />
        </a>
      ),
      accessor: 'id',
      width: 60,
      Cell: (row) => (
        <NavLink className="pt-0 pb-0" to={`/orders/${row.original.id}`} tag={Link}>
          {row.original.id}
        </NavLink>
      )
    },

    {
      Header: 'Skladišče',
      width: 110,
      Cell: function (row) {
        return <div>{row.original?.depot?.name}</div>;
      }
    },

    {
      Header: translate('locations_single'),
      id: 'locations_single',
      width: 200,
      Cell: (row) => (
        <NavLink className="pt-0 pb-0" to={`/locations/${row.original.location.id}`} tag={Link}>
          {row.original.location.short_name}
        </NavLink>
      )
    },
    {
      Header: 'Voznik',
      id: 'driver',
      width: 130,
      Cell: (row) => {
        const driver = row?.original?.driver;
        if (driver) {
          return (
            <NavLink className="p-0" to={`/users/${driver?.uid}`} tag={Link}>
              {driver?.first_name + ' ' + driver?.last_name}
            </NavLink>
          );
        }
        return null;
      }
    },
    {
      Header: translate('report'),
      width: 65,
      Cell: (row) => {
        if ([70, 75].includes(row.original.order_status.id)) {
          if (row.original.order_status.id === 75 && !row.original.latest_erp_generated) {
            return <div>N</div>
          } else {
            return (
              <FaDownload
                className="text-primary"
                style={{ cursor: 'pointer' }}
                onClick={(e) => downloadReport(e, row.original.id, props)}
              />
            )
          };
        }
        return <div></div>;
      }
    },

    {
      Header: translate('order_status'),
      id: 'order_status',
      Cell: (row) => (
        <KotoBadge
          text={translate(orderStatuses[row.original.order_status.id].name)}
          color={orderStatuses[row.original.order_status.id].color}
        />
      )
    },

    {
      Header: () => (
        <a id="actualweight" onClick={markSorted}>
          Stehtana količina<small id={'actualweight'}> (kg)</small>
          <TiArrowUnsorted id={'actualweight'} style={{ cursor: 'pointer' }} />
        </a>
      ),
      id: 'actual_weight',
      width: 200,
      accessor: 'actual_weight',
      Cell: (row) => {
        let value = row.original.actual_weight;
        if (value) {
          value = parseFloat(value);
        } else {
          value = 0.0;
        }
        return `${Number(value).toFixed(2)} kg`;
      }
    },

    {
      Header: () => (
        <Row noGutters className="align-items-center justify-content-center">
          {' '}
          <a id="collectedweight" onClick={markSorted}>
            Skupno <small className="ml-1"> (kg)</small>
            <TiArrowUnsorted id={'collectedweight'} style={{ cursor: 'pointer' }} />
          </a>
        </Row>
      ),
      id: 'collected_weight',
      // width:130,
      Cell: (row) => {
        let value = row.original.collected_weight;
        if (value) {
          value = parseFloat(value);
        } else {
          value = 0.0;
        }
        return `${Number(value).toFixed(2)} kg`;
      }
    },
    {
      Header: () => (
        <a id="weightdeviation" onClick={markSorted}>
          Odstopanje
          <TiArrowUnsorted id={'weightdeviation'} style={{ cursor: 'pointer' }} />
        </a>
      ),
      id: 'weight_deviation',
      width: 100,
      show: true,
      accessor: 'weight_deviation',
      Cell: (row) => <div>{row.original.weight_deviation} %</div>
    },

    {
      Header: () => (
        <a id="preferred_start_time" onClick={markSorted}>
          Datum željenega začetka
          <TiArrowUnsorted id={'preferred_start_time'} style={{ cursor: 'pointer' }} />
        </a>
      ),
      Cell: (row) =>
        row.original.preferred_start_time && <KotoDate format={'LL'} date={row.original.preferred_start_time} />
    },

    {
      Header: () => (
        <a id="order_completed_time" onClick={markSorted}>
          Datum zaključka
          <TiArrowUnsorted id={'order_completed_time'} style={{ cursor: 'pointer' }} />
        </a>
      ),
      Cell: (row) =>
        row.original.order_completed_time && <KotoDate format={'LL'} date={row.original.order_completed_time} />
    },
    {
      Header: 'Začetni čas',
      id: 'preferred_start_time',
      // width:180,
      Cell: (row) =>
        row.original.preferred_start_time && <KotoDate format={'HH:mm'} date={row.original.preferred_start_time} />
    },
    {
      Header: 'Končni čas',
      id: 'preferred_end_time',
      // width:180,
      Cell: (row) =>
        row.original.preferred_end_time && <KotoDate format={'HH:mm'} date={row.original.preferred_end_time} />
    },
    {
      Header: () => (
        <a id="estimated_pick_up_time" onClick={markSorted}>
          {' '}
          {translate('order_estimated_pick_up_time')}
          <TiArrowUnsorted id={'estimated_pick_up_time'} style={{ cursor: 'pointer' }} />
        </a>
      ),
      // width:150,
      id: 'estimated_pick_up_time',
      Cell: (row) =>
        row.original.estimated_pick_up_time && <KotoDate format={'HH:mm'} date={row.original.estimated_pick_up_time} />
    },
    {
      Header: translate('order_completed_time'),
      id: 'order_completed_time',
      Cell: (row) =>
        row.original.order_completed_time && <KotoDate format={'HH:mm'} date={row.original.order_completed_time} />
    }
  ];
};

class Orders extends Component {
  getIntialSize = () => {
    let size = 18;
    const height = window.screen.height;
    if (height < 1000) {
      size = 16;
    }
    if (height <= 900) {
      size = 14;
    }
    return size;
  };

  state = {
    createOpen: false,
    orders: [],
    count_today: 0,
    count_tomorrow: 0,
    following_days: [],
    following_days_kilos_legacy: [],
    following_days_kilos: [],
    count: 1,
    ascDesc: getFromStorage('orders.ascDesc', true),
    sortParam: getFromStorage('orders.sortParam', null),
    showDeleted: getFromStorage('orders.showDeleted', false),
    showOnlyKotoOrders: getFromStorage('orders.showOnlyKotoOrders', false),
    selectedLocations: getFromStorage('orders.selectedLocations', []),
    dateStart: getFromStorage('orders.dateStart', null, moment),
    dateEnd: getFromStorage('orders.dateEnd', null, moment),
    activePage: 1,
    filterStatus: getFromStorage('orders.filterStatus', null),
    driver: getFromStorage('orders.driver', null),
    pool: getFromStorage('orders.pool', null),
    pools: [],
    group: getFromStorage('orders.group', null),
    groups: [],
    pageSize: this.getIntialSize(),
    locationQuery: '',
    url: '/order',
    barrelCode: '',
    currentQuery: ''
  };

  async componentWillMount() {
    let params = queryString.parse(this.props.location.search);
    let activePage = parseInt(params.activePage);

    if (params.activePage && _.isInteger(activePage)) {
      await this.setState({ activePage: activePage });
    }

    await this.fetchOrders(this.state.activePage);
  }

  isStartDateValid = (start) => {
    if (!this.state.dateEnd) return true;

    return start.isSameOrBefore(this.state.dateEnd, 'date');
  };

  isEndDateValid = (end) => {
    if (!this.state.dateStart) return true;

    return end.isSameOrAfter(this.state.dateStart, 'date');
  };

  async handleSorted(event) {
    const sortParam = event.target.id;
    const ascDesc = sortParam === this.state.sortParam ? !this.state.ascDesc : this.state.ascDesc;
    await this.setState({
      sortParam,
      ascDesc
    });
    localStorage.setItem('orders.sortParam', JSON.stringify(sortParam));
    localStorage.setItem('orders.ascDesc', JSON.stringify(ascDesc));

    let params = queryString.parse(this.props.location.search);
    let activePage = parseInt(params.activePage);
    if (params.activePage && _.isInteger(activePage)) {
      await this.setState({
        activePage
      });
    }

    await this.fetchOrders(this.state.activePage);
  }

  async clearDates() {
    await this.setState({
      dateStart: null,
      dateEnd: null
    });
    localStorage.removeItem('orders.dateStart');
    localStorage.removeItem('orders.dateEnd');
    await this.fetchOrders(this.state.activePage);
  }

  async toogleCheckbox() {
    await this.setState({
      showDeleted: !this.state.showDeleted
    });

    localStorage.setItem('orders.showDeleted', this.state.showDeleted);
    await this.fetchOrders(this.state.activePage);
  }

  async toogleShowOnlyKoto() {
    const currentValue = this.state.showOnlyKotoOrders;
    const kotoValue = [{ label: 'Koto', value: 1 }];
    await this.setState({
      showOnlyKotoOrders: !this.state.showOnlyKotoOrders
    });
    await this.setState({
      selectedLocations: this.state.showOnlyKotoOrders ? kotoValue : []
    });
    localStorage.setItem('orders.showOnlyKotoOrders', this.state.showOnlyKotoOrders);
    localStorage.setItem('orders.selectedLocations', JSON.stringify(this.state.selectedLocations));

    await this.fetchOrders(this.state.activePage);
  }

  async fetchOrders(page) {
    page = page - 1;
    await this.props.startLoading();

    try {
      let ascDescPrefix = this.state.ascDesc ? '' : '-';
      let sortParamPath = this.state.sortParam ? `&sortBy=${ascDescPrefix}${this.state.sortParam}` : '';

      const multipleLocations = this.state.selectedLocations.map((location) => location.value).join();
      let location = this.state.selectedLocations.length > 0 ? `&location=${multipleLocations}` : '';
      let dateStart = this.state.dateStart ? `&start=${this.state.dateStart?.toISOString()}` : '';
      let dateEnd = this.state.dateEnd ? `&end=${this.state.dateEnd?.toISOString()}` : '';

      let samoLazyWorkaround = this.state.showDeleted ? 'True' : 'False';
      let showDeleted = `&is_deleted=${samoLazyWorkaround}`;

      let filterStatus = this.state.filterStatus ? `&status=${this.state.filterStatus}` : '';
      let driver = this.state.driver ? `&driver=${this.state.driver}` : '';
      let pool = this.state.pool ? `&pool=${this.state.pool}` : '';
      let group = this.state.group ? `&group=${this.state.group}` : '';
      let query = `?limit=${this.state.pageSize}&offset=${
        this.state.pageSize * page
      }${filterStatus}${location}${sortParamPath}${dateStart}${dateEnd}${showDeleted}${driver}${pool}${group}`;

      if (this.state.barrelCode) {
        query += `&code=${this.state.barrelCode}`;
      }

      let url = this.state.url + query;

      const response = await apiClient.get(url);
      await this.setState({
        orders: response.data.results,
        count_today: response.data.count_today,
        count_tomorrow: response.data.count_tomorrow,
        count: response.data.count,
        following_days: response.data.following_days,
        following_days_kilos_legacy: response.data.kilos_in_following_days_legacy,
        following_days_kilos: response.data.kilos_in_following_days,
        currentQuery: query
      });
      const poolsResponse = await apiClient.get('/pools');
      const pools = poolsResponse.data.map((data) => ({ value: data.id, label: data.name }));
      await this.setState({ pools: pools });

      const groupsResponse = await apiClient.get('/location_groups/options');
      const groups = groupsResponse.data.map((item) => ({ value: item.id, label: item.name }));
      await this.setState({ groups });
    } catch (e) {
      await handleDefaultError(e, this.props);
    }

    await this.props.stopLoading();
  }

  handleInputChange(locationQuery) {
    if (!locationQuery && !this.state.locationQuery) {
      return;
    }
    // eslint-disable-next-line eqeqeq
    if (locationQuery != this.state.locationQuery) {
      this.setState(
        {
          locationQuery
        },
        this.fetchLocations
      );
    }
  }

  handleLocationChange = async (selectedLocations) => {
    if (selectedLocations) {
      await this.setState({ selectedLocations: selectedLocations });
      localStorage.setItem('orders.selectedLocations', JSON.stringify(selectedLocations));
    } else {
      await this.setState({ selectedLocations: [] });
      localStorage.setItem('orders.selectedLocations', []);
    }
    await this.fetchOrders(this.state.activePage);
  };

  handleSelected = async (page) => {
    await this.fetchOrders(page);
  };

  handleBarrelCodeSearch = async (barrelCode) => {
    await this.setState({ barrelCode: barrelCode });
    await this.fetchOrders(1);
  };

  clearBarrelCodeSearch = async () => {
    await this.setState({ barrelCode: '' });
    await this.fetchOrders(1);
  };

  async toggleCreateOpen() {
    await this.setState({ createOpen: !this.state.createOpen });
  }

  async handleStatusChange(event) {
    await this.setState({ filterStatus: event.target.value });
    localStorage.setItem('orders.filterStatus', JSON.stringify(this.state.filterStatus));
    await this.fetchOrders(this.state.activePage);
  }
  async handleDriverChange(event) {
    await this.setState({ driver: event.target.value });
    localStorage.setItem('orders.driver', JSON.stringify(this.state.driver));
    await this.fetchOrders(this.state.activePage);
  }

  async handlePoolChange(event) {
    await this.setState({ pool: event.target.value });
    localStorage.setItem('orders.pool', JSON.stringify(this.state.pool));
    await this.fetchOrders(this.state.activePage);
  }

  async handleGroupChange(event) {
    await this.setState({ group: event.target.value });
    localStorage.setItem('orders.group', JSON.stringify(this.state.pool));
    await this.fetchOrders(this.state.activePage);
  }

  handleDateChange = async (date, label) => {
    if (label === 'start') {
      await this.setState({
        dateStart: date
      });
      localStorage.setItem('orders.dateStart', JSON.stringify(date));
    }
    if (label === 'end') {
      await this.setState({
        dateEnd: date
      });
      localStorage.setItem('orders.dateEnd', JSON.stringify(date));
    }
  };

  translateSortParam = (sortParam, ascDesc) => {
    let ascDescTranslated = ascDesc ? 'Naraščajoče' : 'Padajoče';

    switch (sortParam) {
      case 'id':
        return 'id -' + ascDescTranslated;
      case 'collectedweight':
        return 'Skupno(kg) -' + ascDescTranslated;
      case 'actualweight':
        return 'Stehtana količina(kg) - ' + ascDescTranslated;
      case 'weightdeviation':
        return 'Odstopanju - ' + ascDescTranslated;
      case 'preferred_start_time':
        return 'Datumu - ' + ascDescTranslated;
      default:
        return sortParam;
    }
  };

  render() {
    const downloadExcelTooltipText = `S klikom na gumb se bo zgeneriral excel s trenutno izbranimi naročili. <br/> Če je izbranih naročil veliko, potem lahko generiranje excela traja več časa (tudi do nekaj minut).`;
    return (
      <div>
        <KotoContainer name={this.props.translate('page_orders_title')}>
          <Row noGutters className="justify-content-between">
            <Col md={8}>
              <Button className="mr-2" color="primary" onClick={this.toggleCreateOpen.bind(this)}>
                {this.props.translate('button_add_new')}
              </Button>
              <Button className="mr-3" color="warning" onClick={(e) => downloadOrderExcel(e, this.state.currentQuery)}>
                Prenesi excel izbranih naročil ({this.state.count}){' '}
              </Button>
              <BsInfoCircle data-tip={downloadExcelTooltipText} size="30" /> <ReactTooltip html={true} />
            </Col>

            <Col md={4}>
              <Row style={{ float: 'right' }}>
                <Col className="text-right align-self-center">
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={this.state.showOnlyKotoOrders}
                      onChange={this.toogleShowOnlyKoto.bind(this)}
                    />{' '}
                    {this.props.translate('show_only_koto_orders')}
                  </Label>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row noGutters className="justify-content-between mt-2">
            <Col sm={12} lg={4}>
              <Row>
                <Col>
                  <h6 className="mt-2 mr-1 d-flex text-nowrap">{this.props.translate('date_search_from')}:</h6>

                  <KotoDateTime
                    value={this.state.dateStart}
                    viewDate={moment().set({ hour: 0, minute: 1, second: 0, millisecond: 0 })}
                    name={'preferred_start_time'}
                    isValidDate={this.isStartDateValid}
                    onChange={(e) => this.handleDateChange(e, 'start')}
                  />
                </Col>

                <Col>
                  <h6 className="mt-2 ml-1 mr-1 d-flex text-nowrap">{this.props.translate('date_search_to')}:</h6>
                  <Row noGutters>
                    <Col>
                      <KotoDateTime
                        value={this.state.dateEnd}
                        viewDate={moment().set({ hour: 23, minute: 59, second: 0, millisecond: 0 })}
                        isValidDate={this.isEndDateValid}
                        onChange={(e) => this.handleDateChange(e, 'end')}
                      />
                    </Col>
                    <Button onClick={async () => await this.fetchOrders(this.state.activePage)} className="ml-1">
                      Potrdi
                    </Button>
                    <Button onClick={() => this.clearDates()} className="ml-1">
                      X
                    </Button>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col sm={12} lg={3}>
              <h6 className="mt-2 mr-1 d-flex text-nowrap">{this.props.translate('locations_single')}:</h6>

              <LocationSearch
                selectedValue={this.state.selectedLocations}
                handleSelectChange={this.handleLocationChange.bind(this)}
              />
            </Col>
            <Col sm={12} lg={2}>
              <h6 className="mt-2 mr-1 d-flex text-nowrap">{this.props.translate('order_status')}:</h6>

              <Input
                type="select"
                name="select"
                className="small w-100"
                id="exampleSelect"
                value={this.state.filterStatus}
                onChange={this.handleStatusChange.bind(this)}
              >
                <option key="" value="">
                  {this.props.translate('all')}
                </option>
                {Object.keys(orderStatuses).map((orderKey) => (
                  <option key={orderKey} value={orderKey}>
                    {this.props.translate(orderStatuses[orderKey].name)}
                  </option>
                ))}
              </Input>
            </Col>

            <Col sm={12} lg={2}>
              <h6 className="mt-2 mr-1 d-flex text-nowrap">Voznik</h6>

              <Input
                type="select"
                name="select"
                className="small w-100"
                id="exampleSelect2"
                value={this.state.driver}
                onChange={this.handleDriverChange.bind(this)}
              >
                <option key="" value="">
                  {this.props.translate('all')}
                </option>
                {this.props.drivers?.map((driver) => (
                  <option key={driver.value} value={driver.value}>
                    {driver.label}
                  </option>
                ))}
              </Input>
            </Col>
          </Row>
          <br />
          <Row className="justify-content-between text-right">
            <Col lg={2}>
              <h6 className="mt-2 mr-1 d-flex text-nowrap">Bazen</h6>
              <Input
                type="select"
                name="select"
                className="small w-100"
                id="exampleSelect2"
                value={this.state.pool}
                onChange={this.handlePoolChange.bind(this)}
              >
                <option key="" value="">
                  {this.props.translate('all')}
                </option>
                {this.state.pools?.map((pool) => (
                  <option key={pool.value} value={pool.value}>
                    {pool.label}
                  </option>
                ))}
              </Input>
            </Col>

            <Col lg={2}>
              <h6 className="mt-2 mr-1 d-flex text-nowrap">Skupina</h6>
              <Input
                type="select"
                name="select"
                className="small w-100"
                id="exampleSelect2"
                value={this.state.group}
                onChange={this.handleGroupChange.bind(this)}
              >
                <option key="" value="">
                  {this.props.translate('all')}
                </option>
                {this.state.groups?.map((group) => (
                  <option key={group.value} value={group.value}>
                    {group.label}
                  </option>
                ))}
              </Input>
            </Col>

            <Col>
              <h6 className="mt-2 mr-1 d-flex text-nowrap">Iskanje po oznakah embalaže</h6>
              <Row noGutters className="justify-content-start">
                <SearchBar
                  placeHolder={'Vpiši številko embalaže'}
                  onSearch={this.handleBarrelCodeSearch.bind(this)}
                  onClear={this.clearBarrelCodeSearch.bind(this)}
                  className={'mb-2'}
                />
              </Row>
            </Col>
            <Col>
              <Row noGutters className="justify-content-end">
                <h5 className="justify-content-end">
                  <Badge color="success" className="mr-2">
                    # Naročil v naslednjih dneh{' '}
                  </Badge>
                  <Badge>{this.state.following_days.join(' | ')}</Badge>
                </h5>
              </Row>
              <Row noGutters className="justify-content-end">
                <h5 className="justify-content-end">
                  <Badge color="success" className="mr-2">
                    {' '}
                    Ocena # kilogramov
                  </Badge>
                  <Badge>{this.state.following_days_kilos.join(' | ')}</Badge>
                </h5>
              </Row>
            </Col>
          </Row>

          <KotoModal
            title={() => (
              <Row className="justify-content-end w-100" noGutters>
                {this.props.translate('order_create_new')}{' '}
                <i
                  style={{ fontSize: '13px' }}
                  className=" ml-1 px-2 py-1 font-weight-normal bg-danger text-white rounded"
                >
                  Tretuno globalno izbran ponudnik: <b>{providers[getCurrentProvider('')]}</b>
                </i>
              </Row>
            )}
            isOpen={this.state.createOpen}
            toggle={this.toggleCreateOpen.bind(this)}
          >
            <CreateOrder refresh={this.fetchOrders.bind(this)} toggle={this.toggleCreateOpen.bind(this)} />
          </KotoModal>

          <Row noGutters style={{ fontSize: '12px' }} className="align-items-center justify-content-end mb-1">
            <div className={this.state.sortParam?.length > 0 ? 'visible' : 'invisible'}>
              Sortirano po: <b>{this.translateSortParam(this.state.sortParam, this.state.ascDesc)}</b>{' '}
              <Badge
                onClick={async () => {
                  await this.setState({
                    sortParam: null,
                    ascDesc: null
                  });
                  localStorage.removeItem('orders.sortParam');
                  localStorage.removeItem('orders.ascDesc');
                  await this.fetchOrders(this.state.activePage);
                }}
                style={{ fontSize: '13px', cursor: 'pointer' }}
                className=" ml-1 btn-sm bg-info"
              >
                Ponastavi
              </Badge>
            </div>
          </Row>

          <KotoTable
            columns={columns(this.props.translate, this.handleSorted.bind(this), this.props)}
            data={this.state.orders}
            style={{ fontSize: '80%' }}
            isErrorPresent={(rowInfo) => rowInfo?.original?.has_error}
            SubComponent={(subRow) => {
              return (
                <KotoSubTable
                  data={[
                    {
                      key: 'collected_number',
                      header: () => (
                        <div>
                          Pobrano <strong>(kos)</strong>
                        </div>
                      ),
                      unit: 'kos'
                    },
                    {
                      key: 'collected_quantity_litres',
                      header: () => (
                        <div>
                          Pobrano <strong>(l)</strong>
                        </div>
                      ),
                      unit: 'l'
                    },
                    {
                      key: 'collected_weighted_quantity',
                      header: () => (
                        <div>
                          Pobrano <strong>(kg)</strong>
                        </div>
                      ),
                      unit: 'kg'
                    },
                    {
                      key: 'actual_weight',
                      header: () => (
                        <div>
                          Dejanska teža <strong>(kg)</strong>
                        </div>
                      ),
                      unit: 'kg'
                    },
                    {
                      key: 'weight_deviation',
                      header: () => (
                        <div>
                          Odstopanje <strong>(%)</strong>
                        </div>
                      ),
                      unit: '%'
                    }
                  ]}
                  columns={[
                    {
                      Header: '',
                      Cell: (queryRow) => queryRow.header(),
                      width: '15%'
                    },
                    ...this.props.productTypes.map((product) => {
                      return {
                        Header: () => <div className={`text-${product.color}`}>{product.screen_name}</div>,
                        Cell: (queryRow) => {
                          const set = subRow.original.orderedproduct_set;
                          const matchSet = set?.find((ops) => ops?.product_type?.id == product.id);

                          if (matchSet && (matchSet[queryRow.key] || matchSet[queryRow.key] == 0)) {
                            return (
                              <Badge
                                className={` text-white`}
                                style={{ backgroundColor: product.color, fontSize: '12px' }}
                              >
                                {matchSet[queryRow.key] + ' ' + queryRow.unit}
                              </Badge>
                            );
                          }
                          return (
                            <Badge
                              className={'text-white'}
                              style={{ backgroundColor: product.color, fontSize: '12px' }}
                            >
                              /
                            </Badge>
                          );
                        }
                      };
                    })
                  ]}
                  subTable={true}
                />
              );
            }}
            defaultPageSize={this.state.pageSize}
            minRows={this.state.pageSize}
          />

          <Col sm="12" md={{ size: 6, offset: 3 }}>
            <KotoPagination
              totalItems={this.state.count}
              pageSize={this.state.pageSize}
              activePage={this.state.activePage}
              onSelect={this.handleSelected.bind(this)}
            />
          </Col>
        </KotoContainer>
      </div>
    );
  }
}

export default withI18n(withGlobalConsumer(withAuthConsumer(Orders)));
