import React from 'react';
import KotoModal from '../../../common/KotoModal';
import { Field, Form, Formik } from 'formik';
import { Row, Col, Label } from 'reactstrap';
import FormGroup from 'reactstrap/es/FormGroup';
import { FormInput } from '../../../common/FormInput';
import KotoDateTime from '../../../common/KotoDateTime';
import Button from 'reactstrap/es/Button';
import moment from 'moment';
import { BsTrash } from 'react-icons/bs';
import * as Yup from 'yup';

const UpdatePredictionGroupModal = ({ isOpen, toggle, group, onUpdate, ...props }) => {
  const title = `Spremeni skupino: ${group.name}`;

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Ne sme biti prazno ...'),
    risk_factor: Yup.number().min(0, 'Ne sme biti manjše kot 0').max(100, 'Ne sme biti večje kot 100')
  });

  return (
    <KotoModal isOpen={isOpen} toggle={toggle} title={title}>
      <Formik
        initialValues={{
          name: group.name,
          risk_factor: group.risk_factor ?? 50,
          period_start_date: group.period_start_date ? moment(group.period_start_date) : null,
          period_end_date: group.period_end_date ? moment(group.period_end_date) : null
        }}
        validationSchema={validationSchema}
        onSubmit={onUpdate}
        render={({ values, onSubmit, setFieldValue }) => {
          return (
            <Form>
              <Row>
                <Col md={12}>
                  <FormGroup>
                    <Label for="name">Ime skupine</Label>
                    <Field
                      value={values['name']}
                      name="name"
                      onChange={(e) => setFieldValue('name', e.target.value)}
                      type={'text'}
                      component={FormInput}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg={6} md={12}>
                  <FormGroup>
                    <Label for="period_start_date">
                      Začetni čas obdobja <BsTrash onClick={() => setFieldValue('period_start_date', null)} />
                    </Label>
                    <KotoDateTime
                      value={values['period_start_date']?.format('LL')}
                      name={'period_start_date'}
                      onChange={(e) => setFieldValue('period_start_date', e)}
                    />
                  </FormGroup>
                </Col>
                <Col lg={6} md={12}>
                  <FormGroup>
                    <Label for="period_end_date">
                      Končni čas obdobja <BsTrash onClick={() => setFieldValue('period_end_date', null)} />
                    </Label>
                    <KotoDateTime
                      value={values['period_end_date']?.format('LL')}
                      name={'period_end_date'}
                      onChange={(e) => setFieldValue('period_end_date', e)}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label for="name">Faktor tveganja</Label>
                    <Field
                      value={values['risk_factor']}
                      name="risk_factor"
                      onChange={(e) => setFieldValue('risk_factor', e.target.value)}
                      type={'number'}
                      component={FormInput}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row className="justify-content-end m-2">
                <Button className="m-1" onClick={toggle} color="danger">
                  Prekini
                </Button>
                <Button className="m-1" onClick={onSubmit} color="primary">
                  Ustvari
                </Button>
              </Row>
            </Form>
          );
        }}
      ></Formik>
    </KotoModal>
  );
};

export default UpdatePredictionGroupModal;
