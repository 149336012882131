import { FormFeedback, Input } from 'reactstrap';
import React from 'react';
import Select from 'react-select';

export const FormInput = ({ field, form: { touched, errors }, ...props }) => (
  <div>
    <Input invalid={!!(touched[field.name] && errors[field.name])} {...field} {...props} />
    {touched[field.name] && errors[field.name] && <FormFeedback>{errors[field.name]}</FormFeedback>}
  </div>
);

export const FormCheckbox = ({ field, type, checked, size, form: { touched, errors }, ...props }) => (
  <div className={props.className ? props.className : ''}>
    <label>
      <input style={{ transform: `scale(${size})` }} {...field} type={type} checked={checked} />
    </label>
    {touched[field.name] && errors[field.name] && <FormFeedback>{errors[field.name]}</FormFeedback>}
  </div>
);

export const FormSelect = ({ formik, ...props }) => {
  const renderError = formik.errors[props.name] && !!formik.touched[props.name];
  return (
    <div>
      <Select
        name={props.name}
        styles={{
          control: (base, state) => {
            if (renderError) {
              return {
                ...base,
                borderColor: '#dc3545'
              };
            }
            return {
              ...base
            };
          }
        }}
        {...props}
      ></Select>
      {renderError && (
        <div className="invalid-feedback" style={{ display: 'block' }}>
          {formik.errors[props.name]}
        </div>
      )}
    </div>
  );
};
