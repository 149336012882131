import React, { Component, Fragment } from 'react';

import withI18n from '../../../i18n';
import withAuthConsumer from '../../../api/auth/AuthContext';
import withGlobalConsumer from '../../../global/GlobalProvider';
import KotoContainer from '../KotoContainer';
import SingleTableHeader from '../../common/SingleTableHeader';
import KotoTwoRowTable from '../../common/KotoTwoRowTable';
import apiClient from '../../../api/ApiClient';
import { notificationService } from '../../notifications/NotificationService';
import { NavLink, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import KotoModal from '../../common/KotoModal';
import CreateUser from './CreateUser';
import { handleDefaultError } from '../../../utils/response-handler';
import KotoToggle from '../../common/KotoToggle';
import ForceChangePassword from './ForceChangePassword';
import KotoDate from '../../common/KotoDate';
import { KotoBadge } from '../../common/KotoBadge';
import { userStatuses } from '../../common/statuses';

const columns = (handleBan, translate) => {
  return [
    {
      Header: 'Id',
      id: 'uid',
      width: 50,
      Cell: (row) => (
        <NavLink className="p-0" to={`${row.uid}`} tag={Link}>
          {row.uid}
        </NavLink>
      )
    },

    {
      Header: translate('user_first_name'),
      id: 'first_name',
      width: 250,
      accessor: 'first_name'
    },

    {
      Header: translate('user_last_name'),
      id: 'last_name',
      width: 300,
      accessor: 'last_name'
    },

    {
      Header: translate('user_email'),
      id: 'email',
      accessor: 'email'
    },

    {
      Header: translate('user_is_active'),
      id: 'is_active',

      Cell: (row) => {
        return row && row.uid && <KotoToggle id={row.uid} checked={row.is_active} onChange={handleBan(row.uid)} />;
      }
    },
    {
      Header: translate('user_is_admin'),
      id: 'is_admin',
      Cell: (row) => (
        <KotoBadge status={row.is_admin} text={row.is_admin ? translate('boolean_true') : translate('boolean_false')} />
      )
    },
    {
      Header: 'Tip uporabnika',
      id: 'user_type',
      Cell: (row) => <div>{translate(userStatuses[row.user_type]?.name)}</div>
    },
    {
      Header: translate('locations_title'),
      id: 'locations',
      width: 500,
      Cell: (row) =>
        row.locations
          .map((location) => (
            <NavLink className="p-0" to={`/locations/${location.id}`} tag={Link}>
              {location.short_name}{' '}
            </NavLink>
          ))
          .reduce(
            (acc, x) =>
              acc === null ? (
                x
              ) : (
                <Row noGutters>
                  {acc}, &nbsp; {x}
                </Row>
              ),
            null
          )
    },
    {
      Header: 'Domača lokacija',
      Cell: (row) => {
        if (row.home_location) {
          return (
            <NavLink className="p-0 m-0" to={`/locations/${row.home_location.id}`} tag={Link}>
              {row.home_location.short_name}
            </NavLink>
          );
        } else {
          return '';
        }
      }
    },
    {
      Header: translate('user_is_password_changed'),
      id: 'is_new',
      Cell: (row) => (
        <KotoBadge status={!row.is_new} text={!row.is_new ? translate('boolean_true') : translate('boolean_false')} />
      )
    },
    {
      Header: 'Zadnja prijava',
      // width:150,
      id: 'last_login',
      Cell: (row) => row?.last_login && <KotoDate format={'LLLL'} date={row.last_login} />
    },
    {
      Header: 'Prevoznik',
      Cell: (row) => row.carrier?.name
    }
  ];
};

class SingleUser extends Component {
  state = {
    user: null,
    editOpen: false,
    forceChangePasswordOpen: false
  };

  async componentWillMount() {
    await this.fetchUser();
  }

  async toggleEditOpen() {
    await this.setState({ editOpen: !this.state.editOpen });
  }

  async handleBan(event) {
    const id = event.target.id;
    const allow = event.target.checked;

    await this.props.startLoading();
    try {
      const response = await apiClient.post(`/user/${id}/ban`, { is_active: allow });
      await this.fetchUser();
      // let params = queryString.parse(this.props.location.search)
      // let activePage = parseInt(params.activePage);
      //
      // if(params.activePage &&_.isInteger(activePage)){
      //     await this.fetchUsers(activePage-1)
      // }
      // else {
      //     await this.fetchUsers(0)
      // }
    } catch (e) {
      await handleDefaultError(e, this.props);
    }
    await this.props.stopLoading();
  }

  async fetchUser() {
    await this.props.startLoading();
    try {
      const res = await apiClient.get(`/user/${this.props.match.params.id}`);
      const user = res.data;
      await this.setState({
        user
      });
    } catch (e) {
      if (e?.response?.status === 401) {
        this.props.logout();
      } else {
        notificationService.error('Something went wrong');
      }
    }
    this.props.stopLoading();
  }

  render() {
    return (
      <div>
        <KotoContainer name={this.props.translate('page_users_title')}>
          <div>
            {this.state.user && (
              <Fragment>
                <SingleTableHeader
                  title={
                    this.props.translate('user_single') +
                    ' ' +
                    this.state.user.first_name +
                    ' ' +
                    this.state.user.last_name
                  }
                  onUserChangePassword={() => {
                    this.setState({ forceChangePasswordOpen: !this.state.forceChangePasswordOpen });
                  }}
                  onEdit={this.toggleEditOpen.bind(this)}
                />

                <KotoTwoRowTable
                  title={this.props.translate('user_data')}
                  dataObject={this.state.user}
                  valueSize={'w-50'}
                  columns={columns(() => this.handleBan.bind(this), this.props.translate)}
                  onEdit={this.toggleEditOpen.bind(this)}
                  subTable={false}
                />
                <KotoModal
                  title={this.props.translate('change_password_title')}
                  isOpen={this.state.forceChangePasswordOpen}
                  toggle={() => {
                    this.setState({ forceChangePasswordOpen: !this.state.forceChangePasswordOpen });
                  }}
                >
                  <ForceChangePassword
                    userId={this.state.user.uid}
                    toggle={() => {
                      this.setState({ forceChangePasswordOpen: !this.state.forceChangePasswordOpen });
                    }}
                    {...this.props}
                  />
                </KotoModal>
                <KotoModal
                  title={this.props.translate('user_update') + ' ' + this.state.user.first_name}
                  isOpen={this.state.editOpen}
                  toggle={this.toggleEditOpen.bind(this)}
                >
                  <CreateUser
                    toggle={this.toggleEditOpen.bind(this)}
                    refresh={this.fetchUser.bind(this)}
                    user={this.state.user}
                    userId={this.state.user.uid}
                  />
                </KotoModal>
              </Fragment>
            )}
          </div>
        </KotoContainer>
      </div>
    );
  }
}

export default withI18n(withAuthConsumer(withGlobalConsumer(SingleUser)));
