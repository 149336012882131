import { AuthContext } from '../../api/auth/AuthContext';
import React, { useContext } from 'react';
import { notificationService } from '../../components/notifications/NotificationService';
import { Field, Form, Formik } from 'formik';
import { FormEffect } from '../../components/common/FormEffect';
import { Button, Col, Label, Row } from 'reactstrap';
import FormGroup from 'reactstrap/es/FormGroup';
import { FormInput } from '../../components/common/FormInput';
import * as Yup from 'yup';
import apiClient from '../../api/ApiClient';

import { handleDefaultError } from '../../utils/response-handler';
import { GlobalContext } from '../../global/GlobalProvider';

import { I18nContext } from '../../i18n';
import FormConfirmCancel from '../common/FormConfirmCancel';

const passwordSchema = (translate) => {
  const toShort = translate('value_to_short');
  const notBlank = translate('value_not_empty');

  return Yup.object().shape({
    current_password: Yup.string().required(notBlank),
    new_password: Yup.string()
      .required(notBlank)
      .min(8, toShort + ' 8')
      .test('passwords-match-old', translate('password_must_match'), function (value) {
        return this.parent.new_password_repeat === value;
      }),

    new_password_repeat: Yup.string()
      .required(notBlank)
      .min(8, toShort + ' 8')
      .test('passwords-match-new', translate('password_must_match'), function (value) {
        return this.parent.new_password === value;
      })
  });
};

const ChangePassword = ({ withCancel, toggle, ...props }) => {
  const { startLoading, stopLoading } = useContext(GlobalContext);
  const { authProfile, logout } = useContext(AuthContext);
  const { translate } = useContext(I18nContext);

  return (
    <div>
      <Formik
        initialValues={{
          current_password: '',
          new_password: '',
          new_password_repeat: ''
        }}
        validationSchema={passwordSchema(translate)}
        onSubmit={async (values) => {
          startLoading();
          try {
            const req = { old_password: values.current_password, new_password: values.new_password };
            const response = await apiClient.post(`user/${authProfile.user_id}/update_password`, req);
            notificationService.info(translate('password_successfully_changed'));
            logout();
          } catch (e) {
            if (e.response && e.response.data && e.response.data.Message.startsWith('Old password is not correct')) {
              notificationService.info(translate('password_old_not_correct'));
            } else {
              props = { logout, translate, stopLoading, ...props };
              await handleDefaultError(e, props);
            }
          }
          stopLoading();
        }}
      >
        {(formik) => {
          return (
            <Form>
              <FormEffect
                formik={formik}
                onSubmissionError={() => notificationService.error(translate('error_form'))}
              />
              <Row form>
                <Col>
                  <FormGroup>
                    <Label for="current_password">{translate('current_password')}</Label>
                    <Field name="current_password" type={'password'} component={FormInput} />
                  </FormGroup>
                </Col>
              </Row>
              <Row form>
                <Col>
                  <FormGroup>
                    <Label for="new_password">{translate('new_password')}</Label>
                    <Field name="new_password" type={'password'} component={FormInput} />
                  </FormGroup>
                </Col>
              </Row>
              <Row form>
                <Col>
                  <FormGroup>
                    <Label for="new_password_repeat">{translate('new_password_repeat')}</Label>
                    <Field name="new_password_repeat" type={'password'} component={FormInput} />
                  </FormGroup>
                </Col>
              </Row>
              <Row className="justify-content-between">
                {withCancel ? (
                  <Col>
                    <FormConfirmCancel onCancel={toggle} />
                  </Col>
                ) : (
                  <Col>
                    <Row noGutters className="justify-content-between">
                      <Button color={'primary'}>{translate('button_confirm')}</Button>
                      <Button color={'secondary'} onClick={() => logout()}>
                        Odjava
                      </Button>
                    </Row>
                  </Col>
                )}
              </Row>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default ChangePassword;
