export function getColor(className) {
  switch (className) {
    case 'primary':
      return '#007bff';
    case 'secondary':
      return '#6c757d';
    case 'warning':
      return '#ffc107';
    case 'info':
      return '#17a2b8';
    case 'danger':
      return '#dc3545';
    case 'success':
      return '#28a745';
    case 'dark':
      return '#343a40';
    case 'light':
      return '#cbd3da';
    default:
      return 'white';
  }
}
