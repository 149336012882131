import React from 'react';
import KotoContainer from '../KotoContainer';
import { Card, CardTitle, Table } from 'reactstrap';
import CardGroup from 'reactstrap/es/CardGroup';
import KotoDate from '../../common/KotoDate';
import { FaDownload } from 'react-icons/fa';

export const Dashboard = () => (
  <div>
    <KotoContainer name="Dashboard">
      <CardGroup>
        <Card body inverse color="success">
          <CardTitle>
            Danes je bilo zgeneriranh <strong>356</strong> naročil
          </CardTitle>
        </Card>
        <Card body inverse color="primary">
          <CardTitle>
            Na voljo imamo <strong>12</strong> voznikov
          </CardTitle>
        </Card>
        <Card body inverse color="secondary">
          <CardTitle>
            V optimizaciji imamo <strong>23</strong> naročil
          </CardTitle>
        </Card>
      </CardGroup>
      <div>
        <br />
        <h6>Na voljo imamo sledeče voznike</h6>
        <Table striped>
          <thead>
            <tr>
              <th>#</th>
              <th>Ime</th>
              <th>Priimek</th>
              <th>Začetni čas</th>
              <th>Poročilo voznika</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">1</th>
              <td>Lutz</td>
              <td>Schoefman</td>
              <td>
                <KotoDate date={new Date()} format={'LLLL'}></KotoDate>
              </td>
              <td>
                <FaDownload />
              </td>
            </tr>
            <tr>
              <th scope="row">2</th>
              <td>Janez</td>
              <td>Novak</td>
              <td>
                <KotoDate date={new Date()} format={'LLLL'}></KotoDate>
              </td>
              <td>
                <FaDownload />
              </td>
            </tr>
            <tr>
              <th scope="row">3</th>
              <td>Sandi</td>
              <td>Narobe</td>
              <td>
                <KotoDate date={new Date()} format={'LLLL'}></KotoDate>
              </td>
              <td>
                <FaDownload />
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    </KotoContainer>
  </div>
);
