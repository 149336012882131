import KotoContainer from '../KotoContainer';
import React from 'react';
import { GeneralStatistics } from './GeneralStatistics';
import { Switch, Route, withRouter, Redirect } from 'react-router-dom';
import { LocationStatistics } from './LocationStatistics';
import Row from 'reactstrap/es/Row';
import LocationSearch from '../../LocationSearch';

const statsRouting = [
  { name: 'Generalno', path: '/general', component: GeneralStatistics }
  // { name: 'Po lokacijah', path: '/locations', component: LocationStatistics },
];

const Statistics = ({ match }) => {
  const { path } = match;
  return (
    <KotoContainer name={'Statistika'}>
      <Switch>
        <Switch>
          {statsRouting.map((route) => (
            <Route key={'route' + route.path} path={`${path}${route.path}`}>
              <div>
                <route.component />
              </div>
            </Route>
          ))}
          <Redirect to={`${path}${statsRouting[0].path}`} />
        </Switch>
      </Switch>
    </KotoContainer>
  );
};

export default withRouter(Statistics);
