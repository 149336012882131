import React, { Component, Fragment } from 'react';
import { I18nContextProvider } from './i18n';
import { AuthProvider } from './api/auth/AuthContext';
import './index.css';
import { GlobalProvider } from './global/GlobalProvider';
import AppContainer from './components/AppContainer';
import KotoLoader from './components/Loader';

class App extends Component {
  render() {
    return (
      <Fragment>
        <I18nContextProvider>
          <AuthProvider>
            <GlobalProvider>
              <KotoLoader>
                <AppContainer />
              </KotoLoader>
            </GlobalProvider>
          </AuthProvider>
        </I18nContextProvider>
      </Fragment>
    );
  }
}

export default App;
