import React, { useContext } from 'react';
import { Button, Col, Row } from 'reactstrap';
import withI18n, { I18nContext } from '../../i18n';

const FormConfirmCancel = ({ onCancel, onConfirm }) => {
  const { translate } = useContext(I18nContext);
  return (
    <div>
      <Row className="justify-content-between">
        <Col>
          <Button onClick={onConfirm} color={'primary'}>
            {translate('button_confirm')}
          </Button>
        </Col>
        <Col className="text-right">
          <Button color={'danger'} onClick={onCancel}>
            {translate('button_cancel')}
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default withI18n(FormConfirmCancel);
