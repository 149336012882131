import withI18n from '../../i18n';
import Row from 'reactstrap/es/Row';
import { FaEdit } from 'react-icons/fa';
import React, { Fragment, useState } from 'react';
import Button from 'reactstrap/es/Button';
import { Tooltip } from 'reactstrap';
import KotoPrompt from './KotoPrompt';
import KotoModal from './KotoModal';
import { MdDelete, MdRestore } from 'react-icons/md';

const SingleTableHeader = ({
  title,
  translate,
  onEdit,
  enabled = true,
  disableDescription,
  deleteRestoreEnabled = false,
  deleted,
  onDelete,
  deleteTitle,
  onRestore,
  restoreTitle,
  onUserChangePassword,
  restButtons
}) => {
  const [promptDeleteOpen, setPromptDeleteOpen] = useState(false);
  const [promptRestoreOpen, setPromptRestoreOpen] = useState(false);
  const [toolTipOpen, setToolTipOpen] = useState(false);

  function handleDeleteOpen() {
    setToolTipOpen(false);
    setPromptDeleteOpen(true);
  }
  function handleRestoreOpen() {
    setToolTipOpen(false);
    setPromptRestoreOpen(true);
  }
  function handleDelete() {
    onDelete();
    setPromptDeleteOpen(false);
  }
  function handleRestore() {
    onRestore();
    setPromptRestoreOpen(false);
  }
  return (
    <Row noGutters className="justify-content-between pl-1 pr-1">
      <h4 className="text-secondary mt-2">{title}</h4>

      <Row noGutters>
        {restButtons}
        {onUserChangePassword && (
          <Button onClick={onUserChangePassword} color="danger" className="mb-2 mr-1">
            Spremeni geslo
          </Button>
        )}
        <div onMouseLeave={() => setToolTipOpen(false)}>
          <Button onClick={onEdit} id="editButton" disabled={!enabled} color="primary" className="mb-2 mr-1">
            <Row noGutters>
              <FaEdit className="text-white mr-1" style={{ cursor: 'pointer' }} size="1.5em">
                {' '}
              </FaEdit>

              <div>{translate('edit')}</div>
            </Row>
          </Button>
        </div>
        {onDelete ? (
          <Button onClick={() => handleDeleteOpen()} color="danger" className="mb-2">
            <Row noGutters>
              <MdDelete className="text-white mr-1" style={{ cursor: 'pointer' }} size="1.5em">
                {' '}
              </MdDelete>
              <div>{translate('delete')}</div>
            </Row>
          </Button>
        ) : (
          <></>
        )}
      </Row>

      {!enabled && disableDescription && (
        <Tooltip isOpen={toolTipOpen} autohide={true} toggle={() => setToolTipOpen(!toolTipOpen)} target="editButton">
          {disableDescription}
        </Tooltip>
      )}

      <KotoModal isOpen={promptDeleteOpen} toggle={() => setPromptDeleteOpen(!promptDeleteOpen)} title={deleteTitle}>
        <KotoPrompt onConfirm={() => handleDelete()} onCancel={() => setPromptDeleteOpen(!promptDeleteOpen)} />
      </KotoModal>
      <KotoModal
        isOpen={promptRestoreOpen}
        toggle={() => setPromptRestoreOpen(!promptRestoreOpen)}
        title={restoreTitle}
      >
        <KotoPrompt onConfirm={() => handleRestore()} onCancel={() => setPromptRestoreOpen(!promptRestoreOpen)} />
      </KotoModal>
      {/*<h6 className="text-secondary"   onClick={onEdit}>{translate("edit")}</h6>*/}
    </Row>
  );
};

export default withI18n(SingleTableHeader);
