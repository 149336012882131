import { useState, useContext, useEffect } from 'react';
import React from 'react';
import { Field, Form, Formik } from 'formik';
import { Row, Col } from 'reactstrap';
import Label from 'reactstrap/es/Label';
import Select from 'react-select';
import { GlobalContext } from '../../../global/GlobalProvider';
import FormConfirmCancel from '../../common/FormConfirmCancel';
import apiClient from '../../../api/ApiClient';
import { notificationService } from '../../notifications/NotificationService';

export default function ChangeDriversModal({ targetDate, onClose, currentRoutes, refresh }) {
  const [firstRoute, setFirstRoute] = useState(null);
  const [secondRoute, setSecondRoute] = useState(null);
  const [routeOptions, setRouteOptions] = useState([]);

  async function submitRequest() {
    try {
      const data = {
        target_date: targetDate,
        first_route_pk: firstRoute,
        second_route_pk: secondRoute
      };
      await apiClient.post('/driver_route/change_two_drivers', data);
      notificationService.success('Voznika in vozili na poteh uspešno zamenjani!');
      refresh();
      onClose();
    } catch (e) {
      // console.log(e);
    }
  }

  return (
    <>
      <Formik
        initialValues={{ firstRoutepk: null, secondRoutepk: null }}
        onSubmit={submitRequest}
        render={({ values }) => (
          <>
            <Row className="alert alert-danger m-2 mb-4">
              Od 1.3.2021 se nekatere poti lahko začnejo in končajo tudi na domu nekega voznika. Pri prestavljanju
              voznikov je potrebno paziti na to. Aplikacija še zmeraj dopušča, da se menja voznike tudi na poteh, ki se
              začenjajo na voznikovem domu, saj bo lahko v kakšnih skrajnih primerih to nujno potrebno.
            </Row>
            <hr />
            <Form>
              Izberi poti med katerima bi zamenjal voznika (skupaj z vozilom):
              <Select
                id="firstRoute"
                options={currentRoutes.map((item) => item.object)}
                onChange={(e) => setFirstRoute(e.value)}
              />
              <Select
                className="mt-2"
                id="secondRoute"
                options={currentRoutes.map((item) => item.object)}
                onChange={(e) => setSecondRoute(e.value)}
              />
              <div className="mt-3">
                <FormConfirmCancel onCancel={onClose} />
              </div>
            </Form>
          </>
        )}
      />
    </>
  );
}
