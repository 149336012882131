import Table from 'reactstrap/es/Table';
import React, { Component, Fragment } from 'react';
import _ from 'lodash';

export const KotoSubTable = ({ columns, data, inverse = true, ...props }) => {
  return (
    <Table className={'m-0'} hover dark={inverse} striped size="sm" {...props}>
      <thead>
        <tr>
          {columns.map((column) => {
            let columnStyle = {};
            if (column?.bgColor) {
              columnStyle = { backgroundColor: column.bgColor };
            }
            const widthStyle = column?.width ? { style: { width: column.width, ...columnStyle } } : { ...columnStyle };
            if (_.isFunction(column.Header)) {
              return <th {...widthStyle}>{column.Header()}</th>;
            }

            return <th {...widthStyle}>{column.Header}</th>;
          })}
        </tr>
      </thead>
      <tbody>
        {data?.map((row) => {
          return (
            <tr>
              {columns.map((column) => {
                const cr = column?.accessor;
                let columnStyle = {};
                if (column?.bgColor) {
                  columnStyle = { backgroundColor: column.bgColor };
                }
                if (column.Cell && _.isFunction(column.Cell)) {
                  return <td style={columnStyle}>{column.Cell(row)}</td>;
                }
                if (row[cr]) {
                  return <td style={columnStyle}>{row[cr]}</td>;
                }
                return <td></td>;
              })}
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};
