import React, { Component, Fragment, useContext, useEffect, useState } from 'react';
import KotoContainer from '../KotoContainer';
import KotoDateTime from '../../common/KotoDateTime';
import Row from 'reactstrap/es/Row';
import Col from 'reactstrap/es/Col';
import { useSetState } from 'react-use';
import * as moment from 'moment';
import { GlobalContext } from '../../../global/GlobalProvider';
import { handleDefaultError } from '../../../utils/response-handler';
import { I18nContext } from '../../../i18n';
import apiClient from '../../../api/ApiClient';
import KotoTable from '../../common/KotoTable';
import { Modal, ModalBody, ModalHeader, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import KotoTwoRowTable from '../../common/KotoTwoRowTable';
import { KotoSubTable } from '../../common/KotoSubTable';
import Button from 'reactstrap/es/Button';
import KotoModal from '../../common/KotoModal';
import { UpdateDriverRoute } from './UpdateDriverRoute';
import { KotoBadge } from '../../common/KotoBadge';
import { orderStatuses } from '../../common/statuses';
import Badge from 'reactstrap/es/Badge';
import MapBox from './MapBox';
import { colorArray } from '../../../utils/utils';
import ChangePassword from '../../settings/ChangePassword';
import _ from 'lodash';
import { BsArrowsFullscreen } from 'react-icons/bs';
import Select from 'react-select';
import { getColor } from '../../../utils/getColor';
import { AuthContext } from '../../../api/auth/AuthContext';
import PolylineOverlay from './PolylineOverlay';
import ChangeDriversModal from './changeDrivers';
import MapLegend from './MapLegend';

var polyline = require('@mapbox/polyline');

export const DriverRoutes = () => {
  const { startLoading, stopLoading } = useContext(GlobalContext);
  const { translate } = useContext(I18nContext);
  const { authProfile } = useContext(AuthContext);
  const [driverRouteState, setDriverRouteState] = useSetState({
    selectedDate: moment(),
    routes: [],
    editConfig: { open: false },
    totalRouteDistance: 0,
    totalOrders: 0,
    totalBarrels: 0,
    totalKilos: 0,
    mapOpened: false,
    currentFilteredRoute: null
  });

  // Show pins that describe different types of location or just show the regular basic pins for every location.
  const [showDetailedPins, setShowDetailedPins] = useState(true);

  const columns = React.useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
        width: 80
      },
      {
        Header: 'Voznik',
        accessor: 'prefered_driver_id',
        Cell: (row, index) => {
          const driver = row?.original?.driver;
          const color = colorArray[row.index];

          return <Badge style={{ background: color }}>{driver?.first_name + ' ' + driver?.last_name}</Badge>;
        }
      },
      {
        Header: 'Bazen',
        accessor: 'pool'
      },
      {
        Header: 'Vozilo',
        id: 'vehicle',
        Cell: (row) => (
          <NavLink className="pt-0 pb-0" to={`/vehicles/${row?.original?.vehicle?.id}`} tag={Link}>
            {row?.original?.vehicle?.license_plate}
          </NavLink>
        )
      },
      {
        Header: 'Št. kilometrov',
        accessor: 'full_distance',
        Cell: (row) => (
          <div>{row?.original?.full_distance ? (row?.original?.full_distance / 1000).toFixed(1) + ' km' : ''}</div>
        )
      },
      {
        Header: 'Naročil/Kosov/Kilogramov',
        Cell: (row) => {
          let orders = row?.original?.number_of_orders;
          let barrels = row?.original?.number_of_barrels;
          let kilos = row?.original?.number_of_kilos;
          let tripData = orders.map((item, i) => {
            return [orders[i], barrels[i], kilos[i]];
          });
          return (
            <strong>
              {tripData.map((item, index) => {
                return (
                  <>
                    <span style={{ color: 'blue' }}>{item[0]}</span> /<span style={{ color: 'green' }}> {item[1]}</span>{' '}
                    /<span style={{ color: 'brown' }}> {item[2]}</span>
                    {index <= tripData.length - 2 ? ', ' : ''}
                  </>
                );
              })}
            </strong>
          );
        }
      },
      {
        Header: 'Začetek poti',
        accessor: 'expected_route_start_time',
        Cell: (row) => (
          <div>
            {row?.original?.expected_route_start_time
              ? moment(row.original.expected_route_start_time).format('HH:mm')
              : ''}
          </div>
        )
      },
      {
        Header: 'Konec poti',
        accessor: 'expected_route_end_time',
        Cell: (row) => (
          <div>
            {row?.original?.expected_route_end_time ? moment(row.original.expected_route_end_time).format('HH:mm') : ''}
          </div>
        )
      }
    ],
    []
  );

  const fetchRoutes = async () => {
    startLoading();
    try {
      const response = await apiClient.get(
        `driver_route/show_on_day?target_date=${driverRouteState.selectedDate.format('YYYY-MM-DD')}`
      );
      setDriverRouteState({ routes: response?.data });
    } catch (e) {
      await handleDefaultError(e, { translate, stopLoading, startLoading });
    }
    stopLoading();
  };
  useEffect(() => {
    fetchRoutes();
  }, [driverRouteState.selectedDate]);

  const currentRoutes = () => {
    const { routes } = driverRouteState;
    return (routes ?? []).map((route) => ({
      object: {
        value: route.id,
        label: `${route.id} ${route?.vehicle?.license_plate} - ${route?.driver.first_name} ${
          route?.driver.last_name
        } Nosilnost ${route?.vehicle.capacity} kg ${route?.starting_from_drivers_home ? ' - ZAČENJA OD DOMA' : ''}`
      },
      driver_pk: route?.driver?.pk,
      starting_from_home: route?.starting_from_drivers_home
    }));
  };

  function sumArray(list) {
    return list.reduce((a, b) => a + b, 0);
  }

  useEffect(() => {
    updateTotals();
  }, [driverRouteState.routes]);

  function updateTotals() {
    let routeSum = 0;
    let orderSum = 0;
    let barrelSum = 0;
    let kiloSum = 0;
    driverRouteState.routes.forEach((route) => {
      routeSum += route.full_distance;
      orderSum += sumArray(route.number_of_orders);
      barrelSum += sumArray(route.number_of_barrels);
      kiloSum += sumArray(route.number_of_kilos);
    });
    setDriverRouteState({
      totalRouteDistance: Math.floor(routeSum / 1000),
      totalOrders: orderSum,
      totalBarrels: barrelSum,
      totalKilos: kiloSum
    });
    return;
  }
  function userDropDownFiler(date) {
    if (authProfile?.type === 300 && date) {
      return date?.isAfter(moment().subtract(14, 'days'));
    }
    return true;
  }

  const dot = (color = '#ccc') => ({
    alignItems: 'center',
    display: 'flex',

    ':before': {
      backgroundColor: color,
      borderRadius: 10,
      content: '" "',
      display: 'block',
      marginRight: 8,
      height: 10,
      width: 10
    }
  });
  const renderRouteData = () => {
    const { routes, currentFilteredRoute } = driverRouteState;
    if (!currentFilteredRoute) {
      return null;
    }
    const route = routes.find((rt) => currentFilteredRoute == rt.id);
    return (
      <Col className="pt-2">
        <Row className="py-2" noGutters>
          <strong className="mr-1">ID: </strong> <span>{route.id}</span>
        </Row>
        <Row className="py-2" noGutters>
          <strong className="mr-1">Voznik: </strong>
          <span>{route?.driver?.first_name + ' ' + route?.driver.last_name}</span>
        </Row>
        <Row className="py-2" noGutters>
          <strong className="mr-1">Pričakovan začetek: </strong>
          <span>{moment(route?.expected_route_start_time).format('LLL')}</span>
        </Row>
        <Row className="py-2" noGutters>
          <strong className="mr-1">Pričakovan konec: </strong>
          <span>{moment(route?.expected_route_end_time).format('LLL')}</span>
        </Row>
        <Row className="py-2" noGutters>
          <strong className="mr-1">Celotna razdalja: </strong>{' '}
          <span>{(route?.full_distance / 1000).toFixed(1)} km</span>
        </Row>
      </Col>
    );
  };

  return (
    <KotoContainer name={'Voznikove poti'}>
      <KotoModal
        title={'Zamenjaj voznike na poteh'}
        toggle={() => setDriverRouteState({ editConfig: null })}
        isOpen={driverRouteState?.editConfig?.open}
      >
        <ChangeDriversModal
          currentRoutes={currentRoutes()}
          refresh={() => fetchRoutes()}
          targetDate={driverRouteState.selectedDate.format('YYYY-MM-DD')}
          onClose={() => setDriverRouteState({ editConfig: null })}
        />
      </KotoModal>
      <Row noGutters className="align-items-center">
        <Col>
          <Row>
            <Col className="font-weight-bold">
              Izberi datum poti
              <KotoDateTime
                isValidDate={userDropDownFiler}
                value={driverRouteState.selectedDate.format('LL')}
                timeFormat={false}
                onChange={(e) => setDriverRouteState({ selectedDate: e })}
              />
            </Col>
            <Col className="font-weight-bold mt-4">
              {authProfile?.type !== 150 && (
                <Button color={'primary'} onClick={() => setDriverRouteState({ editConfig: { open: true } })}>
                  Spremeni voznike
                </Button>
              )}
              <Button className="ml-2" color="warning" onClick={() => setDriverRouteState({ mapOpened: true })}>
                Prikaži zemljevid <BsArrowsFullscreen />
              </Button>
            </Col>
          </Row>
        </Col>
        <Col>
          <strong>Skupno število kilometrov:</strong> &nbsp; {driverRouteState.totalRouteDistance} km <br />
          <strong>Skupno število naročil:</strong> &nbsp; {driverRouteState.totalOrders}
          <br />
          <strong>Skupno pričakovano število kosov embalaže:</strong> &nbsp; {driverRouteState.totalBarrels} kosov
          <br />
          <strong>Skupno pričakovano število kg:</strong> &nbsp; {driverRouteState.totalKilos} kg <br />
        </Col>
      </Row>

      <br />
      <Modal
        style={{ maxWidth: '1900px', width: '100%' }}
        isOpen={driverRouteState.mapOpened}
        toggle={() => setDriverRouteState({ mapOpened: false, currentFilteredRoute: null })}
      >
        <ModalBody>
          <ModalHeader
            className="border-0 p-0 m-1"
            toggle={() => setDriverRouteState({ mapOpened: false, currentFilteredRoute: null })}
          >
            Zemljevid voznikovih poti
          </ModalHeader>
          {driverRouteState.routes && (
            <Row>
              <Col sm={9}>
                <MapBox
                  short={driverRouteState.currentFilteredRoute == null}
                  driverRoutes={driverRouteState.routes
                    .map((dru, index) => ({
                      ...dru,
                      color: colorArray[index]
                    }))
                    .filter((route) => {
                      if (driverRouteState.currentFilteredRoute === null) {
                        return true;
                      }
                      return route.id === driverRouteState.currentFilteredRoute;
                    })}
                  showDetailedPins={showDetailedPins}
                />
              </Col>

              <Col>
                <Row className="flex-column justify-content-between h-100">
                  <div>
                    <Select
                      styles={{
                        control: (styles) => ({ ...styles, backgroundColor: 'white' }),
                        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                          const color = data.color;
                          return {
                            ...styles,
                            backgroundColor: isSelected ? getColor('primary') : 'white',
                            color: data.color,
                            cursor: isDisabled ? 'not-allowed' : 'default',

                            ':active': {
                              ...styles[':active'],
                              backgroundColor: 'white'
                            }
                          };
                        },
                        input: (styles) => ({ ...styles, ...dot() }),
                        placeholder: (styles) => ({ ...styles, ...dot() }),
                        singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) })
                      }}
                      options={[
                        {
                          value: null,
                          color: 'black',
                          label: 'Vse poti'
                        }
                      ].concat(
                        driverRouteState.routes.map((drs, index) => {
                          const driver = drs?.driver;
                          return {
                            value: drs.id,
                            label: driver?.first_name + ' ' + driver?.last_name,
                            color: colorArray[index]
                          };
                        })
                      )}
                      defaultValue={{ value: null, color: 'black', label: 'Vse poti' }}
                      onChange={(e) => setDriverRouteState({ currentFilteredRoute: e?.value })}
                    />
                    <Row>{renderRouteData()}</Row>
                  </div>
                  <div>
                    <Button
                      className="mb-3 mt-3"
                      onClick={() => setShowDetailedPins(!showDetailedPins)}
                      color="warning"
                    >
                      {showDetailedPins ? 'Izklopi podrobne oznake' : 'Vklopi podrobne oznake'}
                    </Button>
                    <MapLegend />
                  </div>
                </Row>
              </Col>
            </Row>
          )}
        </ModalBody>
      </Modal>

      {driverRouteState.routes && driverRouteState.routes.length > 0 ? (
        <KotoTable
          columns={columns}
          SubComponent={(row) => {
            return row.original?.driver_orders.length > 0 ? (
              <KotoSubTable
                data={row.original?.driver_orders}
                columns={[
                  {
                    Header: 'Lokacija',
                    width: '17%',
                    Cell: (cell) => <div>{cell?.location}</div>
                  },
                  {
                    Header: 'Naslov',
                    accessor: 'address',
                    width: '30%'
                  },
                  {
                    Header: 'Teža/Kosov',
                    width: '6%',
                    accessor: 'estimated_weight',
                    Cell: (cell) => {
                      return (
                        <>
                          {cell.estimated_weight} / {cell.barrels}
                        </>
                      );
                    }
                  },
                  {
                    Header: 'Status naročila',
                    width: '10%',
                    Cell: (cell) => {
                      const status = orderStatuses[cell?.status];
                      if (status?.name) {
                        return <Badge color={status.color}>{translate(status.name)}</Badge>;
                      }
                      return '';
                    }
                  },
                  {
                    Header: 'Razdalja od prejšnjega postanka',
                    accessor: 'distance_from_previous_stop',
                    width: '15%',
                    Cell: (row) => {
                      let distance = row?.distance_from_previous_stop ? row?.distance_from_previous_stop / 1000 : 0;
                      return <div>{distance >= 1 ? distance.toFixed(1) + ' km' : '< 1 km'}</div>;
                    }
                  },
                  {
                    Header: 'Pričakovan čas pobiranja (časovno okno)',
                    width: '11%',
                    Cell: (cell) => {
                      if (cell?.estimated_pickup_time) {
                        let startTime = cell?.preferred_start_time;
                        let endTime = cell?.preferred_end_time;
                        let estimatedTime = cell?.estimated_pickup_time;
                        let danger = false;
                        if (startTime && endTime) {
                          if (estimatedTime < startTime || estimatedTime > endTime) {
                            danger = true;
                          }
                        }
                        if (!cell?.reload_stop) {
                          return (
                            <div style={danger ? { color: '#FF9999' } : {}}>
                              {moment(cell?.estimated_pickup_time).format('HH:mm')} (
                              {moment(cell?.preferred_start_time).format('HH:mm')} -
                              {moment(cell?.preferred_end_time).format('HH:mm')})
                            </div>
                          );
                        }
                        return (
                          <div style={danger ? { color: '#FF9999' } : {}}>
                            {moment(cell?.estimated_pickup_time).format('HH:mm')}
                          </div>
                        );
                      }
                      return null;
                    }
                  },
                  {
                    Header: 'Čas pobiranja',
                    width: '11%',
                    Cell: (cell) => {
                      if (cell?.actual_pickup_time) {
                        return <div>{moment(cell?.actual_pickup_time).format('HH:mm')}</div>;
                      }
                      return null;
                    }
                  }
                ]}
                subTable={true}
              />
            ) : (
              'Za dano pot ni naročil.'
            );
          }}
          data={driverRouteState.routes ?? []}
        />
      ) : (
        <Row noGutters className="mx-2">
          <h6>Na izbran datum ni dodana nobena pot</h6>
        </Row>
      )}
    </KotoContainer>
  );
};
