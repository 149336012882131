import React from 'react';
import styles from './style';
import { FaDice } from 'react-icons/fa';
import { MdLocationCity } from 'react-icons/md';
import { Row, Col } from 'reactstrap';

const GroupButton = ({ group, selected, setSelectedGroup }) => {
  return (
    <Row
      className="justify-content-between m-2"
      style={selected ? styles.selected : {}}
      onClick={() => setSelectedGroup(group)}
    >
      <div>
        {selected && (
          <b>
            <i>{group.name}</i>
          </b>
        )}
        {!selected && `${group.name}`}
      </div>
      <div className="pr-3" style={styles.alignCenter}>
        <FaDice /> <span className="mr-1 ml-1">{group.risk_factor}</span> <MdLocationCity />
        <span className="mr-1 ml-1">{group.number_of_locations}</span>
      </div>
    </Row>
  );
};

export default GroupButton;
