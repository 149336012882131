import React, { Component, Fragment } from 'react';
import { FaDownload } from 'react-icons/fa';
import { GiBarrel } from 'react-icons/gi';
import { MdDateRange } from 'react-icons/md';
import Loader from 'react-loader-spinner';
import { Link, withRouter } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { Button, NavLink, Row } from 'reactstrap';
import apiClient from '../../../api/ApiClient';
import withAuthConsumer from '../../../api/auth/AuthContext';
import withGlobalConsumer from '../../../global/GlobalProvider';
import withI18n from '../../../i18n';
import { handleDefaultError } from '../../../utils/response-handler';
import { KotoBadge } from '../../common/KotoBadge';
import KotoDate from '../../common/KotoDate';
import KotoModal from '../../common/KotoModal';
import KotoNotification from '../../common/KotoNotification';
import KotoTable from '../../common/KotoTable';
import KotoTwoRowTable from '../../common/KotoTwoRowTable';
import SingleTableHeader from '../../common/SingleTableHeader';
import { orderStatuses } from '../../common/statuses';
import { notificationService } from '../../notifications/NotificationService';
import KotoContainer from '../KotoContainer';
import CreateOrder from "./CreateOrder";
import { downloadReport } from './Orders';
import { UpdateCollectedDataForm } from './UpdateCollectedDataForm';
import UpdateOrderTime from './UpdateOrderTime';

const columns = (translate, props) => {
  return [
    {
      Header: 'Id',
      width: 50,
      Cell: (row) => (
        <NavLink className="p-0" to={`${row.id}`} tag={Link}>
          {row.id}
        </NavLink>
      )
    },

    {
      Header: translate('locations_single'),
      Cell: (row) => (
        <NavLink className="p-0" to={`/locations/${row.location_id}`} tag={Link}>
          {row.location_short_name}
        </NavLink>
      )
    },
    {
      Header: 'Bazen lokacij',
      accessor: 'pool',
      width: 250,
      Cell: (row) => {
        return <div>{row?.name}</div>;
      }
    },
    {
      Header: translate('created_by_user'),
      Cell: (row) => (
        <NavLink className="p-0" to={`/users/${row.user_id}`} tag={Link}>
          {row.user_email}
        </NavLink>
      )
    },
    {
      Header: translate('created_timestamp'),
      id: 'order_created_timestamp',
      Cell: (row) => row.order_created_timestamp && <KotoDate format={'LLLL'} date={row.order_created_timestamp} />
    },
    {
      Header: translate('last_modified'),
      id: 'order_last_modified',
      Cell: (row) => row.order_last_modified && <KotoDate format={'LLLL'} date={row.order_last_modified} />
    },
    {
      Header: 'Naročilo zaključeno',
      id: 'order_completed_time',
      Cell: (row) => row.order_completed_time && <KotoDate format={'LLLL'} date={row.order_completed_time} />
    },
    {
      Header: translate('order_status'),
      Cell: (row) => (
        <KotoBadge
          text={translate(orderStatuses[row.order_status.id].name)}
          color={orderStatuses[row.order_status.id].color}
        />
      )
    },
    {
      Header: 'Komentar o naročilu',
      accessor: 'comment'
    },
    {
      Header: translate('report'),
      width: 65,
      Cell: (row) => {
        if (row.order_status.id == 70) {
          return (
            <div style={{ cursor: 'pointer' }} onClick={(e) => downloadReport(e, row.id, props)}>
              {translate('download')} <FaDownload className="text-primary" />
            </div>
          );
        }
        return <div></div>;
      }
    },
    {
      Header: 'Voznik',
      width: 65,
      Cell: (row) => {
        const driver = row?.driver;
        if (driver) {
          return (
            <NavLink className="p-0" to={`/users/${driver?.uid}`} tag={Link}>
              {driver?.first_name + ' ' + driver?.last_name}
            </NavLink>
          );
        }
        return null;
      }
    },

    {
      Header: translate('order_erp_id'),
      accessor: 'order_erp_id'
    },
    {
      Header: 'ERP Številka (če imamo OKO)',
      accessor: 'order_erp_id_oko'
    },
    {
      Header: translate('location_hint'),
      accessor: 'location_hint'
    },
    {
      Header: 'Število naročenih kosov embalaže (seštevek vseh tipov embalaže)',
      Cell: (row) => {
        const sum = row.order_ordered_barrels?.reduce((prev, el) => prev + el.number, 0)
        return (
            <div>
              {sum}
            </div>
        )
      }
    },
    {
      Header: translate('order_collected_quantity'),
      accessor: 'collected_quantity'
    },
    // {
    //   Header: "Število vrnjenih sodov",
    //   accessor: 'collected_quantity'
    // },
    {
      Header: translate('order_collected_weight'),
      id: 'collected_weight',
      Cell: (row) => {
        let value = row.collected_weight;
        value = parseFloat(value);
        return <div>{Number(value).toFixed(2)} kg</div>;
      }
    },
    {
      Header: translate('order_preferred_start_time'),
      id: 'preferred_start_time',
      Cell: (row) => row.preferred_start_time && <KotoDate format={'LLLL'} date={row.preferred_start_time} />
    },
    {
      Header: translate('order_preferred_end_time'),
      id: 'preferred_end_time',
      Cell: (row) => row.preferred_end_time && <KotoDate format={'LLLL'} date={row.preferred_end_time} />
    },
    {
      Header: translate('order_estimated_pick_up_time'),
      id: 'estimated_pick_up_time',
      Cell: (row) => row.estimated_pick_up_time && <KotoDate format={'LLLL'} date={row.estimated_pick_up_time} />
    }
  ];
};

const OrderdBarrels = (translate) => {
  return [
    {
      Header: "Tip Embalaže",
      Cell: (row) => <div>{row.original?.barrel_type?.screen_name}</div>
    },
    {
      Header: "Količina",
      accessor: 'number',
      Cell: (row) => {
        if (row.original.number > 0) {
          return <div>{row.original.number}</div>;
        } else {
          return '';
        }
      }
    }
  ];
};


const columnsSmall = (translate) => {
  return [
    {
      Header: translate('product_type'),
      Cell: (row) => <div>{row.original.product_type.short_name}</div>
    },
    {
      Header: translate('orderedproduct_set_collected_number'),
      accessor: 'collected_number',
      Cell: (row) => {
        if (row.original.collected_number > 0) {
          return <div>{row.original.collected_number}</div>;
        } else {
          return '';
        }
      }
    },
    {
      Header: translate('orderedproduct_set_collected_quantity_litres'),
      accessor: 'collected_quantity_litres',
      Cell: (row) => {
        if (row.original.collected_quantity_litres > 0) {
          return <div>{row.original.collected_quantity_litres} l</div>;
        } else {
          return '';
        }
      }
    },
    {
      Header: translate('orderedproduct_set_collected_weighted_quantity'),
      id: 'collected_weighted_quantity',
      Cell: (row) => {
        let value = row.original.collected_weighted_quantity;
        if (value > 0) {
          value = parseFloat(value);
          return <div>{Number(value).toFixed(2)} kg</div>;
        } else {
          return '';
        }
      }
    },
    {
      Header: translate('orderedproduct_set_actual_weight'),
      id: 'actual_weight',
      Cell: (row) => {
        let value = row.original.actual_weight;
        if (value > 0) {
          value = parseFloat(value);
          return <div>{Number(value).toFixed(2)} kg</div>;
        } else {
          return '';
        }
      }
    },
    {
      Header: 'Oznake embalaž',
      id: 'barrel_codes',
      Cell: (row) => {
        if (row.original.barrel_codes.length > 0) {
          return (
            <>
              <p data-tip={row.original.barrel_codes.join(', ')}>
                <GiBarrel className="mt-3" />
              </p>
              <ReactTooltip />
            </>
          );
        } else {
          return '';
        }
      }
    }
  ];
};

class SingleOrder extends Component {
  state = {
    order: null,
    isGenerationManual: false,
    reportGenerationLoading: false,
    reportModalOpen: false,
    editOpen: false,
    editDateOpen: false,
    updateCollectedDataFormOpened: false
  };

  async componentDidMount() {
    await this.fetchOrder();
  }

  async toggleEditOpen() {
    await this.setState({ editOpen: !this.state.editOpen });
  }

  async fetchOrder() {
    this.props.startLoading();
    try {
      const res = await apiClient.get(`/order/${this.props.match.params.id}`);
      const { location, user, ...data } = res.data;
      const order = {
        location_id: location.id,
        client_id: location.client,
        location_short_name: location.short_name,
        user_id: user.uid,
        user_email: user.email,
        ...data
      };
      await this.setState({
        order
      });
    } catch (e) {
      await handleDefaultError(e, this.props);
    }
    this.props.stopLoading();
  }

  async toggleEditOpen() {
    await this.setState({ editOpen: !this.state.editOpen });
  }

  async handleRestore() {
    try {
      const response = await apiClient.post(`order/${this.state.order.id}/undelete`);
      notificationService.success(this.props.translate('order_restore_message'));
    } catch (e) {
      handleDefaultError(e, this.props);
    }
    await this.fetchOrder();
  }

  async handleDelete() {
    try {
      const response = await apiClient.delete(`order/${this.state.order.id}`);
      this.props.history.push('/orders');
      notificationService.success(this.props.translate('order_delete_message'));
      return;
    } catch (e) {
      handleDefaultError(e, this.props);
    }
    await this.fetchOrder();
  }

  async toggleEditDateOpen() {
    await this.setState({ editDateOpen: !this.state.editDateOpen });
  }

  async toggleReportModalOpen() {
    await this.setState({ reportModalOpen: !this.state.reportModalOpen });
  }

  async changeGenerationManual() {
    await this.setState({
      isGenerationManual: !this.state.isGenerationManual
    });
  }

  async handleGenerateReport() {
    await this.setState({
      reportGenerationLoading: true
    });
    try {
      // let samoLazyWorkaround = this.state.isGenerationManual ? "True" : "False"

      const response = await apiClient.post(`order/${this.state.order.id}/complete_order`, { manual: 'True' });
      notificationService.success('Poročila so se uspešno zgenerirala');
    } catch (e) {
      handleDefaultError(e, this.props);
    }
    await this.setState({
      reportGenerationLoading: false
    });
  }

  async handleManualReportGeneration(pdf = false, erp = false) {
    try {
      await apiClient.post(`order/${this.state.order.id}/generate_reports`, { pdf_report: pdf, erp_report: erp });
      notificationService.success('Poročila so se uspešno zgenerirala');
    } catch (e) {
      handleDefaultError(e, this.props);
    }
    this.setState({ reportModalOpen: false });
  }

  render() {
    let isDeleted;
    let deleteTitle;
    let restoreTitle;
    if (this.state.order) {
      isDeleted = this.state.order.is_deleted;
      deleteTitle = `${this.props.translate('prompt_delete')} ${this.props.translate('order_single') + '?'} ${
        this.state.order.id
      }`;

      restoreTitle = `${this.props.translate('prompt_restore')} ${this.props.translate('order_single') + '?'} ${
        this.state.order.id
      }`;
    }
    let adminUser = false;
    if (this.props.authProfile) {
      const userType = this.props.authProfile.type;
      adminUser = userType === 100 || userType === 0;
    }

    // console.log("narocilo ",this.state.order)

    return (
      <div>
        {this.state.reportModalOpen && (
          <KotoModal
            title={'Izberite poročila:'}
            isOpen={this.state.reportModalOpen}
            toggle={this.toggleReportModalOpen.bind(this)}
          >
            <Row className="m-3">
              <Button
                style={{ margin: '0 auto' }}
                color="primary"
                onClick={() => this.handleManualReportGeneration(true, false)}
              >
                Ustvari le PDF poročilo
              </Button>
            </Row>
            <Row className="m-3">
              <Button
                style={{ margin: '0 auto' }}
                color="primary"
                onClick={() => this.handleManualReportGeneration(false, true)}
              >
                {' '}
                Ustvari le ERP poročilo
              </Button>
            </Row>
            <Row className="m-3">
              <Button
                style={{ margin: '0 auto' }}
                color="primary"
                onClick={() => this.handleManualReportGeneration(true, true)}
              >
                Ustvari obe poročili
              </Button>
            </Row>
          </KotoModal>
        )}

        <KotoContainer name={this.props.translate('page_orders_title')}>
          <div>
            {this.state.order && this.state.order.location_id && this.state.order.location_short_name && (
              <Fragment>
                <KotoNotification color={'#fff2ed'} borderColor={'#ffb294'}>
                  <strong>Obvestilo:</strong> Naročilo v poljubnem stanju lahko zaključite s tem, da spodaj pritisnete
                  gumb "Popravi izbrane količine" in nato "Potrdi". Če naročilo nima nastavljenega datuma zaključka
                  naročila, potem bo datum zaključka nastavljen na trenutni čas. Če želite zaključiti naročilo s
                  spremenjenim datumom zaključka, potem najprej nastavite datum zaključka s pritiskom na gumb "Nastavi
                  datum zaključka naročila" in nato zaključite naročilo kot prej. <br />
                  <strong>
                    Če spreminjate podatke naročila, ki bi se poznala na poročilih, potem je potrebno poročila ročno
                    ponovno ustvariti. To storite s pritiskom na gumb "Ustvari poročila" in nato izberete ali se naj
                    ustvari samo PDF, samo ERP ali pa obe naročili.
                  </strong>
                </KotoNotification>
                <SingleTableHeader
                  title={this.props.translate('order_single') + ' ' + this.state.order.id}
                  enabled={
                    this.state.order.order_status.id === 10 ||
                    this.state.order.order_status.id == 50 ||
                    ((this.state.order.order_status.id == 60 ||
                      this.state.order.order_status.id === 70 ||
                      this.state.order.order_status.id === 75) &&
                      adminUser)
                  }
                  deleteRestoreEnabled={true}
                  deleted={isDeleted}
                  disableDescription={this.props.translate('order_status_must_be_10_to_edit')}
                  deleteTitle={deleteTitle}
                  restoreTitle={restoreTitle}
                  onDelete={this.handleDelete.bind(this)}
                  onRestore={this.handleRestore.bind(this)}
                  restButtons={
                    <>
                      {this.state.order.order_status.id === 50 || this.state.order.order_status.id === 60 ? (
                        <div className="mr-2">
                          <Button style={{ width: '190px' }} onClick={this.handleGenerateReport.bind(this)}>
                            <Row noGutters>
                              Zaključi naročilo
                              {this.state.reportGenerationLoading && (
                                <div className="ml-2">
                                  <Loader type="Oval" color="white" width={25} height={25} />
                                </div>
                              )}
                            </Row>
                          </Button>
                        </div>
                      ) : null}
                      {this.state.order.order_status.id === 70 || this.state.order.order_status.id === 75 ? (
                        <Button className="mb-2 mr-1" color="success" onClick={this.toggleReportModalOpen.bind(this)}>
                          Ustvari poročila
                        </Button>
                      ) : (
                        <></>
                      )}
                      <Button className="mb-2 mr-1" color="primary" onClick={this.toggleEditDateOpen.bind(this)}>
                        <MdDateRange className="text-white mr-1" />
                        Nastavi datum zaključka naročila
                      </Button>
                    </>
                  }
                  onEdit={this.toggleEditOpen.bind(this)}
                />
                <KotoModal
                  title={this.props.translate('order_edit') + ` ${this.state.order.id}`}
                  isOpen={this.state.editOpen}
                  toggle={this.toggleEditOpen.bind(this)}
                >
                  {/*<UpdateOrder*/}
                  {/*  order={this.state.order}*/}
                  {/*  refresh={this.fetchOrder.bind(this)}*/}
                  {/*  toggle={this.toggleEditOpen.bind(this)}*/}
                  {/*/>*/}
                  <CreateOrder
                    order={this.state.order}
                    refresh={this.fetchOrder.bind(this)}
                    toggle={this.toggleEditOpen.bind(this)}
                  />

                </KotoModal>
                <KotoModal
                  title="Nastavi datum zaključka naročila"
                  isOpen={this.state.editDateOpen}
                  toggle={this.toggleEditDateOpen.bind(this)}
                >
                  <UpdateOrderTime
                    order={this.state.order}
                    refresh={this.fetchOrder.bind(this)}
                    toggle={this.toggleEditDateOpen.bind(this)}
                  />
                </KotoModal>

                <KotoTwoRowTable
                  title={this.props.translate('order_data')}
                  dataObject={this.state.order}
                  deleted={this.state.order.is_deleted}
                  columns={columns(this.props.translate, this.props)}
                  onEdit={this.toggleEditOpen.bind(this)}
                  subTable={false}
                />
                <Row className="m-1 mb-2">
                  <h3>
                    <i>Naročeno število embalaže po tipu embalaže</i>
                  </h3>
                </Row>
                <KotoTable
                    columns={OrderdBarrels(this.props.translate)}
                    data={this.state.order['order_ordered_barrels'].sort((a, b) => a.barrel_type.id - b.barrel_type.id)}
                />

                {this.state.order['orderedproduct_set'] && (
                    <>
                      <Row noGutters className="justify-content-end mb-2">
                        <Button color={'primary'} onClick={() => this.setState({ updateCollectedDataFormOpened: true })}>
                          Popravi vnešene vrednosti
                        </Button>
                      </Row>
                      <KotoModal
                          size={'xl'}
                          isOpen={this.state.updateCollectedDataFormOpened}
                          title={'Popravljanje vnešenih količin'}
                          toggle={() =>
                              this.setState({ updateCollectedDataFormOpened: !this.state.updateCollectedDataFormOpened })
                          }
                      >
                        <UpdateCollectedDataForm
                            fetchOrder={this.fetchOrder.bind(this)}
                            toggle={() =>
                                this.setState({ updateCollectedDataFormOpened: !this.state.updateCollectedDataFormOpened })
                            }
                            orderId={this.state.order.id}
                            orderProductSet={this.state.order['orderedproduct_set']}
                        ></UpdateCollectedDataForm>
                      </KotoModal>
                    </>
                )}

                <KotoTable
                  columns={columnsSmall(this.props.translate)}
                  data={this.state.order['orderedproduct_set'].sort((a, b) => a.product_type.id - b.product_type.id)}
                />
              </Fragment>
            )}
          </div>
        </KotoContainer>
      </div>
    );
  }
}

export default withRouter(withI18n(withGlobalConsumer(withAuthConsumer(SingleOrder))));
