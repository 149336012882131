import React, { useState, useEffect, useRef } from 'react';
import withAuthConsumer from '../../../../api/auth/AuthContext';
import withGlobalConsumer from '../../../../global/GlobalProvider';
import { Row, Col, Button } from 'reactstrap';
import apiClient from '../../../../api/ApiClient';
import RangeSlider from 'react-bootstrap-range-slider';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import KotoDateTime from '../../../common/KotoDateTime';
import KotoDate from '../../../common/KotoDate';
import KotoTable from '../../../common/KotoTable';
import moment from 'moment';
import { notificationService } from '../../../notifications/NotificationService';
import { BsInfoCircle } from 'react-icons/bs';
import ReactTooltip from 'react-tooltip';

const InteractiveCompareRisk = ({ onSubmit, initialRisk, groupId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [safety, setSafety] = useState(initialRisk);
  const [stats, setStats] = useState([]);
  const [date, setDate] = useState(moment());
  const [tooltipVisible, setTooltipVisible] = useState('off');

  const safetyRef = useRef();
  safetyRef.current = safety;

  async function fetchStats() {
    try {
      const url = `/prediction_groups/${groupId}/compare_estimate_accuracy?safety=${safety}&date=${date.format(
        'YYYY-MM-DD'
      )}`;
      const response = await apiClient.get(url);
      const dataSafety = response.data.safety;
      if (dataSafety == safetyRef.current) {
        setStats(response.data.data);
        setIsLoading(false);
      }
    } catch (e) {
      notificationService.error(JSON.stringify(e.response?.data));
    }
  }

  useEffect(() => {
    setIsLoading(true);
    const safetyWhenCalled = safety;
    setTimeout(() => {
      if (safetyWhenCalled == safetyRef.current) {
        fetchStats();
      }
    }, 1000);
  }, [safety, date, groupId]);

  useEffect(() => {
    setSafety(initialRisk);
  }, [initialRisk]);

  const handleSafetyChange = (value) => {
    setTooltipVisible('on');
    setTimeout(() => {
      setTooltipVisible('off');
    }, 2000);
    setSafety(value);
  };

  const toolTip = `
    V tej tabeli lahko interaktivno vidimo, kako dobra bi bila naša ocena kilogramov glede na pobrano število kilogramov prejšnih dni. <br/>
    V obdobju lahko nastavimo za katero obdobje bi radi gledali primerjavo. V tabeli bomo videli podatke za obdobje 20 dni pred izbranim datumom. <br/>
    Če smo z dano nastavitvijo tveganja zadovoljni, jo lahko shranimo s pritiskom na gumb shrani. <br/>
    Barvanje v posamezni vrsti: 
    <ul>
      <li>Temno rdeča - ocenjeni kilogrami so več kot 10% nižji kot pobrani kilogrami </li>
      <li>Svetlo rdeča - ocenjeni kilogrami so nižji kot pobrani, a odstopajo za manj kot 10%</li>
      <li>Svetlo zelena - ocenjeni kilogrami so višji kot pobrani, a odstopajo za manj kot 10%</li>
      <li>Temno zelena - ocenjeni kilogrami so več kot 10% višji kot pobrani kilogrami</li>
    </ul>
    `;

  const statColumns = [
    {
      Header: 'Datum',
      Cell: (row) => {
        return <KotoDate format={'LL'} date={row.original.date} />;
      }
    },
    {
      Header: 'Zaključenih naročil',
      accessor: 'finished_orders'
    },
    {
      Header: 'Vseh naročil',
      accessor: 'all_orders'
    },
    {
      Header: 'Dejanska pobrana teža',
      accessor: 'collected'
    },
    {
      Header: 'Ocenjena teža',
      accessor: 'estimate',
      getProps: (state, rowInfo, column) => {
        let color;
        if (rowInfo && rowInfo.row.estimate < 0.9 * rowInfo.row.collected) {
          color = '#ffb294';
        } else if (rowInfo && rowInfo.row.estimate > 1.1 * rowInfo.row.collected) {
          color = '#a6fa7f';
        } else if (
          rowInfo &&
          0.9 * rowInfo.row.collected < rowInfo.row.estimate &&
          rowInfo.row.estimate < rowInfo.row.collected
        ) {
          color = '#fff2ed';
        } else if (
          rowInfo &&
          1.1 * rowInfo.row.collected > rowInfo.row.estimate &&
          rowInfo.row.estimate > rowInfo.row.collected
        ) {
          color = '#ecffe3';
        }
        return {
          style: {
            background: color
          }
        };
      }
    }
  ];

  return (
    <div>
      <Row>
        <h4 className="p-2 m-2">Interaktivno nastavljanje tveganja</h4>
        <BsInfoCircle className="m-3" data-tip={toolTip} size="30" /> <ReactTooltip html={true} />
      </Row>
      <Row className="m-2">
        <Col md={2}>
          Obdobje
          <KotoDateTime value={date} timeFormat={false} onChange={(e) => setDate(e)} />
        </Col>
        <Col md={9}>
          Faktor tveganja
          <RangeSlider
            value={safety}
            min={0}
            max={100}
            onChange={(e) => handleSafetyChange(e.target.value)}
            tooltip={tooltipVisible}
          />
        </Col>
        <Col md={1}>
          <Button className="ml-2 mt-4 mb-2" color={'primary'} onClick={() => onSubmit(safety)}>
            Shrani
          </Button>
        </Col>
      </Row>
      <Row
        style={{ filter: isLoading ? 'blur(4px)' : 'blur(0)', width: '100%', maxWidth: '100%' }}
        className="m-2 mt-4"
      >
        <Col md={12}>
          <KotoTable data={stats} columns={statColumns} />
        </Col>
      </Row>
    </div>
  );
};

export default withGlobalConsumer(withAuthConsumer(InteractiveCompareRisk));
