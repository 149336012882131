import { toast } from 'react-toastify';

class NotificationService {
  success(message) {
    toast(message);
  }
  error(message) {
    toast.error(message);
  }
  info(message) {
    toast.info(message);
  }
}

export const notificationService = new NotificationService();
