import React, {useContext, useState} from 'react';
import FormConfirmCancel from '../../common/FormConfirmCancel';
import {Field, FieldArray, Form, Formik} from 'formik';
import { Col, Label, Row, Input } from 'reactstrap';
import apiClient from '../../../api/ApiClient';
import { FormCheckbox, FormInput, FormSelect } from '../../common/FormInput';
import Card from 'reactstrap/es/Card';
import {GlobalContext} from "../../../global/GlobalProvider";
import {createBarrelTypeChangesInitial, createEmptyBarrelInstanceChange} from "./barrelChangeUtils";
import InputGroup from "reactstrap/es/InputGroup";
import InputGroupAddon from "reactstrap/es/InputGroupAddon";
import InputGroupText from "reactstrap/es/InputGroupText";

export default function UpdateBarrels({ location, toggle, refresh }) {

    const { barrelTypes } = useContext(GlobalContext);

    const handleSelectChange = async (selectedOption, formik, id, stateFunction) => {
        await formik.setFieldValue(id, selectedOption.value, true);
        await stateFunction();
    };

    async function handleSubmit(values, actions) {
        try {
            await apiClient.post(`/barrel_changes`, values);
            refresh();
            toggle();
        } catch (e) {
            // console.log(e);
        }
    }

    return (
        <Formik
            initialValues={{
                comment: '',
                barrel_instance_change: location && location.barrel_changes ?
                    location.barrel_changes[0].barrel_instance_change.map((el) => {
                        // console.log(el)
                        return ({
                            "barrel_type_id": el.barrel_type.id,
                            "old_quantity": el.new_quantity,
                            "new_quantity": el.new_quantity
                        })
                    }) : []

            }}
            onSubmit={(values, actions) => {
                //  console.log(values);
                // We create manual changes only
                values.change_type = "manual"
                values.location_id = location.id
                // console.log(values)
                handleSubmit(values);
            }}
            render={({ values }) => {
                return (
                    <Form>
                        <FieldArray
                            name="barrel_instance_change"
                            render={(arrayHelpers) => (
                                <div>
                                    <Row noGutters>
                                        <Col>
                                            <h6 className="m-0 p-0">Tip embalaže</h6>
                                        </Col>
                                        <Col>
                                            <h6 className="m-0 p-0">Količina</h6>
                                        </Col>
                                    </Row>
                                    <hr />

                                    {values.barrel_instance_change && values.barrel_instance_change.length > 0 ? (
                                        values.barrel_instance_change.map((barrelChange, index) => {
                                            // console.log(barrelChange)
                                            const barrelType = barrelTypes?.find((pt) => pt.id === barrelChange.barrel_type_id);
                                            return (
                                                <InputGroup className={'py-1'} key={index}>
                                                    <InputGroupAddon className={'w-50'} addonType="prepend">
                                                        <InputGroupText
                                                            style={{
                                                                backgroundColor: '#11aa11', // barrelType?.color,
                                                                width: '100%',
                                                                color: 'white'
                                                            }}
                                                        >
                                                            {barrelType?.screen_name}
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input
                                                        min={0}
                                                        type={'number'}
                                                        step="1"
                                                        value={barrelChange.old_quantity}
                                                        disabled={true}
                                                    />
                                                    <Input
                                                        min={0}
                                                        type={'number'}
                                                        step="1"
                                                        value={barrelChange.new_quantity}
                                                        onChange={(e) => {
                                                            // console.log(e.target.value)
                                                            // console.log(arrayHelpers)
                                                            arrayHelpers.replace(index, {
                                                                ...barrelChange,
                                                                new_quantity: e.target.value
                                                            });
                                                        }}
                                                    />
                                                </InputGroup>
                                            );
                                        })
                                    ) : (
                                        <div />
                                    )}
                                    {/*<FormConfirmCancel onCancel={toggle} />*/}
                                </div>
                            )}
                        />
                        <Row className="mt-1">
                            <Col>
                                <Label for="comment">Komentar</Label>
                                <Field name="comment" type={'text'} component={FormInput} />
                            </Col>
                        </Row>
                        <Row className="mt-1">
                            <Col>
                            <Card className="small p-2 my-2">
                                <div>
                                    V kolikor želite na lokacij dodati nov tip embalaže to storite spodaj v meniju Omogočeni Tipi Embalaže
                                </div>
                            </Card>
                            </Col>
                        </Row>
                        <div className="mt-3">
                            <FormConfirmCancel onCancel={toggle} />
                        </div>
                    </Form>
                );
            }}
        />
    );
}
