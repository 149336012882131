import Orders from '../components/pages/orders/Orders';
import { Dashboard } from '../components/pages/dashboard/Dashboard';
import Locations from '../components/pages/locations/Locations';
import SingleOrder from '../components/pages/orders/SingleOrder';
import SingleLocation from '../components/pages/locations/SingleLocation';
import Users from '../components/pages/users/Users';
import SingleUser from '../components/pages/users/SingleUser';
import Clients from '../components/pages/clients/Clients';
import SingleClient from '../components/pages/clients/SingleClient';
import ConfigOrders from '../components/pages/configs/ConfigOrders';
import ConfigOptimizer from '../components/pages/configs/ConfigOptimizer';
import PoolsComponent from '../components/pages/location-pools/Pools';
import SinglePool from '../components/pages/location-pools/SinglePool';
import ConfigGeneralSettings from '../components/pages/configs/ConfigGeneralSettings';
import ComparePrediction from '../components/pages/configs/ComparePrediction';
import { Vehicles } from '../components/pages/vehicles/Vehicles';
import { SingleVehicle, SingleVehicles } from '../components/pages/vehicles/SingleVehicle';
import { DriverRoutes } from '../components/pages/driver-routes/DriverRoutes';
import Statistics from '../components/pages/statistics/Statistics';
import LocationGroupList from '../components/pages/location-groups/Groups';
import LocationGroupDetail from '../components/pages/location-groups/SingleGroup';
import BarrelDiscrepancy from '../components/pages/configs/BarrelDiscrepancy';
import DriverReports from '../components/pages/configs/Reports';
import Depot from '../components/pages/depots/Depots';
import PredictionGroup from '../components/pages/PredictionGroups/index';

export const routes = [
  {
    path: '/dashboard',
    component: Dashboard,
    exact: true
  },

  {
    path: '/',
    component: Orders,
    exact: true
  },
  {
    path: '/orders',
    component: Orders,
    exact: true
  },
  {
    path: '/orders/:id',
    component: SingleOrder,
    exact: true
  },
  {
    path: '/locations',
    component: Locations,
    exact: true
  },
  {
    path: '/locations/:id',
    component: SingleLocation,
    exact: true
  },
  {
    path: '/pools',
    component: PoolsComponent,
    exact: true
  },
  {
    path: '/pools/:id',
    component: SinglePool,
    exact: true
  },
  {
    path: '/groups',
    component: LocationGroupList,
    exact: true
  },
  {
    path: '/groups/:id',
    component: LocationGroupDetail,
    exact: true
  },
  {
    path: '/depots',
    component: Depot,
    exact: true
  },
  {
    path: '/prediction-groups',
    component: PredictionGroup,
    exact: true
  },
  {
    path: '/users',
    component: Users,
    exact: true
  },
  {
    path: '/users/:id',
    component: SingleUser,
    exact: true
  },
  {
    path: '/clients',
    component: Clients,
    exact: true
  },
  {
    path: '/clients/:id',
    component: SingleClient,
    exact: true
  },
  {
    path: '/vehicles',
    component: Vehicles,
    exact: true
  },
  {
    path: '/driver_routes',
    component: DriverRoutes,
    exact: true
  },

  {
    path: '/vehicles/:id',
    component: SingleVehicle,
    exact: true
  },

  {
    path: '/configs/orders',
    component: ConfigOrders,
    exact: true
  },
  {
    path: '/statistics',
    component: Statistics,
    exact: false
  },
  {
    path: '/configs/optimizer',
    component: ConfigOptimizer,
    exact: true
  },
  {
    path: '/configs/general-settings',
    component: ConfigGeneralSettings,
    exact: true
  },
  {
    path: '/configs/compare-prediction',
    component: ComparePrediction,
    exact: true
  },
  {
    path: '/configs/barrel-discrepancy',
    component: BarrelDiscrepancy,
    exact: true
  },
  {
    path: '/configs/reports',
    component: DriverReports,
    exact: true
  }
];
