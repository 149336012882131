import React, { useContext, useEffect } from 'react';
import { useStatFilter } from './useStatFilter';
import { useChartSelector } from './useChartSelector';
import Row from 'reactstrap/es/Row';
import apiClient from '../../../api/ApiClient';
import { KotoSubTable } from '../../common/KotoSubTable';
import Collapse from 'reactstrap/es/Collapse';
import { useSetState } from 'react-use';
import { GoChevronDown, GoChevronUp } from 'react-icons/go';
import { findProductTypeById, orderStatuses } from '../../common/statuses';
import { I18nContext } from '../../../i18n';
import Col from 'reactstrap/es/Col';
import { columnsLocation } from '../locations/Locations';
import { GlobalContext } from '../../../global/GlobalProvider';
import { AuthContext } from '../../../api/auth/AuthContext';
import Button from 'reactstrap/es/Button';
import { handleDefaultError } from '../../../utils/response-handler';
import { handleDownload } from '../orders/Orders';

const filterToQuery = (name, filter, first) => {
  const predecessor = first ? '?' : '&';
  if (filter && filter?.length > 0) {
    if (Array.isArray(filter)) {
      return `${predecessor}${name}=${filter.join()}`;
    } else {
      return `${predecessor}${name}=${filter}`;
    }
  }
  return '';
};
export const GeneralStatistics = () => {
  const { isAuth, authProfile } = useContext(AuthContext);
  const defaultViewType = authProfile?.type == 300 ? 'drivers' : 'year';
  const { viewType, selector, tableSelect, tableSelectHeader } = useChartSelector(defaultViewType);
  const { component, filters, sorter, sortQuery } = useStatFilter(viewType);
  const [data, setData] = React.useState();
  const [driverData, setDriverData] = React.useState();
  const [depotData, setDepotData] = React.useState([]);
  const { translate } = useContext(I18nContext);
  const { productTypes } = useContext(GlobalContext);
  const [tables, setTables] = useSetState({
    collected_data: true,
    count_by_status: false,
    count_by_time_period: false
  });
  useEffect(() => {
    const locationQuery = filterToQuery('location', filters.location);
    const dayQuery = filterToQuery('day', filters.day?.toString());
    const monthQuery = filterToQuery('month', filters.month?.toString());
    const productTypeQuery = filterToQuery('product_type', filters.product_type?.toString());
    if (viewType !== 'drivers') {
      const generalQueryParams = `view_type=${viewType}${filterToQuery(
        'year',
        filters.year?.toString(),
        false
      )}${monthQuery}${dayQuery}${locationQuery}${productTypeQuery}`;
      const locationQueryParams = `${filterToQuery(
        'year',
        filters.year?.toString(),
        true
      )}${monthQuery}${dayQuery}${locationQuery}${productTypeQuery}`;
      const fullUrl =
        viewType === 'location'
          ? `locations_statistics?${locationQueryParams}&${sortQuery}`
          : `/general_statistics?${generalQueryParams}&${sortQuery}`;

      apiClient.get(fullUrl).then((response) => {
        setData(response.data);
      });
    }
  }, [
    viewType,
    filters.year,
    filters.location,
    filters.day,
    filters.month,
    filters.product_type,
    sortQuery,
    authProfile
  ]);

  useEffect(() => {
    const startDate = filterToQuery('start', filters?.start?.format('YYYY-MM-DD'), true);
    const endDate = filterToQuery('end', filters?.end?.format('YYYY-MM-DD'));
    const productTypeQuery = filterToQuery('product_type', filters.product_type?.toString());
    if (viewType === 'drivers') {
      const generalQueryParams = `${startDate}${endDate}`;
      let formatSorQuery = '&' + sortQuery;
      if (!sortQuery || sortQuery == '') {
        formatSorQuery = '';
      }

      const fullUrl = `/drivers_statistics${generalQueryParams}${formatSorQuery}`;

      apiClient.get(fullUrl).then((response) => {
        setDriverData(response.data);
      });
    }
    if (viewType === 'depots') {
      const generalQueryParams = `${startDate}${endDate}${productTypeQuery}`;
      const fullUrl = `/depot_statistics${generalQueryParams}`;
      apiClient.get(fullUrl).then((response) => {
        setDepotData(response.data);
        //console.log(response.data);
      });
    }
  }, [viewType, filters.start, filters.end, filters.product_type]);

  async function downloadExcel() {
    let fullUrl = '';

    const locationQuery = filterToQuery('location', filters.location);
    const dayQuery = filterToQuery('day', filters.day?.toString());
    const monthQuery = filterToQuery('month', filters.month?.toString());
    const productTypeQuery = filterToQuery('product_type', filters.product_type?.toString());

    if (viewType === 'drivers') {
      const startDate = filterToQuery('start', filters?.start?.format('YYYY-MM-DD'), true);
      const endDate = filterToQuery('end', filters?.end?.format('YYYY-MM-DD'));
      const generalQueryParams = `${startDate}${endDate}`;
      let formatSorQuery = '&' + sortQuery;
      if (!sortQuery || sortQuery == '') {
        formatSorQuery = '';
      }
      fullUrl = `/drivers_statistics${generalQueryParams}${formatSorQuery}`;
    } else if (viewType === 'location') {
      const locationQueryParams = `${filterToQuery(
        'year',
        filters.year?.toString(),
        true
      )}${monthQuery}${dayQuery}${locationQuery}${productTypeQuery}`;
      fullUrl = `locations_statistics?${locationQueryParams}&${sortQuery}`;
    } else {
      const generalQueryParams = `view_type=${viewType}${filterToQuery(
        'year',
        filters.year?.toString(),
        false
      )}${monthQuery}${dayQuery}${locationQuery}${productTypeQuery}`;
      fullUrl = `/general_statistics?${generalQueryParams}&${sortQuery}`;
    }
    fullUrl = fullUrl + '&download=true';
    try {
      const response = await apiClient.get(fullUrl, {
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/json'
        }
      });
      await handleDownload(response, `kompot.Xlsx`);
    } catch (e) {
      await handleDefaultError(e, { translate: translate });
    }
  }

  const columns = [
    {
      Header: tableSelectHeader,
      width: '10%',
      Cell: (row) => tableSelect(row)
    },
    {
      Header: 'Produkt',
      width: '15%',
      Cell: (row) => {
        if ((filters.product_type?.length == 0) | (filters?.product_type && filters.product_type?.includes(''))) {
          return <div>Vsi</div>;
        } else {
          try {
            const selectedProducts = filters.product_type.map(
              (obj) => productTypes.find((pt) => pt.id == obj)?.screen_name ?? ''
            );
            return <div>{selectedProducts.join(', ')}</div>;
          } catch (e) {
            return <div>Vsi</div>;
          }
        }
      }
    },
    {
      Header: translate('orderedproduct_set_collected_number'),
      Cell: (row) => <div>{row?.collected_number}</div>
    },

    {
      Header: 'Stehtana količina (kg)',
      width: '17%',
      Cell: (row) => <div>{row?.actual_weight?.toFixed(0)} kg</div>
    },
    {
      Header: 'Pobrano (l)',
      width: '17%',
      Cell: (row) => <div>{row?.collected_litres?.toFixed(0)} l</div>
    },
    {
      Header: 'Pobrano (kg)',
      width: '16%',
      Cell: (row) => <div>{row?.collected_weight?.toFixed(0)} kg</div>
    },
    {
      Header: 'Povprečna teža embalaže',
      width: '15%',
      Cell: (row) => <div>{row?.average_barrel?.toFixed(2)} kg</div>
    }
  ];
  const columnsStatus = [
    {
      Header: 'Status',
      width: '50%',
      Cell: (row) => <Row noGutters>{translate(orderStatuses[row.order_status].name)}</Row>
    },
    {
      Header: 'Št. naročil',
      width: '50%',
      Cell: (row) => <Row noGutters>{row?.count}</Row>
    }
  ];
  const columnsTimePeriod = [
    {
      Header: tableSelectHeader,
      width: '50%',
      Cell: (row) => <Row noGutters>{tableSelect(row)}</Row>
    },
    {
      Header: 'Št. naročil',
      width: '50%',
      Cell: (row) => <Row noGutters>{row?.count}</Row>
    }
  ];
  const columnsTimePeriodAndStatus = [
    {
      Header: tableSelectHeader,
      width: '25%',
      Cell: (row) => <Row noGutters>{tableSelect(row)}</Row>
    },
    {
      Header: 'Status',
      width: '25%',
      Cell: (row) => <Row noGutters>{translate(orderStatuses[row.order_status].name)}</Row>
    },
    {
      Header: 'Št. naročil',
      width: '50%',
      Cell: (row) => <Row noGutters>{row?.count}</Row>
    }
  ];

  const locationTotalColumns = [
    {
      Header: 'Lokacija',
      Cell: (row) => <Row noGutters>{row?.location__short_name}</Row>
    },

    {
      Header: 'Število pobranih kosov embalaže',
      Cell: (row) => <Row noGutters>{row?.collected_number?.toFixed(0)} kosov</Row>
    },
    {
      Header: 'Pobrano (kg)',
      Cell: (row) => <Row noGutters>{row?.collected_weight?.toFixed(0)} kg</Row>
    },
    {
      Header: 'Stehtana količina (kg)',
      Cell: (row) => <Row noGutters>{row?.actual_weight?.toFixed(0)} kg</Row>
    },

    {
      Header: 'Pobrano (l)',
      Cell: (row) => <Row noGutters>{row?.collected_litres?.toFixed(2)} l</Row>
    },
    {
      Header: 'Povprečna teža embalaže (kg)',
      Cell: (row) => <Row noGutters>{row?.average_barrel?.toFixed(2)} kg</Row>
    }
  ];

  return (
    <div>
      <div></div>

      {authProfile?.type !== 300 && selector}
      {component}
      {viewType !== 'depots' && (
        <Row noGutters className="justify-content-end pt-3 pb-0 m-0">
          <Button style={{ marginRight: '10px' }} onClick={async () => await downloadExcel()}>
            {' '}
            Prenesi excel{' '}
          </Button>
          <div className="font-weight-bolder mr-2">Razvrsti po:</div>
          {sorter}
        </Row>
      )}

      {viewType !== 'location' && viewType !== 'drivers' && viewType !== 'depots' && (
        <div className={''}>
          <Row
            onClick={() => setTables({ collected_data: !tables?.collected_data })}
            noGutters
            className="bg-info pt-2 my-2 rounded-top justify-content-between"
          >
            <h6 className={'text-white ml-2'}>Pobrano</h6>
            {!tables?.collected_data ? (
              <GoChevronDown color={'white'} size={'1.2em'} className="mr-2" />
            ) : (
              <GoChevronUp color={'white'} size={'1.2em'} className="mr-2" />
            )}
          </Row>
          <Collapse isOpen={tables?.collected_data}>
            {data?.collected_data && (
              <KotoSubTable
                inverse={false}
                title={'Pobrano'}
                data={data?.collected_data}
                columns={columns}
              ></KotoSubTable>
            )}
          </Collapse>
          <Row
            onClick={() => setTables({ count_by_status: !tables?.count_by_status })}
            noGutters
            className="bg-info pt-2 my-2 rounded-top justify-content-between"
          >
            <h6 className={'text-white ml-2'}>Po statusu</h6>
            {!tables?.count_by_status ? (
              <GoChevronDown color={'white'} size={'1.2em'} className="mr-2" />
            ) : (
              <GoChevronUp color={'white'} size={'1.2em'} className="mr-2" />
            )}
          </Row>
          <Collapse isOpen={tables?.count_by_status}>
            {data?.count_by_status && (
              <KotoSubTable
                inverse={false}
                title={'Poprano'}
                data={data?.count_by_status}
                columns={columnsStatus}
              ></KotoSubTable>
            )}
          </Collapse>
          <Row
            onClick={() => setTables({ count_by_time_period: !tables?.count_by_time_period })}
            noGutters
            className="bg-info pt-2 rounded-top justify-content-between"
          >
            <h6 className={'text-white ml-2'}>Po časovnem obdobju</h6>
            {!tables?.count_by_time_period ? (
              <GoChevronDown color={'white'} size={'1.2em'} className="mr-2" />
            ) : (
              <GoChevronUp color={'white'} size={'1.2em'} className="mr-2" />
            )}
          </Row>
          <Collapse isOpen={tables?.count_by_time_period}>
            {data?.count_by_time_period && (
              <KotoSubTable
                inverse={false}
                title={'Poprano'}
                data={data?.count_by_time_period}
                columns={columnsTimePeriod}
              ></KotoSubTable>
            )}
          </Collapse>
          <Row
            onClick={() => setTables({ count_by_time_period_and_status: !tables?.count_by_time_period_and_status })}
            noGutters
            className="bg-info pt-2 my-2 rounded-top justify-content-between"
          >
            <h6 className={'text-white ml-2'}>Po statusu in časovnem obdobju</h6>
            {!tables?.count_by_time_period_and_status ? (
              <GoChevronDown color={'white'} size={'1.2em'} className="mr-2" />
            ) : (
              <GoChevronUp color={'white'} size={'1.2em'} className="mr-2" />
            )}
          </Row>
          <Collapse isOpen={tables?.count_by_time_period_and_status}>
            {data?.count_by_time_period_and_status && (
              <KotoSubTable
                inverse={false}
                title={'Poprano'}
                data={data?.count_by_time_period_and_status}
                columns={columnsTimePeriodAndStatus}
              ></KotoSubTable>
            )}
          </Collapse>
        </div>
      )}
      {viewType == 'location' && (
        <div>
          <Row
            onClick={() => setTables({ location_collected_total: !tables?.location_collected_total })}
            noGutters
            className="bg-info pt-2 my-2 rounded-top justify-content-between"
          >
            <h6 className={'text-white ml-2'}>Skupno</h6>
            {!tables?.location_collected_total ? (
              <GoChevronDown color={'white'} size={'1.2em'} className="mr-2" />
            ) : (
              <GoChevronUp color={'white'} size={'1.2em'} className="mr-2" />
            )}
          </Row>
          <Collapse isOpen={tables?.location_collected_total}>
            {data?.collected_total && (
              <KotoSubTable
                inverse={false}
                title={'Poprano'}
                data={data?.collected_total}
                columns={locationTotalColumns}
              ></KotoSubTable>
            )}
          </Collapse>
          <Row
            onClick={() => setTables({ location_collected_by_product: !tables?.location_collected_by_product })}
            noGutters
            className="bg-info pt-2 my-2 rounded-top justify-content-between"
          >
            <h6 className={'text-white ml-2'}>Skupno po izbranem produktu </h6>
            {!tables?.location_collected_by_product ? (
              <GoChevronDown color={'white'} size={'1.2em'} className="mr-2" />
            ) : (
              <GoChevronUp color={'white'} size={'1.2em'} className="mr-2" />
            )}
          </Row>
          <Collapse isOpen={tables?.location_collected_by_product}>
            {data?.collected_by_product && (
              <KotoSubTable
                inverse={false}
                title={'Poprano'}
                data={data?.collected_by_product}
                columns={locationTotalColumns}
              ></KotoSubTable>
            )}
          </Collapse>
        </div>
      )}
      {viewType === 'drivers' && (
        <div className="pt-2">
          {driverData && (
            <div>
              <div>
                <b>Celotna prevožena razdalja vseh voznikov: </b> {(driverData?.total_distance / 1000).toFixed(0)} km
              </div>
              <KotoSubTable
                data={driverData.data}
                columns={[
                  {
                    Header: 'Voznik',
                    accessor: 'driver',
                    width: '17%'
                  },
                  {
                    Header: 'Celotna prevožena razdalja',
                    width: '17%',
                    Cell: (row) => <div>{(row?.total_distance_traveled).toFixed(0)} km</div>
                  },
                  {
                    Header: 'Čas na poti v urah',
                    width: '17%',
                    Cell: (row) => (
                      <div>{row?.time_spent_driving_hours ? Number(row?.time_spent_driving_hours).toFixed(0) : 0}</div>
                    )
                  },
                  {
                    Header: 'Pobrani kosi ebmalaže',
                    width: '17%',
                    Cell: (row) => <div>{row?.collected_barrels}</div>
                  },
                  {
                    Header: 'Stehtana količina (kg)',
                    width: '17%',
                    Cell: (row) => <div>{row?.actual_weight?.toFixed()} kg</div>
                  },
                  {
                    Header: 'Pobrano (kg)',
                    width: '15%',
                    Cell: (row) => <div>{row?.collected_weight?.toFixed()} kg</div>
                  }
                ]}
              />
            </div>
          )}
        </div>
      )}

      {viewType == 'depots' && depotData.length > 0 && (
        <div className="mt-4">
          <KotoSubTable
            data={depotData}
            columns={[
              {
                Header: 'Skladišče',
                accessor: 'depot__short_name'
              },
              {
                Header: 'Pobranih kosov embalaže',
                accessor: 'collected_barrels'
              },
              {
                Header: 'Pobranih teža (kg)',
                accessor: 'collected_weight'
              },
              {
                Header: 'Dejanska pobrana teža (kg)',
                accessor: 'actual_weight'
              }
            ]}
          />
        </div>
      )}
    </div>
  );
};
