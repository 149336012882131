import React, { createRef, useContext, useEffect, useRef, useState } from 'react';
import { Col, ListGroup, ListGroupItem, Row } from 'reactstrap';
import { GlobalContext } from '../../../global/GlobalProvider';
import Input from 'reactstrap/es/Input';
import InputGroup from 'reactstrap/es/InputGroup';
import InputGroupText from 'reactstrap/es/InputGroupText';
import InputGroupAddon from 'reactstrap/es/InputGroupAddon';
import { MdSearch } from 'react-icons/md';
import { getColor } from '../../../utils/getColor';
import Spinner from 'reactstrap/es/Spinner';
import Badge from 'reactstrap/es/Badge';
import { useKey } from 'react-use';
import apiClient from '../../../api/ApiClient';
import { notificationService } from '../../notifications/NotificationService';

export const FilterHorizontal = ({ selectedLocations, setSelectedLocations }) => {
  const [locations, setLocations] = useState([]);
  const selectedKeys = selectedLocations.map((sl) => sl.value);
  const [filterValue, setFilterValue] = useState('');

  const [currentSelectedIndex, setCurrentSelectedIndex] = useState(undefined);

  const addToSelected = (location) => {
    if (selectedKeys.includes(location.id)) {
      return removeFromSelected(location.id);
    }
    setSelectedLocations((prevState) => {
      let array = Array.from(prevState);
      array.push({ value: location.id, label: location.short_name });
      return array;
    });
  };
  const removeFromSelected = (id) => {
    setSelectedLocations((prevState) => {
      let array = Array.from(prevState.filter((el) => el.value !== id));
      return array;
    });
  };

  async function fetchLocations() {
    try {
      const locationsResponse = await apiClient.get('/location/options');
      setLocations(locationsResponse.data);
    } catch (e) {
      notificationService.error('Nekaj je šlo narobe.');
      //console.log(e);
    }
  }

  function selectAllLocations() {
    const allLocations = locations.map((location) => {
      return { value: location.id, label: location.short_name };
    });
    setSelectedLocations(allLocations);
  }

  useEffect(() => {
    const el = document.getElementById('alo');
    if (el) {
      el.focus();
    }
    fetchLocations();
  }, []);

  useEffect(() => {
    const id = 'li_loc_' + currentSelectedIndex;
    const el = document.getElementById(id);
    if (el) {
      el.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center'
      });
    }
  }, [currentSelectedIndex]);

  useKey('ArrowDown', (event) => {
    setCurrentSelectedIndex((prevState) => {
      let prevValue = prevState;
      if (prevValue === undefined) {
        prevValue = -1;
      }
      return prevValue + 1;
    });
  });
  useKey('ArrowUp', (event) => {
    setCurrentSelectedIndex((prevState) => {
      let prevValue = prevState;
      if (prevValue === undefined) {
        return undefined;
      }
      if (prevState === 0) {
        return undefined;
      }
      return prevValue - 1;
    });
  });

  function getCurrentElementValue() {
    const id = 'li_loc_' + currentSelectedIndex;
    const el = document.getElementById(id);

    if (el) {
      const val = el.getAttribute('val');

      if (val) {
        return val;
      }
    }
    return undefined;
  }

  function handlePress(event) {
    let code = event.keyCode || event.which;
    let val = getCurrentElementValue();
    //console.log(event);
    if (code === 13) {
      //13 is the enter keycode

      const location = locations.find((ll) => ll.id == val);
      if (location) {
        addToSelected(location);
      }
    }
  }

  return (
    <div onKeyPress={(e) => handlePress(e)} tabIndex={'0'}>
      {JSON.stringify(currentSelectedIndex)}
      <Row noGutters>
        <Col
          style={{
            border: '1px black solid'
          }}
          className="p-3"
        >
          {locations && locations.length > 0 ? (
            <div>
              <Row noGutters className="justify-content-between">
                <h4 className="">Možne lokacije</h4>
                <Row style={{ cursor: 'pointer' }}>
                  <div className="align-items-center text-primary mr-2" onClick={() => selectAllLocations()}>
                    Izberi vse
                  </div>
                  <div className="mx-2 align-items-center " onClick={() => setSelectedLocations([])}>
                    Počisti vse
                  </div>
                </Row>
              </Row>

              <div className="py-2">
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <MdSearch />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    id={'alo'}
                    autoFocus
                    value={filterValue}
                    onChange={(e) => {
                      setCurrentSelectedIndex(undefined);
                      setFilterValue(e.target.value);
                    }}
                    placeholder="Filtriraj po lokacijah"
                  />
                </InputGroup>
              </div>
              <Row>
                <ListGroup
                  className={'w-100'}
                  style={{
                    height: '375px',
                    overflowY: 'scroll',
                    overflowX: 'hidden'
                  }}
                >
                  {locations
                    .filter((loc) => loc.short_name?.toLowerCase().includes(filterValue.toLowerCase()))
                    .map((location, i) => {
                      let selectedStyle = {};
                      let borderStyle = i === currentSelectedIndex ? { border: '2px blue solid' } : {};
                      let label = location?.short_name;
                      if (selectedKeys.includes(location.id)) {
                        selectedStyle = { color: 'white', background: getColor('primary') };
                      }

                      return (
                        <ListGroupItem
                          tabIndex={i}
                          key={location.id}
                          id={'li_loc_' + i}
                          key={location.id}
                          val={location.id}
                          onClick={() => addToSelected(location)}
                          style={{ ...selectedStyle, ...borderStyle }}
                        >
                          {label}
                        </ListGroupItem>
                      );
                    })}
                </ListGroup>
              </Row>
            </div>
          ) : (
            <Spinner />
          )}
        </Col>
        <div style={{ width: '50px' }}></div>
        <Col
          style={{
            border: '1px black solid',
            minHeight: '500px',
            maxHeight: '500px'
          }}
          className="mr-2"
        >
          <Row noGutters style={{ flexDirection: 'column' }} className="">
            <h5 className="bg-secondary p-3 text-white">
              Izbrane lokacije{' '}
              <Badge color="light" className={''}>
                {selectedLocations.length}
              </Badge>
            </h5>
            {selectedLocations && (
              <ListGroup
                style={{
                  height: '375px',
                  overflowY: 'scroll',
                  overflowX: 'hidden'
                }}
                className={'w-100 p-3'}
              >
                {selectedLocations.map((selectedLocation) => {
                  return <ListGroupItem key={selectedLocation.value}>{selectedLocation.label}</ListGroupItem>;
                })}
              </ListGroup>
            )}
          </Row>
        </Col>
      </Row>
    </div>
  );
};
