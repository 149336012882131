import './KotoDateTime.css';
import React from 'react';
import withI18n from '../../i18n';

let Datetime = require('react-datetime');
// eslint-disable-next-line no-unused-vars
const moment_sl = require(`moment/locale/sl`);

const KotoDateTime = ({ value, onChange, formik, ...props }) => {
  const widthStyle = props.width ? { width: props.width } : {};
  return (
    <div style={widthStyle}>
      <Datetime inputProps={{ readOnly: true }} value={value} onChange={onChange} locale="sl" {...props} />
      {formik && (
        <div className="invalid-feedback" style={{ display: 'block' }}>
          {formik.errors[props.name]}
        </div>
      )}
    </div>
  );
};

export default withI18n(KotoDateTime);
