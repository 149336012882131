import React from 'react';
import Row from 'reactstrap/es/Row';
import Col from 'reactstrap/es/Col';

const KotoNotification = (props) => {
  const styles = {
    margin: '10px',
    paddingLeft: '15px',
    padding: '10px',
    backgroundColor: props.color,
    border: `solid ${props.borderColor}`,
    borderRadius: '10px',
    fontSize: '1.1rem',
    fontWeight: '400'
  };
  return <Row style={styles}>{props.children}</Row>;
};

export default KotoNotification;
