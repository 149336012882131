import React, { Component, Fragment, useContext } from 'react';
import withI18n from '../../../i18n';
import KotoContainer from '../KotoContainer';

import KotoTwoRowTable from '../../common/KotoTwoRowTable';
import apiClient from '../../../api/ApiClient';
import { Link, withRouter } from 'react-router-dom';
import KotoModal from '../../common/KotoModal';
import UpdateLocation from './UpdateLocation';
import SingleTableHeader from '../../common/SingleTableHeader';
import withGlobalConsumer, { GlobalContext } from '../../../global/GlobalProvider';
import withAuthConsumer from '../../../api/auth/AuthContext';
import { handleDefaultError } from '../../../utils/response-handler';
import { NavLink } from 'reactstrap';
import KotoTable from '../../common/KotoTable';
import { TiUserAdd, TiUserDelete } from 'react-icons/ti';
import KotoPrompt from '../../common/KotoPrompt';
import Row from 'reactstrap/es/Row';
import UserToLocation from './UserToLocation';
import Col from 'reactstrap/es/Col';
import { notificationService } from '../../notifications/NotificationService';
import { KotoBadge } from '../../common/KotoBadge';
import { locationStatuses } from '../../common/statuses';
import { getColor } from '../../../utils/getColor';
import Button from 'reactstrap/es/Button';
import { RiContactsBook2Line } from 'react-icons/ri';
import { AddContact } from './AddContact';
import { MdDelete } from 'react-icons/all';
import { providers } from '../../navbar/providers';
import { KotoSubTable } from '../../common/KotoSubTable';
import Badge from 'reactstrap/es/Badge';
import ButtonGroup from 'reactstrap/es/ButtonGroup';
import Select from 'react-select';
import UsersSearch from '../../UsersSearch';
import Loader from 'react-loader-spinner';
import { FormSelect } from '../../common/FormInput';
import OrderBarrelChart from './OrderBarrelChart';
// import UpdateBarrels from './UpdateBarrels';
import KotoDate from '../../common/KotoDate';
import UpdateBarrels from "../barrelHistory/UpdateBarrelChange";

class SingleLocation extends Component {
  state = {
    addProductValues: [],
    addBarrelTypesValues: [],
    location: null,
    client: null,
    editOpen: false,
    editBarrelsOpen: false,
    createUserOpen: false,
    deleteUserOpen: false,
    addContactOpen: false,
    deletingId: null,
    statistics: []
  };

  userColumns = (translate, onDelete) => {
    return [
      {
        Header: 'Id',
        id: 'uid',
        width: 50,
        Cell: (row) => (
          <NavLink className="pt-0 pb-0" to={`/users/${row.original.uid}`} tag={Link}>
            {row.original.uid}
          </NavLink>
        )
      },

      {
        Header: translate('user_first_name'),
        id: 'first_name',
        width: 250,
        accessor: 'first_name'
      },

      {
        Header: translate('user_last_name'),
        id: 'last_name',
        width: 300,
        accessor: 'last_name'
      },

      {
        Header: translate('user_email'),
        id: 'email',
        accessor: 'email'
      },

      {
        Header: '',
        id: 'delete',
        Cell: (row) => (
          <div
            style={{ cursor: 'pointer', textAlign: 'end' }}
            onClick={onDelete(row.original.uid)}
            className="text-danger"
          >
            <TiUserDelete
              onClick={onDelete(row.original.uid)}
              className="text-danger mr-1"
              style={{ cursor: 'pointer' }}
              size="1.5em"
            >
              {' '}
            </TiUserDelete>
            {translate('remove')}
          </div>
        )
      }
    ];
  };

  columnsStatistics = () => {
    return [
      {
        Header: 'Ime',
        accessor: 'name'
      },
      {
        Header: 'Povprečje',
        accessor: 'average'
      },
      {
        Header: 'Standardni odklon',
        accessor: 'std'
      },
      {
        Header: 'Uporabljena ocena v optimizatorju',
        accessor: 'estimate'
      }
    ];
  };

  columnsBarrelChanges = () => {
    const beggining = [
      {
        Header: 'Tip spremembe',
        accessor: 'change_type',
        Cell: (row) => {
          if(row.original.change_type === "order"){
            return (
                <NavLink className="pt-0 pb-0" to={`/orders/${row.original.order.id}`} tag={Link}>
                  {row.original.change_type}
                </NavLink>
            )
          }
          return (
              <div>
                {row.original.change_type}
              </div>
          );
        }
      },
      {
        Header: 'Datum',
        Cell: (row) => row.original.created && <KotoDate format={'LLLL'} date={row.original.created} />
      },
      {
        Header: 'Uporabnik',
        Cell: (row) => {
          if(row.original.user){
            return (
                <div>
                  {`${row.original.user?.first_name} ${row.original.user?.last_name}`}
                </div>
            )
          }
          return (<div> {"---"} </div>)

        }
      },
      {
        Header: 'Komentar',
        accessor: 'comment',
        style: { whiteSpace: 'unset' }
      }
    ];
    for (let i of this.props.barrelTypes) {
      beggining.push({
        Header: `Stanje ${i.screen_name}`,
        Cell: (row) => {
          const barrelChangeObject = row.original.barrel_instance_change.filter((obj) => {
            return obj.barrel_type.short_name === i.short_name;
          });
          if (barrelChangeObject.length === 1) {
            return `${barrelChangeObject[0].new_quantity} (Prej ${barrelChangeObject[0].old_quantity}, sprem ${barrelChangeObject[0].change})`;
          }
          return '---';
        }
      });
    }

    return beggining;
  };

  columnsLocation = (translate) => {
    return [
      {
        Header: 'Id',
        accessor: 'id',
        width: 50
      },
      {
        Header: translate('client_single'),
        Cell: (row) =>
          row.client ? (
            <NavLink className="p-0" to={`/clients/${row.client.id}`} tag={Link}>
              {row.client.short_name}
            </NavLink>
          ) : null
      },
      {
        Header: 'Ponudnik',
        accessor: 'provider',
        width: 250,
        Cell: function (row) {
          return <div>{providers[row?.id]}</div>;
        }
      },
      {
        Header: 'Skupine',
        width: 250,
        Cell: (row) => {
          return row.groups?.map((item) => (
            <NavLink className="p-0" to={`/groups/${item.id}`} tag={Link}>
              {item.name}
            </NavLink>
          ));
        }
      },
      {
        Header: 'Bazen lokacij',
        accessor: 'pool',
        width: 250,
        Cell: (row) => {
          return <div>{row?.name}</div>;
        }
      },
      // {
      //   Header: 'Ocenjeno število sodov na lokaciji',
      //   Cell: (row) => {
      //     let barrels_at_location = row?.barrels_at_location_initial + row?.barrels_at_location_change;
      //     return (
      //       <div>
      //         {barrels_at_location} (Začetno stanje: {row?.barrels_at_location_initial}, sprememba{' '}
      //         {row?.barrels_at_location_change})
      //       </div>
      //     );
      //   }
      // },
      {
        Header: translate('short_name'),
        width: 250,
        accessor: 'short_name'
      },
      {
        Header: translate('locations_can_have_orders_on_weekends'),
        id: 'is_active',
        Cell: (row) => (
          <KotoBadge
            color={row.can_have_orders_on_weekends ? 'success' : 'danger'}
            text={row.can_have_orders_on_weekends ? translate('boolean_true') : translate('boolean_false')}
          />
        )
      },

      {
        Header: translate('locations_can_have_orders_on_holiday'),
        id: 'locations_can_have_orders_on_holiday',
        Cell: (row) => (
          <KotoBadge
            color={row.can_have_orders_on_holiday ? 'success' : 'danger'}
            text={row.can_have_orders_on_holiday ? translate('boolean_true') : translate('boolean_false')}
          />
        )
      },
      {
        Header: translate('largo_location_code'),
        width: 300,
        accessor: 'largo_location_code'
      },
      {
        Header: translate('minimal_time_window_in_minutes'),
        width: 300,
        Cell: (row) => (row.minimal_time_window_in_minutes ? row.minimal_time_window_in_minutes + ' min' : '')
      },
      {
        Header: translate('can_optimizer_widen_time_window'),
        width: 300,
        Cell: (row) => (
          <KotoBadge
            color={row.can_optimizer_widen_time_window === 1 ? 'success' : 'danger'}
            text={row.can_optimizer_widen_time_window === 1 ? translate('boolean_true') : translate('boolean_false')}
          />
        )
      },

      {
        Header: translate('address_description'),
        accessor: 'address_description'
      },
      {
        Header: translate('locations_address'),
        width: 200,
        Cell: (row) =>
          row && row.street_name && row.street_number && <div>{row.street_name + ' ' + row.street_number}</div>
      },

      {
        Header: 'Ponudnik',
        width: 200,
        Cell: (row) => <div>{row?.provider?.name}</div>
      },

      {
        Header: translate('location_optimizer_status'),
        width: 200,
        Cell: (row) => {
          if (row.optimizer_status) {
            const status = locationStatuses[row.optimizer_status];
            return <KotoBadge color={status.color} text={translate(status.name)} />;
          }
        }
      },

      {
        Header: translate('locations_post'),
        width: 200,
        Cell: (row) => row && row.post_code && row.city && <div>{row.post_code + ' ' + row.city}</div>
      },
      {
        Header: translate('locations_country'),
        accessor: 'country'
      }
    ];
  };

  async componentWillMount() {
    await this.fetchLocation();
  }

  async toggleEditOpen() {
    await this.setState({ editOpen: !this.state.editOpen });
  }

  async toggleEditBarrelsOpen() {
    await this.setState({ editBarrelsOpen: !this.state.editBarrelsOpen });
  }

  async toggleDeleteContactOpen() {
    await this.setState({ deleteContactOpen: !this.state.deleteContactOpen });
  }

  async toggleDeleteUserOpen() {
    await this.setState({ deleteUserOpen: !this.state.deleteUserOpen });
  }

  async handleDelete(id) {
    await this.setState({
      deleteUserOpen: true,
      deletingId: id
    });
  }

  async deleteUser() {
    await this.props.startLoading();
    try {
      const response = await apiClient.post(`location/${this.state.location.id}/users`, {
        remove: [this.state.deletingId]
      });
      await this.toggleDeleteUserOpen();
      await this.fetchLocation();
      notificationService.success(
        this.props.translate('locations_success_deleted_user') + `: ${this.state.deletingId}`
      );
    } catch (e) {
      await handleDefaultError(e, this.props);
    }
    await this.props.stopLoading();
  }

  async deleteContact() {
    await this.props.startLoading();
    try {
      const response = await apiClient.post(`location/${this.state.location.id}/contacts`, {
        remove_ids: [this.state.deletingContactId]
      });
      await this.toggleDeleteContactOpen();
      await this.fetchLocation();
      notificationService.success('Uspešno izbrisan kontakt' + `: ${this.state.deletingId}`);
    } catch (e) {
      await handleDefaultError(e, this.props);
    }
    await this.props.stopLoading();
  }

  async deleteProduct(id) {
    await this.props.startLoading();
    try {
      const response = await apiClient.post(`location/${this.state.location.id}/products`, { remove: [id] });
      await this.fetchLocation();
    } catch (e) {
      await handleDefaultError(e, this.props);
    }
    await this.props.stopLoading();
  }

  async addProducts() {
    await this.props.startLoading();
    try {
      const response = await apiClient.post(`location/${this.state.location.id}/products`, {
        add: this.state.addProductValues.map((apv) => apv.value)
      });
      await this.fetchLocation();
      await this.setState({
        addProductValues: []
      });
    } catch (e) {
      await handleDefaultError(e, this.props);
    }
    await this.props.stopLoading();
  }

  // TODO needs testing
  async deleteBarrelType(id) {
    await this.props.startLoading();
    try {
      const response = await apiClient.post(`location/${this.state.location.id}/update_barrel_types`, { remove: [id] });
      await this.fetchLocation();
    } catch (e) {
      await handleDefaultError(e, this.props);
    }
    await this.props.stopLoading();
  }

  // TODO needs testing
  async addBarrelType() {
    await this.props.startLoading();
    try {
      const response = await apiClient.post(`location/${this.state.location.id}/update_barrel_types`, {
        add: this.state.addBarrelTypesValues.map((apv) => apv.value)
      });
      await this.fetchLocation();
      await this.setState({
        addBarrelTypesValues: []
      });
    } catch (e) {
      await handleDefaultError(e, this.props);
    }
    await this.props.stopLoading();
  }

  async fetchLocation() {
    this.props.startLoading();
    try {
      const res = await apiClient.get(`/location/${this.props.match.params.id}`);
      const { users, client, ...data } = res.data;
      const location = { client_id: client.id, ...data };
      const statistics = [
        {
          name: 'Teža naročila v celotnem obdobju',
          average: res.data.alltime_collected_weight_average,
          std: res.data.alltime_collected_weight_std,
          estimate: res.data.alltime_collected_weight_average
        },
        {
          name: 'Število kosov embalaže v celotnem obdobju',
          average: res.data.alltime_collected_number_average,
          std: res.data.alltime_collected_number_std,
          estimate: res.data.alltime_collected_number_average
        },
        {
          name: 'Teža naročila v izbranem obdobju',
          average: res.data.period_collected_weight_average,
          std: res.data.period_collected_weight_std,
          estimate: res.data.period_collected_weight_average
        },
        {
          name: 'Število kosov embalaže v izbranem obdobju',
          average: res.data.period_collected_number_average,
          std: res.data.period_collected_number_std,
          estimate: res.data.period_collected_number_average
        }
      ];
      await this.setState({
        location: location,
        client: client,
        users: users,
        statistics: statistics
      });
    } catch (e) {
      await handleDefaultError(e, this.props);
    }
    this.props.stopLoading();
  }

  render() {
    let deleteTitle;
    if (this.state.location) {
      deleteTitle = `${this.props.translate('prompt_delete')} ${this.props.translate('user_single_delete') + '?'} ${
        this.state.deletingId
      }`;
    }

    const currentProductIds = this.state?.location?.products?.map((mp) => mp.id);
    const missingProducts = this.props.productTypes.filter((pt) => !currentProductIds?.includes(pt.id));
    const currentBarrelTypes = this.state?.location?.available_barrel_types?.map((mp) => mp.barrel_type.id);
    const missingBarrelTypes = this.props.barrelTypes.filter((pt) => {
      return !currentBarrelTypes?.includes(pt?.id)
    });

    return (
      <KotoContainer name={this.props.translate('locations_title')}>
        <div>
          {this.state.location && this.state.client && (
            <SingleTableHeader
              title={this.state.location.short_name}
              onEdit={this.toggleEditOpen.bind(this)}
              restButtons={
                <Button className="mb-2 mr-1" color="primary" onClick={this.toggleEditBarrelsOpen.bind(this)}>
                  Spremeni # kosov embalaže
                </Button>
              }
            />
          )}

          <KotoTwoRowTable
            title={this.props.translate('locations_data')}
            dataObject={{ client: this.state.client, ...this.state.location }}
            columns={this.columnsLocation(this.props.translate)}
            onEdit={this.toggleEditOpen.bind(this)}
            subTable={false}
          />
          <br />
          <hr />

          <Row className="m-1 mb-2">
            <h3>
              <i>Število kosov embalaže na lokaciji</i>
            </h3>
          </Row>
          <Fragment>
            <KotoTable
                className="mb-5"
                columns={this.columnsBarrelChanges(this.state.location?.barrel_changes)}
                data={this.state.location?.barrel_changes}
            />
          </Fragment>
          <hr className="mb-5" />

          <Row className="m-1 mb-2">
            <h3>
              <i>Statistika pobranih kosov embalaže in kilogramov</i>
            </h3>
            <span className="mt-2 ml-4">
              Zaključenih naročil: {this.state.location?.completed_orders_alltime} /{' '}
              {this.state.location?.completed_orders_period} (Skupaj / Izbrano obdobje)
            </span>
            <br />
          </Row>
          <Fragment>
            <KotoTable className="mb-5" columns={this.columnsStatistics()} data={this.state.statistics} />
          </Fragment>
          <hr className="mb-5" />


          {this.state.location && (
            <Fragment>
              <h3>
                <i>Naročenih/pobranih/vrnjenih kosov embalaže za zadnjih 30 naročil</i>
              </h3>
              <OrderBarrelChart
                xaxis={this.state.location.recent_orders.map((item) => item.date)}
                ordered={this.state.location.recent_orders.map((item) => item.ordered)}
                collected={this.state.location.recent_orders.map((item) => item.collected)}
                returned={this.state.location.recent_orders.map((item) => item.returned)}
              />
            </Fragment>
          )}

          {this.state.location && (
            <Fragment>
              <Row noGutters className="mb-2 justify-content-between">
                <Row noGutters className="mb-2 justify-content-between w-100">
                  <Col>
                    <h3>
                      <i>{'Omogočeni produkti'}</i>
                    </h3>
                  </Col>

                  <Col md={5} xs={12}>
                    <Row noGutters className="justify-content-end">
                      <Col md={7} className="mr-2 mb-1">
                        <Select
                          isMulti={true}
                          onChange={(e) =>
                            this.setState({
                              addProductValues: e ? e : []
                            })
                          }
                          value={this.state.addProductValues}
                          options={missingProducts.map((pt) => ({
                            value: pt.id,
                            label: pt.screen_name
                          }))}
                        />
                      </Col>
                      <Col md={4} sm={12} className="text-right">
                        <Button
                          disabled={this.state.addProductValues?.length === 0}
                          onClick={async () => await this.addProducts()}
                          color="primary"
                          className="w-100"
                        >
                          <Row noGutters className="justify-content-between">
                            <Row noGutters>
                              {this.state.addProductValues?.length > 1 ? (
                                <div>Dodaj produkte</div>
                              ) : (
                                <div>Dodaj produkt</div>
                              )}
                            </Row>
                          </Row>
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Row>
              <div style={{ backgroundColor: '#dee2e6' }} className="rounded p-1">
                {this.state?.location?.products?.length > 0 ? (
                  <div>
                    {(this.state?.location?.products ?? []).map((row) => (
                      <ButtonGroup className={'mx-1'} onClick={() => this.deleteProduct(row.id)}>
                        <Button
                          style={{
                            fontSize: '16px',
                            backgroundColor: row.color,
                            color: 'white'
                          }}
                        >
                          {row.screen_name}
                        </Button>
                        <Button color={'danger'}>
                          <MdDelete />
                        </Button>
                      </ButtonGroup>
                    ))}
                  </div>
                ) : (
                  <h6 className="align-items-center my-0">Na trenutni lokaciji ni omogočenih produktov</h6>
                )}
              </div>
            </Fragment>
          )}
          <br />
          <hr />

          {this.state.location && (
              <Fragment>
                <Row noGutters className="mb-2 justify-content-between">
                  <Row noGutters className="mb-2 justify-content-between w-100">
                    <Col>
                      <h3>
                        <i>{'Omogočeni Tipi Embalaže'}</i>
                      </h3>
                    </Col>

                    <Col md={5} xs={12}>
                      <Row noGutters className="justify-content-end">
                        <Col md={7} className="mr-2 mb-1">
                          <Select
                              isMulti={true}
                              onChange={(e) =>
                                  this.setState({
                                    addBarrelTypesValues: e ? e : []
                                  })
                              }
                              value={this.state.addBarrelTypesValues}
                              options={missingBarrelTypes.map((pt) => ({
                                value: pt.id,
                                label: pt.screen_name
                              }))}
                          />
                        </Col>
                        <Col md={4} sm={12} className="text-right">
                          <Button
                              disabled={this.state.addBarrelTypesValues?.length === 0}
                              onClick={async () => await this.addBarrelType()}
                              color="primary"
                              className="w-100"
                          >
                            <Row noGutters className="justify-content-between">
                              <Row noGutters>
                                {this.state.addBarrelTypesValues?.length > 1 ? (
                                    <div>Dodaj tip embalaže</div>
                                ) : (
                                    <div>Dodaj tip Embalaže</div>
                                )}
                              </Row>
                            </Row>
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Row>
                <div style={{ backgroundColor: '#dee2e6' }} className="rounded p-1">
                  {this.state?.location?.available_barrel_types?.length > 0 ? (
                      <div>
                        {(this.state?.location?.available_barrel_types ?? []).map((row) => {
                          return (
                            <ButtonGroup className={'mx-1'} onClick={() => this.deleteBarrelType(row.barrel_type.id)}>
                              <Button
                                  style={{
                                    fontSize: '16px',
                                    backgroundColor: "#00ff00",
                                    color: 'white'
                                  }}
                              >
                                {row.barrel_type.screen_name}
                              </Button>
                              <Button color={'danger'}>
                                <MdDelete />
                              </Button>
                            </ButtonGroup>
                        )})}
                      </div>
                  ) : (
                      <h6 className="align-items-center my-0">Na trenutni lokaciji ni omogočenih tipov embalaže</h6>
                  )}
                </div>
              </Fragment>
          )}
          <br />
          <hr />

          {this.state.users && (
            <Fragment>
              <Row noGutters className="mb-2 justify-content-between">
                <h3>
                  <i>Uporabniki</i>
                </h3>
                <Col md={5} xs={12}>
                  <UserToLocation
                    currentUsers={this.state.users.map((user) => user.uid)}
                    locationId={this.state.location.id}
                    fetch={this.fetchLocation.bind(this)}
                  />
                </Col>
              </Row>
              <KotoTable
                columns={this.userColumns(this.props.translate, (id) => this.handleDelete.bind(this, id))}
                data={this.state.users}
                style={{ fontSize: '80%' }}
              />
            </Fragment>
          )}
          <br />
          <br />
          <br />
          <hr />

          <KotoModal
            isOpen={this.state.addContactOpen}
            toggle={() => this.setState({ addContactOpen: !this.state.addContactOpen })}
            title={'Dodajanke kontakta lokaciji'}
          >
            <AddContact
              locationId={this.state.location?.id}
              toggle={() => this.setState({ addContactOpen: !this.state.addContactOpen })}
              fetch={this.fetchLocation.bind(this)}
            />
          </KotoModal>

          {this.state.location && (
            <>
              <Row noGutters className="mb-2 justify-content-between">
                <h3>
                  <i>Kontakti</i>
                </h3>

                <Button
                  color={'primary'}
                  className="align-items-center"
                  onClick={() => this.setState({ addContactOpen: !this.state.addContactOpen })}
                >
                  <RiContactsBook2Line size={'25px'} /> Dodaj kontakt
                </Button>
              </Row>
              <KotoTable
                columns={[
                  {
                    Header: 'Ime',
                    id: 'name',
                    width: 250,
                    accessor: 'name'
                  },

                  {
                    Header: 'Email',
                    id: 'email',
                    width: 250,
                    accessor: 'email'
                  },

                  {
                    Header: 'Telefonska številka',
                    id: 'phone_number',
                    width: 250,
                    accessor: 'phone_number'
                  },

                  {
                    Header: '',
                    id: 'delete',

                    Cell: (row) => (
                      <div
                        style={{ cursor: 'pointer', textAlign: 'end' }}
                        onClick={() =>
                          this.setState({
                            deleteContactOpen: true,
                            deletingContactId: row.original.id,
                            deletingContactName: row.original.name
                          })
                        }
                        className="text-danger"
                      >
                        <MdDelete className="text-danger mr-1" style={{ cursor: 'pointer' }} size="1.5em">
                          {' '}
                        </MdDelete>{' '}
                        Odstrani
                      </div>
                    )
                  }
                ]}
                data={this.state.location?.contacts ?? []}
                style={{ fontSize: '80%' }}
              />
            </>
          )}
          <KotoModal
            isOpen={this.state.deleteContactOpen}
            toggle={() => this.toggleDeleteContactOpen()}
            title={'Ste prepričani da želite izbrisati kontakt ' + this.state.deletingContactName + '?'}
          >
            <KotoPrompt onConfirm={() => this.deleteContact()} onCancel={() => this.toggleDeleteContactOpen()} />
          </KotoModal>

          <KotoModal isOpen={this.state.deleteUserOpen} toggle={() => this.toggleDeleteUserOpen()} title={deleteTitle}>
            <KotoPrompt onConfirm={() => this.deleteUser()} onCancel={() => this.toggleDeleteUserOpen()} />
          </KotoModal>
          <KotoModal
            title={
              this.props.translate('locations_update') + ' ' + (this.state.location && this.state.location.short_name)
            }
            isOpen={this.state.editOpen}
            toggle={this.toggleEditOpen.bind(this)}
          >
            {this.state.location && (
              <UpdateLocation
                toggle={this.toggleEditOpen.bind(this)}
                refresh={this.fetchLocation.bind(this)}
                location={this.state.location}
                client={this.state.client}
                locationId={this.state.location.id}
              />
            )}
          </KotoModal>
          <KotoModal
            title={`Spremeni število embalaže na lokaciji: ${this.state.location?.short_name}`}
            isOpen={this.state.editBarrelsOpen}
            toggle={this.toggleEditBarrelsOpen.bind(this)}
          >
            <UpdateBarrels
              toggle={this.toggleEditBarrelsOpen.bind(this)}
              refresh={this.fetchLocation.bind(this)}
              location={this.state.location}
            />
          </KotoModal>
        </div>
      </KotoContainer>
    );
  }
}

export default withI18n(withRouter(withGlobalConsumer(withAuthConsumer(SingleLocation))));
