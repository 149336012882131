import React, { useContext, useEffect, useMemo } from 'react';
import { useSetState } from 'react-use';
import apiClient from '../../../api/ApiClient';
import KotoContainer from '../KotoContainer';
import Button from 'reactstrap/es/Button';
import KotoModal from '../../common/KotoModal';
import KotoTable from '../../common/KotoTable';
import { Col, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import KotoPagination from '../../common/KotoPagination';
import { AddVehicle } from './AddVehicle';
import { GlobalContext } from '../../../global/GlobalProvider';

export const Vehicles = () => {
  const { locations } = useContext(GlobalContext);
  const [state, setState] = useSetState({
    results: [],
    count: 1,
    defaultPageSize: 15,
    pageSize: 15,
    createModalOpened: false
  });

  const fetch = (page) => {
    page = page - 1;
    const param = `?limit=${state.pageSize}&offset=${state.pageSize * page}`;
    apiClient.get(`/vehicle${param}`).then((response) => {
      const { results, count } = response?.data;
      setState({ results, count });
    });
  };
  useEffect(() => {
    fetch(1);
  }, []);

  const vehicleColumns = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
        width: 50,
        Cell: (row) => (
          <NavLink className="pt-0 pb-0" to={`/vehicles/${row.original.id}`} tag={Link}>
            {row.original.id}
          </NavLink>
        )
      },

      {
        Header: 'Ime',
        accessor: 'name'
      },

      {
        Header: 'Registrska številka',
        accessor: 'license_plate'
      },

      {
        Header: 'Kapaciteta (kg)',
        accessor: 'capacity'
      },

      {
        Header: 'Voznik',
        accessor: 'prefered_driver_id',
        Cell: (row) => {
          const driver = row?.original?.prefered_driver;
          if (driver) {
            return (
              <NavLink className="p-0" to={`/users/${driver?.pk}`} tag={Link}>
                {driver?.first_name + ' ' + driver?.last_name}
              </NavLink>
            );
          }
          return null;
        }
      }
    ],
    [locations]
  );

  return (
    <div>
      <KotoContainer name={'Vozila'}>
        <Button color="primary" className="mb-2" onClick={() => setState({ createModalOpened: true })}>
          Dodaj vozilo
        </Button>
        <KotoModal
          title={'Dodajanje vozila'}
          isOpen={state.createModalOpened}
          toggle={() => setState({ createModalOpened: !state.createModalOpened })}
        >
          <AddVehicle
            refetch={() => fetch(1)}
            onCancel={() => setState({ createModalOpened: !state.createModalOpened })}
          />
        </KotoModal>
        <KotoTable
          data={state.results}
          defaultPageSize={state.defaultPageSize}
          minRows={state.pageSize}
          columns={vehicleColumns}
        />
        <Col sm="12" md={{ size: 6, offset: 3 }}>
          <KotoPagination totalItems={state.count} pageSize={state.pageSize} onSelect={(page) => fetch(page)} />
        </Col>
      </KotoContainer>
    </div>
  );
};
