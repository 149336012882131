import React, { useContext } from 'react';
import { Field, Form, Formik } from 'formik';
import { FormEffect } from '../../common/FormEffect';
import { notificationService } from '../../notifications/NotificationService';
import FormConfirmCancel from '../../common/FormConfirmCancel';
import { I18nContext } from '../../../i18n';
import { Col, Label, Row } from 'reactstrap';
import FormGroup from 'reactstrap/es/FormGroup';
import { FormInput, FormSelect } from '../../common/FormInput';
import LocationSearch from '../../LocationSearch';
import * as Yup from 'yup';
import { GlobalContext } from '../../../global/GlobalProvider';
import { handleDefaultError } from '../../../utils/response-handler';
import apiClient from '../../../api/ApiClient';
import KotoDateTime from '../../common/KotoDateTime';
import moment from 'moment';

export const AddVehicle = ({ vehicle, onCancel, refetch }) => {
  const { translate } = useContext(I18nContext);
  const { drivers, startLoading, stopLoading } = useContext(GlobalContext);

  const schema = Yup.object().shape({
    name: Yup.string().min(1, 'Ime je prekratko').max(50, 'Ime je predolgo').required('Ne sme biti prazno'),
    license_plate: Yup.string().min(1, 'Ime je prekratko').max(50, 'Ime je predolgo').required('Ne sme biti prazno'),
    capacity: Yup.number().min(1, 'Ime je prekratko').required('Ne sme biti prazno'),
    prefered_driver_id: Yup.number().min(1, 'Ne sme biti prazno').required('Ne sme biti prazno')
  });

  return (
    <div>
      <Formik
        initialValues={{
          name: vehicle?.name ?? '',
          capacity: vehicle?.capacity ?? 1,
          license_plate: vehicle?.license_plate ?? '',
          prefered_driver_id: vehicle?.prefered_driver?.pk ?? 0
        }}
        validationSchema={schema}
        isValid={vehicle}
        onSubmit={async (values) => {
          const { prefered_driver_id, ...rest } = values;
          let request = { ...rest };
          if (prefered_driver_id) {
            request['prefered_driver_id'] = prefered_driver_id;
          }

          startLoading();
          try {
            if (vehicle) {
              await apiClient.put(`vehicle/${vehicle.id}`, request);
            } else {
              await apiClient.post('vehicle', request);
            }
          } catch (e) {
            await handleDefaultError(e, { translate: translate, stopLoading: stopLoading });
          }
          stopLoading();
          refetch();
          onCancel();
        }}
      >
        {(formik) => {
          const defaultValue = drivers.find((driver) => driver?.value === formik?.values?.prefered_driver_id);
          return (
            <Form>
              <FormEffect
                formik={formik}
                onSubmissionError={(e) => {
                  return notificationService.error(translate('error_form'));
                }}
              />

              <Row form className="justify-content-between">
                <Col md={5}>
                  <FormGroup>
                    <Label for="name">Ime</Label>
                    <Field name="name" type={'text'} component={FormInput} />
                  </FormGroup>
                </Col>

                <Col md={5}>
                  <FormGroup>
                    <Label for="license_plate">Registrska</Label>
                    <Field name="license_plate" type={'text'} component={FormInput} />
                  </FormGroup>
                </Col>
                <Col md={2}>
                  <FormGroup>
                    <Label for="capacity">Kapaciteta (kg)</Label>
                    <Field name="capacity" type={'number'} min={1} component={FormInput} />
                  </FormGroup>
                </Col>
              </Row>
              <Row noGutters xl={4} sm={12}>
                <FormGroup className="w-100">
                  <Label for="prefered_driver_id">Izberi voznika</Label>
                  <FormSelect
                    formik={formik}
                    className="koto-zIndex-888 w-100"
                    isMulti={false}
                    name={'prefered_driver_id'}
                    defaultValue={defaultValue}
                    onChange={(value) => formik.setFieldValue('prefered_driver_id', value.value)}
                    options={drivers}
                  />
                </FormGroup>
              </Row>
              <FormConfirmCancel onCancel={onCancel} />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
