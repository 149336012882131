import * as React from 'react';
import { PureComponent } from 'react';
import * as moment from 'moment';

export default class CityInfo extends PureComponent {
  render() {
    const { info } = this.props;
    return (
      <div>
        <div className="font-weight-bold">{info.location}</div>
        <div>
          <b>Pričakovan čas pobiranja:</b> {moment(info.estimated_pickup_time).format('LLL')}
        </div>
        {!info.reload_stop && (
          <>
            <div>
              <b>Željen čas pobiranja:</b> {moment(info.preferred_start_time).format('HH:mm')} -{' '}
              {moment(info.preferred_end_time).format('HH:mm')}
            </div>
            <div>
              <b>Optimizator sme podaljševati čas:</b> {info.can_optimizer_widden_time_window ? 'Ja' : 'Ne'}
            </div>
          </>
        )}
      </div>
    );
  }
}
