import React, { Component } from 'react';
import apiClient from '../api/ApiClient';
import withAuthConsumer from '../api/auth/AuthContext';
import { authService } from '../api/auth/AuthService';

export const GlobalContext = React.createContext();

class GlobalProvider extends Component {
  state = {
    isLoading: false,
    holidays: [],
    locations: [],
    startingLocations: [],
    drivers: [],
    vehicles: [],
    pools: [],
    groups: [],
    productTypes: [],
    productFetched: false,
    barrelTypes: [],
    barrelTypesFetched: false
  };

  constructor(props) {
    super(props);
    this.startLoading = this.startLoading.bind(this);
    this.stopLoading = this.stopLoading.bind(this);
    this.fetchLocations = this.fetchLocations.bind(this);
    this.fetchDrivers = this.fetchDrivers.bind(this);
    this.fetchVehicles = this.fetchVehicles.bind(this);
    this.fetchPools = this.fetchPools.bind(this);
    this.fetchGroups = this.fetchGroups.bind(this);
    this.fetchStartingLocations = this.fetchStartingLocations.bind(this);
    this.fetchProductTypes = this.fetchProductTypes.bind(this);
    this.fetchBarrelTypes = this.fetchBarrelTypes.bind(this);
    this.initAsyncState();
  }

  async initAsyncState() {
    const loggedIn = await authService.isLoggedIn();
    if (!loggedIn) {
      return;
    }
    if (this.state.locations.length === 0) {
      await this.fetchLocations();
    }
    if (this.state.pools.length === 0) {
      await this.fetchPools();
    }
    if (this.state.drivers.length === 0) {
      await this.fetchDrivers();
    }
    if (this.state.vehicles.length === 0) {
      await this.fetchVehicles();
    }
    if (this.state.startingLocations.length === 0) {
      await this.fetchStartingLocations();
    }
    if (!this.state.productFetched) {
      await this.fetchProductTypes();
    }
    if (!this.state.barrelTypesFetched) {
      await this.fetchBarrelTypes();
    }
    if (this.state.holidays.length === 0) {
      try {
        const response = await apiClient.get('/order/holidays');
        await this.setState({ holidays: response.data });
      } catch (e) {}
    }
  }

  async fetchPools() {
    try {
      const response = await apiClient.get('/pools');
      const pools = response.data.map((data) => ({
        value: data.id,
        label: data.name
      }));
      await this.setState({ pools });
    } catch (e) {}
  }

  async fetchGroups() {
    try {
      const response = await apiClient.get('/location_groups/options');
      const groups = response.data.map((data) => ({
        value: data.id,
        label: data.name
      }));
      await this.setState({ groups });
    } catch (e) {}
  }

  async fetchDrivers() {
    try {
      //&user_type=driver
      const response = await apiClient.get(`/user?paginated=false&user_type=driver`);
      const drivers = response.data.map((data) => ({
        value: data.uid,
        label: `${data.first_name} ${data.last_name}`
      }));
      await this.setState({ drivers });
    } catch (e) {}
  }

  async fetchVehicles() {
    try {
      //&user_type=driver
      const response = await apiClient.get(`/vehicle/choices`);
      const vehicles = response.data.map((data) => ({
        value: data?.id,
        label: data?.license_plate
      }));
      await this.setState({ vehicles });
    } catch (e) {}
  }

  async fetchStartingLocations() {
    try {
      const response = await apiClient.get(`/location/depot_locations`);
      const startingLocations = response.data.map((data) => ({ value: data.id, label: data.short_name }));
      await this.setState({ startingLocations });
    } catch (e) {}
  }

  async fetchLocations() {
    try {
      const response = await apiClient.get(`/location/options`);
      const locations = response.data.map((data) => ({ value: data.id, label: data.short_name }));
      await this.setState({ locations });
    } catch (e) {}
  }

  async fetchProductTypes() {
    try {
      const response = await apiClient.get(`/products`);
      //console.log('Prodct Types');
      //console.log(response.data);
      const productTypes = response.data;
      await this.setState({ productTypes: productTypes, productFetched: true });
    } catch (e) {}
  }

  async fetchBarrelTypes() {
    try {
      const response = await apiClient.get(`/barreltypes`);
      //console.log('Barrel Types');
      //console.log(response.data);
      const barrelTypes = response.data;
      await this.setState({ barrelTypes: barrelTypes, barrelTypesFetched: true });
    } catch (e) {}
  }

  async startLoading() {
    await this.setState({
      isLoading: true
    });
  }

  async stopLoading() {
    await this.setState({
      isLoading: false
    });
  }

  render() {
    return (
      <GlobalContext.Provider
        value={{
          isLoading: this.state.isLoading,
          startLoading: this.startLoading,
          stopLoading: this.stopLoading,
          fetchLocations: this.fetchLocations,
          fetchDrivers: this.fetchDrivers,
          fetchPools: this.fetchPools,
          fetchGroups: this.fetchGroups,
          holidays: this.state.holidays,
          locations: this.state.locations,
          drivers: this.state.drivers,
          vehicles: this.state.vehicles,
          pools: this.state.pools,
          startingLocations: this.state.startingLocations,
          productTypes: this.state.productTypes,
          barrelTypes: this.state.barrelTypes,
          initAsyncState: this.initAsyncState.bind(this)
        }}
      >
        {this.props.children}
      </GlobalContext.Provider>
    );
  }
}

const GlobalConsumer = withAuthConsumer(GlobalContext.Consumer);
export default function withGlobalConsumer(Component) {
  return function ComponentWithGlobal(props) {
    return (
      <GlobalConsumer>
        {({
          isLoading,
          startLoading,
          stopLoading,
          holidays,
          locations,
          startingLocations,
          productTypes,
          barrelTypes,
          drivers,
          vehicles,
          initAsyncState,
          fetchLocations,
          fetchDrivers,
          pools,
          groups
        }) => (
          <Component
            {...props}
            isLoading={isLoading}
            startLoading={startLoading}
            stopLoading={stopLoading}
            holidays={holidays}
            locations={locations}
            startingLocations={startingLocations}
            pools={pools}
            groups={groups}
            productTypes={productTypes}
            barrelTypes={barrelTypes}
            drivers={drivers}
            vehicles={vehicles}
            fetchLocations={fetchLocations}
            fetchDrivers={fetchDrivers}
            initAsyncState={initAsyncState}
          />
        )}
      </GlobalConsumer>
    );
  };
}
export { GlobalProvider, GlobalConsumer };
