import React, { Fragment, useEffect, useState } from 'react';
import { withRouter, Link } from 'react-router-dom';
import withI18n from '../../../i18n';
import withGlobalConsumer from '../../../global/GlobalProvider';
import Button from 'reactstrap/es/Button';
import { FilterHorizontal } from './FilterHorizontal';
import Container from 'reactstrap/es/Container';
import { Modal, ModalBody, ModalHeader, NavLink } from 'reactstrap';
import Row from 'reactstrap/es/Row';
import Col from 'reactstrap/es/Col';
import apiClient from '../../../api/ApiClient';
import KotoTable from '../../common/KotoTable';
import { notificationService } from '../../notifications/NotificationService';
import { MdDelete } from 'react-icons/md';
import KotoModal from '../../common/KotoModal';
import KotoPrompt from '../../common/KotoPrompt';
import { FiMap } from 'react-icons/fi';

const LocationGroupDetail = ({ startLoading, stopLoading, translate, ...props }) => {
  const [promptDeleteOpen, setPromptDeleteOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [group, setGroup] = useState({});
  const [selectedLocations, setSelectedLocations] = useState([]);

  const handleSubmit = async () => {
    try {
      const response = await apiClient.post(`/location_groups/${props.match.params.id}/set_locations`, {
        locations: selectedLocations.map((sl) => sl.value)
      });
      notificationService.success('Uspešno posodobljene lokacije skupine!');
      setModalOpen(false);
      await fetchGroup();
    } catch (e) {
      //console.log(e);
      notificationService.error('Nekaj seje zalomilo');
    }
  };
  const fetchGroup = async () => {
    try {
      const response = await apiClient.get(`/location_groups/${props.match.params.id}`);
      setGroup(response.data);
      const locations = response.data.locations.map((location) => {
        return { value: location.id, label: location.short_name };
      });
      setSelectedLocations(locations);
    } catch (e) {
      notificationService.error('Nekaj seje zalomilo');
      //console.log(e);
    }
  };

  async function handleDelete() {
    try {
      await apiClient.delete(`/location_groups/${group.id}`);
      notificationService.success('Skupina uspešno izbrisana!');
      setPromptDeleteOpen(false);
      props.history.push('/groups');
    } catch (e) {
      notificationService.error(e);
    }
  }

  const locationColumns = [
    {
      Header: 'Id',
      accessor: 'id',
      Cell: (row) => (
        <NavLink className="pt-0 pb-0" to={`/locations/${row.original.id}`} tag={Link}>
          {row.original.id}
        </NavLink>
      )
    },
    {
      Header: 'Ime',
      accessor: 'short_name'
    }
  ];

  useEffect(() => {
    fetchGroup();
  }, []);

  return (
    <Fragment>
      <br />
      <Container fluid className={'px-3'}>
        <Row>
          <Col sm={8}>
            <h4>
              <i>Skupina: {group?.name}</i>
            </h4>
          </Col>
          <Col sm={4} className="text-right align-self-center">
            <Button className="mr-2" color={'primary'} onClick={() => setModalOpen(true)}>
              <Row noGutters>
                <FiMap className={'mr-2'} style={{ cursor: 'pointer' }} size="1.5em"></FiMap>
                <div>Dodaj lokacije skupini</div>
              </Row>
            </Button>
            <Button className="mr-2" color={'danger'} onClick={() => setPromptDeleteOpen(true)}>
              <Row noGutters>
                <MdDelete className="text-white mr-1" style={{ cursor: 'pointer' }} size="1.5em">
                  {' '}
                </MdDelete>
                <div>Izbriši</div>
              </Row>
            </Button>
          </Col>
        </Row>
        <br />
        <Modal style={{ maxWidth: '1900px', width: '80%' }} isOpen={modalOpen} toggle={() => setModalOpen(false)}>
          <ModalBody>
            <ModalHeader className="border-0 p-0 mt-3"></ModalHeader>
            <FilterHorizontal selectedLocations={selectedLocations} setSelectedLocations={setSelectedLocations} />
            <Row noGutters className="justify-content-end pt-3">
              <Button color={'primary'} onClick={async (e) => await handleSubmit()}>
                {translate('button_confirm')}
              </Button>

              <Button className={'ml-2'} color={'danger'} onClick={() => setModalOpen(false)}>
                {translate('button_cancel')}
              </Button>
            </Row>
          </ModalBody>
        </Modal>

        {group?.locations && (
          <KotoTable
            data={group.locations}
            defaultPageSize={20}
            minRows={20}
            showPagination={true}
            columns={locationColumns}
          />
        )}
      </Container>
      <KotoModal
        isOpen={promptDeleteOpen}
        toggle={() => setPromptDeleteOpen(!promptDeleteOpen)}
        title={'Izbriši bazen?'}
      >
        <KotoPrompt onConfirm={() => handleDelete()} onCancel={() => setPromptDeleteOpen(!promptDeleteOpen)} />
      </KotoModal>
    </Fragment>
  );
};

export default withRouter(withI18n(withGlobalConsumer(LocationGroupDetail)));
