import * as React from 'react';
import { Component, Fragment } from 'react';
import MapGL, { Popup } from 'react-map-gl';
import Pins from './Pins';
import CityInfo from './CityInfo';

export default class MapBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewport: {
        latitude: 46.23946,
        longitude: 14.995751,
        zoom: 7.3,
        bearing: 0,
        pitch: 0
      },
      popupInfo: null
    };
  }

  _updateViewport = (viewport) => {
    this.setState({ viewport });
  };

  _onClickMarker = (city) => {
    this.setState({ popupInfo: city });
  };

  _renderPopup() {
    const { popupInfo } = this.state;

    return (
      popupInfo && (
        <Popup
          tipSize={5}
          anchor="top"
          longitude={popupInfo.longitude}
          latitude={popupInfo.latitude}
          closeOnClick={false}
          onClose={() => this.setState({ popupInfo: null })}
        >
          <CityInfo info={popupInfo} />
        </Popup>
      )
    );
  }

  render() {
    const { viewport } = this.state;
    //console.log(this.props);
    return (
      <MapGL
        {...viewport}
        width="100%"
        height="80vh"
        mapStyle="mapbox://styles/mapbox/dark-v9"
        onViewportChange={this._updateViewport}
        mapboxApiAccessToken={'pk.eyJ1IjoibmVqY3IiLCJhIjoiY2p2dTJmbTdkMGg2NDQzcGhsNHNia2FvdiJ9.EVm6QKmjdJXC80o4HReGuQ'}
      >
        <Pins data={this.props.pool.locations} onClick={this._onClickMarker} />

        {this._renderPopup()}
      </MapGL>
    );
  }
}
