import React, { useContext } from 'react';
import Select from 'react-select';
import { useSetState } from 'react-use';
import Row from 'reactstrap/es/Row';
import moment from 'moment';
import Col from 'reactstrap/es/Col';
import _ from 'lodash';
import LocationSearch from '../../LocationSearch';
import { GlobalContext } from '../../../global/GlobalProvider';
import KotoDateTime from '../../common/KotoDateTime';
import { AuthContext } from '../../../api/auth/AuthContext';

const isFilterVisible = (viewType, filter) => {
  switch (viewType) {
    case 'year':
      return filter === 'location' || filter === 'product_type';
    case 'month':
      return filter === 'year' || filter === 'location' || filter === 'product_type';
    case 'day':
      return filter !== 'day' && filter !== 'start';
    case 'weekday':
      return filter !== 'day' && filter !== 'start';
    case 'location':
      return filter === 'location' || filter === 'product_type';
    case 'drivers':
      return filter === 'start' || filter === 'end';
    case 'depots':
      return filter === 'start' || filter === 'end' || filter === 'product_type';
    default:
      return true;
  }
};

const StatFilter = ({ viewType, filters, setFilters }) => {
  const currentMonthDates = Array.from(Array(moment().daysInMonth()), (_, x) => x + 1);
  const { authProfile } = useContext(AuthContext);
  function userDropDownFiler(date) {
    if (authProfile?.type === 300 && date) {
      return date?.isAfter(moment().subtract(14, 'days'));
    }
    return true;
  }

  const { productTypes } = useContext(GlobalContext);
  return (
    <Row>
      {isFilterVisible(viewType, 'year') && (
        <Col>
          <h6 className="mt-2 mr-1 d-flex text-nowrap">Leto</h6>

          <Select
            options={[2018, 2019, 2020, 2021, 2022].map((y) => ({ value: y, label: y }))}
            defaultValue={{ label: moment().year(), value: moment().year() }}
            onChange={(e) => {
              const value = e?.value == 'Vsa' ? null : e?.value;
              return setFilters({ year: value });
            }}
          />
        </Col>
      )}
      {isFilterVisible(viewType, 'month') && (
        <Col>
          <h6 className="mt-2 mr-1 d-flex text-nowrap">Mesec</h6>

          <Select
            options={moment.months().map((y, x) => ({
              value: x + 1,
              label: _.capitalize(y)
            }))}
            defaultValue={{ label: _.capitalize(moment().format('MMMM')), value: moment().month() + 1 }}
            onChange={(e) => {
              const value = e?.value == null ? null : e?.value;
              return setFilters({ month: value });
            }}
          />
        </Col>
      )}
      {isFilterVisible(viewType, 'day') && (
        <Col>
          <h6 className="mt-2 mr-1 d-flex text-nowrap">Dan v mesecu</h6>

          <Select
            options={[{ label: 'Vsi', value: null }].concat(currentMonthDates.map((y) => ({ value: y, label: y })))}
            defaultValue={{ label: 'Vsi', value: null }}
            onChange={(e) => {
              const value = e?.value == null ? null : e?.value;
              return setFilters({ day: value });
            }}
          />
        </Col>
      )}
      {isFilterVisible(viewType, 'location') && (
        <Col>
          <h6 className="mt-2 mr-1 d-flex text-nowrap">Lokacije</h6>
          <LocationSearch
            handleSelectChange={(e) => {
              return setFilters({ location: e?.map((v) => v.value) });
            }}
          />
        </Col>
      )}
      {isFilterVisible(viewType, 'product_type') && (
        <Col>
          <h6 className="mt-2 mr-1 d-flex text-nowrap">Produkti</h6>

          <Select
            options={[{ value: '', label: 'Vsi' }].concat(
              productTypes.map((y) => ({
                value: y.id,
                label: y.screen_name
              }))
            )}
            isMulti={true}
            defaultValue={{ value: '', label: 'Vsi' }}
            onChange={(e) => {
              const value = e?.value == null ? null : e?.value;
              return setFilters({ product_type: e?.map((v) => v.value) });
            }}
          />
        </Col>
      )}

      {isFilterVisible(viewType, 'start') && (
        <Row className="w-100 m-0">
          <Col>
            <h6 className="mt-2 mr-1 d-flex text-nowrap">Datum od:</h6>
            <KotoDateTime
              value={filters.start.format('LL')}
              isValidDate={userDropDownFiler}
              onChange={(e) => setFilters({ start: e })}
            />
          </Col>
          <Col>
            <h6 className="mt-2 mr-1 d-flex text-nowrap">Datum od:</h6>
            <KotoDateTime timeFormat={false} value={filters?.end} onChange={(e) => setFilters({ end: e })} />
          </Col>
        </Row>
      )}
    </Row>
  );
};

export const useStatFilter = (viewType) => {
  const { productTypes } = useContext(GlobalContext);
  const { authProfile } = useContext(AuthContext);
  const startDate = authProfile?.type === 300 ? moment().subtract(14, 'days') : moment().startOf('month');

  const [filters, setFilters] = useSetState({
    year: moment().year(),
    month: moment().month() + 1,
    start: moment().subtract(14, 'days'),
    end: moment().endOf('month'),
    day: null,
    location: '',
    product_type: ''
  });
  const [sortBy, setSortBy] = React.useState('');
  const sortOptions = [
    {
      value: '',
      label: ''
    },
    {
      value: 'collected_number',
      label: 'Pobrano (kos) - naraščajoče'
    },
    {
      value: '-collected_number',
      label: 'Pobrano (kos) - padajoče'
    },
    {
      value: 'collected_weight',
      label: 'Pobrano (kg) - naraščajoče'
    },
    {
      value: '-collected_weight',
      label: 'Pobrano (kg) - padajoče'
    },
    {
      value: 'collected_litres',
      label: 'Pobrano (l) - naraščajoče'
    },
    {
      value: '-collected_litres',
      label: 'Pobrano (l) - padajoče'
    },
    {
      value: 'actual_weight ',
      label: 'Stehtana količina (kg)  - naraščajoče'
    },
    {
      value: '-actual_weight ',
      label: 'Stehtana količina (kg)  - padajoče'
    },
    {
      value: 'average_barrel ',
      label: 'Povprečna teža kosa  - naraščajoče'
    },
    {
      value: '-average_barrel ',
      label: 'Povprečna teža kosa  - padajoče'
    }
  ];
  const getSortOptions = (viewType) => {
    if (viewType === 'drivers') {
      return [
        {
          value: '',
          label: ''
        },

        {
          value: 'collected_weight',
          label: 'Pobrano (kg) - naraščajoče'
        },
        {
          value: '-collected_weight',
          label: 'Pobrano (kg) - padajoče'
        },
        {
          value: 'actual_weight',
          label: 'Stehtana količina (kg)  - naraščajoče'
        },
        {
          value: '-actual_weight',
          label: 'Stehtana količina (kg)  - padajoče'
        },
        {
          value: 'collected_barrels',
          label: 'Pobrani kosi embalaže  - naraščajoče'
        },
        {
          value: '-collected_barrels',
          label: 'Pobrani kosi embalaže  - padajoče'
        },

        {
          value: 'total_distance_traveled',
          label: 'Prevoženi razdalja  - naraščajoče'
        },
        {
          value: '-total_distance_traveled',
          label: 'Prevoženi razdalja  - padajoče'
        },

        {
          value: 'time_spent_driving_hours',
          label: 'Ure na terenu - naraščajoče'
        },
        {
          value: '-time_spent_driving_hours',
          label: 'Ure na terenu - padajoče'
        }
      ];
    }

    return sortOptions;
  };

  const formattedFilters = {};
  Object.keys(filters)
    .filter((key) => isFilterVisible(viewType, key))
    .forEach((key) => {
      formattedFilters[key] = filters[key];
    });
  const sortQuery = sortBy && sortBy.length > 0 ? `sortBy=${sortBy}` : '';
  return {
    filters: formattedFilters,
    component: <StatFilter viewType={viewType} filters={filters} setFilters={setFilters} />,
    sortQuery: sortQuery,
    sorter: (
      <select onChange={(e) => setSortBy(e.target.value)}>
        {getSortOptions(viewType).map((so) => (
          <option value={so.value}>{so.label}</option>
        ))}
      </select>
    )
  };
};
