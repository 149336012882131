import React, { Fragment, useContext, useState } from 'react';
import Select from 'react-select';
import { GlobalContext } from '../global/GlobalProvider';
import withI18n from '../i18n';

const LocationSearch = ({
  query,
  fetch,
  handleSelectChange,
  placeholder,
  isMulti = true,
  isClearable = true,
  selectedValue,
  starting = false
}) => {
  const { locations, startingLocations } = useContext(GlobalContext);
  const [locationQuery, setLocationQuery] = useState('');

  function handleInputChange(typedQuery) {
    setLocationQuery('');
  }

  const options = (starting ? startingLocations : locations).filter((location) =>
    location.label.startsWith(locationQuery)
  );

  return (
    <Fragment>
      <Select
        className="koto-zIndex-888 w-100"
        placeholder={placeholder ? placeholder : 'Izberi lokacijo'}
        isMulti={isMulti}
        isClearable={isClearable}
        value={selectedValue}
        options={options}
        onInputChange={(e) => handleInputChange(e)}
        onChange={handleSelectChange}
      />
    </Fragment>
  );
};

export default withI18n(LocationSearch);
