import React, { Component, useContext } from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import FormGroup from 'reactstrap/es/FormGroup';
import { Col, Label, Row } from 'reactstrap';
import withI18n from '../../../i18n';
import { notificationService } from '../../notifications/NotificationService';
import { FormEffect } from '../../common/FormEffect';
import { FormCheckbox, FormInput, FormSelect } from '../../common/FormInput';
import apiClient from '../../../api/ApiClient';
import withGlobalConsumer from '../../../global/GlobalProvider';
import FormConfirmCancel from '../../common/FormConfirmCancel';
import { handleDefaultError } from '../../../utils/response-handler';
import withAuthConsumer from '../../../api/auth/AuthContext';
import { getCurrentProvider, getProviderSelectValues } from '../../navbar/providers';
import { GlobalContext } from '../../../global/GlobalProvider';

const locationSchema = (translate) => {
  const toShort = translate('value_to_short');
  const toLong = translate('value_to_long');
  const notBlank = translate('value_not_empty');
  const exactly = translate('value_must_be_exactly_length');

  const regexMatcher = /^[a-zA-Z0-9šđčćžŠĐČĆŽ@/\- ]*$/;
  const regexMatcherWithCommaAndDot = /^[a-zA-Z0-9šđčćžŠĐČĆŽ,.@/\- ]*$/;
  const errorMessage = `${translate('value_alphanumeric')} ${translate('and_signs').toLowerCase()}: -/`;
  return Yup.object().shape({
    client: Yup.string()
      .min(1, toShort + ' 1')
      .max(50, toLong + ' 50')
      .required(notBlank),
    short_name: Yup.string()
      .min(1, toShort + ' 1')
      .max(100, toLong + ' 100')
      .matches(regexMatcherWithCommaAndDot, errorMessage)
      .required(notBlank),
    address_description: Yup.string().matches(regexMatcherWithCommaAndDot, errorMessage),
    largo_location_code: Yup.string()
      .min(1, toShort + ' 1')
      .max(20, toLong + ' 20')
      .matches(regexMatcher, errorMessage)
      .required(notBlank),
    street_name: Yup.string()
      .min(1, toShort + ' 1')
      .max(50, toLong + ' 50')
      .matches(regexMatcherWithCommaAndDot, errorMessage)
      .required(notBlank),
    street_number: Yup.string()
      .min(1, toShort + ' 1')
      .max(10, toLong + ' 50')
      .matches(regexMatcher, errorMessage)
      .required(notBlank),
    post_code: Yup.string()
      .min(3, exactly + ' 4')
      .max(5, exactly + ' 4')
      .required(notBlank),
    city: Yup.string()
      .min(2, toShort + ' 1')
      .max(50, toLong + ' 50')
      .matches(regexMatcher, errorMessage)
      .required(notBlank),
    minimal_time_window_in_minutes: Yup.number()
      .min(10, toShort + ' 10')
      .max(10000, toLong + ' 10000')
      .required(notBlank),

    country: Yup.string()
      .min(2, toShort + ' 1')
      .max(50, toLong + ' 50')
      .required(notBlank)
  });
};

class UpdateLocation extends Component {
  state = {
    selectedOption: { value: '', label: '' },
    query: '',
    selectLoading: false,
    clients: null,
    pools: null
  };

  async componentDidMount() {
    if (this.props.client && this.props.client.id) {
      await this.setState({
        selectedOption: { value: this.props.client.id, label: this.props.client.short_name }
      });
    }
    await this.fetchData();
  }

  async fetchData() {
    try {
      await this.setState({ selectLoading: true });
      const response = await apiClient.get(`/client?starts_with=${this.state.query}`);
      const clients = response.data.results.map((data) => ({ value: data.id, label: data.short_name }));
      await this.setState({ clients });
      await this.setState({ selectLoading: false });
      const poolsResponse = await apiClient.get('/pools');
      const pools = poolsResponse.data.map((data) => ({ value: data.id, label: data.name }));
      await this.setState({ pools });
    } catch (e) {
      await handleDefaultError(e, this.props);
    }
  }

  handleInputChange(query) {
    if (!query && !this.state.query) {
      return;
    }
    // eslint-disable-next-line eqeqeq
    if (query != this.state.query) {
      this.setState(
        {
          query
        },
        this.fetchData
      );
    }
  }

  handleSelectChange = async (selectedOption, formik) => {
    await formik.setFieldValue('client', selectedOption.value, true);
    await this.setState({ selectedOption });
  };

  handleProviderChange = async (selectedOption, formik) => {
    await formik.setFieldValue('provider', selectedOption.value, true);
    await this.setState({ selectedOption });
  };

  render() {
    let location = this.props?.location;
    let provider = 1;
    if (location) {
      if (location?.provider?.id) {
        provider = location.provider.id;
      } else {
        provider = getCurrentProvider(1);
      }
    } else {
      provider = getCurrentProvider(1);
    }

    //console.log(this.props.pools);

    return (
      <div>
        <h1>{this.props.title}</h1>

        <Formik
          initialValues={{
            client: this.props.location ? this.props.location.client_id : this.state.selectedOption.value,
            short_name: this.props.location ? this.props.location.short_name : '',
            pool: undefined, // this.props.location ? this.props.pools.filter(obj => (obj.value === this.props.location.pool)) : "",
            address_description: this.props.location ? this.props.location.address_description : '',
            street_name: this.props.location ? this.props.location.street_name : '',
            street_number: this.props.location ? this.props.location.street_number : '',
            can_have_orders_on_weekends: this.props.location ? this.props.location.can_have_orders_on_weekends : false,
            can_have_orders_on_holiday: this.props.location ? this.props.location.can_have_orders_on_holiday : true,
            post_code: this.props.location ? this.props.location.post_code : '',
            city: this.props.location ? this.props.location.city : '',
            largo_location_code: this.props.location ? this.props.location.largo_location_code : '',
            minimal_time_window_in_minutes: this.props.location
              ? this.props.location.minimal_time_window_in_minutes
              : 120,
            can_optimizer_widen_time_window: this.props.location
              ? this.props.location.can_optimizer_widen_time_window === 1
                ? true
                : false
              : true,
            country: this.props.location ? this.props.location.country : '',
            provider: provider
          }}
          isValid={this.props.locationId}
          validationSchema={locationSchema(this.props.translate)}
          onSubmit={async (values) => {
            let { can_optimizer_widen_time_window, ...rest } = values;
            can_optimizer_widen_time_window = can_optimizer_widen_time_window ? 1 : 0;
            const formattedValues = { can_optimizer_widen_time_window, ...rest };

            try {
              await this.props.startLoading();
              let response;
              let successMessage;
              if (this.props.locationId) {
                response = await apiClient.put(`/location/${this.props.locationId}`, formattedValues);
                successMessage = `${this.props.translate('locations_success_update_message')} ${response.data.id}`;
              } else {
                response = await apiClient.post(`/location`, formattedValues);
                successMessage = `${this.props.translate('locations_success_add_message')} ${response.data.id}`;
              }

              await this.props.fetchLocations();
              notificationService.success(successMessage);
            } catch (e) {
              await handleDefaultError(e, this.props);
            }

            await this.props.refresh();
            await this.props.toggle();
            await this.props.stopLoading();
          }}
        >
          {(formik) => {
            const defaultProvider = getProviderSelectValues().find((gps) => gps.value == formik?.values?.provider);
            let defaultPool;
            try {
              defaultPool = this.props.pools.find((pool) => pool.value == this.props.location.pool.id);
            } catch (e) {
              defaultPool = undefined;
            }
            return (
              <Form>
                <FormEffect
                  formik={formik}
                  onSubmissionError={(e) => {
                    return notificationService.error(this.props.translate('error_form'));
                  }}
                />

                <Row form>
                  <Col md={5}>
                    <FormGroup>
                      <Label for="client">{this.props.translate('client_single')}</Label>
                      {this.state.clients && (
                        <FormSelect
                          formik={formik}
                          name={'client'}
                          isDisabled={this.props.client}
                          isClearable={false}
                          isLoading={this.state.selectLoading}
                          defaultValue={this.state.selectedOption}
                          options={this.state.clients}
                          onInputChange={(e) => this.handleInputChange(e)}
                          onChange={(e) => this.handleSelectChange(e, formik)}
                        />
                      )}
                    </FormGroup>
                  </Col>
                  <Col md={7}>
                    <FormGroup>
                      <Label for="short_name">{this.props.translate('short_name')}</Label>
                      <Field name="short_name" type={'text'} component={FormInput} />
                    </FormGroup>
                  </Col>
                </Row>
                <Row noGutters>
                  <Col md={6} className="mt-2 text-center">
                    <Row noGutters>
                      <Field
                        name="can_have_orders_on_weekends"
                        type="checkbox"
                        className="ml-2"
                        checked={formik.values.can_have_orders_on_weekends}
                        size="2"
                        component={FormCheckbox}
                      />
                      <Label className="ml-3" style={{ fontSize: '90%' }} for="can_have_orders_on_weekends">
                        {this.props.translate('locations_can_have_orders_on_weekends')}
                      </Label>
                    </Row>
                  </Col>
                  <Col md={6} className="mt-2 text-center">
                    <Row noGutters>
                      <Field
                        name="can_have_orders_on_holiday"
                        type="checkbox"
                        className="ml-2"
                        checked={formik.values.can_have_orders_on_holiday}
                        size="2"
                        component={FormCheckbox}
                      />
                      <Label className="ml-3" style={{ fontSize: '90%' }} for="can_have_orders_on_holiday">
                        {this.props.translate('locations_can_have_orders_on_holiday')}
                      </Label>
                    </Row>
                  </Col>
                </Row>
                <br />
                <Row noGutters>
                  <Col md={6} className="mt-2 text-left">
                    <Row noGutters>
                      <Field
                        name="can_optimizer_widen_time_window"
                        type="checkbox"
                        className="ml-2"
                        checked={formik.values.can_optimizer_widen_time_window}
                        size="2"
                        component={FormCheckbox}
                      />
                      <Label className="ml-3" style={{ fontSize: '90%' }} for="can_optimizer_widen_time_window">
                        {this.props.translate('can_optimizer_widen_time_window')}
                      </Label>
                    </Row>
                  </Col>
                </Row>
                <Row noGutters>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="provider">Ponudnik</Label>
                      <FormSelect
                        formik={formik}
                        name={'provider'}
                        defaultValue={defaultProvider}
                        isClearable={false}
                        options={getProviderSelectValues()}
                        onChange={(e) => this.handleProviderChange(e, formik)}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="pool">Bazen</Label>
                      <FormSelect
                        formik={formik}
                        name={'pool'}
                        defaultValue={defaultPool}
                        isClearable={false}
                        options={this.state.pools}
                        onChange={(e) => formik.setFieldValue('pool', e.value, true)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <br />
                <Row form>
                  <Col md={7}>
                    <FormGroup>
                      <Label for="largo_location_code">{this.props.translate('largo_location_code')}</Label>
                      <Field name="largo_location_code" type={'text'} component={FormInput} />
                    </FormGroup>
                  </Col>
                  <Col md={5} className="text-left">
                    <FormGroup>
                      <Label for="minimal_time_window_in_minutes">
                        {this.props.translate('minimal_time_window_in_minutes')}
                      </Label>
                      <Field name="minimal_time_window_in_minutes" type={'text'} component={FormInput} />
                    </FormGroup>
                  </Col>
                </Row>
                <Row form>
                  <Col md={12}>
                    <FormGroup>
                      <Label for="address_description">{this.props.translate('address_description')}</Label>
                      <Field name="address_description" type={'text'} component={FormInput} />
                    </FormGroup>
                  </Col>
                </Row>
                <FormGroup>
                  <Row form>
                    <Col md={8}>
                      <Label for="street_name">{this.props.translate('locations_street_name')}</Label>
                      <Field name="street_name" type={'text'} component={FormInput} />
                    </Col>
                    <Col md={4}>
                      <FormGroup>
                        <Label for="street_number">{this.props.translate('locations_street_number')}</Label>
                        <Field name="street_number" type={'text'} component={FormInput} />
                      </FormGroup>
                    </Col>
                  </Row>
                </FormGroup>
                <Row form>
                  <Col md={3}>
                    <FormGroup>
                      <Label for="post_code">{this.props.translate('locations_post_number')}</Label>
                      <Field name="post_code" type={'text'} component={FormInput} />
                    </FormGroup>
                  </Col>
                  <Col md={5}>
                    <FormGroup>
                      <Label for="city">{this.props.translate('locations_city')}</Label>
                      <Field name="city" type={'text'} component={FormInput} />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup>
                      <Label for="country">{this.props.translate('locations_country')}</Label>
                      <Field name="country" type={'text'} component={FormInput} />
                    </FormGroup>
                  </Col>
                </Row>
                <FormConfirmCancel onCancel={this.props.toggle} />
              </Form>
            );
          }}
        </Formik>
      </div>
    );
  }
}

export default withI18n(withGlobalConsumer(withAuthConsumer(UpdateLocation)));
