import React, { Fragment } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import withI18n from '../../i18n';
import _ from 'lodash';

const KotoModal = ({ title, isOpen, toggle, size = 'lg', ...props }) => {
  return (
    <Fragment>
      <Modal size={size} isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>{_.isFunction(title) ? title() : title}</ModalHeader>
        <ModalBody>{props.children}</ModalBody>
      </Modal>
    </Fragment>
  );
};

export default withI18n(KotoModal);
