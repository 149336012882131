import React from 'react';
import { Badge } from 'reactstrap';

export const KotoBadge = ({ status, text, color, large = false }) => {
  let resolvedColor = 'danger';
  if (status) {
    resolvedColor = 'success';
  }
  if (color) {
    resolvedColor = color;
  }
  if (large) {
    return (
      <h5 key={Math.random().toString()} className="m-0">
        <Badge pill color={resolvedColor}>
          {text}
        </Badge>
      </h5>
    );
  }
  return (
    <h6 key={Math.random().toString()} className="m-0">
      <Badge pill color={resolvedColor}>
        {text}
      </Badge>
    </h6>
  );
};
