import React, { Fragment, useContext } from 'react';

import 'react-toastify/dist/ReactToastify.css';

import { HashRouter, Route, Switch } from 'react-router-dom';
import withI18n from '../i18n';
import { routes } from '../routes/routes';
import Login from './pages/Login';
import { ToastContainer } from 'react-toastify';
import { NotFound } from '../routes/404';
import { PrivateRoute } from '../routes/PrivateRoute';
import { PublicRoute } from '../routes/PublicRoute';
import { AuthContext } from '../api/auth/AuthContext';

function AppContainer() {
  const { isAuth, authProfile } = useContext(AuthContext);

  return (
    <Fragment>
      <HashRouter>
        <div style={{ minHeight: '100%' }}>
          <Switch>
            {routes.map((route, i) => {
              return <PrivateRoute key={i} path={route.path} exact={route.exact} component={route.component} />;
            })}
            <PublicRoute exact path={'/login'} component={Login} />
            <Route render={() => <NotFound />} />
          </Switch>
        </div>

        <ToastContainer />
        <Fragment>
          <footer className="card-footer text-center">©koto.si</footer>
        </Fragment>
      </HashRouter>
    </Fragment>
  );
}

export default withI18n(AppContainer);
