import React, { Component, useEffect } from 'react';

import withI18n from '../../../i18n';
import withAuthConsumer from '../../../api/auth/AuthContext';
import withGlobalConsumer from '../../../global/GlobalProvider';
import KotoContainer from '../KotoContainer';
import Button from 'reactstrap/es/Button';
import KotoTable from '../../common/KotoTable';
import { Col, Input, NavLink } from 'reactstrap';
import KotoPagination from '../../common/KotoPagination';
import apiClient from '../../../api/ApiClient';
import { Link, withRouter } from 'react-router-dom';
import { KotoBadge } from '../../common/KotoBadge';
import KotoModal from '../../common/KotoModal';
import CreateUser from './CreateUser';
import { handleDefaultError } from '../../../utils/response-handler';

import * as queryString from 'query-string';
import _ from 'lodash';
import KotoToggle from '../../common/KotoToggle';
import { SearchBar } from '../../SearchBar';
import KotoDate from '../../common/KotoDate';
import { orderStatuses, userStatuses } from '../../common/statuses';
import Row from 'reactstrap/es/Row';
import { getFromStorage } from '../../../utils/utils';
import { MdDelete } from 'react-icons/all';
import KotoPrompt from '../../common/KotoPrompt';
import { notificationService } from '../../notifications/NotificationService'; // for ES6 modules
import { BsInfoCircle } from 'react-icons/bs';
import ReactTooltip from 'react-tooltip';

const toolTip = `
Voznikom in prevoznikom lahko nastavimo prevoznika (trenutno KOTO d.o.o. ali AMG Tina Trček s.p.). <br />
Za voznike bo to pomenilo, da bo na njihovih poročilih pod prevoznika napisan nastavljen prevoznik. <br /> 
Za prevoznika pa bo to pomenilo, da bo v svojem pregledu lahko imel pregled le nad vozniki, ki jih ima on pod kontrolo. <br />
Torej, če je nek uporabnik tipa prevoznik in ima za prevoznika nastavljenega "AMG Tina Trček s.p.", <br /> 
bo lahko v svojem pregledu videl le voznike, ki vozijo za  "AMG Tina Trček s.p." (Voznikove poti in statistika). <br />
Za admin uporabnike ni nobenih sprememb, oni še zmeraj lahko vidijo vse. Ta sprememba omogoča, da se omeji pregled nad vozniki in voznikovimi poti za uporabnike tipa prevoznik.
`;

const userColumns = (translate, type, handleBan, setDeleteId) => {
  return [
    {
      Header: 'Id',
      id: 'uid',
      width: 50,
      Cell: (row) => (
        <NavLink className="pt-0 pb-0" to={`/users/${row.original.uid}`} tag={Link}>
          {row.original.uid}
        </NavLink>
      )
    },

    {
      Header: translate('user_first_name'),
      id: 'first_name',
      accessor: 'first_name',
      width: 200
    },

    {
      Header: translate('user_last_name'),
      id: 'last_name',
      accessor: 'last_name',
      width: 200
    },

    {
      Header: translate('user_email'),
      id: 'email',
      accessor: 'email',
      width: 300
    },
    {
      Header: 'Domača lokacija',
      Cell: (row) => {
        if (row.original?.home_location) {
          return (
            <NavLink className="pt-0 pb-0" to={`/locations/${row.original.home_location.id}`} tag={Link}>
              {row.original.home_location.short_name}
            </NavLink>
          );
        } else {
          return '';
        }
      }
    },
    {
      Header: 'Tip uporabnika',
      id: 'user_type',
      Cell: (row) => <div>{translate(userStatuses[row.original.user_type]?.name)}</div>
    },
    {
      Header: 'Prevoznik',
      id: 'carrier',
      Cell: (row) => (row.original?.carrier ? row.original?.carrier.name : '')
    },
    {
      Header: translate('user_is_password_changed'),
      id: 'is_new',
      Cell: (row) => (
        <KotoBadge
          status={!row.original.is_new}
          text={!row.original.is_new ? translate('boolean_true') : translate('boolean_false')}
        />
      )
    },
    {
      Header: translate('user_is_active'),
      id: 'is_active',
      show: type === 0,
      Cell: (row) => (
        <KotoToggle
          id={row.original.uid.toString()}
          defaultChecked={row.original.is_active}
          onChange={handleBan(row.original.uid)}
        />
      )
    },
    {
      Header: 'Zadnja prijava',
      id: 'last_login',
      Cell: (row) => row.original?.last_login && <KotoDate format={'LLLL'} date={row.original.last_login} />
    },
    {
      Header: '',
      id: 'last_login',
      width: 60,
      Cell: (row) => (
        <MdDelete
          className="text-danger "
          onClick={() => setDeleteId(row.original.uid)}
          style={{ cursor: 'pointer' }}
          size="1.5em"
        />
      )
    }
  ];
};

class Users extends Component {
  state = {
    createOpen: false,
    users: [],
    count: 1,
    pageSize: 20,
    userFilter: null,
    searchValue: getFromStorage('users.search', ''),
    searchKey: 'users.search',
    carrier: null,
    url: '/user',
    activePage: 0
  };

  async componentWillMount() {
    let params = queryString.parse(this.props.location.search);
    let activePage = parseInt(params.activePage);

    if (params.activePage && _.isInteger(activePage)) {
      await this.setState({ activePage: activePage });
    }
    await this.fetchUsers(this.state.activePage);
  }

  async fetchUsers(page) {
    await this.props.startLoading();
    const searchParam =
      this.state.searchValue && this.state.searchValue.length > 0 ? `&search=${this.state.searchValue}` : '';
    const type =
      this.state.userFilter === null || this.state.userFilter === '' ? '' : `&user_type=${this.state.userFilter}`;
    const carrier = this.state.carrier !== null ? `&carrier=${this.state.carrier}` : '';
    page = page - 1;

    try {
      const response = await apiClient.get(
        this.state.url +
          `?limit=${this.state.pageSize}&offset=${this.state.pageSize * page}&sortBy=uid${searchParam}${type}${carrier}`
      );
      await this.setState({
        users: response.data.results,
        count: response.data.count
      });
    } catch (e) {
      await handleDefaultError(e, this.props);
    }

    await this.props.stopLoading();
  }

  handleSelected = async (page) => {
    await this.fetchUsers(page);
  };

  async handleBan(event) {
    const id = event.target.id;
    const allow = event.target.checked;

    await this.props.startLoading();
    try {
      const response = await apiClient.post(this.state.url + `/${id}/ban`, { is_active: allow });
      await this.fetchUsers(0);
    } catch (e) {
      await handleDefaultError(e, this.props);
    }
    await this.props.stopLoading();
  }

  async toggleCreateOpen() {
    await this.setState({ createOpen: !this.state.createOpen });
  }

  async handleFilterChange(e) {
    const value = e.target.value;
    await this.setState({ userFilter: value });
    await this.fetchUsers(this.state.activePage);
  }

  async handleCarrierChange(e) {
    await this.setState({ carrier: e.target.value });
    await this.fetchUsers(this.state.activePage);
  }

  async execSearch(searchValue) {
    await this.setState({ searchValue: searchValue });
    await this.fetchUsers(this.state.activePage);
  }

  async handleUserDelete() {
    try {
      const res = await apiClient.delete(`user/${this.state.deleteId}`);
      notificationService.success('Uspešno izbrisan uporabnik z id-jem:' + this.state.deleteId);
      await this.setState({
        deleteId: null
      });
      await this.fetchUsers(this.state.activePage);
    } catch (e) {
      await handleDefaultError(e, this.props);
    }
  }

  async execClear() {
    await this.setState({ searchValue: '' });
    await this.fetchUsers(this.state.activePage);
  }

  render() {
    return (
      <KotoContainer name={this.props.translate('page_users_title')}>
        <Button color="primary" className="mb-2" onClick={this.toggleCreateOpen.bind(this)}>
          {this.props.translate('button_add_new')}
        </Button>
        <BsInfoCircle className="ml-3 pb-1" data-tip={toolTip} size="30" /> <ReactTooltip html={true} />
        <Row>
          <Col>
            <h6 className=" mr-1 d-flex text-nowrap invisible">{this.props.translate('order_status')}:</h6>
            <SearchBar
              placeHolder={'Išči uporabnike'}
              onSearch={this.execSearch.bind(this)}
              saveKey={this.state.searchKey}
              onClear={this.execClear.bind(this)}
              className={'mb-2'}
            />
          </Col>

          <Col>
            <h6 className=" mr-1 d-flex text-nowrap">Tip uporabnika</h6>

            <Input
              type="select"
              name="select"
              className="small w-100"
              id="exampleSelect"
              onChange={(e) => this.handleFilterChange(e)}
            >
              <option key="" value="">
                {this.props.translate('all')}
              </option>
              {Object.keys(userStatuses)
                .filter((key) => key != 0)
                .map((userKey) => (
                  <option key={userKey} value={userKey}>
                    {this.props.translate(userStatuses[userKey].name)}
                  </option>
                ))}
            </Input>
          </Col>
          <Col>
            <h6 className=" mr-1 d-flex text-nowrap">Prevoznik</h6>
            <Input
              type="select"
              name="select"
              className="small w-100"
              id="selectCarrier"
              onChange={(e) => this.handleCarrierChange(e)}
            >
              <option key={null} value="">
                Brez filtra
              </option>
              <option value={1}>AMG1, Tina Trček s.p</option>
              <option value={2}>KOTO d.o.o</option>
            </Input>
          </Col>
        </Row>
        {this.props.authProfile && (
          <KotoTable
            columns={userColumns(
              this.props.translate,
              this.props.authProfile.type,
              () => this.handleBan.bind(this),
              (deleteId) => this.setState({ deleteId: deleteId })
            )}
            data={this.state.users}
            defaultPageSize={this.state.pageSize}
            minRows={this.state.pageSize}
          />
        )}
        <KotoModal
          title={this.props.translate('user_create_new')}
          isOpen={this.state.createOpen}
          toggle={this.toggleCreateOpen.bind(this)}
        >
          <CreateUser toggle={this.toggleCreateOpen.bind(this)} refresh={this.fetchUsers.bind(this)} />
        </KotoModal>
        <KotoModal
          title={'Ste prepričani da želite izbrisati uporabnika ' + this.state.deleteId}
          isOpen={this.state.deleteId}
          toggle={() => this.setState({ deleteId: null })}
        >
          <KotoPrompt onConfirm={this.handleUserDelete.bind(this)} onCancel={() => this.setState({ deleteId: null })} />
        </KotoModal>
        <Col sm="12" md={{ size: 6, offset: 3 }}>
          <KotoPagination
            totalItems={this.state.count}
            pageSize={this.state.pageSize}
            activePage={this.state.activePage}
            onSelect={this.handleSelected.bind(this)}
          />
        </Col>
      </KotoContainer>
    );
  }
}

export default withI18n(withAuthConsumer(withGlobalConsumer(withRouter(Users))));
