import React from 'react';
import withI18n from '../../../i18n';
import withAuthConsumer from '../../../api/auth/AuthContext';
import withGlobalConsumer from '../../../global/GlobalProvider';
import { Form, Formik } from 'formik';
import { notificationService } from '../../notifications/NotificationService';
import { FormEffect } from '../../common/FormEffect';
import { Col, Label, Row } from 'reactstrap';
import FormGroup from 'reactstrap/es/FormGroup';
import FormConfirmCancel from '../../common/FormConfirmCancel';
import moment from 'moment';
import apiClient from '../../../api/ApiClient';
import { handleDefaultError } from '../../../utils/response-handler';
import KotoDateTime from '../../common/KotoDateTime';
import Card from 'reactstrap/es/Card';

class UpdateOrderTime extends React.Component {
  handleDateChange = async (date, label, formik) => {
    await formik.setFieldValue(label, date, true);
  };

  async handleSubmit(values) {
    let { order_completed_time, ...rest } = values;

    function alz(str, targetLength) {
      str = str.toString()
      return str.padStart(targetLength, '0');
    }

    const year = order_completed_time.year()
    const month = alz(order_completed_time.month()+1,2)
    const date = alz(order_completed_time.date(),2)

    // TODO this is a dirty trick to adjust for backend timezone issue (resolve branch timezone_fix_test to simply just pass hour)
    // TODO this only works in summer time CEST, use in winter : const hour =alz(order_completed_time.hour()+1,2)
    const hour = Math.min(alz(order_completed_time.hour()+1,23),2)
    const minutes = alz(order_completed_time.minutes(),2)
    const seconds = alz(order_completed_time.seconds(),2)

    const dateFormatted = `${year}-${month}-${date}T${hour}:${minutes}:${seconds}.000Z`

    // order_completed_time = order_completed_time.toISOString();
    // The iso strign one is off by one hour
    order_completed_time = dateFormatted

    let req = { order_completed_time };
    try {
      await this.setState({ selectLoading: true });
      const response = await apiClient.post(`/order/${this.props.order.id}/update_completed_time`, req);
      notificationService.success('Succesfully updated order with id:' + response.data.id);
    } catch (e) {
      if (e.response && e.response.data && e.response.data.Message) {
        return notificationService.error(e.response.data.Message[0]);
      }
      await handleDefaultError(e, this.props);
    }
    await this.props.stopLoading();
    await this.props.refresh();
    await this.props.toggle();
    await this.props.stopLoading();
  }

  render() {
    let adminUser = false;
    if (this.props.authProfile) {
      const userType = this.props.authProfile.type;
      adminUser = userType === 100 || userType === 0;
    }

    const now = moment()
    const startDate = now.startOf('day');
    const midDay = startDate.add(moment.duration(12, 'hours'));

    return (
      <div>
        <h1>{this.props.title}</h1>

        <Formik
          initialValues={{
            order_completed_time: this.props.order.order_completed_time
              ? moment(this.props.order.order_completed_time)
              : midDay
          }}
          onSubmit={async (values) => this.handleSubmit(values)}
        >
          {(formik) => (
            <Form>
              <FormEffect
                formik={formik}
                onSubmissionError={(e) => {
                  return notificationService.error(this.props.translate('error_form'));
                }}
              />
              <Row form>
                <Col md={12}>
                  <FormGroup>
                    <Label for="order_completed_time">Naročilo zaključeno</Label>
                    <KotoDateTime
                      value={formik.values['order_completed_time']}
                      formik={formik}
                      name={'order_completed_time'}
                      isValidDate={this.isStartDateValid}
                      onChange={(e) => this.handleDateChange(e, 'order_completed_time', formik)}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row className="m-1">
                <div>
                  Datum zaključka naročila bo nastavljen na izbran datum. Pri tem se <strong>ne</strong> bo zgodil vnos
                  v ERP sistem.
                </div>
              </Row>
              <hr />
              <FormConfirmCancel onCancel={this.props.toggle} />
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

export default withI18n(withAuthConsumer(withGlobalConsumer(UpdateOrderTime)));
