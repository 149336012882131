import React, { Fragment, useContext, useState } from 'react';

import { I18nContext } from '../../../i18n';
import { Field, Form, Formik } from 'formik';
import apiClient from '../../../api/ApiClient';
import { notificationService } from '../../notifications/NotificationService';
import { handleDefaultError } from '../../../utils/response-handler';
import { FormEffect } from '../../common/FormEffect';
import { Button, Col, Label, Row, Table } from 'reactstrap';
import FormGroup from 'reactstrap/es/FormGroup';
import { FormInput } from '../../common/FormInput';
import FormConfirmCancel from '../../common/FormConfirmCancel';
import withGlobalConsumer from '../../../global/GlobalProvider';
import * as Yup from 'yup';
import withAuthConsumer from '../../../api/auth/AuthContext';
import Select from 'react-select';
import moment from 'moment';
import KotoDateTime from '../../common/KotoDateTime';

const optimizerSchema = (translate) => {
  const toShort = translate('value_to_short');
  const toLong = translate('value_to_long');
  const notBlank = translate('value_not_empty');

  return Yup.object().shape({
    short_name: Yup.string()
      .min(1, toShort + ' 1')
      .max(50, toLong + ' 50')
      .matches(/^[a-zA-Z0-9šđčćžŠĐČĆŽ@ ]*$/, translate('value_alphanumeric'))
      .required(notBlank)
  });
};

const UpdateOptimizerSetting = ({ optimizerSetting, ...props }) => {
  const { translate } = useContext(I18nContext);

  return (
    <Fragment>
      <div>
        <div>
          <h1>{props.title}</h1>

          <Formik
            initialValues={{
              short_name: optimizerSetting?.short_name
            }}
            validationSchema={optimizerSchema(translate)}
            onSubmit={async (values) => {
              try {
                await props.startLoading();

                let response;
                let successMessage;
                response = await apiClient.put(`/optimizer_settings/${optimizerSetting.id}`, values);
                successMessage = `${translate('optimizer_success_update_message')} ${response.data.id}`;

                notificationService.success(successMessage);
              } catch (e) {
                props = { translate, ...props };
                if (
                  e.response &&
                  e.response.data &&
                  e.response.data.short_name &&
                  e.response.data.short_name.length !== 0
                ) {
                  await props.stopLoading();
                  notificationService.error(translate('optimizer_setting_short_name_exits'));
                  return;
                } else {
                  await handleDefaultError(e, props);
                }
                await handleDefaultError(e, props);
              }
              await props.stopLoading();
              await props.refresh();
              await props.toggle();
            }}
          >
            {(formik) => {
              return (
                <Form>
                  <FormEffect
                    formik={formik}
                    onSubmissionError={() => notificationService.error('Please correct red colored fields')}
                  />
                  <Row form>
                    <Col md={12}>
                      <FormGroup>
                        <Label for="short_name">{translate('short_name')}</Label>
                        <Field name="short_name" type={'text'} component={FormInput} />
                      </FormGroup>
                    </Col>
                  </Row>

                  <br />
                  <br />

                  <FormConfirmCancel onCancel={props.toggle} />
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </Fragment>
  );
};

export default withGlobalConsumer(withAuthConsumer(UpdateOptimizerSetting));
