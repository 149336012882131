import React from 'react';
import _ from 'lodash';

export function FormEffect(props) {
  const effect = () => {
    if (props.formik.submitCount > 0 && !props.formik.isValid) {
      if (!_.isEmpty(props.formik.errors)) {
        props.onSubmissionError(props.formik.errors);
      }
    }
  };
  React.useEffect(effect, [props.formik.submitCount]);
  return null;
}
