import React from 'react';
import { DropdownItem } from 'reactstrap';
import { getProviderSelectValues, providers, useProvider } from './providers';
import Select from 'react-select';
import Col from 'reactstrap/es/Col';

export const ProviderSelect = (props) => {
  const [provider, setProvider] = useProvider();
  const onLanguageSelect = function (e) {
    setProvider(e);
    window.location.reload();
  };

  const renderOption = (code) => (
    <DropdownItem key={code} onClick={() => onLanguageSelect(code)}>
      {providers[code]}
    </DropdownItem>
  );

  const sortedElements = Object.keys(providers).sort((a, b) => {
    return b - a;
  });
  return (
    <Col className={'w-100'}>
      <Select
        options={getProviderSelectValues(true)}
        value={getProviderSelectValues(true).find((sv) => sv.value == provider)}
        onChange={(e) => onLanguageSelect(e.value)}
      />
    </Col>
  );
};
