import React from 'react';
import withI18n from '../../../i18n';
import withAuthConsumer from '../../../api/auth/AuthContext';
import withGlobalConsumer from '../../../global/GlobalProvider';
import {Field, FieldArray, Form, Formik} from 'formik';
import { notificationService } from '../../notifications/NotificationService';
import { FormEffect } from '../../common/FormEffect';
import {Col, Input, Label, Row} from 'reactstrap';
import FormGroup from 'reactstrap/es/FormGroup';
import { FormCheckbox, FormInput, FormSelect } from '../../common/FormInput';
import FormConfirmCancel from '../../common/FormConfirmCancel';
import moment from 'moment';
import apiClient from '../../../api/ApiClient';
import { handleDefaultError } from '../../../utils/response-handler';
import KotoDateTime from '../../common/KotoDateTime';
import * as Yup from 'yup';
import { weekDays } from '../../common/statuses';
import Card from 'reactstrap/es/Card';
import InputGroup from "reactstrap/es/InputGroup";
import InputGroupAddon from "reactstrap/es/InputGroupAddon";
import InputGroupText from "reactstrap/es/InputGroupText";

let Datetime = require('react-datetime');

const orderSchema = (translate) => {
  const notBlank = translate('value_not_empty');
  const toShort = translate('value_to_short');
  const toLong = translate('value_to_long');
  const atLeastOneProductChosen = translate('order_at_least_one_product_chosen');
  const orderAfter6 = translate('order_repeating_start_time_after_6');
  const orderBefore18 = translate('order_repeating_end_time_before_18');

  return Yup.object().shape({
    weekday: Yup.string().required(notBlank),
    location_id: Yup.string().required(notBlank),
    // ordered_number_oil: Yup.number().test("error", atLeastOneProductChosen, function (value) {
    //     const {ordered_number_slops} = this.parent;
    //     if (ordered_number_slops === 0) return value !== 0;
    //     return true
    // }),
    // ordered_number_slops: Yup.number().test("error", atLeastOneProductChosen, function (value) {
    //     const {ordered_number_oil} = this.parent;
    //     if (ordered_number_oil === 0) return value !== 0;
    //     return true
    // }),
    preferred_start_time: Yup.date().test('error,', 'Končni čas mora biti večji od začetnega', function (value) {
      // const start = moment(value)
      // const earliestTime = moment('05:59 am', "HH:mm a");
      // return start.isAfter(earliestTime)
      const { preferred_end_time } = this.parent;
      const start = moment(value);

      return moment(preferred_end_time).isAfter(start);
    }),
    frequency_in_weeks: Yup.number()
      .required(notBlank)
      .min(1, toShort + ' 1')
      .max(56, toLong + ' 56'),

    preferred_end_time: Yup.date().test('error,', 'Končni čas mora biti večji od začetnega', function (value) {
      const { preferred_start_time } = this.parent;
      const endTime = moment(value);
      const latestTime = moment('06:00 pm', 'HH:mm a');

      const chosenStartTime = moment(preferred_start_time);
      return chosenStartTime.isBefore(endTime);
      // const chosenStartTimeInMinutes = chosenStartTime.hours() * 60 + chosenStartTime.minutes();
      // const chosenEndTimeInMinutes = endTime.hours() * 60 + endTime.minutes();
      // const latestTimeInMinutes = latestTime.hours() * 60 + endTime.minutes();
      // return chosenEndTimeInMinutes < latestTimeInMinutes && chosenEndTimeInMinutes - chosenStartTimeInMinutes > 120;
    })
  });
};

class CreateRepeatingOrder extends React.Component {
  state = {
    selectedOption: { value: '', label: '' },
    query: '',
    selectLoading: false,
    locations: null,
    startDate: moment()
  };

  async componentDidMount() {
    // if(this.props.order && this.props.order.id) {
    //     await this.setState({
    //         selectedOption:{value: this.props.client.id ,label:this.props.client.short_name}
    //     })
    // }
    await this.fetchData();
    if (this.props.repeatingOrder && this.props.repeatingOrder.location) {
      await this.setState({
        selectedOption: {
          value: this.props.repeatingOrder.location.id,
          label: this.props.repeatingOrder.location.short_name
        }
      });
    }
  }

  handleInputChange(query) {
    if (!query && !this.state.query) {
      return;
    }
    // eslint-disable-next-line eqeqeq
    if (query != this.state.query) {
      this.setState(
        {
          query
        },
        this.fetchData
      );
    }
  }

  handleSelectChange = async (selectedOption, formik, id, stateFunction) => {
    await formik.setFieldValue(id, selectedOption.value, true);
    await stateFunction();
  };

  handleDateChange = async (date, label, formik) => {
    await formik.setFieldValue(label, date, true);
  };

  isStartDateValid = (current) => {
    const min = moment().subtract(1, 'days');
    return current.isAfter(min);
  };

  async fetchData() {
    try {
      await this.setState({ selectLoading: true });
      const response = await apiClient.get(`/location?search=${this.state.query}`);
      const locations = response.data.results.map((data) => {
        let id = data.id;
        return { value: id, label: data.short_name };
      });
      await this.setState({ locations });
      await this.setState({ selectLoading: false });
    } catch (e) {
      await handleDefaultError(e, this.props);
    }
  }



  render() {
    const translatedWeekDays = weekDays.map((entry) => {
      return { value: entry.value - 1, label: this.props.translate(entry.label) };
    });

    console.log(this.props.barrelTypes)
    const repeatingOrder = this.props.repeatingOrder;
    const defaultWeekDay =
      translatedWeekDays.find((weekday) => weekday.value == repeatingOrder?.weekday) ?? translatedWeekDays[0];
    return (
      <div>
        <h1>{this.props.title}</h1>

        <Formik
          initialValues={{
            weekday: repeatingOrder && repeatingOrder.weekday ? repeatingOrder.weekday : 0,
            location_id: repeatingOrder && repeatingOrder.location ? repeatingOrder.location.id : '',
            order_status_id: 1,
            start_date:
              repeatingOrder && repeatingOrder.start_date ? moment(repeatingOrder.start_date, 'YYYY-MM-DD') : moment(),
            is_active: repeatingOrder ? repeatingOrder.is_active : true,
            preferred_start_time:
              repeatingOrder && repeatingOrder.preferred_start_time
                ? moment(repeatingOrder.preferred_start_time, 'HH:mm')
                : moment(),
            preferred_end_time:
              repeatingOrder && repeatingOrder.preferred_end_time
                ? moment(repeatingOrder.preferred_end_time, 'HH:mm')
                : moment().add(121, 'minutes'),
            // ordered_barrels: repeatingOrder && repeatingOrder.ordered_barrels ? repeatingOrder.ordered_barrels : 1,
            frequency_in_weeks:
              repeatingOrder && repeatingOrder.frequency_in_weeks ? repeatingOrder.frequency_in_weeks : 1,
            repeating_ordered_barrels: repeatingOrder && repeatingOrder.repeating_ordered_barrels ?
                this.props.barrelTypes.map((c) => {
                      const spec_el = this.props.repeatingOrder.repeating_ordered_barrels.filter((el) => el.barrel_type === c.id)[0]
                      if(spec_el){
                        return (
                            { barrel_type: c.id, number: spec_el?.number || 0, id: spec_el?.pk }
                        )
                      } else {
                        return (
                            { barrel_type: c.id, number: 0 }
                        )
                      }
                    }
                )
                // this.props.barrelTypes.map((c) => ({ barrel_type: c.id, number: (repeatingOrder.repeating_ordered_barrels.filter((el) => el.barrel_type === c.id)[0]?.number || 0) }))
                : this.props.barrelTypes.map((c) => ({ barrel_type: c.id, number: 0 }))
          }}
          validationSchema={orderSchema(this.props.translate)}
          onSubmit={async (values) => {
            let { preferred_start_time, preferred_end_time, start_date, ...rest } = values;

            preferred_start_time = preferred_start_time.format('HH:mm:ss');
            preferred_end_time = preferred_end_time.format('HH:mm:ss');
            if (start_date) {
              start_date = start_date.format('YYYY-MM-DD');
            }

            const req = { preferred_start_time, preferred_end_time, start_date, ...rest };

            const post = async () => {
              const response = await apiClient.post(`/repeating_order`, req);
              notificationService.success('Successfully created repeating_order with id:' + response.data.id);
            };
            const patch = async () => {
              const response = await apiClient.patch(`/repeating_order/${repeatingOrder.id}`, req);
              notificationService.success('Successfully updated repeating_order with id:' + response.data.id);
            };

            const execReq = () => (repeatingOrder ? patch() : post());

            let doToggle = true;
            try {
              await this.props.startLoading();
              await execReq();
            } catch (e) {
              await handleDefaultError(e, this.props);
              doToggle = !(e.response && e.response.data && e.response.data.Message);
            }

            await this.props.stopLoading();
            await this.props.refresh(this.props.currentPage);
            if (doToggle) {
              await this.props.toggle();
            }

            await this.props.clearEditingOrder();
            await this.props.stopLoading();
          }}
        >
          {(formik) => (
            <Form>
              <FormEffect
                formik={formik}
                onSubmissionError={(e) => {
                  return notificationService.error(this.props.translate('error_form'));
                }}
              />

              <Row form className="justify-content-between">
                <Col md={10}>
                  <FormGroup>
                    <Label for="location_id">{this.props.translate('locations_single')}</Label>
                    {this.state.locations && (
                      <FormSelect
                        formik={formik}
                        name={'location_id'}
                        isDisabled={!!repeatingOrder}
                        isClearable={false}
                        isLoading={this.state.selectLoading}
                        value={this.state.selectedOption}
                        options={this.state.locations}
                        onInputChange={(e) => this.handleInputChange(e)}
                        onChange={(e) =>
                          this.handleSelectChange(e, formik, 'location_id', async () =>
                            this.setState({ selectedOption: e })
                          )
                        }
                      />
                    )}
                  </FormGroup>
                </Col>
                <Col md={2} className="mt-2 text-center">
                  <Label for="is_active">{this.props.translate('order_repeating_active')}</Label>
                  <Field
                    name="is_active"
                    type="checkbox"
                    className="pl-1"
                    checked={formik.values.is_active}
                    size="2.3"
                    component={FormCheckbox}
                  />
                </Col>
              </Row>

              <Row form>
                <Col lg={6} md={12}>
                  <FormGroup>
                    <Label for="weekday">{this.props.translate('weekday_title')}</Label>
                    <FormSelect
                      formik={formik}
                      name="weekday"
                      defaultValue={[defaultWeekDay]}
                      options={translatedWeekDays}
                      onChange={(e) => this.handleSelectChange(e, formik, 'weekday', () => null)}
                    />
                  </FormGroup>
                </Col>
                <Col lg={6} md={12}>
                  <FormGroup>
                    <Label style={{ fontSize: '80%' }} className="w-100" for="frequency_in_weeks">
                      {this.props.translate('frequency_in_weeks')}
                    </Label>
                    <Field className="w-100" name="frequency_in_weeks" type={'number'} component={FormInput} />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg={6} md={12}>
                  <FormGroup>
                    <Label for="preferred_start_time">{this.props.translate('order_preferred_start_time')}</Label>
                    <KotoDateTime
                      value={formik.values['preferred_start_time']}
                      formik={formik}
                      dateFormat={false}
                      name={'preferred_start_time'}
                      isValidDate={this.isStartDateValid}
                      onChange={(e) => this.handleDateChange(e, 'preferred_start_time', formik)}
                    />
                  </FormGroup>
                </Col>
                <Col lg={6} md={12}>
                  <FormGroup>
                    <Label for="preferred_end_time">{this.props.translate('order_preferred_end_time')}</Label>
                    <KotoDateTime
                      value={formik.values['preferred_end_time']}
                      formik={formik}
                      dateFormat={false}
                      name={'preferred_end_time'}
                      isValidDate={this.isStartDateValid}
                      onChange={(e) => this.handleDateChange(e, 'preferred_end_time', formik)}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <FieldArray
                  name="repeating_ordered_barrels"
                  render={(arrayHelpers) => (
                      <div>
                        <Row noGutters>
                          <Col>
                            <h6 className="m-0 p-0">Tip embalaže</h6>
                          </Col>
                          <Col>
                            <h6 className="m-0 p-0">Količina</h6>
                          </Col>
                        </Row>
                        <hr />

                        {formik.values.repeating_ordered_barrels && formik.values.repeating_ordered_barrels.length > 0 ? (
                            formik.values.repeating_ordered_barrels.map((barrelAmount, index) => {
                              const barrelType = this.props.barrelTypes?.find((pt) => pt.id === barrelAmount.barrel_type);
                              return (
                                  <InputGroup className={'py-1'} key={index}>
                                    <InputGroupAddon className={'w-50'} addonType="prepend">
                                      <InputGroupText
                                          style={{
                                            backgroundColor: '#11aa11', // barrelType?.color,
                                            width: '100%',
                                            color: 'white'
                                          }}
                                      >
                                        {barrelType?.screen_name}
                                      </InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                        min={0}
                                        type={'number'}
                                        step="1"
                                        value={barrelAmount.number}
                                        onChange={(e) => {
                                          // console.log(e.target.value)
                                          // console.log(arrayHelpers)
                                          arrayHelpers.replace(index, {
                                            ...barrelAmount,
                                            number: e.target.value
                                          });
                                        }}
                                    />
                                  </InputGroup>
                              );
                            })
                        ) : (
                            <div />
                        )}
                        {/*<FormConfirmCancel onCancel={toggle} />*/}
                      </div>
                  )}
              />
              {/*<Row>*/}
              {/*  <Col md={6}>*/}
              {/*    <FormGroup>*/}
              {/*      <Label for="ordered_barrels">Naročeno število sodov</Label>*/}
              {/*      <Field name="ordered_barrels" min={1} type={'number'} component={FormInput} />*/}
              {/*    </FormGroup>*/}
              {/*  </Col>*/}
              {/*</Row>*/}
              <Row noGutters>
                <Col md={3} className="font-weight-bold px-2">
                  Velja od
                  <KotoDateTime
                    value={formik.values.start_date}
                    timeFormat={false}
                    onChange={(e) => formik.setFieldValue('start_date', e)}
                  />
                </Col>
              </Row>
              <Row noGutters>
                <Card className="small p-2 my-2">
                  <div>
                    Ponavljajoče naročilo bo veljalo od izbranega datuma v polju <strong>Velja od</strong>.
                  </div>
                  <br />
                  <div>
                    Če ponavljajoče naročilo (narediš neaktivnega/popraviš/izbrišeš) bo to vplivalo na vsa naročila, ki
                    so bila narejena preko tega ponavljajočega naročila in so v prihodnosti in v statusu{' '}
                    <strong className="font-weight-bolder flex-nowrap d-inline">Naročeno</strong>.
                  </div>
                  <br />
                  <div>
                    Če ponavljajoče naročilo izbrišeš/narediš neaktivnega, se izbrišejo vsa prihodnja naročila tega
                    ponavljajočega naročila, ki so v statusu <strong>Naročeno</strong>.
                  </div>
                  <br />
                  <div>
                    Če ponavljajoče naročilo spremeniš, se bodo ustrezno popravila vsa prihodnja naročila tega
                    ponavljajočega naročila, ki so v statusu <strong>Naročeno</strong>.
                  </div>
                  <br />
                  <div>
                    Če kljub temu želiš popraviti uro naročila ali pa število naročenih kosov embalaže za neko naročilo, ki je že
                    v optimizaciji ali pa je že optimizirano, potem lahko to popraviš na naročilu samem. Optimizator v
                    tem primeru teh stvari <strong>NE</strong> bo upošteval, razen če se optimizacija za dani datum
                    potem požene ročno. Prinese pa nova optimizacija lahko kakšne druge posledice (recimo, če so
                    SMSi/emaili že bili poslani, potem ure v teh obvestilih najbrž ne bodo več prave) zato je to početje
                    nezaželjeno.
                  </div>

                  {/*Če ponavljajoče naročilo izbrišeš/narediš neaktivnega, se izbrišejo vsa prihodnja naročila tega ponavljajočega naročila, ki so v statusu *Naročeno**/}
                  {/*Če ponavljajoče naročilo spremeniš, se bodo ustrezno popravila vsa prihodnja naročila tega ponavljajočega naročila, ki so v statusu *Naročeno*.*/}

                  {/*Če kljub temu želiš popraviti uro naročila ali pa število naročenih sodov za neko naročilo, ki je že v optimizaciji ali pa je že optimizirano, potem lahko to popraviš na naročilu samem. Optimizator v tem primeru teh stvari *NE* bo upošteval, razen če se optimizacija za dani datum potem požene ročno. Prinese pa nova optimizacija lahko kakšne druge posledice (recimo, če so SMSi/emaili že bili poslani, potem ure v teh obvestilih najbrž ne bodo več prave) zato je to početje nezaželjeno.*/}
                </Card>
              </Row>
              <FormConfirmCancel
                onCancel={() => {
                  this.props.clearEditingOrder();
                  this.props.toggle();
                }}
              />
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

export default withI18n(withAuthConsumer(withGlobalConsumer(CreateRepeatingOrder)));
