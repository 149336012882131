import React from 'react';
import Row from 'reactstrap/es/Row';

const ICON = `M20.2,15.7L20.2,15.7c1.1-1.6,1.8-3.6,1.8-5.7c0-5.6-4.5-10-10-10S2,4.5,2,10c0,2,0.6,3.9,1.6,5.4c0,0.1,0.1,0.2,0.2,0.3
  c0,0,0.1,0.1,0.1,0.2c0.2,0.3,0.4,0.6,0.7,0.9c2.6,3.1,7.4,7.6,7.4,7.6s4.8-4.5,7.4-7.5c0.2-0.3,0.5-0.6,0.7-0.9
  C20.1,15.8,20.2,15.8,20.2,15.7z`;

const SinglePin = (props) => {
  return (
    <div style={{ padding: '5px' }}>
      <Row className="ml-2">
        <div style={props.boxStyle}>
          <svg
            height={20}
            viewBox="0 0 24 24"
            style={{
              cursor: 'pointer',
              fill: props.pinColor,
              stroke: 'none'
            }}
          >
            <path d={ICON}></path>
            <text
              fontSize="15"
              fill={props.pinFontColor}
              fontFamily="Verdana"
              textAnchor="middle"
              alignmentBaseline="baseline"
              x="12"
              y="16.4"
            >
              {8}
            </text>
          </svg>
        </div>
        <div className="ml-2" style={{ alignSelf: 'center' }}>
          <i> {props.description}</i>
        </div>
      </Row>
    </div>
  );
};

const MapLegend = (props) => {
  return (
    <div style={{ flexDirection: 'row', backgroundColor: '#D8B69F', borderRadius: '10px', padding: '10px' }}>
      <h5>Legenda:</h5>
      <SinglePin pinColor="yellow" pinFontColor="black" description="... Začetna/končna oziroma lokacija za postanek" />
      <SinglePin pinColor="#ff751a" pinFontColor="white" description="... Lokacija pobiranja" />
      <SinglePin
        pinColor="#ff751a"
        pinFontColor="white"
        description="... Brez možnosti podaljšanja časa optimizatorja"
        boxStyle={{ borderColor: 'red', borderStyle: 'solid', borderWidth: '3px', borderRadius: '5px' }}
      />
      <SinglePin
        pinColor="#ff751a"
        pinFontColor="white"
        description="... Voznik bo na terenu izven željenega časa"
        boxStyle={{ backgroundColor: 'white', borderRadius: '5px' }}
      />
    </div>
  );
};

export default MapLegend;
