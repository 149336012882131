import { notificationService } from '../components/notifications/NotificationService';

export async function handleDefaultError(e, props) {
  if (e.response && e.response.data && e.response.data.Message) {
    if (e.response.data.si) {
      notificationService.error(JSON.stringify(e.response.data.si));
      return;
    } else {
      notificationService.error(JSON.stringify(e.response.data.Message));
      return;
    }
  }
  if (e?.response?.data && e?.response?.status !== 401) {
    const responseData = e?.response?.data;
    if (typeof responseData === 'object') {
      const keys = Object.keys(responseData);
      if (keys && keys.length > 0) {
        notificationService.error(JSON.stringify(responseData[keys[0]]));
        return;
      }
    }
  }

  if (typeof e.response === 'undefined') {
    notificationService.error(props?.translate('network_error'));
    if (props && props.stopLoading) {
      props.stopLoading();
    }

    return;
  }
  if (e.response.status === 401) {
    if (props && props.logout) {
      props.logout();
    }
  } else {
    notificationService.error(props?.translate('unknown_error'));
  }
}
