import React, { useContext, useReducer } from 'react';

import EN from './translations/en.json';
import SI from './translations/si.json';
import { useLocalStorage } from '../utils/hooks/useLocalStorage';
import momentTimezone from 'moment-timezone';

const languageData = {
  en: {
    translation: EN,
    locale: 'en'
  },
  si: {
    translation: SI,
    locale: 'sl'
  }
};

export const enabledTranslations = Object.keys(languageData);

const getTranslate = (langCode) => (key) => languageData[langCode].translation[key] || key;
const getLocale = (langCode) => languageData[langCode].locale || 'si';

export const defaultLanguage = 'si';
export const defaultTimeZone = 'Europe/Budapest';
momentTimezone.tz.setDefault(defaultTimeZone);

function initialState(lang) {
  return {
    langCode: lang,
    locale: getLocale(lang),
    translate: getTranslate(lang),
    timeZone: defaultTimeZone
  };
}

export const I18nContext = React.createContext(initialState(defaultLanguage));

export const I18nContextProvider = ({ children }) => {
  const [lang, setLang] = useLocalStorage('lang', defaultLanguage);
  const reducer = (state, action) => {
    switch (action.type) {
      case 'setLanguage':
        setLang(action.payload);
        return {
          langCode: action.payload,
          locale: getLocale(action.payload),
          translate: getTranslate(action.payload),
          timeZone: defaultTimeZone
        };
      default:
        return { ...initialState(lang) };
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState(lang));

  return <I18nContext.Provider value={{ ...state, dispatch }}>{children}</I18nContext.Provider>;
};

export function getTranslation(key) {
  let lang = localStorage.getItem('lang');
  if (lang == null) {
    lang = defaultLanguage;
  } else {
    lang = JSON.parse(lang);
  }
  return languageData[lang].translation[key];
}

export default function withI18n(Component) {
  return function ComponentWithI18n(props) {
    const { translate, locale } = useContext(I18nContext);

    return <Component {...props} translate={translate} locale={locale} />;
  };
}
