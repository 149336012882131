import withI18n, { defaultTimeZone } from '../../i18n';
import PropTypes from 'prop-types';

import moment from 'moment';
import momentTimezone from 'moment-timezone';

const KotoDate = ({ locale, timeZone, date, format }) => {
  if (!timeZone) {
    timeZone = defaultTimeZone;
  }
  let tds = momentTimezone.tz(date, timeZone);
  let momentObject = moment(tds).locale(locale);
  return momentObject.format(format);
};

export default withI18n(KotoDate);

KotoDate.propTypes = {
  date: PropTypes.string.isRequired
};
