import { AuthContext } from '../api/auth/AuthContext';
import KotoNavbar from '../components/navbar/KotoNavbar';
import React, { Fragment, useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';
import KotoModal from '../components/common/KotoModal';
import ChangePassword from '../components/settings/ChangePassword';
import { I18nContext } from '../i18n';
import { NotFound } from './404';

export function PrivateRoute({ component: Component, path, ...rest }) {
  const { isAuth, authProfile } = useContext(AuthContext);
  const { translate } = useContext(I18nContext);

  function doIt(props) {
    if (authProfile?.type === 300) {
      if (path !== '/driver_routes') {
        return <Component {...props} />;
      } else if (path.startsWith('/statistics')) {
        return <Component {...props} />;
      }
    }
    return <NotFound {...props} />;
  }

  const handleRenderForUsers = (props) => {
    if (authProfile?.type === 150) {
      if (path === '/driver_routes') {
        return <Component {...props} />;
      } else {
        return <NotFound {...props} />;
      }
    }
    if (authProfile?.type === 300) {
      if (path === '/driver_routes' || path.startsWith('/statistics')) {
        return <Component {...props} />;
      } else {
        return <NotFound {...props} />;
      }
    }
    return <Component {...props} />;
  };
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuth ? (
          <Fragment>
            {authProfile && path === '/' && (authProfile?.type === 300 || authProfile?.type === 150) && (
              <Redirect
                to={{
                  pathname: '/driver_routes',
                  state: { from: props.location }
                }}
              />
            )}
            {authProfile && path === '/' && (authProfile?.type !== 300 || authProfile?.type !== 150) && (
              <Redirect
                to={{
                  pathname: '/orders',
                  state: { from: props.location }
                }}
              />
            )}

            <KotoNavbar />
            {authProfile && (
              <KotoModal title={translate('change_password_title')} isOpen={authProfile.is_new}>
                <ChangePassword withCancel={false} {...props} />
              </KotoModal>
            )}
            {handleRenderForUsers(props)}
          </Fragment>
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
}
