import React from 'react';
import { Row, Col } from 'reactstrap';
import GroupButton from './GroupButton';
import apiClient from '../../../api/ApiClient';
import moment from 'moment';
import Button from 'reactstrap/es/Button';
import CreatePredictionGroupModal from './CreateModal';
import UpdatePredictionGroupModal from './UpdateModal';
import DeletePredictionGroupModal from './DeleteModal';
import GroupDetail from './GroupDetail';
import { notificationService } from '../../notifications/NotificationService';

const PredictionGroupLayout = (props) => {
  const [selectedGroup, setSelectedGroup] = React.useState(undefined);
  const [groups, setGroups] = React.useState([]);
  const [selectedLocations, setSelectedLocations] = React.useState([]);
  const [groupDetail, setGroupDetail] = React.useState(undefined);
  const [statisticsCalculation, setStatisticsCalculation] = React.useState(undefined);
  const [createModalOpen, setCreateModalOpen] = React.useState(false);
  const [updateModalOpen, setUpdateModalOpen] = React.useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);
  const [viewType, setViewType] = React.useState('locations');

  const fetchGroups = async () => {
    try {
      const response = await apiClient.get(`/prediction_groups`);
      setGroups(response.data);
    } catch (e) {
      notificationService.error(JSON.stringify(e.response?.data));
    }
  };

  const fetchGroup = async () => {
    try {
      const response = await apiClient.get(`/prediction_groups/${selectedGroup.id}`);
      setGroupDetail(response.data);
    } catch (e) {
      notificationService.error(JSON.stringify(e.response?.data));
    }
  };

  const refetchData = async () => {
    await fetchGroups();
  };

  const submitCreateGroup = async (values) => {
    try {
      let data = {
        ...values,
        period_start_date: values.period_start_date?.format('YYYY-MM-DD'),
        period_end_date: values.period_end_date?.format('YYYY-MM-DD')
      };

      await apiClient.post('/prediction_groups', data);
      notificationService.success('Skupina uspešno ustvarjena!');
      setCreateModalOpen(false);
      refetchData();
    } catch (e) {
      notificationService.error(JSON.stringify(e.response?.data));
    }
  };

  const submitUpdateGroup = async (values) => {
    try {
      let data = {
        ...values,
        period_start_date: values.period_start_date?.format('YYYY-MM-DD') ?? null,
        period_end_date: values.period_end_date?.format('YYYY-MM-DD') ?? null
      };

      await apiClient.put(`/prediction_groups/${groupDetail.id}`, data);
      notificationService.success('Skupina uspešno posodobljena!');
      setUpdateModalOpen(false);
      refetchData();
    } catch (e) {
      notificationService.error(JSON.stringify(e.response?.data));
    }
  };

  const submitDeleteGroup = async () => {
    try {
      await apiClient.delete(`/prediction_groups/${groupDetail.id}`);
      notificationService.success('Skupina uspešno izbrisana!');
      setSelectedGroup(null);
      setDeleteModalOpen(false);
      refetchData();
    } catch (e) {
      notificationService.error(JSON.stringify(e.response?.data));
    }
  };

  const submitRiskChange = async (risk_factor) => {
    try {
      await apiClient.post(`/prediction_groups/${groupDetail.id}/change_risk_factor`, { risk_factor });
      notificationService.success('Tveganje uspešno spremenjeno!');
      refetchData();
    } catch (e) {
      notificationService.error(JSON.stringify(e.response?.data));
    }
  };

  const fetchStatisticsCalculation = async () => {
    try {
      const response = await apiClient.get(`/settings/predictions`);
      setStatisticsCalculation(response.data);
    } catch (e) {
      notificationService.error(JSON.stringify(e.response?.data));
    }
  };

  const initiateCalculateStatistics = async () => {
    try {
      await apiClient.post(`/prediction_groups/calculate_statistics`);
      fetchStatisticsCalculation();
    } catch (e) {
      notificationService.error(JSON.stringify(e.response?.data));
    }
  };
  React.useEffect(() => {
    fetchGroups();
    fetchStatisticsCalculation();
  }, []);

  React.useEffect(() => {
    const interval = setInterval(() => {
      fetchStatisticsCalculation();
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  React.useEffect(() => {
    if (selectedGroup) {
      fetchGroup();
    }
  }, [selectedGroup]);

  React.useEffect(() => {
    if (groups.length > 0 && !selectedGroup) {
      setSelectedGroup(groups[0]);
    } else {
      fetchGroup();
    }
  }, [groups, selectedGroup]);

  React.useEffect(() => {
    setSelectedLocations(
      groupDetail?.locations.map((location) => ({ value: location.id, label: location.short_name }))
    );
  }, [groupDetail]);

  return (
    <>
      <Row style={{ maxWidth: '100%' }}>
        <Col md={3} style={{ minWidth: 400 }} className="m-2">
          <div className="ml-2">
            <b>Zadnji izračun statistike:</b> <br />{' '}
            <i>
              {statisticsCalculation && moment(statisticsCalculation.last_statistics_calculation_time).format('LLL')}
            </i>
          </div>
          <div className="ml-2">
            <b>Naslednji izračun statistike:</b> <br />{' '}
            {statisticsCalculation?.is_calculating_statistics ? (
              <i>V teku</i>
            ) : (
              <i>{statisticsCalculation && moment(statisticsCalculation.next_statistics_calculation).format('LLL')}</i>
            )}
          </div>
          <Button className="mt-3 ml-2" color="primary" onClick={() => initiateCalculateStatistics()}>
            Izračunaj statistiko
          </Button>
          <Button className="mt-3 ml-2" color="primary" onClick={() => setCreateModalOpen(true)}>
            Ustvari novo skupino
          </Button>
          <hr />
          <div className="m-2" onChange={(e) => setViewType(e.target.value)}>
            <b>Pogled: </b>
            <input
              type="radio"
              value="locations"
              name="locations"
              className="m-2"
              checked={viewType === 'locations'}
            />{' '}
            Lokacije
            <input type="radio" value="risk" name="risk" className="m-2" checked={viewType === 'risk'} /> Interaktivno
            določi tveganje
          </div>
          <hr />
          <div className="mt-3">
            {groups.map((group) => (
              <GroupButton
                key={group.id}
                group={group}
                selected={group.id === selectedGroup?.id}
                setSelectedGroup={setSelectedGroup}
              />
            ))}
          </div>
        </Col>
        <Col className="m-2" style={{ flexGrow: 1 }}>
          {groupDetail ? (
            <GroupDetail
              group={groupDetail}
              refetch={refetchData}
              selectedLocations={selectedLocations}
              setSelectedLocations={setSelectedLocations}
              setUpdateModalOpen={setUpdateModalOpen}
              setDeleteModalOpen={setDeleteModalOpen}
              viewType={viewType}
              submitRiskChange={submitRiskChange}
            />
          ) : (
            <></>
          )}
        </Col>
      </Row>
      <CreatePredictionGroupModal
        isOpen={createModalOpen}
        toggle={() => setCreateModalOpen(!createModalOpen)}
        onCreate={submitCreateGroup}
      />
      {groupDetail && (
        <UpdatePredictionGroupModal
          isOpen={updateModalOpen}
          toggle={() => setUpdateModalOpen(!updateModalOpen)}
          onUpdate={submitUpdateGroup}
          group={groupDetail}
        />
      )}
      {groupDetail && (
        <DeletePredictionGroupModal
          isOpen={deleteModalOpen}
          toggle={() => setDeleteModalOpen(!deleteModalOpen)}
          onDelete={submitDeleteGroup}
          group={groupDetail}
        />
      )}
    </>
  );
};

export default PredictionGroupLayout;
