import React, { useState, useEffect } from 'react';
import withAuthConsumer from '../../../api/auth/AuthContext';
import withGlobalConsumer from '../../../global/GlobalProvider';
import { ConfigSettingsContainer } from './ConfigSettingsContainer';
import { Row, Col } from 'reactstrap';
import apiClient from '../../../api/ApiClient';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import KotoDateTime from '../../common/KotoDateTime';
import KotoDate from '../../common/KotoDate';
import KotoTable from '../../common/KotoTable';
import moment from 'moment';
import { handleDefaultError } from '../../../utils/response-handler';

const ComparePrediction = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [stats, setStats] = useState([]);
  const [date, setDate] = useState(moment());

  async function fetchStats() {
    try {
      const url = `compare_estimate_accuracy?date=${date.format('YYYY-MM-DD')}`;
      const response = await apiClient.get(url);
      setStats(response.data.data);
      setIsLoading(false);
    } catch (e) {
      handleDefaultError('Nekaj se je zalomilo ...');
    }
  }

  useEffect(() => {
    setIsLoading(true);
    fetchStats();
  }, [date]);

  const statColumns = [
    {
      Header: 'Datum',
      Cell: (row) => {
        //console.log(row, row.original.date);
        return <KotoDate format={'LL'} date={row.original.date} />;
      }
    },
    {
      Header: 'Zaključenih naročil',
      accessor: 'finished_orders'
    },
    {
      Header: 'Vseh naročil',
      accessor: 'all_orders'
    },
    {
      Header: 'Dejanska pobrana teža',
      accessor: 'collected'
    },
    {
      Header: 'Ocenjena teža',
      accessor: 'estimate',
      getProps: (state, rowInfo, column) => {
        let color;
        if (rowInfo && rowInfo.row.estimate < 0.9 * rowInfo.row.collected) {
          color = '#ffb294';
        } else if (rowInfo && rowInfo.row.estimate > 1.1 * rowInfo.row.collected) {
          color = '#a6fa7f';
        } else if (
          rowInfo &&
          0.9 * rowInfo.row.collected < rowInfo.row.estimate &&
          rowInfo.row.estimate < rowInfo.row.collected
        ) {
          color = '#fff2ed';
        } else if (
          rowInfo &&
          1.1 * rowInfo.row.collected > rowInfo.row.estimate &&
          rowInfo.row.estimate > rowInfo.row.collected
        ) {
          color = '#ecffe3';
        }
        return {
          style: {
            background: color
          }
        };
      }
    }
  ];

  return (
    <ConfigSettingsContainer>
      <Row className="justify-content-between">
        <Col>
          <h4 className="p-2 m-2">Primerjaj uspešnost napovedi kg</h4>
        </Col>
      </Row>
      <Row className="ml-2">
        <Col md={2}>
          Obdobje
          <KotoDateTime value={date} timeFormat={false} onChange={(e) => setDate(e)} />
        </Col>
      </Row>
      <div className="alert alert-warning m-2 mt-3">
        V tej tabeli lahko interaktivno vidimo, kako dobra bi bila naša ocena kilogramov glede na pobrano število
        kilogramov prejšnih dni. Tu so upoštevane nastavitve tveganj posamezne skupine lokacij iz menija "Napovedne
        skupine". <br />
        V obdobju lahko nastavimo za katero obdobje bi radi gledali primerjavo. V tabeli bomo videli podatke za obdobje
        20 dni pred izbranim datumom. <br />
        <ul>
          <li>Temno rdeča - ocenjeni kilogrami so več kot 10% nižji kot pobrani kilogrami </li>
          <li>Svetlo rdeča - ocenjeni kilogrami so nižji kot pobrani, a odstopajo za manj kot 10%</li>
          <li>Svetlo zelena - ocenjeni kilogrami so višji kot pobrani, a odstopajo za manj kot 10%</li>
          <li>Temno zelena - ocenjeni kilogrami so več kot 10% višji kot pobrani kilogrami</li>
        </ul>
      </div>
      <Row
        style={{ filter: isLoading ? 'blur(4px)' : 'blur(0)', width: '100%', maxWidth: '100%' }}
        className="m-2 mt-4"
      >
        <Col md={12}>
          <KotoTable data={stats} columns={statColumns} />
        </Col>
      </Row>
    </ConfigSettingsContainer>
  );
};

export default withGlobalConsumer(withAuthConsumer(ComparePrediction));
