import React, { useContext, useState } from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle, Nav, NavItem, NavLink } from 'reactstrap';
import Dropdown from 'reactstrap/es/Dropdown';
import { AuthContext } from '../../api/auth/AuthContext';
import { FaSignOutAlt } from 'react-icons/fa';
import Row from 'reactstrap/es/Row';
import { Link, withRouter } from 'react-router-dom';
import { MdLock, MdSettings } from 'react-icons/md';
import { I18nContext } from '../../i18n';
import KotoModal from '../common/KotoModal';
import ChangePassword from '../settings/ChangePassword';
import { ProviderSelect } from './ProviderSelect';

const NavFilteredItem = ({ bannedTypes = [], children, ...props }) => {
  const { isAuth, authProfile } = useContext(AuthContext);
  if (bannedTypes.includes(authProfile?.type)) {
    return null;
  }
  return <NavItem {...props}>{children}</NavItem>;
};

const KotoNavbar = ({ location, ...props }) => {
  const [settingsToggle, setSettingsToggle] = useState(false);
  const { logout, authProfile } = useContext(AuthContext);
  const { translate } = useContext(I18nContext);
  const [passwordToggle, setPasswordToggle] = useState(false);

  return (
    <div>
      <KotoModal
        title={translate('change_password_title')}
        isOpen={passwordToggle}
        toggle={() => setPasswordToggle(!passwordToggle)}
      >
        <ChangePassword withCancel={true} toggle={() => setPasswordToggle(!passwordToggle)} {...props} />
      </KotoModal>
      <Nav tabs className="justify-content-end">
        <Nav light="true" expand="md" className="d-inline-flex">
          <div style={{ alignSelf: 'center', width: '300px' }}>
            <ProviderSelect />
          </div>
          <Dropdown direction="down" inNavbar isOpen={settingsToggle} toggle={() => setSettingsToggle(!settingsToggle)}>
            <DropdownToggle nav className="text-dark">
              {authProfile ? authProfile.first_name : ''}{' '}
              <MdSettings style={{ marginBottom: '3px', marginRight: '5px' }} />
            </DropdownToggle>
            <DropdownMenu flip right>
              <DropdownItem onClick={() => setPasswordToggle(!passwordToggle)}>
                <MdLock style={{ marginBottom: '3px', marginRight: '5px' }} />
                {translate('change_password')}
              </DropdownItem>
              <DropdownItem onClick={logout}>
                <FaSignOutAlt style={{ marginBottom: '3px', marginRight: '5px' }} onClick={logout} />
                {translate('logout_button')}
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </Nav>
      </Nav>
      <Nav tabs>
        <div className="justify-content-start d-inline-flex">
          <Row noGutters>
            {/*<Col>*/}
            {/*    <NavItem>*/}
            {/*        <NavLink className="text-nowrap" tag={Link} to="/dashboard" active={location.pathname.startsWith("/dashboard")}>{translate("page_dashboard_title")}</NavLink>*/}
            {/*    </NavItem>*/}
            {/*</Col>*/}

            <NavFilteredItem bannedTypes={[300, 200, 150]}>
              <NavLink className="text-nowrap" tag={Link} to="/orders" active={location.pathname.startsWith('/orders')}>
                {translate('page_orders_title')}
              </NavLink>
            </NavFilteredItem>

            <NavFilteredItem bannedTypes={[300, 200, 150]}>
              <NavLink className="text-nowrap" tag={Link} to="/depots" active={location.pathname.startsWith('/depots')}>
                Skladišča
              </NavLink>
            </NavFilteredItem>

            <NavFilteredItem bannedTypes={[300, 150]}>
              <NavLink
                className="text-nowrap"
                tag={Link}
                to="/clients"
                active={location.pathname.startsWith('/clients')}
              >
                {translate('page_clients_title')}
              </NavLink>
            </NavFilteredItem>

            <NavFilteredItem bannedTypes={[300, 150]}>
              <NavLink className="text-nowrap" tag={Link} to="/users" active={location.pathname.startsWith('/users')}>
                {translate('page_users_title')}
              </NavLink>
            </NavFilteredItem>

            <NavFilteredItem bannedTypes={[300, 150]}>
              <NavLink
                className="text-nowrap"
                tag={Link}
                to="/locations"
                active={location.pathname.startsWith('/locations')}
              >
                {translate('page_locations_title')}
              </NavLink>
            </NavFilteredItem>

            <NavFilteredItem bannedTypes={[300, 150]}>
              <NavLink className="text-nowrap" tag={Link} to="/pools" active={location.pathname.startsWith('/pools')}>
                Bazeni lokacij
              </NavLink>
            </NavFilteredItem>

            <NavFilteredItem bannedTypes={[300, 150]}>
              <NavLink className="text-nowrap" tag={Link} to="/groups" active={location.pathname.startsWith('/groups')}>
                Skupine lokacij
              </NavLink>
            </NavFilteredItem>

            <NavFilteredItem bannedTypes={[300, 200, 150]}>
              <NavLink
                className="text-nowrap"
                tag={Link}
                to="/prediction-groups"
                active={location.pathname.startsWith('/prediction-groups')}
              >
                Napovedne skupine
              </NavLink>
            </NavFilteredItem>

            <NavFilteredItem bannedTypes={[300, 150]}>
              <NavLink
                className="text-nowrap"
                tag={Link}
                to="/vehicles"
                active={location.pathname.startsWith('/vehicles')}
              >
                Vozila
              </NavLink>
            </NavFilteredItem>
            <NavFilteredItem bannedTypes={[]}>
              <NavLink
                className="text-nowrap"
                tag={Link}
                to="/driver_routes"
                active={location.pathname.startsWith('/driver_routes')}
              >
                Voznikove poti
              </NavLink>
            </NavFilteredItem>
            <NavFilteredItem bannedTypes={[150]}>
              <NavLink
                className="text-nowrap"
                tag={Link}
                to="/statistics/general"
                active={location.pathname.startsWith('/statistics')}
              >
                Statistika
              </NavLink>
            </NavFilteredItem>

            <NavFilteredItem bannedTypes={[300, 150]}>
              <NavLink
                className="text-nowrap"
                tag={Link}
                to="/configs/orders"
                active={location.pathname.startsWith('/configs')}
              >
                {translate('page_configs_title')}
              </NavLink>
            </NavFilteredItem>
          </Row>
        </div>
      </Nav>
    </div>
  );
};
export default withRouter(KotoNavbar);
