import React, { Fragment } from 'react';
import withI18n from '../../i18n';
import FormConfirmCancel from './FormConfirmCancel';
import Col from 'reactstrap/es/Col';

const KotoPrompt = ({ title, onConfirm, onCancel }) => (
  <Fragment>
    <Col>
      <h5>{title}</h5>
    </Col>
    <FormConfirmCancel onCancel={onCancel} onConfirm={onConfirm} />
  </Fragment>
);

export default withI18n(KotoPrompt);
