import moment from 'moment';

export function getFirstGoodOrderDay(date = null) {
  let now;
  if (date == null) {
    now = moment();
  } else {
    now = moment(date);
  }
  let goodDay = now;
  if (now.day() === 5) {
    if (now.hour() >= 15) {
      goodDay = now.add(3, 'days').hour(8);
    }
  }
  if (now.day() === 6) {
    goodDay = now.add(2, 'days').hour(8);
  }
  if (now.day() === 0) {
    goodDay = now.add(1, 'days').hour(8);
  } else {
    if (now.hour() >= 15) {
      goodDay = now.add(1, 'days').hour(8);
    }
  }
  return goodDay;
}
