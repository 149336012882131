import * as React from 'react';
import { Component } from 'react';
import MapGL, { Popup } from 'react-map-gl';
import CityInfo from './CityInfo';
import Pins from './Pins';


var polyline = require('@mapbox/polyline');

export default class MapBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewport: {
        latitude: 46.23946,
        longitude: 14.995751,
        zoom: 7.3,
        bearing: 0,
        pitch: 0
      },
      popupInfo: null
    };
  }

  _updateViewport = (viewport) => {
    this.setState({ viewport });
  };

  _onClickMarker = (city) => {
    this.setState({ popupInfo: city });
  };

  _renderPopup() {
    const { popupInfo } = this.state;

    return (
      popupInfo && (
        <Popup
          tipSize={5}
          anchor="top"
          longitude={popupInfo.longitude}
          latitude={popupInfo.latitude}
          closeOnClick={false}
          onClose={() => this.setState({ popupInfo: null })}
        >
          <CityInfo info={popupInfo} />
        </Popup>
      )
    );
  }

  render() {
    const { viewport } = this.state;
    return (
      <MapGL
        {...viewport}
        width="100%"
        height="80vh"
        mapStyle="mapbox://styles/mapbox/dark-v9"
        onViewportChange={this._updateViewport}
        mapboxApiAccessToken={'pk.eyJ1IjoibmVqY3IiLCJhIjoiY2p2dTJmbTdkMGg2NDQzcGhsNHNia2FvdiJ9.EVm6QKmjdJXC80o4HReGuQ'}
      >
        {/* TODO: fix polyline in be and then in fe */}
        {/* {this.props.driverRoutes.map((driverRoute, index) => {
          if (driverRoute?.has_driver_orders) {
            const poly = this.props.short ? driverRoute.short_polyline : driverRoute.long_polyline;
            const points = polyline.decode(poly);
            return <PolylineOverlay points={points} color={driverRoute?.color} />;
          }
          return null;
        })} */}

        {this.props.driverRoutes.map((driverRoute, index) => {
          if (Array.isArray(driverRoute.driver_orders)) {
            return (
              <Pins
                data={driverRoute.driver_orders}
                color={driverRoute?.color}
                onClick={this._onClickMarker}
                detailedPin={this.props.showDetailedPins}
              />
            );
          }
          return null;
        })}

        {this._renderPopup()}
      </MapGL>
    );
  }
}
