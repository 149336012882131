import React from 'react';
import { Row, Col, NavLink, Modal, ModalBody, ModalHeader } from 'reactstrap';
import KotoTable from '../../../common/KotoTable';
import { Link } from 'react-router-dom';
import Button from 'reactstrap/es/Button';
import { FilterHorizontal } from './filterHorizontal';
import apiClient from '../../../../api/ApiClient';
import { handleDefaultError } from '../../../../utils/response-handler';
import moment from 'moment';
import InteractiveCompareRisk from '../InteractiveCompare';

const locationColumns = [
  {
    Header: 'ime',
    accessor: 'short_name',
    Cell: (row) => (
      <NavLink className="pb-0 pt-0 text-left" to={`/locations/${row.original.id}`} tag={Link}>
        {row.original.short_name}
      </NavLink>
    )
  },
  {
    Header: 'naročil',
    accessor: 'completed_orders_period'
  },
  {
    Header: 'avg. kg',
    accessor: 'period_collected_weight_average'
  },
  {
    Header: 'std. kg',
    accessor: 'period_collected_weight_std'
  },
  {
    Header: 'ocena kg',
    accessor: 'period_weight_prediction'
  }
];

const GroupDetail = ({
  group,
  refetch,
  selectedLocations,
  setSelectedLocations,
  setUpdateModalOpen,
  setDeleteModalOpen,
  submitRiskChange,
  viewType,
  ...props
}) => {
  const [addLocationsModalOpen, setAddLocationsModalOpen] = React.useState(false);

  const submitLocations = async () => {
    try {
      const locations = selectedLocations.map((location) => location.value);
      await apiClient.post(`/prediction_groups/${group.id}/add_locations`, { locations });
      setAddLocationsModalOpen(false);
      refetch();
    } catch (e) {
      handleDefaultError(e);
    }
  };

  return (
    <div className="mt-3">
      <Row className="justify-content-between">
        <Col style={{ flexGrow: 1 }}>
          <h3>
            <i>{group?.name}</i>
          </h3>
        </Col>
        <Col>
          <Row className="justify-content-end">
            <Button className="mr-1 ml-1" color="primary" onClick={() => setAddLocationsModalOpen(true)}>
              Dodaj lokacije
            </Button>
            <Button className="mr-1 ml-1" color="primary" onClick={() => setUpdateModalOpen(true)}>
              Spremeni
            </Button>
            <Button className="mr-1 ml-1" color="danger" onClick={() => setDeleteModalOpen(true)}>
              Izbriši
            </Button>
          </Row>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <span className="mr-3">
            <b>Začetek statističnega obdobja:</b>
          </span>
          {group?.period_start_date ? moment(group.period_start_date).format('LL') : 'Od začetka'}
        </Col>
        <Col>
          <span className="mr-3">
            <b>Konec statističnega obdobja:</b>
          </span>
          {group?.period_end_date ? moment(group.period_end_date).format('LL') : 'Do danes'}
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <span className="mr-3">
            <b>Faktor tveganja:</b>
          </span>
          <span
            style={{
              fontWeight: 700,
              fontSize: 18,
              color: group?.risk_factor <= 50 ? 'green' : 'red'
            }}
          >
            {group?.risk_factor}
          </span>
        </Col>
      </Row>

      <Modal
        style={{ maxWidth: '1900px', width: '80%' }}
        isOpen={addLocationsModalOpen}
        toggle={() => setAddLocationsModalOpen(false)}
      >
        <ModalBody>
          <ModalHeader className="border-0 p-0 mt-3"></ModalHeader>
          <FilterHorizontal selectedLocations={selectedLocations} setSelectedLocations={setSelectedLocations} />
          <Row noGutters className="justify-content-end pt-3">
            <Button color={'primary'} onClick={() => submitLocations()}>
              Potrdi
            </Button>

            <Button className={'ml-2'} color={'danger'} onClick={() => setAddLocationsModalOpen(false)}>
              Prekliči
            </Button>
          </Row>
        </ModalBody>
      </Modal>

      {viewType === 'locations' && (
        <>
          {group?.locations && (
            <KotoTable
              data={group.locations}
              defaultPageSize={20}
              minRows={20}
              showPagination={true}
              columns={locationColumns}
            />
          )}{' '}
        </>
      )}
      {viewType === 'risk' && (
        <InteractiveCompareRisk onSubmit={submitRiskChange} initialRisk={group.risk_factor} groupId={group.id} />
      )}
    </div>
  );
};

export default GroupDetail;
