import React, { Fragment, useEffect, useState } from 'react';
import Select from 'react-select';
import withI18n from '../i18n';
import apiClient from '../api/ApiClient';

const UsersSearch = ({ currentUsers, handleSelectChange, placeholder, value }) => {
  const [users, setUsers] = useState([]);
  const [userQuery, setUserQuery] = useState('');

  function doFetch() {
    return apiClient
      .get(`/user?starts_with=${userQuery}`)
      .then((response) => {
        setUsers(response.data.results);
      })
      .catch((error) => console.log(error));
  }

  function handleFetch(e) {
    setUserQuery(e);
    doFetch();
  }

  useEffect(() => {
    doFetch();
  }, []);

  const options = users
    .filter((u) => !currentUsers.includes(u.uid))
    .map((user) => ({
      value: user.uid,
      label: user.email
    }));
  return (
    <Fragment>
      <Select
        className="koto-zIndex-888"
        placeholder={placeholder ? placeholder : 'Izberi uporabnika'}
        isClearable={true}
        value={value}
        options={options}
        onInputChange={handleFetch}
        onChange={handleSelectChange}
      />
    </Fragment>
  );
};

export default withI18n(UsersSearch);
