import React, { Component } from 'react';
import { Button, Col, Container, Form, FormFeedback, FormGroup, FormText, Input, Label } from 'reactstrap';
import './Login.css';
import Row from 'reactstrap/es/Row';
import withAuthConsumer from '../../api/auth/AuthContext';
import withI18n from '../../i18n';
import withGlobalConsumer from '../../global/GlobalProvider';
import { authService } from '../../api/auth/AuthService';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      validate: {
        emailState: ''
      }
    };
    this.handleChange = this.handleChange.bind(this);
  }

  validateEmail(e) {
    const emailRex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const { validate } = this.state;
    if (emailRex.test(e.target.value)) {
      validate.emailState = 'has-success';
    } else {
      validate.emailState = 'has-danger';
    }
    this.setState({ validate });
  }

  handleChange = async (event) => {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    await this.setState({
      [name]: value
    });
  };

  async submitForm(e) {
    e.preventDefault();
    this.props.startLoading();
    let success = await this.props.login(this.state.email, this.state.password);

    this.props.stopLoading();
    await this.props.initAsyncState();
    const authProfile = await authService.getProfile();
    if (success) {
      if (authProfile.type === 300 || authProfile.type === 150) {
        return await this.props.history.push('/driver_routes');
      } else {
        return await this.props.history.push('/orders');
      }
    }
    return;
  }

  render() {
    const { email, password } = this.state;

    return (
      <Row className="no-gutters">
        <Col sm="12" md={{ size: 6, offset: 3 }} className="my-4">
          <Container fluid className="Login">
            <Form className="form" onSubmit={(e) => this.submitForm(e)}>
              <Col>
                <h2>{this.props.translate('login_label')}</h2>
                <FormGroup>
                  <Label>{this.props.translate('login_email')}</Label>
                  <Input
                    type="email"
                    name="email"
                    id="exampleEmail"
                    placeholder="myemail@email.com"
                    value={email}
                    valid={this.state.validate.emailState === 'has-success'}
                    invalid={this.state.validate.emailState === 'has-danger'}
                    onChange={(e) => {
                      this.validateEmail(e);
                      this.handleChange(e);
                    }}
                  />

                  {this.state.email !== '' && <FormFeedback>{this.props.translate('login_label_error')}</FormFeedback>}
                  {this.state.email === '' && <FormText>{this.props.translate('login_label_info')}</FormText>}
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label for="examplePassword">{this.props.translate('login_password')}</Label>
                  <Input
                    type="password"
                    name="password"
                    id="examplePassword"
                    value={password}
                    onChange={(e) => this.handleChange(e)}
                  />
                </FormGroup>
                <Button>{this.props.translate('login_button')}</Button>
              </Col>
            </Form>
          </Container>
        </Col>
      </Row>
    );
  }
}

export default withI18n(withAuthConsumer(withGlobalConsumer(Login)));
