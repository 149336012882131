import { AuthContext } from '../api/auth/AuthContext';
import React, { Fragment, useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';

export function PublicRoute({ component: Component, ...rest }) {
  const { isAuth } = useContext(AuthContext);

  return (
    <Route
      {...rest}
      render={(props) =>
        !isAuth ? (
          <Fragment>
            <Component {...props} />
          </Fragment>
        ) : (
          <Redirect
            to={{
              pathname: '/orders',
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
}
