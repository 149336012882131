import ButtonGroup from 'reactstrap/es/ButtonGroup';
import Button from 'reactstrap/es/Button';
import React, { useState } from 'react';
import NavItem from 'reactstrap/es/NavItem';
import Nav from 'reactstrap/es/Nav';
import moment from 'moment';

const getTableHeader = (viewType) => {
  switch (viewType) {
    case 'year':
      return 'Leto';
    case 'month':
      return 'Mesec';
    case 'day':
      return 'Dan';
    case 'weekday':
      return 'Dan v tednu';
    case 'location':
      return 'Lokacija';
    default:
      return 'Header';
  }
};

function handleSpecialDateFormat(data) {
  switch (data) {
    case 1:
      return 'Nedelja';
    case 2:
      return 'Ponedeljek';
    case 3:
      return 'Torek';
    case 4:
      return 'Sreda';
    case 5:
      return 'Četrtek';
    case 6:
      return 'Petek';
    case 7:
      return 'Sobota';
  }
}

const formatTableSelect = (row, viewType) => {
  let suffix = viewType;
  if (viewType === 'weekday') {
    suffix = 'week_day';
  }
  const accessor = 'preferred_start_time__' + suffix;
  switch (viewType) {
    case 'year':
      return <div>{row[accessor]}</div>;
    case 'month':
      if (row[accessor]) {
        return (
          <div>
            {moment()
              .month(row[accessor] - 1)
              .format('MMMM')}
          </div>
        ); // February</div>
      }
      return <div />;

    case 'day':
      return <div>{row[accessor]}</div>;
    case 'weekday':
      if (row[accessor]) {
        return <div>{handleSpecialDateFormat(row[accessor])}</div>;
      }
      return <div />;

    case 'location':
      return <div>Leto</div>;
    default:
      return 'Header';
  }
};
const ChartSelector = ({ viewType, setViewType }) => {
  return (
    <ButtonGroup tag={Nav} pills className={'py-2'} size="md">
      <Button tag={NavItem} active={viewType === 'year'} color={'primary'} onClick={() => setViewType('year')}>
        Letno
      </Button>
      <Button tag={NavItem} active={viewType === 'month'} color={'primary'} onClick={() => setViewType('month')}>
        Po mesecih
      </Button>
      <Button tag={NavItem} active={viewType === 'day'} color={'primary'} onClick={() => setViewType('day')}>
        Po dnevih
      </Button>
      <Button tag={NavItem} active={viewType === 'weekday'} color={'primary'} onClick={() => setViewType('weekday')}>
        Po dnevu tedna
      </Button>
      <Button tag={NavItem} active={viewType === 'location'} color={'primary'} onClick={() => setViewType('location')}>
        Po lokacijah
      </Button>

      <Button tag={NavItem} active={viewType === 'drivers'} color={'primary'} onClick={() => setViewType('drivers')}>
        Po voznikih
      </Button>
      <Button tag={NavItem} active={viewType === 'depots'} color={'primary'} onClick={() => setViewType('depots')}>
        Skladišča
      </Button>
    </ButtonGroup>
  );
};

export const useChartSelector = (defaultViewType = 'year') => {
  const [viewType, setViewType] = useState(defaultViewType);
  let tableSelect = viewType;
  if (viewType === 'weekday') {
    tableSelect = 'week_day';
  }
  return {
    viewType,
    tableSelectHeader: getTableHeader(viewType),
    tableSelect: (row) => formatTableSelect(row, viewType),
    selector: <ChartSelector viewType={viewType} setViewType={setViewType} />
  };
};
