import React, { Component } from 'react';

import withI18n from '../../../i18n';
import withAuthConsumer from '../../../api/auth/AuthContext';
import withGlobalConsumer from '../../../global/GlobalProvider';
import Button from 'reactstrap/es/Button';
import KotoTable from '../../common/KotoTable';
import { Col, NavLink } from 'reactstrap';
import KotoPagination from '../../common/KotoPagination';
import KotoContainer from '../KotoContainer';
import { Link } from 'react-router-dom';
import apiClient from '../../../api/ApiClient';
import { KotoBadge } from '../../common/KotoBadge';
import { handleDefaultError } from '../../../utils/response-handler';
import KotoDate from '../../common/KotoDate';
import KotoModal from '../../common/KotoModal';
import CreateClient from './CreateClient';
import * as queryString from 'query-string';
import _ from 'lodash';
import { SearchBar } from '../../SearchBar';
import { getFromStorage } from '../../../utils/utils';

const columns = (translate) => {
  return [
    {
      Header: 'Id',
      accessor: 'id',
      width: 50,
      Cell: (row) => (
        <NavLink className="pt-0 pb-0" to={`/clients/${row.original.id}`} tag={Link}>
          {row.original.id}
        </NavLink>
      )
    },

    {
      Header: translate('short_name'),
      width: 250,
      accessor: 'short_name'
    },

    {
      Header: translate('largo_client_code'),
      accessor: 'largo_client_code'
    },
    // {
    //     Header: translate("oko_kilo_to_euros"),
    //     accessor: "oko_kilo_to_euros"
    // },
    // {
    //     Header: translate("ojo_kilo_to_euros"),
    //     accessor: "ojo_kilo_to_euros"
    // },
    //
    // {
    //     Header: translate("oko_barrel_to_euros"),
    //     accessor: "oko_barrel_to_euros"
    // },
    // {
    //     Header: translate("ojo_barrel_to_euros"),
    //     accessor: "ojo_barrel_to_euros"
    // },
    {
      Header: 'ZOL',
      accessor: 'zol_turnover_type'
    },
    {
      Header: translate('litres_to_kilos_conversion_rate'),
      accessor: 'litres_to_kilos_conversion_rate'
    },

    {
      Header: 'Odštevam težo embalaže',
      accessor: 'subtract_barrel_weight',
      Cell: (row) => (
        <KotoBadge
          status={row.original.subtract_barrel_weight}
          text={row.original.subtract_barrel_weight ? translate('boolean_true') : translate('boolean_false')}
        />
      )
    },

    {
      Header: translate('client_added'),
      id: 'added_timestamp',
      Cell: (row) => row.original.added_timestamp && <KotoDate format={'LLLL'} date={row.original.added_timestamp} />
    },

    {
      Header: translate('client_active'),
      Cell: (row) => (
        <KotoBadge
          status={row.original.active}
          text={row.original.active ? translate('boolean_true') : translate('boolean_false')}
        />
      )
    }
  ];
};

class Clients extends Component {
  state = {
    createOpen: false,
    clients: [],
    count: 1,
    pageSize: 20,
    searchValue: getFromStorage('clients.search', ''),
    searchKey: 'clients.search',
    activePage: 1,
    url: '/client'
  };

  async componentWillMount() {
    let params = queryString.parse(this.props.location.search);
    let activePage = parseInt(params.activePage);

    if (params.activePage && _.isInteger(activePage)) {
      await this.setState({ activePage: activePage });
    }
    await this.fetchClients(this.state.activePage);
  }

  async execSearch(searchValue) {
    await this.setState({ searchValue: searchValue });
    await this.fetchClients(this.state.activePage);
  }

  async execClear() {
    await this.setState({ searchValue: '' });
    await this.fetchClients(this.state.activePage);
  }

  async fetchClients(page) {
    await this.props.startLoading();
    page = page - 1;
    const searchParam =
      this.state.searchValue && this.state.searchValue.length > 0 ? `&search=${this.state.searchValue}` : '';
    try {
      const response = await apiClient.get(
        this.state.url + `?limit=${this.state.pageSize}&offset=${this.state.pageSize * page}${searchParam}`
      );
      await this.setState({
        clients: response.data.results,
        count: response.data.count
      });
    } catch (e) {
      await handleDefaultError(e, this.props);
    }

    await this.props.stopLoading();
  }

  async toggleCreateOpen() {
    await this.setState({ createOpen: !this.state.createOpen });
  }

  handleSelected = async (page) => {
    await this.fetchClients(page);
  };

  render() {
    return (
      <KotoContainer name={this.props.translate('page_clients_title')}>
        <Button color="primary" className="mb-2" onClick={this.toggleCreateOpen.bind(this)}>
          {this.props.translate('button_add_new')}
        </Button>
        <SearchBar
          placeHolder={'Išči stranke'}
          onSearch={this.execSearch.bind(this)}
          saveKey={this.state.searchKey}
          onClear={this.execClear.bind(this)}
          className={'mb-2'}
        />
        <KotoModal
          title={this.props.translate('client_create_new')}
          isOpen={this.state.createOpen}
          toggle={this.toggleCreateOpen.bind(this)}
        >
          <CreateClient toggle={this.toggleCreateOpen.bind(this)} refresh={this.fetchClients.bind(this)} />
        </KotoModal>
        <KotoTable
          columns={columns(this.props.translate)}
          data={this.state.clients}
          defaultPageSize={this.state.pageSize}
          minRows={this.state.pageSize}
        />

        <Col sm="12" md={{ size: 6, offset: 3 }}>
          <KotoPagination
            totalItems={this.state.count}
            pageSize={this.state.pageSize}
            activePage={this.state.activePage}
            onSelect={this.handleSelected.bind(this)}
          />
        </Col>
      </KotoContainer>
    );
  }
}

export default withI18n(withAuthConsumer(withGlobalConsumer(Clients)));
