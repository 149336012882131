import React, { Component } from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import PropTypes from 'prop-types';
import './KotoPaginationStyle.css';
import withI18n from '../../i18n';
import { withRouter } from 'react-router-dom';
import withWindowWidth from '../../utils/hooks/useWindowSize';

class KotoPagination extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: this.props.activePage <= 0 ? 1 : this.props.activePage,
      firstPaginationNumber: 1
    };
    this.pages = this.getNumberOfPages(this.props);
  }

  componentWillReceiveProps(props) {
    this.pages = this.getNumberOfPages(props);
    let fp = this.getNumberOfPages(props);

    if (this.state.activePage === 1) {
      this.setState({
        firstPaginationNumber: 1
      });
      return this.forceUpdate();
    } else if (this.state.activePage === 2) {
      this.setState({
        firstPaginationNumber: 1
      });
      return this.forceUpdate();
    } else if (this.state.activePage === 3) {
      this.setState({
        firstPaginationNumber: 1
      });
      return this.forceUpdate();
    } else if (this.state.activePage === this.pages) {
      fp = fp - 2;
      this.setState({
        firstPaginationNumber: fp
      });
      return this.forceUpdate();
    } else if (this.state.activePage === this.pages - 1) {
      fp = fp - 3;
      this.setState({
        firstPaginationNumber: fp
      });
      return this.forceUpdate();
    } else {
      this.setState({
        firstPaginationNumber: this.state.activePage - 1
      });
      return this.forceUpdate();
    }
  }

  getNumberOfPages = (props) => {
    const auxPages = props.totalItems / props.pageSize;
    let pages = parseInt(auxPages, 10);
    pages += pages !== auxPages ? 1 : 0;
    return pages;
  };

  paginationItems = () => {
    const small = this.props.size.width <= 758;

    let items = [];
    this.lastPaginationNumber = this.getLastPaginationNumber();
    items.push(this.firstOrLastPagItem(small ? '<<' : this.props.translate('pagination_first'), 1));
    items.push(this.nextOrPreviousPagItem(small ? '<' : this.props.translate('pagination_previous'), 1, 'l'));
    for (var i = this.state.firstPaginationNumber; i <= this.lastPaginationNumber; i++) {
      items.push(this.numberedPagItem(i));
    }
    items.push(this.nextOrPreviousPagItem(small ? '>' : this.props.translate('pagination_next'), this.pages, 'r'));
    items.push(this.firstOrLastPagItem(small ? '>>' : this.props.translate('pagination_last'), this.pages));
    return items;
  };

  getLastPaginationNumber = () => {
    const minNumberPages = Math.min(this.pages, this.props.maxPaginationNumbers);
    return this.state.firstPaginationNumber + minNumberPages - 1;
  };

  numberedPagItem = (i) => {
    return (
      <PaginationItem key={i} id={`pagebutton${i}`} active={this.state.activePage === i} onClick={this.handleClick}>
        <PaginationLink style={{ minWidth: '43.5px' }}>{i}</PaginationLink>
      </PaginationItem>
    );
  };

  nextOrPreviousPagItem = (name, page, direction) => {
    return (
      <PaginationItem
        key={name}
        disabled={this.state.activePage === page}
        onClick={(e) => this.handleSelectNextOrPrevious(direction)}
      >
        <PaginationLink>{name}</PaginationLink>
      </PaginationItem>
    );
  };

  firstOrLastPagItem = (name, page) => {
    let event = {
      currentTarget: {
        getAttribute: () => `pagebutton${page}`
      }
    };
    const disabled = this.state.activePage === page;
    return (
      <PaginationItem key={name} disabled={disabled} onClick={() => this.handleClick(event, disabled)}>
        <PaginationLink>{name}</PaginationLink>
      </PaginationItem>
    );
  };

  handleClick = (event, disabled) => {
    if (!disabled) {
      const newActivePage = parseInt(event.currentTarget.getAttribute('id').split('pagebutton').pop(), 10);
      this.props.onSelect(newActivePage);
      this.props.history.push({ search: `?activePage=${newActivePage}` });
      this.setState({
        activePage: newActivePage
      });
      this.handlePaginationNumber(newActivePage);
    }
  };

  handleSelectNextOrPrevious = (direction) => {
    const activePage = this.state.activePage;
    if ((direction === 'r' && activePage === this.pages) || (direction === 'l' && activePage === 1)) return;

    const newActivePage = direction === 'r' ? activePage + 1 : activePage - 1;

    this.setState({
      activePage: newActivePage
    });

    this.handlePaginationNumber(newActivePage);
    this.props.onSelect(newActivePage);
    this.props.history.push({ search: `?activePage=${newActivePage}` });
  };

  handlePaginationNumber = (activePage) => {
    const distance = Math.floor(this.props.maxPaginationNumbers / 2);
    const newFPNumber = activePage - distance;
    const newLPNumber = activePage + distance;
    if (newFPNumber <= distance) {
      if (this.state.firstPaginationNumber !== 1) {
        this.setState({
          firstPaginationNumber: 1
        });
      }
    } else if (newLPNumber <= this.pages) {
      this.setState({
        firstPaginationNumber: newFPNumber
      });
    } else if (newLPNumber >= this.pages) {
      this.setState({
        firstPaginationNumber: this.pages - this.props.maxPaginationNumbers + 1
      });
    }
  };

  render() {
    return (
      <div className="pt-4">
        <Pagination>{this.paginationItems()}</Pagination>
      </div>
    );
  }
}

KotoPagination.propTypes = {
  totalItems: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  onSelect: PropTypes.func.isRequired,
  maxPaginationNumbers: PropTypes.number,
  activePage: PropTypes.number
};

KotoPagination.defaultProps = {
  maxPaginationNumbers: 3,
  activePage: 1
};

export default withI18n(withRouter(withWindowWidth(KotoPagination)));
